import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import {
    COLOR_BG_ROW_DELETED,
    MAX_WIDTH_CENTRAL_CONTAINER,
    parsePathForEntityID,
    parsePathForEntityUUID,
    pbUUIDToUuid,
    uuidToPbUUID,
} from "../utils/utils"
import { useLocation, useHistory, Link } from "react-router-dom"
import {
    Button,
    Col,
    Empty,
    Input,
    Popconfirm,
    Row,
    Spin,
    Tag,
    Tooltip,
    Typography,
    message as antdMessage,
} from "antd"
import {
    EResultTag,
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import Container from "../components/commons-ts/container"
import { Helmet } from "react-helmet"
import { ErrorAlert } from "../components/commons-ts/errorAlert"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import { Translated, translated } from "../utils/translated"
import {
    normTypeElement,
    methodElement,
    isLabTestElement,
    sportsMatTypeDescriptionStr,
} from "../components/commons-ts/tags"
import UploadImage from "../components/commons-ts/UploadImage"
import {
    ArrowRightOutlined,
    CheckCircleOutlined,
    DeleteFilled,
    DeleteOutlined,
    DoubleRightOutlined,
    SaveOutlined,
    VerticalAlignBottomOutlined,
} from "@ant-design/icons"
import Table, { ColumnsType } from "antd/es/table"
import { SliderButton } from "./Impact/SliderButton"

import {
    IImpactKinematics,
    getCFH_Global,
    getImpactKinematics,
    getImpactResultPFAdq,
    getImpactResultSM,
    getZoneResultPF_CFH,
} from "../utils/maths"

import {
    Equipment,
    Impact,
    MatTypeEN12503,
    MethodTypeEN1177,
    NormType,
    Site,
    UUID,
    Zone,
} from "../generated/proto-ts/main"
import {
    useUsercommDeleteImpactBLE,
    useUsercommDeleteZoneBLE,
    useUsercommDeleteZoneSoftBLE,
    useUsercommEquipmentBLE,
    useUsercommSetImpactParentBLE,
    useUsercommSiteBLE,
    useUsercommUpdateZoneBLE,
    useUsercommZoneBLE,
    useUsercommZoneImpactsBLE,
} from "../usercomm/usercommAsyncRequestBLE"
import { useUsercommContextBLE } from "../usercomm/usercommProviderBLE"
import { ZoneChartPF_CFH } from "../components/commons-ts/charts"
import { UploadImageListV2 } from "../components/commons-ts/UploadImageV2AD"
import { DataTreeDrawer } from "./TreeDrawer"

export const ZoneImpactsTableSM: FC<{
    impacts: Impact[] | null
    equipment: Equipment | null
    currentImpactUUID?: string | null
    onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, equipment, currentImpactUUID, onDeleteImpact }) => {
    const memoSportsMatType = useMemo((): MatTypeEN12503 | null => {
        if (equipment === null) {
            return null
        }
        return equipment.sports_mat_type
    }, [equipment])

    const memoSportsMatThicknessMean = useMemo((): number | null => {
        if (equipment === null) {
            return null
        }
        let thicknesses: number[] = []
        if (equipment.sports_mat_thickness_side_one !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_one)
        }
        if (equipment.sports_mat_thickness_side_two !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_two)
        }
        if (equipment.sports_mat_thickness_side_three !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_three)
        }
        if (equipment.sports_mat_thickness_side_four !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_four)
        }
        let mean = thicknesses.reduce((a, b) => a + b, 0) / thicknesses.length
        let std = Math.sqrt(
            thicknesses
                .map((x) => Math.pow(x - mean, 2))
                .reduce((a, b) => a + b, 0) / thicknesses.length,
        )
        if (isNaN(mean) || isNaN(std)) {
            return null
        }
        return mean
    }, [equipment])

    const memoImpactKinematicsMap = useMemo((): Record<
        string,
        IImpactKinematics
    > | null => {
        if (impacts === null) {
            return null
        }
        let impactKinematicsMap: Record<string, IImpactKinematics> = {}
        for (let impact of impacts) {
            let impactKinematics = getImpactKinematics(
                impact,
                memoSportsMatThicknessMean,
            )
            if (impactKinematics === null) {
                continue
            }
            impactKinematicsMap[pbUUIDToUuid(impact.uuid)] = impactKinematics
        }
        return impactKinematicsMap
    }, [impacts, memoSportsMatThicknessMean])

    const columns: ColumnsType<Impact> = useMemo(() => {
        if (impacts === null) {
            return []
        }
        let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
        return [
            {
                title: "#",
                render: (_, impact) => {
                    return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
                },
                // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
            },
            {
                key: "ffh",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Free Fall Height, m" />}
                    >
                        <div>
                            <Translated keyEn="Height, m" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_ffh === 0) {
                        return null
                    }
                    return <span>{impact.impact_ffh.toFixed(2)}</span>
                },
                sorter: (a, b) => a.impact_ffh - b.impact_ffh,
                defaultSortOrder: "ascend",
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, impact) => {
                    if (memoImpactKinematicsMap === null) {
                        return null
                    }
                    let impactKinematics =
                        memoImpactKinematicsMap[pbUUIDToUuid(impact.uuid)]
                    if (impactKinematics === undefined) {
                        return null
                    }
                    if (impactKinematics.calculatedGmax === null) {
                        return "N/A"
                    } else {
                        return (
                            <span>
                                {impactKinematics.calculatedGmax.toFixed(1)}
                            </span>
                        )
                    }
                },
                sorter: (a, b) => a.impact_gmax - b.impact_gmax,
            },
            {
                key: "deflectionDistance",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Deflection Distance" />}
                    >
                        <div>
                            <Translated keyEn="Def. mm" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (memoImpactKinematicsMap === null) {
                        return null
                    }
                    let impactKinematics =
                        memoImpactKinematicsMap[pbUUIDToUuid(impact.uuid)]
                    if (impactKinematics === undefined) {
                        return null
                    }
                    if (impactKinematics.deflectionDistanceMM === null) {
                        return "N/A"
                    } else {
                        return (
                            <span>
                                {impactKinematics.deflectionDistanceMM.toFixed(
                                    1,
                                )}
                            </span>
                        )
                    }
                },
            },
            {
                key: "deflectionPercentage",
                title: (
                    <Tooltip overlay={<Translated keyEn="Deflection %" />}>
                        <div>
                            <Translated keyEn="Def. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (memoImpactKinematicsMap === null) {
                        return null
                    }
                    let impactKinematics =
                        memoImpactKinematicsMap[pbUUIDToUuid(impact.uuid)]
                    if (impactKinematics === undefined) {
                        return null
                    }
                    if (impactKinematics.deflectionDistancePerc === null) {
                        return "N/A"
                    } else {
                        return (
                            <span>
                                {impactKinematics.deflectionDistancePerc.toFixed(
                                    0,
                                )}
                            </span>
                        )
                    }
                },
            },
            {
                key: "resilience",
                title: (
                    <Tooltip overlay={<Translated keyEn="Resilience, %" />}>
                        <div>
                            <Translated keyEn="Res. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (memoImpactKinematicsMap === null) {
                        return null
                    }
                    let impactKinematics =
                        memoImpactKinematicsMap[pbUUIDToUuid(impact.uuid)]
                    if (impactKinematics === undefined) {
                        return null
                    }
                    if (impactKinematics.resiliencePerc === null) {
                        return "N/A"
                    } else {
                        return (
                            <span>
                                {impactKinematics.resiliencePerc.toFixed(0)}
                            </span>
                        )
                    }
                },
            },
            {
                key: "timestamp",
                title: <Translated keyEn="Date" />,
                render: (_, impact) => {
                    if (impact.created_at === 0) {
                        return null
                    }
                    return new Date(impact.created_at).toLocaleString()
                },
                sorter: (a, b) => a.created_at - b.created_at,
                defaultSortOrder: "descend",
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, impact) => {
                    if (equipment === null) {
                        return null
                    }
                    let result = getImpactResultSM(
                        impact,
                        memoSportsMatType,
                        memoSportsMatThicknessMean,
                    )
                    return <EResultTag result={result} />
                },
            },
            {
                title: "",
                width: "2rem",
                render: (_, impact) => {
                    return (
                        <FlexRow style={{ gap: 0 }}>
                            {onDeleteImpact !== undefined && (
                                <Popconfirm
                                    title={
                                        <>
                                            <Translated keyEn="Are you sure you want to delete this impact" />
                                            ?
                                        </>
                                    }
                                    onConfirm={() => {
                                        onDeleteImpact(impact)
                                    }}
                                >
                                    <Button
                                        danger
                                        type="link"
                                        size="small"
                                        icon={<DeleteFilled />}
                                    />
                                </Popconfirm>
                            )}
                            {currentImpactUUID === undefined ||
                            currentImpactUUID !== pbUUIDToUuid(impact.uuid) ? (
                                <Link
                                    to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}
                                >
                                    <Button type="link" size="small">
                                        <DoubleRightOutlined />
                                    </Button>
                                </Link>
                            ) : (
                                <FlexRow
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CheckCircleOutlined />
                                </FlexRow>
                            )}
                        </FlexRow>
                    )
                },
                fixed: "right",
            },
        ]
    }, [impacts, memoSportsMatType, memoImpactKinematicsMap, currentImpactUUID])

    if (memoSportsMatType === null) {
        return null
    }

    return (
        <Table
            dataSource={impacts ?? []}
            loading={impacts === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            style={{
                width: "100%",
            }}
            onRow={(impact) => {
                return {
                    style: {
                        backgroundColor:
                            impact.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                        fontWeight:
                            pbUUIDToUuid(impact.uuid) === currentImpactUUID
                                ? 700
                                : 400,
                    },
                }
            }}
            footer={() => {
                return (
                    <i>
                        <Translated keyEn="Table of impacts: Sports Mat" />{" "}
                        <b>
                            Type {memoSportsMatType}:{" "}
                            {sportsMatTypeDescriptionStr(memoSportsMatType)}
                        </b>
                    </i>
                )
            }}
        />
    )
}

export const ZoneImpactsTablePFAdq: FC<{
    impacts: Impact[] | null
    currentImpactId?: string | null
    onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, currentImpactId, onDeleteImpact }) => {
    const columns: ColumnsType<Impact> = useMemo(() => {
        if (impacts === null) {
            return []
        }
        let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
        return [
            {
                title: "#",
                render: (_, impact) => {
                    return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
                },
                // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
            },
            {
                key: "height",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Free Fall Height, m" />}
                    >
                        <div>
                            <Translated keyEn="Height, m" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_gmax === null) {
                        return null
                    }
                    return <span>{impact.impact_gmax.toFixed(1)}</span>
                },
                sorter: (a, b) => a.impact_ffh - b.impact_ffh,
                defaultSortOrder: "ascend",
            },
            {
                key: "hic",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Head Injury Criterion" />}
                    >
                        <div>
                            <Translated keyEn="HIC" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_hic === null) {
                        return null
                    }
                    return <span>{impact.impact_hic.toFixed(0)}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, impact) => {
                    if (impact.impact_gmax === null) {
                        return null
                    }
                    return <span>{impact.impact_gmax.toFixed(1)}</span>
                },
            },
            {
                key: "timestamp",
                title: <Translated keyEn="Date" />,
                render: (_, impact) => {
                    if (impact.created_at === 0) {
                        return null
                    }
                    return new Date(impact.created_at).toLocaleString()
                },
                sorter: (a, b) => a.created_at - b.created_at,
                defaultSortOrder: "descend",
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, impact) => {
                    let result = getImpactResultPFAdq(impact)
                    return <EResultTag result={result} />
                },
            },
            {
                title: "",
                width: "2rem",
                render: (_, impact) => {
                    return (
                        <FlexRow style={{ gap: 0 }}>
                            {onDeleteImpact !== undefined && (
                                <Popconfirm
                                    title={
                                        <>
                                            <Translated keyEn="Are you sure you want to delete this impact" />
                                            ?
                                        </>
                                    }
                                    onConfirm={() => {
                                        onDeleteImpact(impact)
                                    }}
                                >
                                    <Button
                                        danger
                                        type="link"
                                        size="small"
                                        icon={<DeleteFilled />}
                                    />
                                </Popconfirm>
                            )}
                            {currentImpactId === undefined ||
                            currentImpactId !== pbUUIDToUuid(impact.uuid) ? (
                                <Link
                                    to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}
                                >
                                    <Button type="link" size="small">
                                        <DoubleRightOutlined />
                                    </Button>
                                </Link>
                            ) : (
                                <FlexRow
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CheckCircleOutlined />
                                </FlexRow>
                            )}
                        </FlexRow>
                    )
                },
                fixed: "right",
            },
        ]
    }, [impacts])

    return (
        <Table
            dataSource={impacts ?? []}
            loading={impacts === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            style={{
                width: "100%",
            }}
            onRow={(impact) => {
                return {
                    style: {
                        backgroundColor:
                            impact.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                        fontWeight:
                            pbUUIDToUuid(impact.uuid) === currentImpactId
                                ? 700
                                : 400,
                    },
                }
            }}
            footer={() => {
                return (
                    <i>
                        <Translated keyEn="Table of impacts: Method 2: Adequacy for Playground Floors" />
                    </i>
                )
            }}
        />
    )
}

export const ZoneImpactsTablePF_CFH: FC<{
    impacts: Impact[] | null
    currentImpactId?: string | null
    zone: Zone | null
    onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, currentImpactId, zone, onDeleteImpact }) => {
    const columns: ColumnsType<Impact> = useMemo(() => {
        if (impacts === null) {
            return []
        }
        let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
        return [
            {
                title: "#",
                render: (_, impact) => {
                    return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
                },
                // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
            },
            {
                key: "height",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Free Fall Height, m" />}
                    >
                        <div>
                            <Translated keyEn="Height, m" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_gmax === 0) {
                        return null
                    }
                    return <span>{impact.impact_gmax.toFixed(1)}</span>
                },
                sorter: (a, b) => a.impact_ffh - b.impact_ffh,
                defaultSortOrder: "ascend",
            },
            {
                key: "hic",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Head Injury Criterion" />}
                    >
                        <div>
                            <Translated keyEn="HIC" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_hic === null) {
                        return null
                    }
                    return <span>{impact.impact_hic.toFixed(0)}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, impact) => {
                    if (impact.impact_gmax === 0) {
                        return null
                    }
                    return <span>{impact.impact_gmax.toFixed(1)}</span>
                },
            },
            {
                key: "timestamp",
                title: <Translated keyEn="Date" />,
                render: (_, impact) => {
                    if (impact.created_at === 0) {
                        return null
                    }
                    return new Date(impact.created_at).toLocaleString()
                },
                sorter: (a, b) => a.created_at - b.created_at,
                defaultSortOrder: "descend",
            },
            {
                title: "",
                width: "2rem",
                render: (_, impact) => {
                    return (
                        <FlexRow style={{ gap: 0 }}>
                            {onDeleteImpact !== undefined && (
                                <Popconfirm
                                    title={
                                        <>
                                            <Translated keyEn="Are you sure you want to delete this impact" />
                                            ?
                                        </>
                                    }
                                    onConfirm={() => {
                                        onDeleteImpact(impact)
                                    }}
                                >
                                    <Button
                                        danger
                                        type="link"
                                        size="small"
                                        icon={<DeleteFilled />}
                                    />
                                </Popconfirm>
                            )}
                            {currentImpactId === undefined ||
                            currentImpactId !== pbUUIDToUuid(impact.uuid) ? (
                                <Link
                                    to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}
                                >
                                    <Button type="link" size="small">
                                        <DoubleRightOutlined />
                                    </Button>
                                </Link>
                            ) : (
                                <FlexRow
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CheckCircleOutlined />
                                </FlexRow>
                            )}
                        </FlexRow>
                    )
                },
                fixed: "right",
            },
        ]
    }, [impacts])

    const memoCalculatedCriticalFallHeightStr = useMemo((): string | null => {
        if (impacts === null) {
            return null
        }
        let cfhMeanStd = getCFH_Global(impacts)
        if (cfhMeanStd === null) {
            return null
        }
        return cfhMeanStd.mean.toFixed(1) // meters
    }, [impacts])

    const memoResult = useMemo((): ReactElement => {
        if (zone === null || impacts === null) {
            return <>N/A</>
        }

        let result = getZoneResultPF_CFH(zone, impacts)
        if (result === null) {
            return <Tag>N/A</Tag>
        }
        return <EResultTag result={result.result} />
    }, [zone, impacts])

    return (
        <Table
            dataSource={impacts ?? []}
            loading={impacts === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            style={{
                width: "100%",
            }}
            onRow={(impact) => {
                return {
                    style: {
                        backgroundColor:
                            impact.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                        fontWeight:
                            pbUUIDToUuid(impact.uuid) === currentImpactId
                                ? 700
                                : 400,
                    },
                }
            }}
            footer={() => {
                return (
                    <FlexCol>
                        <span>
                            Result: {memoResult} (CFH ={" "}
                            {memoCalculatedCriticalFallHeightStr}m )
                        </span>
                        <i>
                            <Translated keyEn="Table of impacts: Method 1: Critical Fall Height" />
                        </i>
                    </FlexCol>
                )
            }}
        />
    )
}

export const ZonePage: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const memoZoneUUID = useMemo(() => {
        return parsePathForEntityUUID(location.pathname)
    }, [location.pathname])

    // Equipment
    const [equipment, getEquipment] = useUsercommEquipmentBLE()
    // Equipment fields
    const [equipmentUUID, setEquipmentUUID] = useState<string | null>(null)
    const [equipmentName, setEquipmentName] = useState<string | null>(null)

    // Ste
    const [site, getSite] = useUsercommSiteBLE()
    // Site fields
    const [siteUUID, setSiteUUID] = useState<string | null>(null)
    const [siteName, setSiteName] = useState<string | null>(null)
    const [normType, setNormType] = useState<NormType | null>(null)
    const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
    const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

    // Zone
    const [zone, getZone] = useUsercommZoneBLE()
    const [updatedZoneAck, updateZone] = useUsercommUpdateZoneBLE()
    const [deletedZoneAck, deleteZoneSoft] = useUsercommDeleteZoneSoftBLE()
    // Zone fields
    const [zoneName, setZoneName] = useState<string | null>(null)
    const [zoneFreeFallHeightMetersStr, setZoneFreeFallHeightMetersStr] =
        useState<string | null>(null)
    const [
        zoneGroundThicknessMillimetersStr,
        setZoneGroundThicknessMillimetersStr,
    ] = useState<string | null>(null)
    const [pictures, setPictures] = useState<UUID[]>([])
    const [zoneComment, setZoneComment] = useState<string | null>(null)
    const [zoneTemperature, setZoneTemperature] = useState<number | null>(null)
    const [zoneHumidity, setZoneHumidity] = useState<number | null>(null)

    // Impacts
    const [impacts, getImpacts] = useUsercommZoneImpactsBLE()
    const [setImpactParentAck, setImpactParent] =
        useUsercommSetImpactParentBLE()
    const [deletedImpactAck, deleteImpact] = useUsercommDeleteImpactBLE()
    const [error, setError] = useState<string | null>(null)
    const [releaseResetTrigger, setReleaseResetTrigger] = useState<number>(0)
    const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false)
    const [newImpactUUID, setNewImpactUUID] = useState<string | null>(null)

    const {
        stationSensors,
        hicRawMeasurementConsumable,

        emitDropHIC,
        consumeHICRawMeasurement,
    } = useUsercommContextBLE()

    // Fetch zone
    useEffect(() => {
        if (memoZoneUUID === null) {
            return
        }
        getZone(uuidToPbUUID(memoZoneUUID))
    }, [memoZoneUUID])

    useEffect(() => {
        if (zone === null) {
            return
        }
        console.log(`ZonePage: zone received:`, zone.toObject())
        setZoneName(zone.zone_name)
        let ffhMetres = zone.zone_ffh_max / 100
        setZoneFreeFallHeightMetersStr(ffhMetres.toFixed(2))
        setZoneGroundThicknessMillimetersStr(zone.floor_thickness.toFixed(2))
        setZoneTemperature(zone.zone_temperature)
        setZoneHumidity(zone.zone_humidity)
        setZoneComment(zone.comments)
        setPictures(zone.pictures)
    }, [zone])

    // // Fetch equipment
    useEffect(() => {
        if (zone === null || zone.equipment_uuid === null) {
            return
        }
        getEquipment(zone.equipment_uuid)
    }, [zone])

    useEffect(() => {
        if (equipment === null) {
            return
        }
        console.log(`ZonePage: equipment received:`, equipment.toObject())
        setEquipmentUUID(pbUUIDToUuid(equipment.uuid))
        setEquipmentName(equipment.equipment_name)
    }, [equipment])

    // Fetch site
    useEffect(() => {
        if (equipment === null || equipment.site_uuid === null) {
            return
        }
        getSite(pbUUIDToUuid(equipment.site_uuid))
    }, [equipment])

    useEffect(() => {
        if (site === null) {
            return
        }
        console.log(`ZonePage: site received:`, site.toObject())
        setSiteUUID(pbUUIDToUuid(site.uuid))
        setSiteName(site.site_name)
        setNormType(site.norm_type)
        setMethod(site.method_type_en_1177)
        setIsLabTest(site.is_lab_test)
    }, [site])

    // Fetch impacts
    useEffect(() => {
        if (memoZoneUUID === null || equipment === null || site === null) {
            return
        }
        getImpacts(uuidToPbUUID(memoZoneUUID))
    }, [memoZoneUUID, equipment, site])

    // Impact reception
    useEffect(() => {
        if (hicRawMeasurementConsumable === null) {
            return
        }
        consumeHICRawMeasurement()
        console.log(`Zone: newRecord received:`, hicRawMeasurementConsumable)
        antdMessage.success(<Translated keyEn="Received new impact!" />)
        if (memoZoneUUID === null) {
            return
        }
        setImpactParent(hicRawMeasurementConsumable, uuidToPbUUID(memoZoneUUID))
        setNewImpactUUID(pbUUIDToUuid(hicRawMeasurementConsumable))
    }, [memoZoneUUID, hicRawMeasurementConsumable])

    useEffect(() => {
        if (setImpactParentAck === null || newImpactUUID === null) {
            return
        }
        antdMessage.success(
            <Translated keyEn="New impact was successfully associated to the zone!" />,
        )
        setIsReleaseLoading(false)
        setReleaseResetTrigger(Date.now())
        setTimeout(() => {
            history.push(`/impacts/${newImpactUUID}`)
        }, 1000)
    }, [newImpactUUID, setImpactParentAck])

    // CRUD
    const onDelete = useCallback(async () => {
        if (zone === null) {
            return
        }
        deleteZoneSoft(zone)
    }, [zone])
    useEffect(() => {
        if (deletedZoneAck === null || zone === null) {
            return
        }
        antdMessage.info(<>Zone deleted!</>)
        if (equipmentUUID !== null) {
            setTimeout(() => {
                history.push(`/equipments/${pbUUIDToUuid(zone.equipment_uuid)}`)
            }, 1000)
        } else {
            console.warn(
                `ZonePage: received deletedZoneAck but equipmentUUID is null!`,
            )
        }
    }, [deletedZoneAck, equipmentUUID])

    const onSave = useCallback(async () => {
        if (memoZoneUUID === null || zone === null) {
            return
        }
        let updatedZone = zone.clone()
        let ffhCm: number | null = null
        if (zoneFreeFallHeightMetersStr !== null) {
            ffhCm = Math.floor(parseFloat(zoneFreeFallHeightMetersStr) * 100)
        }
        let groundThicknessMm: number | null = null
        if (zoneGroundThicknessMillimetersStr !== null) {
            groundThicknessMm = Math.floor(
                parseFloat(zoneGroundThicknessMillimetersStr),
            )
        }
        updatedZone.zone_name = zoneName ?? ""
        updatedZone.zone_ffh_max = ffhCm ?? 0
        updatedZone.floor_thickness = groundThicknessMm ?? 0
        updatedZone.comments = zoneComment ?? ""
        updatedZone.zone_temperature = zoneTemperature ?? 0
        updatedZone.zone_humidity = zoneHumidity ?? 0
        updatedZone.pictures = pictures
        updateZone(updatedZone)
    }, [
        memoZoneUUID,
        equipment,
        zoneName,
        zoneFreeFallHeightMetersStr,
        zoneGroundThicknessMillimetersStr,
        pictures,
        zoneComment,
        zoneTemperature,
        zoneHumidity,
    ])
    useEffect(() => {
        if (updatedZoneAck === null || memoZoneUUID === null) {
            return
        }
        antdMessage.info(
            <>
                Zone <b>{zoneName}</b> was successfully updated!
            </>,
        )
        getZone(uuidToPbUUID(memoZoneUUID))
    }, [updatedZoneAck, memoZoneUUID])

    const onDeleteImpact = useCallback(async (impact: Impact | null) => {
        if (impact === null) {
            return
        }
        deleteImpact(impact.uuid)
    }, [])
    useEffect(() => {
        if (deletedImpactAck === null || memoZoneUUID === null) {
            return
        }
        antdMessage.info(<>Impact was successfully deleted!</>)
        getImpacts(uuidToPbUUID(memoZoneUUID))
    }, [deletedImpactAck, memoZoneUUID])

    useEffect(() => {
        if (impacts === null) {
            return
        }
        console.log(
            `ZonePage: impacts received:`,
            impacts.map((i) => i.toObject()),
        )
    }, [impacts])

    const memoNormTypeElement = useMemo(() => {
        return normTypeElement(normType)
    }, [normType])

    const memoMethodElement = useMemo(() => {
        if (normType === null || normType === NormType.EN_12503) {
            return null
        }
        return methodElement(method)
    }, [normType, method])

    const memoIsLabTestElement = useMemo(() => {
        return isLabTestElement(isLabTest)
    }, [isLabTest])

    const memoImpactsTable = useMemo(() => {
        if (normType === null) {
            return null
        }
        if (normType === NormType.EN_12503) {
            return (
                <ZoneImpactsTableSM
                    impacts={impacts}
                    equipment={equipment}
                    currentImpactUUID={newImpactUUID}
                    onDeleteImpact={onDeleteImpact}
                />
            )
        } else if (normType === NormType.EN_1177) {
            if (
                method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
            ) {
                return (
                    <ZoneImpactsTablePF_CFH
                        impacts={impacts}
                        currentImpactId={newImpactUUID}
                        zone={zone}
                        onDeleteImpact={onDeleteImpact}
                    />
                )
            } else if (
                method === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE
            ) {
                return (
                    <ZoneImpactsTablePFAdq
                        impacts={impacts}
                        currentImpactId={newImpactUUID}
                        onDeleteImpact={onDeleteImpact}
                    />
                )
            }
        }
    }, [method, zoneFreeFallHeightMetersStr, impacts, normType, equipment])

    const memoCalculatedCriticalFallHeight = useMemo((): number | null => {
        if (
            impacts === null ||
            method !== MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
        ) {
            return null
        }
        let cfhMeanStd = getCFH_Global(impacts)
        if (cfhMeanStd === null) {
            return null
        }
        return cfhMeanStd.mean // meters
    }, [method, impacts])

    if (zone === null) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <ErrorAlert error={error} />
                <SimplifiedBreadcrumb
                    previousItems={[
                        { href: `/sites/${siteUUID}`, label: siteName },
                        {
                            href: `/equipments/${equipmentUUID}`,
                            label: equipmentName,
                        },
                    ]}
                    currentItem={{ label: zoneName }}
                />
                <Row
                    justify="space-between"
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Col>
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Zone to test" />
                            </Label>
                            <Typography.Text
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                {zoneName}
                            </Typography.Text>
                        </FlexCol>
                    </Col>
                    <Col>
                        <FlexCol style={{ gap: 3 }}>
                            {memoNormTypeElement}
                            {memoMethodElement}
                            {memoIsLabTestElement}
                        </FlexCol>
                    </Col>
                </Row>
                {/* Zone name */}
                <LabeledInput
                    label={translated("Zone name")}
                    value={zoneName}
                    setValue={setZoneName}
                />
                {/* Impacts Chart */}
                {normType === NormType.EN_1177 &&
                    method ===
                        MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION && (
                        <div>
                            <UnderlinedSectionTitle>
                                <Translated keyEn="CFH polynomial" />
                            </UnderlinedSectionTitle>
                            {impacts && impacts.length > 0 ? (
                                <FlexCol
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "400px",
                                        marginBottom: "2rem",
                                    }}
                                >
                                    <ZoneChartPF_CFH
                                        zone={zone}
                                        impacts={impacts}
                                    />
                                </FlexCol>
                            ) : (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <Translated keyEn="No impacts" />
                                    }
                                />
                            )}
                        </div>
                    )}
                {/* Zone impacts */}
                <div>
                    <Label>
                        <Translated keyEn="Impacts" />
                    </Label>
                    {memoImpactsTable}
                </div>
                {/* Zone picture */}
                <Label>
                    <Translated keyEn="Picture" />
                </Label>
                <UploadImageListV2
                    pictures={pictures}
                    setPictures={setPictures}
                />
                {/* Zone measured values */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Measured values" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                            justifyContent: "end",
                            alignItems: "end",
                        }}
                    >
                        {memoCalculatedCriticalFallHeight !== null && (
                            <Col flex="auto">
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Calculated CFH" />
                                    </Label>
                                    <Input
                                        size="large"
                                        value={
                                            memoCalculatedCriticalFallHeight
                                                ? memoCalculatedCriticalFallHeight.toFixed(
                                                      1,
                                                  )
                                                : ""
                                        }
                                        disabled={true}
                                        variant="filled"
                                        addonAfter="m"
                                    />
                                </FlexCol>
                            </Col>
                        )}
                        <Col>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Temperature" />
                                </Label>
                                <Input
                                    size="large"
                                    value={zoneTemperature ?? ""}
                                    onChange={(value) => {
                                        if (value.target.value === "") {
                                            setZoneTemperature(null)
                                            return
                                        }
                                        setZoneTemperature(
                                            parseInt(value.target.value),
                                        )
                                    }}
                                    style={{
                                        maxWidth: "10rem",
                                    }}
                                    // disabled={true}
                                    variant="filled"
                                    addonAfter="°C"
                                />
                            </FlexCol>
                        </Col>
                        <Col>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Humidity" />
                                </Label>
                                <Input
                                    size="large"
                                    value={zoneHumidity ?? ""}
                                    onChange={(value) => {
                                        if (value.target.value === "") {
                                            setZoneHumidity(null)
                                            return
                                        }
                                        setZoneHumidity(
                                            parseInt(value.target.value),
                                        )
                                    }}
                                    style={{
                                        maxWidth: "10rem",
                                    }}
                                    // disabled={true}
                                    variant="filled"
                                    addonAfter="%"
                                />
                            </FlexCol>
                        </Col>
                        <Col>
                            <Button
                                size="large"
                                type="primary"
                                disabled={stationSensors === null}
                                onClick={() => {
                                    if (stationSensors === null) {
                                        return
                                    }
                                    setZoneTemperature(
                                        Math.round(stationSensors.temperature),
                                    )
                                    setZoneHumidity(
                                        Math.round(
                                            stationSensors.relative_humidity,
                                        ),
                                    )
                                }}
                                icon={<VerticalAlignBottomOutlined />}
                            >
                                Auto
                            </Button>
                        </Col>
                    </Row>
                </div>
                {/* New impact slider button */}
                <div className="flex-col mx-1">
                    <SliderButton
                        onReleaseHIC={() => {
                            emitDropHIC()
                            setIsReleaseLoading(true)
                            setTimeout(() => {
                                setIsReleaseLoading(false)
                                setReleaseResetTrigger(Date.now())
                            }, 10000)
                        }}
                        isLoading={isReleaseLoading}
                        resetTrigger={releaseResetTrigger}
                    />
                    {newImpactUUID !== null && (
                        <FlexRow
                            style={{
                                justifyContent: "end",
                                marginRight: "0.25rem",
                            }}
                        >
                            <Link to={`/impacts/${newImpactUUID}`}>
                                <Button size="large" type="primary">
                                    <Translated keyEn="Go to the new impact" />{" "}
                                    <DoubleRightOutlined />
                                </Button>
                            </Link>
                        </FlexRow>
                    )}
                </div>
                {/* Zone data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Zone" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Free Fall Height"} />
                                </Label>
                                <Input
                                    size="large"
                                    value={zoneFreeFallHeightMetersStr ?? ""}
                                    onChange={(value) => {
                                        setZoneFreeFallHeightMetersStr(
                                            value.target.value,
                                        )
                                    }}
                                    variant="filled"
                                    addonAfter="m"
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Ground thickness"} />
                                </Label>
                                <Input
                                    size="large"
                                    value={
                                        zoneGroundThicknessMillimetersStr ?? ""
                                    }
                                    onChange={(value) => {
                                        setZoneGroundThicknessMillimetersStr(
                                            value.target.value,
                                        )
                                    }}
                                    type="number"
                                    variant="filled"
                                    addonAfter="mm"
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24}>
                            <LabeledInput
                                label={translated("Comments")}
                                value={zoneComment}
                                setValue={setZoneComment}
                            />
                        </Col>
                    </Row>
                </div>
                <FlexRow
                    style={{
                        alignItems: "center",
                        alignSelf: "flex-end",
                    }}
                >
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this site" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "0.8rem",
                                }}
                            >
                                <Translated keyEn="Delete" />
                            </span>
                        </Button>
                    </Popconfirm>
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="large"
                            block
                            onClick={onSave}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                <Translated keyEn="Save" />
                            </span>
                        </Button>
                    </div>
                </FlexRow>
            </FlexCol>
            <DataTreeDrawer site={site} selectedKey={memoZoneUUID} />
        </>
    )
}
