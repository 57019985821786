import { createContext } from "react"
import {
    HICState,
    HICConfig,
    StationConfig,
    StationSensors,
    UCPayload,
    UUID,
} from "../generated/proto-ts/main"

const MAX_MESSAGE_QUEUE_SIZE = 100

interface IUsercommContextProviderState {
    socket: WebSocket | undefined
    socketIsConnected: boolean
    bleDevice: BluetoothDevice | undefined
    bleIsConnected: boolean

    hicState: HICState | null
    hicConfig: HICConfig | null
    stationConfig: StationConfig | null
    stationSensors: StationSensors | null

    hicConfigSetAckConsumable: boolean | null
    stationConfigSetAckConsumable: boolean | null
    hicRawMeasurementConsumable: UUID | null

    recvMessageQueue: UCPayload[]
    emitMessageQueue: UCPayload[]

    emitMtu: number
    recvMtu: number
}

export interface IUsercommContextProviderContextType
    extends IUsercommContextProviderState {
    // setSocket: (socket: WebSocket) => void
    setSocketIsConnected: (isConnected: boolean) => void
    setBleDevice: (device: BluetoothDevice | undefined) => void
    setBleIsConnected: (isConnected: boolean) => void

    emitGetHICConfig: () => void
    emitSetHICConfig: (config: HICConfig) => void
    emitGetStationConfig: () => void
    emitSetStationConfig: (config: StationConfig) => void
    emitDropHIC: () => void
    emitRestartStation: () => void
    emitRebootStation: () => void
    emitHaltStation: () => void

    consumeHICConfigSetAck: () => void
    consumeStationConfigSetAck: () => void
    consumeHICRawMeasurement: () => void

    consumeRecvMessage: (uuid: UCPayload["uuid"]) => void
    addEmitMessage: (payload: UCPayload) => void

    // emitMtuTest: () => void
}

export interface Action {
    type: string
    payload?: any
}

export const usercommStateReducer = (
    state: IUsercommContextProviderState,
    action: Action,
): IUsercommContextProviderState => {
    switch (action.type) {
        case "SET_SOCKET_IS_CONNECTED":
            return {
                ...state,
                socketIsConnected: action.payload,
            } satisfies typeof state
        case "SET_HIC_STATE":
            return {
                ...state,
                hicState: action.payload,
            } satisfies typeof state
        case "SET_HIC_CONFIG":
            return {
                ...state,
                hicConfig: action.payload,
            } satisfies typeof state
        case "SET_STATION_CONFIG":
            return {
                ...state,
                stationConfig: action.payload,
            } satisfies typeof state
        case "SET_STATION_SENSORS":
            return {
                ...state,
                stationSensors: action.payload,
            } satisfies typeof state
        case "SET_HIC_CONFIG_SET_ACK_CONSUMABLE":
            return {
                ...state,
                hicConfigSetAckConsumable: action.payload,
            } satisfies typeof state
        case "SET_STATION_CONFIG_SET_ACK_CONSUMABLE":
            return {
                ...state,
                stationConfigSetAckConsumable: action.payload,
            } satisfies typeof state
        case "SET_HIC_RAW_MEASUREMENT_CONSUMABLE":
            return {
                ...state,
                hicRawMeasurementConsumable: action.payload,
            } satisfies typeof state
        case "ADD_MESSAGE_TO_RECV_QUEUE":
            let newMessageQueue = [...state.recvMessageQueue, action.payload]
            if (newMessageQueue.length > MAX_MESSAGE_QUEUE_SIZE) {
                newMessageQueue.shift()
            }
            return {
                ...state,
                recvMessageQueue: newMessageQueue,
            } satisfies typeof state
        case "CONSUME_MESSAGE_FROM_RECV_QUEUE":
            const messageUuid = action.payload
            const updatedMessages = state.recvMessageQueue.filter(
                (message) => message.uuid !== messageUuid,
            )
            return {
                ...state,
                recvMessageQueue: updatedMessages,
            } satisfies typeof state
        case "ADD_MESSAGE_TO_EMIT_QUEUE":
            let newEmitMessageQueue = [
                ...state.emitMessageQueue,
                action.payload,
            ]
            if (newEmitMessageQueue.length > MAX_MESSAGE_QUEUE_SIZE) {
                newEmitMessageQueue.shift()
            }
            return {
                ...state,
                emitMessageQueue: newEmitMessageQueue,
            } satisfies typeof state
        case "CONSUME_MESSAGE_FROM_EMIT_QUEUE":
            const emitMessageUuid = action.payload
            const updatedEmitMessages = state.emitMessageQueue.filter(
                (message) => message.uuid !== emitMessageUuid,
            )
            return {
                ...state,
                emitMessageQueue: updatedEmitMessages,
            } satisfies typeof state
        case "SET_BLE_DEVICE":
            return {
                ...state,
                bleDevice: action.payload,
            } satisfies typeof state
        case "SET_BLE_IS_CONNECTED":
            return {
                ...state,
                bleIsConnected: action.payload,
            } satisfies typeof state
        case "SET_SOCKET":
            return {
                ...state,
                socket: action.payload,
            } satisfies typeof state
        case "SET_SOCKET_IS_CONNECTED":
            return {
                ...state,
                socketIsConnected: action.payload,
            } satisfies typeof state
        default:
            console.warn(`usercommStateReducer: unknown action type:`, action)
            return state
    }
}

export const usercommContextV2_PB = createContext<
    IUsercommContextProviderContextType | undefined
>(undefined)
