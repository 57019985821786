import { Button, Input, Select, Spin, Typography, message } from "antd"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { Translated } from "../../utils/translated"
import { BugOutlined } from "@ant-design/icons"
import { callHostServerAPI } from "../../utils/hostServerAPI"

import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite"
import "react-json-view-lite/dist/index.css"
import { Label } from "../../components/commons-ts/input"
import { BaseOptionType, OptionProps } from "antd/es/select"
import { useUsercommLegacyHostApiBLE } from "../../usercomm/usercommAsyncRequestBLE"

// Host server API endpoints:
//
// /wpa/list
// /wpa/status
// /wpa/add
// /wpa/remove
// /wpa/connect
// /wpa/scan
//
// /ap/status
// /ap/configure
//
// /update/check
//
// /update/perform                  | simplecommand
// /docker-compose/pull             | simplecommand
// /git/pull                        | simplecommand
// /updater/restart                 | simplecommand
// /updater/status                  | simplecommand
// /git/status                      | verysimplecommand
// /git/repair                      | verysimplecommand
// /updater/logs                    | verysimplecommand
// /hic/scan                        | verysimplecommand
// /host-server/restart             | simplecommand
// /host-server/status              | simplecommand
// /docker-compose/restart          | simplecommand
// /docker-compose/status           | simplecommand
// /docker-compose/logs             | verysimplecommand
// /docker-compose/prune            | simplecommand
// /docker-compose/remove-orphans   | verysimplecommand
// /rootfs/expand                   | simplecommand
// /system/reboot                   | simplecommand
// /system/shutdown                 | simplecommand
// /system/blackout                 | simplecommand
// /i2c/detect                      | verysimplecommand
// /me

export const HostServerDebugServerSettings: FC = () => {
    const [path, setPath] = useState<string>("/wpa/status")
    const [selectedPath, setSelectedPath] = useState<string>("/wpa/status")
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [legacyHostApiResponse, requestLegacyhostApi] =
        useUsercommLegacyHostApiBLE()

    const memoPathOptions = useMemo((): BaseOptionType[] => {
        return [
            {
                value: "/wpa/list",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>List all wifi networks</span>
                        <span>/wpa/list</span>
                    </FlexRow>
                ),
            },
            {
                value: "/wpa/status",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get wifi status</span>
                        <span>/wpa/status</span>
                    </FlexRow>
                ),
            },
            {
                value: "/wpa/add",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Add wifi network</span>
                        <span>/wpa/add</span>
                    </FlexRow>
                ),
            },
            {
                value: "/wpa/remove",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Remove wifi network</span>
                        <span>/wpa/remove</span>
                    </FlexRow>
                ),
            },
            {
                value: "/wpa/connect",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Connect to wifi network</span>
                        <span>/wpa/connect</span>
                    </FlexRow>
                ),
            },
            {
                value: "/wpa/scan",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Scan wifi networks</span>
                        <span>/wpa/scan</span>
                    </FlexRow>
                ),
            },
            {
                value: "/system/reboot",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Reboot system</span>
                        <span>/system/reboot</span>
                    </FlexRow>
                ),
            },
            {
                value: "/ap/status",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get wifi hotspot status</span>
                        <span>/ap/status</span>
                    </FlexRow>
                ),
            },
            {
                value: "/ap/configure",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Configure wifi hotspot</span>
                        <span>/ap/configure</span>
                    </FlexRow>
                ),
            },
            {
                value: "/update/check",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Check for updates</span>
                        <span>/update/check</span>
                    </FlexRow>
                ),
            },
            {
                value: "/update/perform",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Perform update</span>
                        <span>/update/perform</span>
                    </FlexRow>
                ),
            },
            {
                value: "/docker-compose/pull",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Pull docker images</span>
                        <span>/docker-compose/pull</span>
                    </FlexRow>
                ),
            },
            {
                value: "/git/pull",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Pull git repository</span>
                        <span>/git/pull</span>
                    </FlexRow>
                ),
            },
            {
                value: "/updater/restart",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Restart updater</span>
                        <span>/updater/restart</span>
                    </FlexRow>
                ),
            },
            {
                value: "/updater/status",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get updater status</span>
                        <span>/updater/status</span>
                    </FlexRow>
                ),
            },
            {
                value: "/git/status",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get git status</span>
                        <span>/git/status</span>
                    </FlexRow>
                ),
            },
            {
                value: "/git/repair",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Repair git repository</span>
                        <span>/git/repair</span>
                    </FlexRow>
                ),
            },
            {
                value: "/updater/logs",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get updater logs</span>
                        <span>/updater/logs</span>
                    </FlexRow>
                ),
            },
            {
                value: "/hic/scan",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Scan HIC</span>
                        <span>/hic/scan</span>
                    </FlexRow>
                ),
            },
            {
                value: "/host-server/restart",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Restart host server</span>
                        <span>/host-server/restart</span>
                    </FlexRow>
                ),
            },
            {
                value: "/host-server/status",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get host server status</span>
                        <span>/host-server/status</span>
                    </FlexRow>
                ),
            },
            {
                value: "/docker-compose/restart",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Restart docker-compose</span>
                        <span>/docker-compose/restart</span>
                    </FlexRow>
                ),
            },
            {
                value: "/docker-compose/status",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get docker-compose status</span>
                        <span>/docker-compose/status</span>
                    </FlexRow>
                ),
            },
            {
                value: "/docker-compose/logs",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Get docker-compose logs</span>
                        <span>/docker-compose/logs</span>
                    </FlexRow>
                ),
            },
            {
                value: "/docker-compose/prune",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Prune docker-compose</span>
                        <span>/docker-compose/prune</span>
                    </FlexRow>
                ),
            },
            {
                value: "/docker-compose/remove-orphans",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Remove docker-compose orphans</span>
                        <span>/docker-compose/remove-orphans</span>
                    </FlexRow>
                ),
            },
            {
                value: "/rootfs/expand",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Expand rootfs</span>
                        <span>/rootfs/expand</span>
                    </FlexRow>
                ),
            },
            {
                value: "/system/reboot",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Reboot system</span>
                        <span>/system/reboot</span>
                    </FlexRow>
                ),
            },
            {
                value: "/system/shutdown",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Shutdown system</span>
                        <span>/system/shutdown</span>
                    </FlexRow>
                ),
            },
            {
                value: "/system/blackout",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Blackout system</span>
                        <span>/system/blackout</span>
                    </FlexRow>
                ),
            },
            {
                value: "/i2c/detect",
                label: (
                    <FlexRow style={{ justifyContent: "space-between" }}>
                        <span>Detect I2C devices</span>
                        <span>/i2c/detect</span>
                    </FlexRow>
                ),
            },
        ]
    }, [])

    const memoResponse = useMemo(() => {
        if (isLoading) {
            return (
                <FlexCol
                    style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spin />
                </FlexCol>
            )
        }
        if (legacyHostApiResponse === null) {
            return <>N/A</>
        }

        let bodyText = new TextDecoder().decode(legacyHostApiResponse.body)
        let bodyObject: any = null
        try {
            bodyObject = JSON.parse(bodyText)
        } catch (e) {}

        return (
            <JsonView
                data={bodyObject !== null ? bodyObject : bodyText}
                shouldExpandNode={allExpanded}
                style={defaultStyles}
            />
        )
    }, [legacyHostApiResponse, isLoading])

    const sendCommand = useCallback(() => {
        if (path === "") {
            return
        }
        setIsLoading(true)
        requestLegacyhostApi(path)
    }, [path])

    useEffect(() => {
        if (legacyHostApiResponse === null) {
            return
        }
        setIsLoading(false)
    }, [legacyHostApiResponse])

    return (
        <FlexCol
            style={{
                width: "100%",
                // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            {/* <FlexCol style={{ gap: 0 }}>
                <Label>
                    <Translated keyEn="Host server debug helper" />
                </Label>
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="Host Server Debug" /> <BugOutlined />
                </Typography.Text>
            </FlexCol> */}
            {/* Path input and send button */}
            <FlexCol>
                <Label>
                    <Translated keyEn="Commands" />
                </Label>
                <Select
                    value={selectedPath}
                    options={memoPathOptions}
                    onChange={(value: string) => {
                        setSelectedPath(value)
                        setPath(value)
                    }}
                    style={{
                        width: "100%",
                    }}
                />
                <FlexRow>
                    <Input
                        placeholder="Select or manually type command path.."
                        value={path}
                        onChange={(e) => setPath(e.target.value)}
                        style={{
                            width: "100%",
                        }}
                    />
                    <Button onClick={sendCommand} type="primary">
                        <span
                            style={{
                                textTransform: "uppercase",
                            }}
                        >
                            <Translated keyEn="Send" />
                        </span>
                    </Button>
                </FlexRow>
            </FlexCol>
            {/* Response */}
            <FlexCol>
                <Label>
                    <Translated keyEn="Response" />
                </Label>
                {memoResponse}
            </FlexCol>
        </FlexCol>
    )
}
