import { HistoryOutlined } from "@ant-design/icons"
import { toast } from "react-toastify"
import { FlexCol } from "../components/commons-ts/common"
import { Button } from "antd"

export const toastNotifyUpdateIsInstalled = () => {
    toast(
        <FlexCol>
            <div>
                A <b>new version</b> of the Lugsoft App has been installed for
                you!
                <br />
                Just <b>reload the app</b> to use the new version.
            </div>
            <Button type="primary" onClick={() => window.location.reload()}>
                Reload App
            </Button>
        </FlexCol>,
        {
            icon: <HistoryOutlined />,
            style: {
                minWidth: "20rem",
                fontSize: "1.2rem",
            },
            autoClose: false,
        },
    )
}
