import { CSSProperties, FC, ReactNode } from "react"

export const Paper: FC<{ children?: ReactNode; style?: CSSProperties }> = ({
    children,
    style,
}) => {
    return (
        <div className="paper" style={style}>
            {children}
        </div>
    )
}
