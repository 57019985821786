import { FC, useState, useRef, useEffect, useCallback } from "react"
import {
    IImpactModel,
    IKinematicPoint,
    IZoneModel,
    MeanWithStd,
} from "../../types"

import uPlot from "uplot"
import "uplot/dist/uPlot.min.css"
import { Point, findParabolicAandC } from "../../utils/goldenSectionSearch"
import {
    FREE_FALL_ACCELERATION_THRESHOLD,
    TARGET_GMAX,
    TARGET_HIC,
    powArr,
    solveQuadraticEquation,
    sumArr,
} from "../../utils/maths"
import { Zone, Impact } from "../../generated/proto-ts/main"
import { decodeImpactDataPoints } from "../../usercomm/usercommUtils"
import { COLOR_BG_CURVE, colorHexToRgba } from "../../utils/utils"

const UPLOT_DEFAULT_WIDTH = 400
const UPLOT_DEFAULT_HEIGHT = 200
const UPLOT_DEFAULT_MARGIN_BOTTOM = 50

const COLOR_HIC = "#8884d8"
const COLOR_GMAX = "#82ca9d"

const CANVAS_CTX_FONT = "bold 18px Arial"

const drawVerticalArrow = (
    ctx: CanvasRenderingContext2D,
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
) => {
    ctx.beginPath()
    ctx.moveTo(fromX, fromY)
    ctx.lineTo(toX, toY)
    ctx.stroke()
    ctx.closePath()

    let sens = 1
    if (toY > fromY) {
        sens = -1
    }

    let headlenY = 20
    if (Math.abs(fromY - toY) < headlenY) {
        headlenY = Math.abs(fromY - toY) / 2
    }
    let headlenX = headlenY / 3
    ctx.beginPath()
    ctx.moveTo(toX, toY)
    ctx.lineTo(toX - headlenX, toY + sens * headlenY)
    ctx.lineTo(toX + headlenX, toY + sens * headlenY)
    ctx.lineTo(toX, toY)
    ctx.fillStyle = ctx.strokeStyle
    ctx.fill()
}

const ResizableUplot: FC<{
    uplotOptions: uPlot.Options | null
    uplotData: uPlot.AlignedData | null
    mooSync: uPlot.SyncPubSub | null
}> = ({ uplotOptions, uplotData, mooSync }) => {
    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    useEffect(() => {
        if (
            uplotOptions === null ||
            uplotData === null ||
            targetRef.current === null
        ) {
            return
        }
        if (chartRef.current !== null) {
            chartRef.current.destroy()
            chartRef.current = null
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: targetRef.current.clientHeight,
            })
            if (mooSync !== null) {
                mooSync.sub(plot)
            }
            chartRef.current = plot
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: targetRef.current.clientHeight,
                })
            }
        }
        resizePlot()
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
            ref={targetRef}
        />
    )
}

export const ZoneChartPF_CFH: FC<{
    zone: Zone | null
    impacts: Impact[] | null
}> = ({ zone, impacts }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    useEffect(() => {
        if (zone === null || impacts === null) {
            console.log(
                `ZoneCFHChart: zone is null or zone.impacts is undefined`,
            )
            return
        }
        let X: number[] = []
        let Y1: (number | null)[] = []
        let Y2: (number | null)[] = []
        let Y1bis: number[] = []
        let Y2bis: number[] = []

        let minHeight = 1e6
        let maxHeight = -1e6
        let maxGmax = 200 // -1e6
        let effectiveImpacts: Impact[] = []
        for (let impact of impacts) {
            if (impact.deleted_at !== 0) {
                continue
            }
            if (impact.impact_gmax !== null && impact.impact_hic !== null) {
                effectiveImpacts.push(impact)
                minHeight = Math.min(minHeight, impact.impact_ffh)
                maxHeight = Math.max(maxHeight, impact.impact_ffh)
                maxGmax = Math.max(maxGmax, impact.impact_gmax)
            }
        }
        let deltaHeight = maxHeight - minHeight

        // Critical Fall Height (CFH)
        let heightGmaxPoints: Point[] = []
        let heightHICPoints: Point[] = []
        for (let impact of effectiveImpacts) {
            heightGmaxPoints.push([impact.impact_ffh, impact.impact_gmax])
            heightHICPoints.push([impact.impact_ffh, impact.impact_hic])
        }
        let { equation: gmaxEquation } = findParabolicAandC(heightGmaxPoints)
        let [_, gmaxCFH] = solveQuadraticEquation(gmaxEquation, TARGET_GMAX)

        let { equation: hicEquation } = findParabolicAandC(heightHICPoints)
        let [__, hicCFH] = solveQuadraticEquation(hicEquation, TARGET_HIC)

        if (gmaxCFH === null || hicCFH === null) {
            console.log(`ZoneCFHChart: gmaxCFH or hicCFH is null`)
            return
        }

        let maxCFH = Math.max(gmaxCFH, hicCFH)
        let minCFH = Math.min(gmaxCFH, hicCFH)

        console.log(
            `ZoneCFHChart: maxCFH: ${maxCFH}, minCFH: ${minCFH}; GmaxEq: ${gmaxEquation}; HIC: ${hicEquation}`,
        )

        // let upperHeightLimit = maxCFH + 0.1 * maxCFH
        let lowerHeightLimit = minHeight - 0.1 * deltaHeight
        if (lowerHeightLimit < 0) {
            lowerHeightLimit = 0
        }
        let upperHeightLimit = maxCFH + 0.1 * (maxCFH - minCFH)
        let step = (upperHeightLimit - lowerHeightLimit) / 100

        let [AGmax, BGmax, CGmax] = gmaxEquation
        let [AHIC, BHIC, CHIC] = hicEquation

        for (let h = lowerHeightLimit; h < upperHeightLimit; h += step) {
            X.push(h)
            let gmaxProj = AGmax * h * h + BGmax * h + CGmax
            Y1bis.push(gmaxProj)
            let hicProj = AHIC * h * h + BHIC * h + CHIC
            Y2bis.push(hicProj)

            // Align source points with projected curve
            let prevH = h - step
            let _projected = false
            for (let impact of effectiveImpacts) {
                // console.log(`ZoneCFHChart: impact:`, impact.height, h)
                if (impact.impact_ffh >= prevH && impact.impact_ffh <= h) {
                    Y1.push(impact.impact_gmax)
                    Y2.push(impact.impact_hic)
                    _projected = true
                }
            }
            if (!_projected) {
                Y1.push(null)
                Y2.push(null)
            }
        }

        // console.log(`ZoneCFHChart: data:`, X, Y1, Y2, Y1bis, Y2bis)
        setUplotData([X, Y1, Y2, Y1bis, Y2bis])

        setUplotOptions({
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
            },
            axes: [
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(1))
                    },
                    label: "Height, m",
                },
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toString())
                    },
                    label: "Accel, g",
                    scale: "g",
                },
                {
                    grid: {
                        // show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toString())
                    },
                    label: "HIC",
                    side: 1,
                    scale: "hic",
                },
            ],
            scales: {
                x: {
                    time: false,
                },
                g: {
                    range: [0, maxGmax * 1.1],
                },
                hic: {
                    range: [0, 5 * (maxGmax * 1.1)],
                },
            },
            series: [
                {
                    show: true,
                    label: "Height, m",
                },
                {
                    label: "Gmax",
                    stroke: COLOR_GMAX,
                    show: true,
                    points: { show: true, size: 20 },
                    scale: "g",
                },
                {
                    label: "HIC",
                    stroke: COLOR_HIC,
                    show: true,
                    points: { show: true, size: 20 },
                    scale: "hic",
                },
                {
                    label: "Gmax Projection",
                    stroke: COLOR_GMAX,
                    points: { show: false },
                    spanGaps: true,
                    show: true,
                    scale: "g",
                    width: 2,
                },
                {
                    label: "HIC Projection",
                    stroke: COLOR_HIC,
                    points: { show: false },
                    spanGaps: true,
                    show: true,
                    scale: "hic",
                    width: 2,
                },
            ],
            hooks: {
                draw: [
                    (u) => {
                        const ctx = u.ctx
                        // Vertical line @ gmaxCFH
                        if (gmaxCFH !== null) {
                            const y0 = u.valToPos(0, "g", true)
                            const y1 = u.valToPos(200, "g", true)
                            const x = u.valToPos(gmaxCFH, "x", true)
                            ctx.strokeStyle = COLOR_GMAX
                            ctx.lineWidth = 2
                            ctx.setLineDash([20, 5, 5, 5])
                            ctx.beginPath()
                            ctx.moveTo(x, y0)
                            ctx.lineTo(x, y1)
                            ctx.stroke()
                        }
                        // Vertical line @ hicCFH
                        if (hicCFH !== null) {
                            const y0 = u.valToPos(0, "hic", true)
                            const y1 = u.valToPos(1000, "hic", true)
                            const x = u.valToPos(hicCFH, "x", true)
                            ctx.strokeStyle = COLOR_HIC
                            ctx.lineWidth = 2
                            ctx.setLineDash([20, 5, 5, 5])
                            ctx.beginPath()
                            ctx.moveTo(x, y0)
                            ctx.lineTo(x, y1)
                            ctx.stroke()
                        }
                        // Horizontal line @ 200g
                        const y = u.valToPos(200, "g", true)
                        const x0 = u.valToPos(lowerHeightLimit, "x", true)
                        const x1 = u.valToPos(maxCFH, "x", true)
                        ctx.strokeStyle = "red"
                        ctx.lineWidth = 2
                        ctx.setLineDash([20, 5, 5, 5])
                        ctx.beginPath()
                        ctx.moveTo(x0, y)
                        ctx.lineTo(x1, y)
                        ctx.stroke()

                        // Note minCFH
                        ctx.font = CANVAS_CTX_FONT
                        let minCFHmStr = minCFH.toFixed(1)
                        let txt = `CFH: ${minCFHmStr}m`
                        ctx.fillStyle = "black"
                        const yCFH = u.valToPos(0, "g", true)
                        const xCFH = u.valToPos(minCFH, "x", true)
                        let labelWidth = ctx.measureText(txt).width
                        ctx.fillText(txt, xCFH - labelWidth - 2, yCFH - 10)

                        // reset styles
                        ctx.setLineDash([])
                    },
                ],
            },
        })
    }, [zone])

    if (zone === null || impacts === null) {
        return null
    }

    return (
        <ResizableUplot
            uplotOptions={uplotOptions}
            uplotData={uplotData}
            mooSync={null}
        />
    )
}

export const ImpactChartRawAcceleration: FC<{
    impact: Impact | null
    mooSync: uPlot.SyncPubSub | null
}> = ({ impact, mooSync }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    useEffect(() => {
        if (impact === null) {
            console.log(`ImpactChartAcceleration: impact is null`)
            return
        }
        let [aq_points, ax_points, ay_points, az_points] =
            decodeImpactDataPoints(impact)
        let f = impact.decimated_frequency
        if (f === 0) {
            f = 1e3
        }
        let X: number[] = []
        let Y1: number[] = [] // AX
        let Y2: number[] = [] // AY
        let Y3: number[] = [] // AZ
        let Y4: number[] = [] // AQ
        let Y5: number[] = [] // AQ own

        for (let i = 0; i < ax_points.length; i++) {
            let ax = ax_points[i]
            let ay = ay_points[i]
            let az = az_points[i]
            let aq = aq_points[i]
            let as = [ax]
            if (ay !== 0) {
                as.push(ay)
            } else {
                as.push(ax)
            }
            if (az !== 0) {
                as.push(az)
            } else {
                as.push(ax)
            }

            let aq_own = Math.sqrt(sumArr(powArr(as, 2)))
            X.push(1000 * (i / f))
            Y1.push(ax)
            Y2.push(ay)
            Y3.push(az)
            Y4.push(aq)
            Y5.push(Math.sign(aq) * aq_own)
        }
        setUplotData([X, Y1, Y2, Y3, Y4, Y5])

        setUplotOptions({
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
            },
            cursor: {
                sync: {
                    key: mooSync ? mooSync.key : "",
                },
            },
            axes: [
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(0))
                    },
                    label: "Time, ms",
                },
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(0))
                    },
                    label: "Deceleration, g",
                },
            ],
            scales: {
                x: {
                    time: false,
                },
            },
            series: [
                {
                    show: true,
                    label: "Time, ms",
                },
                {
                    label: "X",
                    stroke: "green",
                    show: true,
                    points: { show: false },
                    dash: [10, 5],
                },
                {
                    label: "Y",
                    stroke: "blue",
                    show: true,
                    points: { show: false },
                    dash: [10, 5],
                },
                {
                    label: "Z",
                    stroke: "red",
                    show: true,
                    points: { show: false },
                    dash: [10, 5],
                },
                {
                    label: "Quadratic",
                    stroke: colorHexToRgba(COLOR_BG_CURVE, 1),
                    show: true,
                    points: { show: false },
                    width: 2,
                },
                // {
                //     label: "Quadratic (ind)",
                //     stroke: colorHexToRgba(COLOR_BG_CURVE, 1),
                //     show: false,
                //     points: { show: false },
                //     width: 2,
                //     dash: [10, 5],
                // },
            ],
        })
    }, [impact])

    return (
        <ResizableUplot
            uplotOptions={uplotOptions}
            uplotData={uplotData}
            mooSync={mooSync}
        />
    )
}

export const ImpactChartKinematicAcceleration: FC<{
    kinematicPoints: IKinematicPoint[] | null
    maxAcceptableG: number | null
    mooSync: uPlot.SyncPubSub | null
    shouldDrawRequirements: boolean
}> = ({
    kinematicPoints,
    maxAcceptableG: maxAcceptableG,
    mooSync,
    shouldDrawRequirements,
}) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    useEffect(() => {
        if (kinematicPoints === null) {
            console.log(`ImpactChartAcceleration: kinematicPoints is null`)
            return
        }
        let X: number[] = []
        let Y1: number[] = []

        let maxMeasuredG = 0
        let tAtMaxMeasuredG = 0
        let totalTMs = 0
        let firstPoint = kinematicPoints[0]
        if (firstPoint === undefined) {
            return
        }
        for (let kinematicPoint of kinematicPoints) {
            let dtMs = 1000 * (kinematicPoint.t - firstPoint.t)
            X.push(dtMs)
            let a = kinematicPoint.a
            let g = a / 9.81
            if (g > maxMeasuredG) {
                maxMeasuredG = g
                tAtMaxMeasuredG = dtMs
            }
            totalTMs = dtMs
            Y1.push(g)
        }
        setUplotData([X, Y1])

        setUplotOptions({
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
            },
            cursor: {
                sync: {
                    key: mooSync ? mooSync.key : "",
                },
            },
            axes: [
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(0))
                    },
                    label: "Time, ms",
                },
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(0))
                    },
                    label: "Deceleration, g",
                },
            ],
            scales: {
                x: {
                    time: false,
                },
                y: {
                    max: Math.max(maxMeasuredG, maxAcceptableG || 0) * 1.25,
                },
            },
            series: [
                {
                    show: true,
                    label: "Time, ms",
                    value: (u, v) => (v !== null ? v.toFixed(0) : "N/A"),
                },
                {
                    label: "Deceleration, g",
                    stroke: colorHexToRgba(COLOR_BG_CURVE, 1),
                    show: true,
                    width: 2,
                    fill: colorHexToRgba(COLOR_BG_CURVE, 0.1),
                    fillTo: 0,
                    points: { show: false },
                    value: (u, v) => (v !== null ? v.toFixed(2) : "N/A"),
                },
            ],
            hooks: {
                draw: [
                    (u) => {
                        const ctx = u.ctx
                        if (!shouldDrawRequirements) {
                            return
                        }
                        // Horizontal line @ maxAcceptableG
                        if (maxAcceptableG !== null) {
                            const yAcpt = u.valToPos(maxAcceptableG, "y", true)
                            const x0 = u.valToPos(0, "x", true)
                            const x1 = u.valToPos(totalTMs, "x", true)
                            ctx.strokeStyle = "green"
                            if (maxMeasuredG > maxAcceptableG) {
                                ctx.strokeStyle = "red"
                            }
                            ctx.lineWidth = 2
                            ctx.setLineDash([20, 5, 5, 5])
                            ctx.beginPath()
                            ctx.moveTo(x0, yAcpt)
                            ctx.lineTo(x1, yAcpt)
                            ctx.stroke()

                            // Vertical arrow joining maxAcceptableG and maxMeasuredG
                            ctx.lineWidth = 2
                            ctx.setLineDash([])
                            const yMeas = u.valToPos(maxMeasuredG, "y", true)
                            const xMid = u.valToPos(tAtMaxMeasuredG, "x", true)
                            void drawVerticalArrow(
                                ctx,
                                xMid,
                                yAcpt,
                                xMid,
                                yMeas,
                            )
                            // Note OK/NOK
                            ctx.font = CANVAS_CTX_FONT
                            let txtOk = `OK`
                            ctx.fillStyle = "green"
                            if (maxMeasuredG > maxAcceptableG) {
                                txtOk = `NOK`
                                ctx.fillStyle = "red"
                            }
                            let m = ctx.measureText(txtOk)
                            ctx.fillText(
                                txtOk,
                                xMid + m.width + 5,
                                (yAcpt + yMeas) / 2,
                            )

                            // Note maxAcceptableG
                            ctx.font = CANVAS_CTX_FONT
                            let txtCond = `≤${maxAcceptableG.toFixed(0)}g`
                            ctx.fillStyle = "green"
                            if (maxMeasuredG > maxAcceptableG) {
                                txtCond = `>${maxAcceptableG.toFixed(0)}g`
                                ctx.fillStyle = "red"
                            }
                            m = ctx.measureText(txtCond)
                            ctx.fillText(txtCond, x0 + m.width, yAcpt + 12)
                        }
                    },
                    (u) => {
                        const ctx = u.ctx
                        // Horizontal line @ maxMeasuredG
                        const yTop = u.valToPos(maxMeasuredG, "y", true)
                        // const yBottom = u.valToPos(0, "y", true)
                        const xLeft = u.valToPos(0, "x", true)
                        const xMid = u.valToPos(tAtMaxMeasuredG, "x", true)
                        const xRight = u.valToPos(totalTMs, "x", true)

                        ctx.strokeStyle = "black"
                        ctx.lineWidth = 1
                        ctx.setLineDash([10, 10])
                        ctx.beginPath()
                        ctx.moveTo(xLeft, yTop)
                        ctx.lineTo(xRight, yTop)
                        ctx.stroke()

                        // reset styles
                        ctx.setLineDash([])

                        // Note maxMeasuredG
                        ctx.font = CANVAS_CTX_FONT
                        let txt = `${maxMeasuredG.toFixed(1)}g`
                        ctx.fillStyle = "black"
                        ctx.fillText(txt, xRight, yTop - 10)
                    },
                ],
            },
        })
    }, [kinematicPoints, shouldDrawRequirements])

    return (
        <ResizableUplot
            uplotOptions={uplotOptions}
            uplotData={uplotData}
            mooSync={mooSync}
        />
    )
}

export const ImpactChartKinematicVelocity: FC<{
    kinematicPoints: IKinematicPoint[] | null
    maxAcceptableResiliencePerc: number | null
    mooSync: uPlot.SyncPubSub | null
    shouldDrawRequirements: boolean
}> = ({
    kinematicPoints,
    maxAcceptableResiliencePerc,
    mooSync,
    shouldDrawRequirements,
}) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    useEffect(() => {
        if (kinematicPoints === null) {
            console.log(`ImpactChartVelocity: kinematicPoints is null`)
            return
        }
        let X: number[] = []
        let Y1: number[] = [] // Velocity

        let firstPoint = kinematicPoints[0]
        if (firstPoint === undefined) {
            return
        }
        let lastPoint = kinematicPoints[kinematicPoints.length - 1]
        if (lastPoint === undefined) {
            return
        }
        let vInit = 0
        let vFinal = 0
        let tInit = 0
        let tFinal = 0
        for (let i = 0; i < kinematicPoints.length; i++) {
            let kinematicPoint = kinematicPoints[i]
            vInit = kinematicPoint.v
            tInit = kinematicPoint.t
            if (kinematicPoint.a > FREE_FALL_ACCELERATION_THRESHOLD) {
                break
            }
        }
        for (let i = kinematicPoints.length - 1; i >= 0; i--) {
            let kinematicPoint = kinematicPoints[i]
            vFinal = Math.abs(kinematicPoint.v)
            tFinal = kinematicPoint.t
            if (kinematicPoint.a > FREE_FALL_ACCELERATION_THRESHOLD) {
                break
            }
        }
        let totalTMs = 1000 * (lastPoint.t - tInit)
        let measuredResilience = Math.pow(vFinal, 2) / Math.pow(vInit, 2)
        let minAbsVelocity = 1e6
        let tAtMinAbsVelocity = 0
        for (let kinematicPoint of kinematicPoints) {
            let dt = kinematicPoint.t
            let dtMs = 1000 * dt
            X.push(dtMs)
            let v = kinematicPoint.v
            let vAbs = Math.abs(v)
            Y1.push(vAbs)
            if (vAbs < minAbsVelocity) {
                minAbsVelocity = vAbs
                tAtMinAbsVelocity = dtMs
            }
        }
        setUplotData([X, Y1])

        setUplotOptions({
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
            },
            cursor: {
                sync: {
                    key: mooSync ? mooSync.key : "",
                },
            },
            axes: [
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(0))
                    },
                    label: "Time, ms",
                },
                {
                    grid: {
                        show: true,
                    },
                    label: "Absolute Velocity, m/s",
                },
            ],
            scales: {
                x: {
                    time: false,
                },
                y: {
                    // min: 0,
                    max: Math.max(...Y1) * 1.25,
                },
            },
            series: [
                {
                    show: true,
                    label: "Time, ms",
                    value: (u, v) => (v !== null ? v.toFixed(0) : "N/A"),
                },
                {
                    label: "Absolute Velocity, m/s",
                    stroke: colorHexToRgba(COLOR_BG_CURVE, 1),
                    show: true,
                    width: 2,
                    fill: colorHexToRgba(COLOR_BG_CURVE, 0.1),
                    fillTo: 0,
                    points: { show: false },
                    value: (u, v) => (v !== null ? v.toFixed(2) : "N/A"),
                },
            ],
            hooks: {
                draw: [
                    (u) => {
                        const ctx = u.ctx
                        if (!shouldDrawRequirements) {
                            return
                        }
                        // Horizontal line @ maxAcceptableResilience
                        if (maxAcceptableResiliencePerc !== null) {
                            let vFinalAtMaxAcceptableResilience = Math.sqrt(
                                (maxAcceptableResiliencePerc / 100) *
                                    Math.pow(vInit, 2),
                            )
                            const yAcpt = u.valToPos(
                                vFinalAtMaxAcceptableResilience,
                                "y",
                                true,
                            )
                            const xLeft = u.valToPos(0, "x", true)
                            const xRight = u.valToPos(totalTMs, "x", true)
                            ctx.strokeStyle = "green"
                            if (
                                measuredResilience >
                                maxAcceptableResiliencePerc / 100
                            ) {
                                ctx.strokeStyle = "red"
                            }
                            ctx.lineWidth = 2
                            ctx.setLineDash([20, 5, 5, 5])
                            ctx.beginPath()
                            ctx.moveTo(xLeft, yAcpt)
                            ctx.lineTo(xRight, yAcpt)
                            ctx.stroke()

                            // Vertical arrow joining maxAcceptableResilience and measuredResilience
                            ctx.lineWidth = 2
                            ctx.setLineDash([])
                            const yMeas = u.valToPos(vFinal, "y", true)
                            const xMid = u.valToPos(
                                tAtMinAbsVelocity,
                                "x",
                                true,
                            )
                            void drawVerticalArrow(
                                ctx,
                                xMid,
                                yAcpt,
                                xMid,
                                yMeas,
                            )

                            // Note OK/NOK
                            ctx.font = CANVAS_CTX_FONT
                            let txtOk = `OK`
                            ctx.fillStyle = "green"
                            if (
                                measuredResilience >
                                maxAcceptableResiliencePerc / 100
                            ) {
                                txtOk = `NOK`
                                ctx.fillStyle = "red"
                            }
                            let m = ctx.measureText(txtOk)
                            ctx.fillText(
                                txtOk,
                                xMid + m.width + 5,
                                (yAcpt + yMeas) / 2,
                            )

                            // Note maxAcceptableResilience
                            ctx.font = CANVAS_CTX_FONT
                            let txt = `≤${maxAcceptableResiliencePerc.toFixed(0)}%`
                            ctx.fillStyle = "green"
                            if (
                                measuredResilience >
                                maxAcceptableResiliencePerc / 100
                            ) {
                                txt = `>${maxAcceptableResiliencePerc.toFixed(0)}%`
                                ctx.fillStyle = "red"
                            }
                            m = ctx.measureText(txt)
                            ctx.fillText(txt, xLeft + m.width, yAcpt + 12)
                        }
                    },
                    (u) => {
                        const ctx = u.ctx
                        // Horizontal lines @ vInit and vFinal
                        const yMin = u.valToPos(vInit, "y", true)
                        const yMax = u.valToPos(vFinal, "y", true)
                        const xLeft = u.valToPos(0, "x", true)
                        const xRight = u.valToPos(totalTMs, "x", true)

                        ctx.strokeStyle = "black"
                        ctx.lineWidth = 1
                        ctx.setLineDash([10, 10])
                        ctx.beginPath()

                        ctx.moveTo(xLeft, yMin)
                        ctx.lineTo(xRight, yMin)

                        ctx.moveTo(xLeft, yMax)
                        ctx.lineTo(xRight, yMax)

                        ctx.stroke()

                        // reset styles
                        ctx.setLineDash([])

                        // Note initial (max) velocity
                        ctx.font = CANVAS_CTX_FONT
                        let txt = `${vInit.toFixed(2)} — 100%`
                        ctx.fillStyle = "black"
                        ctx.fillText(txt, xRight, yMin - 10)

                        // Note final velocity
                        txt = `${vFinal.toFixed(2)} — ${(100 * measuredResilience).toFixed(0)}%`
                        ctx.fillStyle = "black"
                        ctx.fillText(txt, xRight, yMax - 10)
                    },
                ],
            },
        })
    }, [kinematicPoints, shouldDrawRequirements])

    return (
        <ResizableUplot
            uplotOptions={uplotOptions}
            uplotData={uplotData}
            mooSync={mooSync}
        />
    )
}

export const ImpactChartKinematicDeflection: FC<{
    kinematicPoints: IKinematicPoint[] | null
    totalDepth: MeanWithStd | null
    maxAcceptableDeformationPerc: number | null
    maxAcceptableDeformationDist: number | null
    mooSync: uPlot.SyncPubSub | null
    shouldDrawRequirements: boolean
}> = ({
    kinematicPoints,
    totalDepth,
    maxAcceptableDeformationPerc,
    maxAcceptableDeformationDist,
    mooSync,
    shouldDrawRequirements,
}) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    useEffect(() => {
        if (kinematicPoints === null) {
            console.log(`ImpactChartDeflection: kinematicPoints is null`)
            return
        }
        let X: number[] = []
        let Y1: number[] = [] // Deflection

        let firstPoint = kinematicPoints[0]
        if (firstPoint === undefined) {
            return
        }
        let lastPoint = kinematicPoints[kinematicPoints.length - 1]
        if (lastPoint === undefined) {
            return
        }
        let tInit = firstPoint.t
        let tFinal = lastPoint.t
        let tAtDMin = 0
        let dMin = 1e6
        let totalTMs = 1000 * (tFinal - tInit)
        for (let kinematicPoint of kinematicPoints) {
            let dt = kinematicPoint.t - tInit
            let dtMs = 1000 * dt
            X.push(dtMs)
            let dMm = 1000 * kinematicPoint.d // mm
            if (dMm < dMin) {
                dMin = dMm
                tAtDMin = dtMs
            }
            Y1.push(dMm)
        }
        setUplotData([X, Y1])

        setUplotOptions({
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
            },
            cursor: {
                sync: {
                    key: mooSync ? mooSync.key : "",
                },
            },
            axes: [
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => v.toFixed(0))
                    },
                    label: "Time, ms",
                },
                {
                    grid: {
                        show: true,
                    },
                    values: (u, vals, space) => {
                        return vals.map((v) => Math.abs(v).toFixed(0))
                    },
                    label: "Deflectiont, mm",
                },
            ],
            scales: {
                x: {
                    time: false,
                },
                y: {
                    min: totalDepth === null ? undefined : -totalDepth.mean,
                    max: 0,
                },
            },
            series: [
                {
                    show: true,
                    label: "Time, ms",
                    value: (u, v) => (v !== null ? v.toFixed(0) : "N/A"),
                },
                {
                    label: "Displacement, mm",
                    stroke: colorHexToRgba(COLOR_BG_CURVE, 1),
                    show: true,
                    width: 2,
                    fill: colorHexToRgba(COLOR_BG_CURVE, 0.1),
                    fillTo: 0,
                    points: { show: false },
                    value: (u, v) => (v !== null ? v.toFixed(2) : "N/A"),
                },
            ],
            hooks: {
                draw: [
                    (u) => {
                        const ctx = u.ctx
                        if (!shouldDrawRequirements) {
                            return
                        }
                        // Horizontal line @ maxAcceptableDeformation
                        let dAtMaxAcceptableDeformation =
                            maxAcceptableDeformationDist
                        if (
                            totalDepth !== null &&
                            maxAcceptableDeformationPerc !== null
                        ) {
                            dAtMaxAcceptableDeformation = Math.abs(
                                (maxAcceptableDeformationPerc / 100) *
                                    totalDepth.mean,
                            )
                        }
                        if (dAtMaxAcceptableDeformation !== null) {
                            const y = u.valToPos(
                                -dAtMaxAcceptableDeformation,
                                "y",
                                true,
                            )
                            const x0 = u.valToPos(0, "x", true)
                            const x1 = u.valToPos(totalTMs, "x", true)
                            ctx.strokeStyle = "green"
                            if (Math.abs(dMin) > dAtMaxAcceptableDeformation) {
                                ctx.strokeStyle = "red"
                            }
                            ctx.lineWidth = 2
                            ctx.setLineDash([20, 5, 5, 5])
                            ctx.beginPath()
                            ctx.moveTo(x0, y)
                            ctx.lineTo(x1, y)
                            ctx.stroke()

                            // Note maxAcceptableDeformation
                            ctx.font = CANVAS_CTX_FONT
                            let txt = `≤${dAtMaxAcceptableDeformation.toFixed(0)}`
                            ctx.fillStyle = "green"
                            if (Math.abs(dMin) > dAtMaxAcceptableDeformation) {
                                txt = `>${dAtMaxAcceptableDeformation.toFixed(0)}`
                                ctx.fillStyle = "red"
                            }
                            let m = ctx.measureText(txt)
                            ctx.fillText(txt, x0 + m.width, y - 10)

                            // Vertical arrow joining maxAcceptableDeformation and dMin
                            ctx.lineWidth = 2
                            ctx.setLineDash([])
                            const yMeas = u.valToPos(dMin, "y", true)
                            const xMid = u.valToPos(tAtDMin, "x", true)
                            void drawVerticalArrow(ctx, xMid, y, xMid, yMeas)

                            // Note OK/NOK
                            ctx.font = CANVAS_CTX_FONT
                            let txtOk = `OK`
                            ctx.fillStyle = "green"
                            if (Math.abs(dMin) > dAtMaxAcceptableDeformation) {
                                txtOk = `NOK`
                                ctx.fillStyle = "red"
                            }
                            m = ctx.measureText(txtOk)
                            ctx.fillText(
                                txtOk,
                                xMid + m.width + 5,
                                (y + yMeas) / 2,
                            )
                        }
                    },
                    (u) => {
                        const ctx = u.ctx
                        // Horizontal line @ maxDepth
                        if (totalDepth !== null) {
                            const y = u.valToPos(-totalDepth.mean, "y", true)
                            const x0 = u.valToPos(0, "x", true)
                            const x1 = u.valToPos(totalTMs, "x", true)
                            ctx.strokeStyle = "black"
                            ctx.lineWidth = 2
                            ctx.setLineDash([])
                            ctx.beginPath()
                            ctx.moveTo(x0, y)
                            ctx.lineTo(x1, y)
                            ctx.stroke()

                            // Note maxDepth
                            ctx.font = CANVAS_CTX_FONT
                            let txt = `${totalDepth.mean.toFixed(0)}mm — 100%`
                            ctx.fillStyle = "black"
                            ctx.fillText(txt, x1, y - 10)
                        }

                        // Lines @ dMin
                        const yMain = u.valToPos(dMin, "y", true)
                        const ySecondary = u.valToPos(0, "y", true)
                        const xLeft = u.valToPos(0, "x", true)
                        const xMid = u.valToPos(tAtDMin, "x", true)
                        const xRight = u.valToPos(totalTMs, "x", true)
                        ctx.strokeStyle = "black"
                        ctx.lineWidth = 1
                        ctx.setLineDash([10, 10])
                        ctx.beginPath()
                        ctx.moveTo(xLeft, yMain)
                        ctx.lineTo(xRight, yMain)
                        ctx.moveTo(xMid, yMain)
                        ctx.lineTo(xMid, ySecondary)
                        ctx.stroke()

                        // reset styles
                        ctx.setLineDash([])

                        // Note dMin
                        ctx.font = CANVAS_CTX_FONT
                        let txt = `${Math.abs(dMin).toFixed(1)}mm`
                        ctx.fillStyle = "black"
                        ctx.fillText(txt, xRight, yMain + 10)

                        // Note deformation percentage
                        if (totalDepth !== null) {
                            let deformationPerc =
                                100 * (Math.abs(dMin) / totalDepth.mean)
                            let deformationDistanceMiddle = dMin / 2
                            let txt = `${deformationPerc.toFixed(0)}%`
                            const yMid = u.valToPos(
                                deformationDistanceMiddle,
                                "y",
                                true,
                            )
                            ctx.fillStyle = "black"
                            ctx.fillText(txt, xMid, yMid)
                        }
                    },
                ],
            },
        })
    }, [kinematicPoints, shouldDrawRequirements])

    return (
        <ResizableUplot
            uplotOptions={uplotOptions}
            uplotData={uplotData}
            mooSync={mooSync}
        />
    )
}
