import { useCallback, useEffect, useRef, useState } from "react"
import {
    Annex,
    BashCmd,
    Equipment,
    Impact,
    ImpactWithReferences,
    LegacyHostApiRequest,
    LegacyHostApiResponse,
    RawStorageEntity,
    ReleaseRequest,
    ReleaseResponse,
    Site,
    StorageEntityType,
    StorageRequest,
    StorageResponse,
    StorageStats,
    UCAck,
    UCError,
    UCPayload,
    UCPayloadType,
    UUID,
    Zone,
} from "../generated/proto-ts/main"
import { v4 as uuidv4, stringify as uuidStringify } from "uuid"
import { Mutex, MutexInterface } from "async-mutex"
import { decodeLengthDelimitedArray, equalUint8Array } from "./usercommUtils"
import { useUsercommContextBLE } from "./usercommProviderBLE"
import { pbUUIDToUuid, uuidToPbUUID } from "../utils/utils"
import { message as antdMessage } from "antd"

export const DEFAULT_PREPROCESS_DECIMATION_RATE = 10

export const useUsercommAsyncRequestBLE = (): [
    UCPayload | null,
    (payload: UCPayload) => void,
] => {
    const [usercommRequestUUID, setUsercommRequestUUID] =
        useState<Uint8Array | null>(null)
    const [usercommResponse, setUsercommResponse] = useState<UCPayload | null>(
        null,
    )
    const { recvMessageQueue, consumeRecvMessage, addEmitMessage } =
        useUsercommContextBLE()
    const asyncMutexRef = useRef<MutexInterface>(new Mutex())

    const usercommRequest = useCallback(async (payload: UCPayload) => {
        // console.log("UsercommRequest (BLE): request", payload.toObject())
        if (asyncMutexRef.current.isLocked() && usercommRequestUUID !== null) {
            console.log(
                `UsercommRequest (BLE): mutex is already locked: request #${uuidStringify(usercommRequestUUID)} is already in progress`,
            )
            return
        }
        await asyncMutexRef.current.acquire()
        let _uuid = uuidv4<Uint8Array>(null, new Uint8Array(16))
        setUsercommRequestUUID(_uuid)
        payload.uuid = new UUID({ value: _uuid })
        addEmitMessage(payload)
    }, [])

    useEffect(() => {
        if (usercommRequestUUID === null) {
            return
        }
        for (let msg of recvMessageQueue) {
            if (!msg.has_uuid) {
                continue
            }
            if (equalUint8Array(msg.uuid.value, usercommRequestUUID)) {
                consumeRecvMessage(msg.uuid)
                setUsercommResponse(msg)
                asyncMutexRef.current.release()
                setUsercommRequestUUID(null)
                break
            }
        }
    }, [usercommRequestUUID, recvMessageQueue])

    return [usercommResponse, usercommRequest]
}

const _handleUCPayloadError = (
    key: string,
    expectedPayloadType: UCPayloadType,
    responsePayload: UCPayload,
): boolean => {
    if (responsePayload.type === UCPayloadType.SICO_ERROR) {
        let error = UCError.deserializeBinary(responsePayload.data)
        let msg = `Error ${key}: ${error.value}`
        console.error(msg)
        antdMessage.error(msg)
        return false
    }
    if (responsePayload.type !== expectedPayloadType) {
        let msg = `Could not get ${key}: unexpected response type: ${responsePayload.type}`
        console.error(msg)
        antdMessage.error(msg)
        return false
    }
    return true
}

// STATS
export const useUsercommStatsBLE = (): [StorageStats | null, () => void] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [stats, setStats] = useState<StorageStats | null>(null)
    const [trigger, setTrigger] = useState<number>(0)

    useEffect(() => {
        if (trigger === 0) {
            return
        }
        let storageRequest = new StorageRequest()
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_STATS,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [trigger])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting stats",
                UCPayloadType.SICO_STORAGE_GET_STATS,
                responsePayload,
            )
        ) {
            let _stats = StorageStats.deserializeBinary(responsePayload.data)
            setStats(_stats)
        }
    }, [responsePayload])

    const getStats = useCallback(() => {
        setTrigger((prev) => prev + 1)
    }, [])

    return [stats, getStats]
}

// SITES
export const useUsercommSitesBLE = (): [Site[] | null, () => void] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [sites, setSites] = useState<Site[] | null>(null)
    const [trigger, setTrigger] = useState<number>(0)

    useEffect(() => {
        if (trigger === 0) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [trigger])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting sites",
                UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _sites = decodeLengthDelimitedArray(
                Site,
                storageResponse.multiple_entities_data,
            )
            setSites(_sites)
        }
    }, [responsePayload])

    const getSites = useCallback(() => {
        setTrigger((prev) => prev + 1)
    }, [])

    return [sites, getSites]
}

export const useUsercommSiteBLE = (): [
    Site | null,
    (siteUUID: string | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [siteUUID, setSiteUUID] = useState<string | null>(null)
    const [site, setSite] = useState<Site | null>(null)

    useEffect(() => {
        if (siteUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
            entity_uuid: uuidToPbUUID(siteUUID),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [siteUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting site",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _site = Site.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setSite(_site)
        }
    }, [responsePayload])

    const getSite = useCallback((siteUUID: string | null) => {
        setSiteUUID(siteUUID)
    }, [])

    return [site, getSite]
}

export const useUsercommCreateSiteBLE = (): [
    UUID | null,
    (userUUID: string | null, site: Site | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)
    const [site, setSite] = useState<Site | null>(null)
    const [userUUID, setUserUUID] = useState<string | null>(null)

    useEffect(() => {
        if (site === null || userUUID === null) {
            return
        }
        site.uuid = uuidToPbUUID(uuidv4())
        site.user_uuid = uuidToPbUUID(userUUID)
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
            parent_uuid: uuidToPbUUID(userUUID),
            entity_uuid: site.uuid,
            single_entity_data: site.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [site, userUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating site",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    const createSite = useCallback(
        (userUUID: string | null, site: Site | null) => {
            setUserUUID(userUUID)
            setSite(site)
        },
        [],
    )

    return [newUUID, createSite]
}

export const useUsercommUpdateSiteBLE = (): [
    UCAck | null,
    (site: Site) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [site, setSite] = useState<Site | null>(null)

    useEffect(() => {
        if (site === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
            entity_uuid: site.uuid,
            single_entity_data: site.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [site])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating site",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const updateSite = useCallback((site: Site) => {
        setSite(site)
    }, [])

    return [ack, updateSite]
}

export const useUsercommDeleteSiteBLE = (): [
    UCAck | null,
    (siteUUID: string) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [siteUUID, setSiteUUID] = useState<string | null>(null)

    useEffect(() => {
        if (siteUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
            entity_uuid: uuidToPbUUID(siteUUID),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [siteUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting site",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteSite = useCallback((siteUUID: string) => {
        setSiteUUID(siteUUID)
    }, [])

    return [ack, deleteSite]
}

export const useUsercommDeleteSiteSoftBLE = (): [
    UCAck | null,
    (site: Site) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [site, setSite] = useState<Site | null>(null)

    useEffect(() => {
        if (site === null) {
            return
        }
        site.deleted_at = Date.now()
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
            entity_uuid: site.uuid,
            single_entity_data: site.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [site])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "soft-deleting site",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteSiteSoft = useCallback((site: Site) => {
        setSite(site)
    }, [])

    return [ack, deleteSiteSoft]
}

// ANNEXES
export const useUsercommSiteAnnexesBLE = (): [
    Annex[] | null,
    (siteUUID: string | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [annexes, setAnnexes] = useState<Annex[] | null>(null)

    const requestAnnexes = useCallback((siteUUID: string | null) => {
        if (siteUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ANNEX,
            parent_uuid: uuidToPbUUID(siteUUID),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting annexes",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _annexes = decodeLengthDelimitedArray(
                Annex,
                storageResponse.multiple_entities_data,
            )
            setAnnexes(_annexes)
        }
    }, [responsePayload])

    const getAnnexes = useCallback((siteUUID: string | null) => {
        requestAnnexes(siteUUID)
    }, [])

    return [annexes, getAnnexes]
}

export const useUsercommAnnexBLE = (): [
    Annex | null,
    (annexUUID: string | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [annexUUID, setAnnexUUID] = useState<string | null>(null)
    const [annex, setAnnex] = useState<Annex | null>(null)

    useEffect(() => {
        if (annexUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ANNEX,
            entity_uuid: uuidToPbUUID(annexUUID),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [annexUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting annex",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _annex = Annex.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setAnnex(_annex)
        }
    }, [responsePayload])

    const getAnnex = useCallback((annexUUID: string | null) => {
        setAnnexUUID(annexUUID)
    }, [])

    return [annex, getAnnex]
}

export const useUsercommCreateAnnexBLE = (): [
    UUID | null,
    (siteUUID: string | null, annex: Annex | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)
    const [annex, setAnnex] = useState<Annex | null>(null)
    const [siteUUID, setSiteUUID] = useState<string | null>(null)

    useEffect(() => {
        if (annex === null || siteUUID === null) {
            return
        }
        annex.uuid = uuidToPbUUID(uuidv4())
        annex.site_uuid = uuidToPbUUID(siteUUID)
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ANNEX,
            parent_uuid: uuidToPbUUID(siteUUID),
            entity_uuid: annex.uuid,
            single_entity_data: annex.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [annex, siteUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating annex",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    const createAnnex = useCallback(
        (siteUUID: string | null, annex: Annex | null) => {
            setSiteUUID(siteUUID)
            setAnnex(annex)
        },
        [],
    )

    return [newUUID, createAnnex]
}

export const useUsercommUpdateAnnexBLE = (): [
    UCAck | null,
    (annex: Annex) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [annex, setAnnex] = useState<Annex | null>(null)

    useEffect(() => {
        if (annex === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ANNEX,
            entity_uuid: annex.uuid,
            single_entity_data: annex.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [annex])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating annex",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const updateAnnex = useCallback((annex: Annex) => {
        setAnnex(annex)
    }, [])

    return [ack, updateAnnex]
}

export const useUsercommDeleteAnnexBLE = (): [
    UCAck | null,
    (annexUUID: Annex["uuid"]) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [annexUUID, setAnnexUUID] = useState<Annex["uuid"] | null>(null)

    useEffect(() => {
        if (annexUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ANNEX,
            entity_uuid: annexUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [annexUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting annex",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteAnnex = useCallback((annexUUID: Annex["uuid"]) => {
        setAnnexUUID(annexUUID)
    }, [])

    return [ack, deleteAnnex]
}

// RECURSIVE SITE CHILDREN
export const useUsercommSiteChildrenRecursiveBLE = (): [
    Record<string, Equipment[]>, // Site's equipments
    Record<string, Zone[]>, // Equipment's zones
    Record<string, Impact[]>, // Zone's impacts
    (
        siteUUID: Site["uuid"] | null,
        preprocess_include_quadratic_points: boolean,
    ) => void,
] => {
    const [siteEquipmentsMap, setSiteEquipmentsMap] = useState<
        Record<string, Equipment[]>
    >({})
    const [equipmentZonesMap, setEquipmentZonesMap] = useState<
        Record<string, Zone[]>
    >({})
    const [zoneImpactsMap, setZoneImpactsMap] = useState<
        Record<string, Impact[]>
    >({})

    const [getEntitiesRecursiveResponse, getEntitiesRecursiveRequest] =
        useUsercommAsyncRequestBLE()

    // REQUEST ENTITIES RECURSIVE
    const requestEntitiesRecursive = useCallback(
        (
            siteUUID: Site["uuid"] | null,
            preprocess_include_quadratic_points: boolean,
        ) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                parent_uuid: siteUUID,
                preprocess_decimation_rate: DEFAULT_PREPROCESS_DECIMATION_RATE,
                preprocess_include_axes_points: false,
                preprocess_include_quadratic_points,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                data: storageRequest.serializeBinary(),
            })
            getEntitiesRecursiveRequest(payload)
        },
        [],
    )
    // RESPONSE ENTITIES RECURSIVE
    useEffect(() => {
        if (getEntitiesRecursiveResponse === null) {
            return
        }
        if (
            !_handleUCPayloadError(
                "getting site children recursive",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                getEntitiesRecursiveResponse,
            )
        ) {
            return
        }
        let storageResponse = StorageResponse.deserializeBinary(
            getEntitiesRecursiveResponse.data,
        )
        let rawStorageEntities = decodeLengthDelimitedArray(
            RawStorageEntity,
            storageResponse.multiple_entities_data,
        )
        let _siteEquipmentsMap: Record<string, Equipment[]> = {}
        let _equipmentZonesMap: Record<string, Zone[]> = {}
        let _zoneImpactsMap: Record<string, Impact[]> = {}
        for (let entity of rawStorageEntities) {
            // console.log(`SiteChildrenRecursive: entity`, entity.toObject())
            if (entity.entity_type === StorageEntityType.EQUIPMENT) {
                let equipment = Equipment.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_siteEquipmentsMap[parentUUIDStr] === undefined) {
                    _siteEquipmentsMap[parentUUIDStr] = []
                }
                _siteEquipmentsMap[parentUUIDStr].push(equipment)
            }
            if (entity.entity_type === StorageEntityType.ZONE) {
                let zone = Zone.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_equipmentZonesMap[parentUUIDStr] === undefined) {
                    _equipmentZonesMap[parentUUIDStr] = []
                }
                _equipmentZonesMap[parentUUIDStr].push(zone)
            }
            if (entity.entity_type === StorageEntityType.IMPACT) {
                let impact = Impact.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_zoneImpactsMap[parentUUIDStr] === undefined) {
                    _zoneImpactsMap[parentUUIDStr] = []
                }
                _zoneImpactsMap[parentUUIDStr].push(impact)
            }
        }
        setSiteEquipmentsMap(_siteEquipmentsMap)
        setEquipmentZonesMap(_equipmentZonesMap)
        setZoneImpactsMap(_zoneImpactsMap)
    }, [getEntitiesRecursiveResponse])

    return [
        siteEquipmentsMap,
        equipmentZonesMap,
        zoneImpactsMap,
        requestEntitiesRecursive,
    ]
}

// EQUIPMENTS
export const useUsercommSiteEquipmentsBLE = (): [
    Equipment[] | null,
    (siteUUID: UUID | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [equipments, setEquipments] = useState<Equipment[] | null>(null)
    const [siteUUID, setSiteUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (siteUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.EQUIPMENT,
            parent_uuid: siteUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [siteUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting equipments",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _equipments = decodeLengthDelimitedArray(
                Equipment,
                storageResponse.multiple_entities_data,
            )
            setEquipments(_equipments)
        }
    }, [responsePayload])

    const getEquipments = useCallback((siteUUID: UUID | null) => {
        setSiteUUID(siteUUID)
    }, [])

    return [equipments, getEquipments]
}

export const useUsercommEquipmentBLE = (): [
    Equipment | null,
    (equipmentUUID: UUID | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [equipmentUUID, setEquipmentUUID] = useState<UUID | null>(null)
    const [equipment, setEquipment] = useState<Equipment | null>(null)

    useEffect(() => {
        if (equipmentUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.EQUIPMENT,
            entity_uuid: equipmentUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [equipmentUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting equipment",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _equipment = Equipment.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setEquipment(_equipment)
        }
    }, [responsePayload])

    const getEquipment = useCallback((equipmentUUID: UUID | null) => {
        setEquipmentUUID(equipmentUUID)
    }, [])

    return [equipment, getEquipment]
}

export const useUsercommCreateEquipmentBLE = (): [
    UUID | null,
    (siteUUID: UUID | null, equipment: Equipment | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)
    const [equipment, setEquipment] = useState<Equipment | null>(null)
    const [siteUUID, setSiteUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (equipment === null || siteUUID === null) {
            return
        }
        equipment.uuid = uuidToPbUUID(uuidv4())
        equipment.site_uuid = siteUUID
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.EQUIPMENT,
            parent_uuid: siteUUID,
            entity_uuid: equipment.uuid,
            single_entity_data: equipment.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [equipment, siteUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating equipment",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    const createEquipment = useCallback(
        (siteUUID: UUID | null, equipment: Equipment | null) => {
            setSiteUUID(siteUUID)
            setEquipment(equipment)
        },
        [],
    )

    return [newUUID, createEquipment]
}

export const useUsercommUpdateEquipmentBLE = (): [
    UCAck | null,
    (equipment: Equipment) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [equipment, setEquipment] = useState<Equipment | null>(null)

    useEffect(() => {
        if (equipment === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.EQUIPMENT,
            entity_uuid: equipment.uuid,
            single_entity_data: equipment.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [equipment])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating equipment",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const updateEquipment = useCallback((equipment: Equipment) => {
        setEquipment(equipment)
    }, [])

    return [ack, updateEquipment]
}

export const useUsercommDeleteEquipmentBLE = (): [
    UCAck | null,
    (equipmentUUID: UUID) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [equipmentUUID, setEquipmentUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (equipmentUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.EQUIPMENT,
            entity_uuid: equipmentUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [equipmentUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting equipment",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteEquipment = useCallback((equipmentUUID: UUID) => {
        setEquipmentUUID(equipmentUUID)
    }, [])

    return [ack, deleteEquipment]
}

export const useUsercommDeleteEquipmentSoftBLE = (): [
    UCAck | null,
    (equipment: Equipment) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [equipment, setEquipment] = useState<Equipment | null>(null)

    useEffect(() => {
        if (equipment === null) {
            return
        }
        equipment.deleted_at = Date.now()
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.EQUIPMENT,
            entity_uuid: equipment.uuid,
            single_entity_data: equipment.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [equipment])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "soft-deleting equipment",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteEquipmentSoft = useCallback((equipment: Equipment) => {
        setEquipment(equipment)
    }, [])

    return [ack, deleteEquipmentSoft]
}

// RECURSIVE EQUIPMENT CHILDREN
export const useUsercommEquipmentChildrenRecursiveBLE = (): [
    Record<string, Impact[]>, // Zone's impacts
    (equipmentUUID: Equipment["uuid"] | null) => void,
] => {
    const [equipmentUUID, setEquipmentUUID] = useState<
        Equipment["uuid"] | null
    >(null)

    const [getEntitiesRecursiveResponse, getEntitiesRecursiveRequest] =
        useUsercommAsyncRequestBLE()

    const [zoneImpactsMap, setZoneImpactsMap] = useState<
        Record<string, Impact[]>
    >({})

    // REQUEST ENTITIES RECURSIVE
    useEffect(() => {
        if (equipmentUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            parent_uuid: equipmentUUID,
            preprocess_decimation_rate: DEFAULT_PREPROCESS_DECIMATION_RATE,
            preprocess_include_axes_points: false,
            preprocess_include_quadratic_points: true,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
            data: storageRequest.serializeBinary(),
        })
        getEntitiesRecursiveRequest(payload)
    }, [equipmentUUID])
    // RESPONSE ENTITIES RECURSIVE
    useEffect(() => {
        if (getEntitiesRecursiveResponse === null) {
            return
        }
        if (
            !_handleUCPayloadError(
                "getting equipment children recursive",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                getEntitiesRecursiveResponse,
            )
        ) {
            return
        }
        let storageResponse = StorageResponse.deserializeBinary(
            getEntitiesRecursiveResponse.data,
        )
        let rawStorageEntities = decodeLengthDelimitedArray(
            RawStorageEntity,
            storageResponse.multiple_entities_data,
        )
        let _zoneImpactsMap: Record<string, Impact[]> = {}
        for (let entity of rawStorageEntities) {
            if (entity.entity_type === StorageEntityType.IMPACT) {
                let impact = Impact.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_zoneImpactsMap[parentUUIDStr] === undefined) {
                    _zoneImpactsMap[parentUUIDStr] = []
                }
                _zoneImpactsMap[parentUUIDStr].push(impact)
            }
        }
        setZoneImpactsMap(_zoneImpactsMap)
    }, [getEntitiesRecursiveResponse])

    const getEntitiesRecursive = useCallback(
        (equipmentUUID: Equipment["uuid"] | null) => {
            setEquipmentUUID(equipmentUUID)
        },
        [],
    )
    return [zoneImpactsMap, getEntitiesRecursive]
}

// ZONES
export const useUsercommEquipmentZonesBLE = (): [
    Zone[] | null,
    (equipmentUUID: UUID | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [zones, setZones] = useState<Zone[] | null>(null)
    const [equipmentUUID, setEquipmentUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (equipmentUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ZONE,
            parent_uuid: equipmentUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [equipmentUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting zones",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _zones = decodeLengthDelimitedArray(
                Zone,
                storageResponse.multiple_entities_data,
            )
            setZones(_zones)
        }
    }, [responsePayload])

    const getZones = useCallback((equipmentUUID: UUID | null) => {
        setEquipmentUUID(equipmentUUID)
    }, [])

    return [zones, getZones]
}

export const useUsercommZoneBLE = (): [
    Zone | null,
    (zoneUUID: UUID | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [zoneUUID, setZoneUUID] = useState<UUID | null>(null)
    const [zone, setZone] = useState<Zone | null>(null)

    useEffect(() => {
        if (zoneUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ZONE,
            entity_uuid: zoneUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [zoneUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting zone",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _zone = Zone.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setZone(_zone)
        }
    }, [responsePayload])

    const getZone = useCallback((zoneUUID: UUID | null) => {
        setZoneUUID(zoneUUID)
    }, [])

    return [zone, getZone]
}

export const useUsercommCreateZoneBLE = (): [
    UUID | null,
    (equipmentUUID: UUID | null, zone: Zone | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)
    const [zone, setZone] = useState<Zone | null>(null)
    const [equipmentUUID, setEquipmentUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (zone === null || equipmentUUID === null) {
            return
        }
        zone.uuid = uuidToPbUUID(uuidv4())
        zone.equipment_uuid = equipmentUUID
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ZONE,
            parent_uuid: equipmentUUID,
            entity_uuid: zone.uuid,
            single_entity_data: zone.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [zone, equipmentUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating zone",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    const createZone = useCallback(
        (equipmentUUID: UUID | null, zone: Zone | null) => {
            setEquipmentUUID(equipmentUUID)
            setZone(zone)
        },
        [],
    )

    return [newUUID, createZone]
}

export const useUsercommUpdateZoneBLE = (): [
    UCAck | null,
    (zone: Zone) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [zone, setZone] = useState<Zone | null>(null)

    useEffect(() => {
        if (zone === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ZONE,
            entity_uuid: zone.uuid,
            single_entity_data: zone.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [zone])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating zone",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const updateZone = useCallback((zone: Zone) => {
        setZone(zone)
    }, [])

    return [ack, updateZone]
}

export const useUsercommDeleteZoneBLE = (): [
    UCAck | null,
    (zoneUUID: UUID) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [zoneUUID, setZoneUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (zoneUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ZONE,
            entity_uuid: zoneUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [zoneUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting zone",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteZone = useCallback((zoneUUID: UUID) => {
        setZoneUUID(zoneUUID)
    }, [])

    return [ack, deleteZone]
}

export const useUsercommDeleteZoneSoftBLE = (): [
    UCAck | null,
    (zone: Zone) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [zone, setZone] = useState<Zone | null>(null)

    useEffect(() => {
        if (zone === null) {
            return
        }
        zone.deleted_at = Date.now()
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.ZONE,
            entity_uuid: zone.uuid,
            single_entity_data: zone.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [zone])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "soft-deleting zone",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteZoneSoft = useCallback((zone: Zone) => {
        setZone(zone)
    }, [])

    return [ack, deleteZoneSoft]
}

// IMPACTS

export const useUsercommZoneImpactsBLE = (): [
    Impact[] | null,
    (zoneUUID: UUID | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [impacts, setImpacts] = useState<Impact[] | null>(null)
    const [zoneUUID, setZoneUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (zoneUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            parent_uuid: zoneUUID,
            preprocess_decimation_rate: 10,
            preprocess_include_axes_points: false,
            preprocess_include_quadratic_points: true,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [zoneUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting impacts",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _impacts = decodeLengthDelimitedArray(
                Impact,
                storageResponse.multiple_entities_data,
            )

            setImpacts(_impacts)
        }
    }, [responsePayload])

    const getImpacts = useCallback((zoneUUID: UUID | null) => {
        setZoneUUID(zoneUUID)
    }, [])

    return [impacts, getImpacts]
}

export const useUsercommImpactsBLE = (): [Impact[] | null, () => void] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [impacts, setImpacts] = useState<Impact[] | null>(null)

    const requestImpacts = useCallback(() => {
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            preprocess_decimation_rate: DEFAULT_PREPROCESS_DECIMATION_RATE,
            preprocess_include_axes_points: false,
            preprocess_include_quadratic_points: false,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting all impacts",
                UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _impacts = decodeLengthDelimitedArray(
                Impact,
                storageResponse.multiple_entities_data,
            )

            setImpacts(_impacts)
        }
    }, [responsePayload])

    return [impacts, requestImpacts]
}

export interface SyntheticImpactWithEmbeddedReferences {
    impact: Impact
    zone: Zone | null
    equipment: Equipment | null
    site: Site | null
}
export const useUsercommSyntheticImpactWithEmbeddedReferencesBLE = (): [
    SyntheticImpactWithEmbeddedReferences[] | null,
    () => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [syntheticImpacts, setSyntheticImpacts] = useState<
        SyntheticImpactWithEmbeddedReferences[] | null
    >(null)

    const requestAllEntities = useCallback(() => {
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            preprocess_decimation_rate: DEFAULT_PREPROCESS_DECIMATION_RATE,
            preprocess_include_axes_points: false,
            preprocess_include_quadratic_points: false,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting synthetic impacts",
                UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let rawStorageEntities = decodeLengthDelimitedArray(
                RawStorageEntity,
                storageResponse.multiple_entities_data,
            )
            let sitesMap: Record<string, Site> = {}
            let equipmentsMap: Record<string, Equipment> = {}
            let zonesMap: Record<string, Zone> = {}
            let _syntheticImpacts: SyntheticImpactWithEmbeddedReferences[] = []
            for (let rawStorageEntity of rawStorageEntities) {
                switch (rawStorageEntity.entity_type) {
                    case StorageEntityType.SITE:
                        let site = Site.deserializeBinary(rawStorageEntity.data)
                        sitesMap[pbUUIDToUuid(rawStorageEntity.uuid)] = site
                        break
                    case StorageEntityType.EQUIPMENT:
                        let equipment = Equipment.deserializeBinary(
                            rawStorageEntity.data,
                        )
                        equipmentsMap[pbUUIDToUuid(rawStorageEntity.uuid)] =
                            equipment
                        break
                    case StorageEntityType.ZONE:
                        let zone = Zone.deserializeBinary(rawStorageEntity.data)
                        zonesMap[pbUUIDToUuid(rawStorageEntity.uuid)] = zone
                        break
                    case StorageEntityType.IMPACT:
                        let impact = Impact.deserializeBinary(
                            rawStorageEntity.data,
                        )
                        let syntheticImpact: SyntheticImpactWithEmbeddedReferences =
                            {
                                impact: impact,
                                zone: null,
                                equipment: null,
                                site: null,
                            }
                        _syntheticImpacts.push(syntheticImpact)
                        break
                }
            }
            for (let syntheticImpact of _syntheticImpacts) {
                if (!syntheticImpact.impact.zone_uuid) {
                    continue
                }
                let zoneUUID = pbUUIDToUuid(syntheticImpact.impact.zone_uuid)
                let zone = zonesMap[zoneUUID]
                if (zone === undefined) {
                    console.warn(
                        `Zone not found for impact ${syntheticImpact.impact.uuid}`,
                    )
                    continue
                }
                syntheticImpact.zone = zone
                let equipmentUUID = pbUUIDToUuid(zone.equipment_uuid)
                let equipment = equipmentsMap[equipmentUUID]
                if (equipment === undefined) {
                    console.warn(
                        `Equipment not found for impact ${syntheticImpact.impact.uuid}`,
                    )
                    continue
                }
                syntheticImpact.equipment = equipment
                let siteUUID = pbUUIDToUuid(equipment.site_uuid)
                let site = sitesMap[siteUUID]
                if (site === undefined) {
                    console.warn(
                        `Site not found for impact ${syntheticImpact.impact.uuid}`,
                    )
                    continue
                }
                syntheticImpact.site = site
            }
            setSyntheticImpacts(_syntheticImpacts)
        }
    }, [responsePayload])

    return [syntheticImpacts, requestAllEntities]
}

export const useUsercommImpactBLE = (): [
    Impact | null,
    (impactUUID: UUID | null, decimationRate?: number) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [impact, setImpact] = useState<Impact | null>(null)

    const requestImpact = useCallback(
        (impactUUID: UUID | null, decimationRate?: number) => {
            if (impactUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.IMPACT,
                entity_uuid: impactUUID,
                preprocess_decimation_rate:
                    decimationRate === undefined
                        ? DEFAULT_PREPROCESS_DECIMATION_RATE
                        : decimationRate,
                preprocess_include_axes_points: true,
                preprocess_include_quadratic_points: true,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting impact",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _impact = Impact.deserializeBinary(
                storageResponse.single_entity_data,
            )

            setImpact(_impact)
        }
    }, [responsePayload])

    const getImpact = useCallback(
        (impactUUID: UUID | null, decimationRate?: number) => {
            requestImpact(impactUUID, decimationRate)
        },
        [],
    )

    return [impact, getImpact]
}

const useUsercommUpdateImpactBLE = (): [
    UCAck | null,
    (impact: Impact) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [impact, setImpact] = useState<Impact | null>(null)

    useEffect(() => {
        if (impact === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            entity_uuid: impact.uuid,
            single_entity_data: impact.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [impact])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating impact",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const updateImpact = useCallback((impact: Impact) => {
        setImpact(impact)
    }, [])

    return [ack, updateImpact]
}

export const useUsercommDeleteImpactBLE = (): [
    UCAck | null,
    (impactUUID: UUID) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [impactUUID, setImpactUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (impactUUID === null) {
            return
        }
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            entity_uuid: impactUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [impactUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting impact",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteImpact = useCallback((impactUUID: UUID | null) => {
        setImpactUUID(impactUUID)
    }, [])

    return [ack, deleteImpact]
}

export const useUsercommDeleteImpactSoftBLE = (): [
    UCAck | null,
    (impact: Impact) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [impact, setImpact] = useState<Impact | null>(null)

    useEffect(() => {
        if (impact === null) {
            return
        }
        impact.deleted_at = Date.now()
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            entity_uuid: impact.uuid,
            single_entity_data: impact.serializeBinary(),
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [impact])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "soft-deleting impact",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteImpactSoft = useCallback((impact: Impact) => {
        setImpact(impact)
    }, [])

    return [ack, deleteImpactSoft]
}

export const useUsercommSetImpactParentBLE = (): [
    UCAck | null,
    (impactUUID: UUID, newParentUUID: UUID) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [impactUUID, setImpactUUID] = useState<UUID | null>(null)
    const [newParentUUID, setNewParentUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (impactUUID === null || newParentUUID === null) {
            return
        }
        console.log(
            `Usercomm: about to set impact parent`,
            impactUUID,
            newParentUUID,
        )
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
            entity_uuid: impactUUID,
            parent_uuid: newParentUUID,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_SET_ENTITY_PARENT,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [impactUUID, newParentUUID])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "setting impact parent",
                UCPayloadType.SICO_STORAGE_SET_ENTITY_PARENT,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const setImpactParentUUID = useCallback(
        (impactUUID: UUID | null, newParentUUID: UUID | null) => {
            setImpactUUID(impactUUID)
            setNewParentUUID(newParentUUID)
        },
        [],
    )

    return [ack, setImpactParentUUID]
}

export const useUsercommLegacyHostApiBLE = (): [
    LegacyHostApiResponse | null,
    (path: string | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [legacyHostApiResponse, setLegacyHostApiResponse] =
        useState<LegacyHostApiResponse | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let legacyHostApiResponse = LegacyHostApiResponse.deserializeBinary(
            responsePayload.data,
        )
        setLegacyHostApiResponse(legacyHostApiResponse)
    }, [responsePayload])

    const sendLegacyHostApiRequest = useCallback(
        (legacyHostApiRequest: LegacyHostApiRequest) => {
            let payload = new UCPayload({
                type: UCPayloadType.SICO_LEGACY_HOST_API_REQUEST,
                data: legacyHostApiRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [],
    )

    const requestLegacyHostApi = useCallback((path: string | null) => {
        if (path === null) {
            return
        }
        let legacyHostApiRequest = new LegacyHostApiRequest({
            path: path,
        })
        sendLegacyHostApiRequest(legacyHostApiRequest)
    }, [])

    return [legacyHostApiResponse, requestLegacyHostApi]
}

export const useUsercommGetReleasesBLE = (): [
    ReleaseResponse | null,
    () => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [releases, setReleases] = useState<ReleaseResponse | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let releaseResponse = ReleaseResponse.deserializeBinary(
            responsePayload.data,
        )
        setReleases(releaseResponse)
    }, [responsePayload])

    const getReleases = useCallback(() => {
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GET_RELEASES,
        })
        setRequestPayload(payload)
    }, [])

    return [releases, getReleases]
}

export const useUsercommRenameReleaseBLE = (): [
    UCAck | null,
    (releaseKey: string, newReleaseKey: string) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [releaseKey, setReleaseKey] = useState<string | null>(null)
    const [newReleaseKey, setNewReleaseKey] = useState<string | null>(null)

    useEffect(() => {
        if (releaseKey === null || newReleaseKey === null) {
            return
        }
        let releaseRequest = new ReleaseRequest({
            release_key: releaseKey,
            new_release_key: newReleaseKey,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_RENAME_RELEASE,
            data: releaseRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [releaseKey, newReleaseKey])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "renaming release",
                UCPayloadType.SICO_RENAME_RELEASE,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const renameRelease = useCallback(
        (releaseKey: string, newReleaseKey: string) => {
            setReleaseKey(releaseKey)
            setNewReleaseKey(newReleaseKey)
        },
        [],
    )

    return [ack, renameRelease]
}

export const useUsercommDeleteReleaseBLE = (): [
    UCAck | null,
    (releaseKey: string) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [releaseKey, setReleaseKey] = useState<string | null>(null)

    useEffect(() => {
        if (releaseKey === null) {
            return
        }
        let deleteReleaseRequest = new ReleaseRequest({
            release_key: releaseKey,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_DELETE_RELEASE,
            data: deleteReleaseRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [releaseKey])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting release",
                UCPayloadType.SICO_DELETE_RELEASE,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const deleteRelease = useCallback((releaseKey: string) => {
        setReleaseKey(releaseKey)
    }, [])

    return [ack, deleteRelease]
}

export const useUsercommSetReleaseBLE = (): [
    ReleaseResponse | null,
    (releaseKey: string, releaseData: Uint8Array, offset: number) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [releaseResponse, setReleaseResponse] =
        useState<ReleaseResponse | null>(null)

    const requestSetRelease = useCallback(
        async (
            releaseKey: string,
            releaseValue: Uint8Array,
            offset: number,
        ) => {
            if (releaseKey === null || releaseValue === null) {
                return
            }
            console.log(`Usercomm: about to set release`, releaseKey, offset)
            let setReleaseRequest = new ReleaseRequest({
                release_key: releaseKey,
                release_data: releaseValue,
                offset: offset,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_SET_RELEASE,
                data: setReleaseRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "setting release data on offset",
                UCPayloadType.SICO_SET_RELEASE,
                responsePayload,
            )
        ) {
            let releaseResponse = ReleaseResponse.deserializeBinary(
                responsePayload.data,
            )
            setReleaseResponse(releaseResponse)
        }
    }, [responsePayload])

    return [releaseResponse, requestSetRelease]
}

export const useUsercommBashCommandBLE = (): [
    UCAck | null,
    (command: string) => void,
] => {
    const [responsePayload, setRequestPayload] = useUsercommAsyncRequestBLE()
    const [ack, setAck] = useState<UCAck | null>(null)

    const requestBashCommand = useCallback((cmd: string) => {
        if (cmd === null) {
            return
        }
        let bashCommandRequest = new BashCmd({
            cmd: cmd,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_BASH_CMD,
            data: bashCommandRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "executing bash command",
                UCPayloadType.SICO_BASH_CMD,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, requestBashCommand]
}
