import {
    Alert,
    Button,
    Col,
    DatePicker,
    Input,
    Row,
    Switch,
    Typography,
    message as antdMessage,
} from "antd"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { SaveOutlined } from "@ant-design/icons"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { Translated } from "../../utils/translated"
import dayjs from "dayjs"
import { Label } from "../../components/commons-ts/input"
import { useUsercommContextBLE } from "../../usercomm/usercommProviderBLE"
import { StationRestartWidget } from "../../components/commons-ts/stationRestartWidget"

export const StationConfigSettings: FC = () => {
    const {
        bleIsConnected,
        stationConfig,

        stationConfigSetAckConsumable,
        consumeStationConfigSetAck,

        emitGetStationConfig,
        emitSetStationConfig,
    } = useUsercommContextBLE()
    const [lastMetrology, setLastMetrology] = useState<dayjs.Dayjs | null>(null)
    const [nextMetrology, setNextMetrology] = useState<dayjs.Dayjs | null>(null)
    const [serialNumber, setSerialNumber] = useState<string | null>(null)
    const [headHicBdAddr, setHeadHicBdAddr] = useState<string | null>(null)
    const [lidarDistanceOffsetCm, setLidarDistanceOffsetCm] = useState<
        string | null
    >(null)
    const [buzzerState, setBuzzerState] = useState<boolean>(false)

    useEffect(() => {
        emitGetStationConfig()
    }, [bleIsConnected])

    useEffect(() => {
        if (stationConfigSetAckConsumable) {
            consumeStationConfigSetAck()
            antdMessage.success("Station configuration saved!")
        }
    }, [stationConfigSetAckConsumable])

    useEffect(() => {
        if (stationConfig === null) {
            return
        }
        if (stationConfig.last_metrology_date > 0) {
            setLastMetrology(dayjs(stationConfig.last_metrology_date))
        }
        if (stationConfig.next_metrology_date > 0) {
            setNextMetrology(dayjs(stationConfig.next_metrology_date))
        }
        setLidarDistanceOffsetCm(
            stationConfig.lidar_distance_offset_cm.toString(),
        )
        if (stationConfig.serial_number !== "") {
            setSerialNumber(stationConfig.serial_number)
        }
        if (stationConfig.head_hic_bd_addr !== "") {
            setHeadHicBdAddr(stationConfig.head_hic_bd_addr)
        }
        if (stationConfig.buzzer_state !== undefined) {
            setBuzzerState(stationConfig.buzzer_state)
        }
    }, [stationConfig])

    const onSave = useCallback(() => {
        if (stationConfig === null) {
            return
        }
        let newStationConfig = stationConfig.clone()
        if (lidarDistanceOffsetCm !== null && lidarDistanceOffsetCm !== "") {
            newStationConfig.lidar_distance_offset_cm = parseInt(
                lidarDistanceOffsetCm,
            )
        }
        if (serialNumber !== null && serialNumber !== "") {
            newStationConfig.serial_number = serialNumber
        }
        if (headHicBdAddr !== null && headHicBdAddr !== "") {
            newStationConfig.head_hic_bd_addr = headHicBdAddr
        }
        if (lastMetrology !== null) {
            newStationConfig.last_metrology_date = lastMetrology
                .toDate()
                .getTime()
        }
        if (nextMetrology !== null) {
            newStationConfig.next_metrology_date = nextMetrology
                .toDate()
                .getTime()
        }
        newStationConfig.buzzer_state = buzzerState
        emitSetStationConfig(newStationConfig)
    }, [
        lastMetrology,
        nextMetrology,
        serialNumber,
        headHicBdAddr,
        lidarDistanceOffsetCm,
        buzzerState,
        emitSetStationConfig,
        stationConfig,
    ])

    const memoSerialNumberOrBdAddrChanged = useMemo(() => {
        if (stationConfig === null) {
            return false
        }
        if (serialNumber !== stationConfig.serial_number) {
            return true
        }
        if (headHicBdAddr !== stationConfig.head_hic_bd_addr) {
            return true
        }
        return false
    }, [stationConfig, serialNumber, headHicBdAddr])

    return (
        <FlexCol
            style={{
                width: "100%",
                // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            <FlexCol style={{ gap: 0 }}>
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="Station" />
                </Typography.Text>
            </FlexCol>
            {/* Picture (HIC) */}
            {/* <FlexCol
                style={{
                    gap: 0,
                }}
            >
                <Label>
                    <Translated keyEn="HIC picture" />
                </Label>
                <UploadImage
                    pictureName={devicePicture}
                    setPictureName={setDevicePicture}
                    processURL="/device-config/picture"
                />
            </FlexCol> */}
            {/* Limit Values */}
            <Row gutter={[10, 10]}>
                <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="Serial number" />
                        </Label>
                        <Input
                            type="text"
                            value={serialNumber ?? ""}
                            onChange={(e) => setSerialNumber(e.target.value)}
                            size="large"
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="HIC BD address" />
                        </Label>
                        <Input
                            type="text"
                            value={headHicBdAddr ?? ""}
                            onChange={(e) => setHeadHicBdAddr(e.target.value)}
                            size="large"
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="Lidar distance offset (cm)" />
                        </Label>
                        <Input
                            type="number"
                            value={lidarDistanceOffsetCm ?? ""}
                            onChange={(e) =>
                                setLidarDistanceOffsetCm(e.target.value)
                            }
                            size="large"
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={12}>
                    <FlexCol style={{ gap: 0, alignItems: "start" }}>
                        <Label>
                            <Translated keyEn="Enable BIP" />
                        </Label>
                        <Switch
                            checked={buzzerState}
                            onChange={(checked) => {
                                setBuzzerState(checked)
                            }}
                        />
                    </FlexCol>
                </Col>
                {/* <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="Gmax level: WARNING" />
                        </Label>
                        <Input
                            type="number"
                            value={gmaxLevelWarningStr ?? ""}
                            onChange={(e) =>
                                setGmaxLevelWarningStr(e.target.value)
                            }
                            size="large"
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="Gmax level: CRITICAL" />
                        </Label>
                        <Input
                            type="number"
                            value={gmaxLevelCriticalStr ?? ""}
                            onChange={(e) =>
                                setGmaxLevelCriticalStr(e.target.value)
                            }
                            size="large"
                            variant="filled"
                        />
                    </FlexCol>
                </Col> */}
                <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="Last metrology" />
                        </Label>
                        <DatePicker
                            value={lastMetrology}
                            onChange={(date) => setLastMetrology(date)}
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </FlexCol>
                </Col>
                <Col xs={12}>
                    <FlexCol style={{ gap: 0 }}>
                        <Label>
                            <Translated keyEn="Next metrology" />
                        </Label>
                        <DatePicker
                            value={nextMetrology}
                            onChange={(date) => setNextMetrology(date)}
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </FlexCol>
                </Col>
                {memoSerialNumberOrBdAddrChanged && (
                    <Col xs={24}>
                        <Alert
                            style={{
                                width: "100%",
                            }}
                            type="warning"
                            message={
                                <FlexRow
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        <Translated
                                            keyEn={
                                                "Station S/N or HIC BD address have changed."
                                            }
                                        />
                                        <br />
                                        <Translated keyEn="Please SAVE and RESTART the station for the changes to take effect." />
                                    </div>
                                </FlexRow>
                            }
                        />
                    </Col>
                )}
            </Row>
            {/* Save */}
            <FlexRow
                style={{
                    alignItems: "center",
                    alignSelf: "flex-end",
                }}
            >
                <StationRestartWidget />
                <div
                    style={{
                        width: 200,
                    }}
                >
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        size="large"
                        block
                        onClick={onSave}
                    >
                        <span
                            style={{
                                textTransform: "uppercase",
                            }}
                        >
                            <Translated keyEn="Save" />
                        </span>
                    </Button>
                </div>
            </FlexRow>
        </FlexCol>
    )
}
