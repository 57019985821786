import { ICloudDevice, ISiteModel, LocalTree, RemoteTree } from "../types"

export const getDeviceApiEndpoint = () => {
    // return `${window.location.protocol}//${window.location.hostname}/api`
    return `${window.location.protocol}//lugsoft:5000`
}

export const callDeviceAPI = async <T>(
    path: string,
    requestInit?: RequestInit,
): Promise<T | null> => {
    if (!requestInit) {
        requestInit = {}
    }
    let uri = getDeviceApiEndpoint() + path
    try {
        let resp = await fetch(uri, requestInit)
        if (!resp.ok) {
            throw new Error(
                `callDeviceApi (${path}) NOK: ${resp.status} ${resp.statusText}`,
            )
        }
        if (resp.status === 204) {
            // Response to DELETE is typically with no body
            return null
        }
        try {
            let json = await resp.json()
            return json as T
        } catch (e: any) {
            throw new Error(
                `callDeviceApi (${path}) JSON decoding error: ${e.message}`,
            )
        }
    } catch (e: any) {
        throw e
    }
}

export const callDeviceAPIGetDevice = async () => {
    return await callDeviceAPI<ICloudDevice>("/device")
}

export const callDeviceAPIDownloadRemoteSite = async (siteID: string) => {
    return await callDeviceAPI<ISiteModel>(
        `/data/download_remote_site?site_id=${siteID}`,
    )
}

export const callDeviceAPIUploadLocalSite = async (siteID: number) => {
    return await callDeviceAPI<void>(
        `/data/upload_local_site?site_id=${siteID}`,
    )
}

export const callDeviceAPIGetLocalTree = async () => {
    return await callDeviceAPI<LocalTree>("/data/get_local_tree")
}

export const callDeviceAPIGetRemoteForeignTree = async (enterprise: string) => {
    return callDeviceAPI<RemoteTree>(
        `/data/get_remote_foreign_tree?enterprise=${enterprise}`,
    )
}

export const callDeviceAPIGetRemoteOwnTree = async () => {
    return await callDeviceAPI<RemoteTree>("/data/get_remote_own_tree")
}
