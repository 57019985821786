import {
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { useLocation, useHistory } from "react-router-dom"
import {
    COLOR_BG_DARK,
    COLOR_BG_GRAY,
    COLOR_BG_GREEN,
    COLOR_BG_RED,
    MAX_WIDTH_CENTRAL_CONTAINER,
    parsePathForEntityUUID,
    pbUUIDToUuid,
    uuidToPbUUID,
} from "../utils/utils"
import { IKinematicPoint, MeanWithStd } from "../types"
import {
    Button,
    Col,
    Divider,
    Popconfirm,
    Row,
    Spin,
    Switch,
    Tooltip,
    Typography,
    message as antdMessage,
} from "antd"
import {
    normTypeElement,
    methodElement,
    isLabTestElement,
    sportsMatTypeElement,
} from "../components/commons-ts/tags"
import {
    FlexCol,
    FlexRow,
    RequirementsAlertPF,
    RequirementsAlertSM,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import Container from "../components/commons-ts/container"
import { Helmet } from "react-helmet"
import { ErrorAlert } from "../components/commons-ts/errorAlert"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Translated } from "../utils/translated"
import {
    ZoneImpactsTablePFAdq,
    ZoneImpactsTablePF_CFH,
    ZoneImpactsTableSM,
} from "./Zone"
import { DeleteOutlined } from "@ant-design/icons"
import { Label } from "../components/commons-ts/input"
import {
    ImpactChartKinematicAcceleration,
    ImpactChartKinematicDeflection,
    ImpactChartKinematicVelocity,
    ImpactChartRawAcceleration,
} from "../components/commons-ts/charts"
import uPlot from "uplot"
import {
    IImpactKinematicHIC,
    getImpactKinematics,
    getRequirementsSM,
} from "../utils/maths"
import { getEquipmentSportsMatThicknessMeanAndStd } from "./Equipment"
import { SliderButton } from "./Impact/SliderButton"
import { MethodTypeEN1177, NormType, UUID } from "../generated/proto-ts/main"
import { useUsercommContextBLE } from "../usercomm/usercommProviderBLE"
import {
    useUsercommDeleteImpactBLE,
    useUsercommDeleteImpactSoftBLE,
    useUsercommEquipmentBLE,
    useUsercommImpactBLE,
    useUsercommSetImpactParentBLE,
    useUsercommSiteBLE,
    useUsercommZoneBLE,
    useUsercommZoneImpactsBLE,
} from "../usercomm/usercommAsyncRequestBLE"
import { ImpactChart } from "./Impact/ImpactChart"
import { DataTreeDrawer } from "./TreeDrawer"

const MeasuredValueElement: FC<{
    children: JSX.Element
}> = memo(({ children }) => {
    return (
        <div
            style={{
                fontSize: "2rem",
                fontWeight: "bold",
            }}
        >
            {children}
        </div>
    )
})

const DividerVertical: FC = memo(() => {
    return (
        <div
            style={{
                border: `${COLOR_BG_GRAY} solid 1px`,
            }}
        />
    )
})

export const ImpactPage: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const memoImpactUUID = useMemo((): string | null => {
        return parsePathForEntityUUID(location.pathname)
    }, [location.pathname])

    // Fetch zone
    const [zone, getZone] = useUsercommZoneBLE()
    // Zone fields
    const [zoneUUID, setZoneUUID] = useState<string | null>(null)
    const [zoneName, setZoneName] = useState<string | null>(null)

    // Fetch equipment
    const [equipment, getEquipment] = useUsercommEquipmentBLE()
    // Equipment fields
    const [equipmentUUID, setEquipmentUUID] = useState<string | null>(null)
    const [equipmentName, setEquipmentName] = useState<string | null>(null)

    // Fetch site
    const [site, getSite] = useUsercommSiteBLE()
    // Site fields
    const [siteUUID, setSiteUUID] = useState<string | null>(null)
    const [siteName, setSiteName] = useState<string | null>(null)
    const [normType, setNormType] = useState<NormType | null>(null)
    const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
    const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

    // Fetch impact
    const [impact, getImpact] = useUsercommImpactBLE()
    // Impact fields
    const [impactHeightM, setImpactHeightM] = useState<number | null>(null)
    const [impactHIC, setImpactHIC] = useState<IImpactKinematicHIC | null>(null)
    const [impactGmax, setImpactGmax] = useState<number | null>(null)

    // Update impact (set parent)
    const [setImpactParentAck, setImpactParent] =
        useUsercommSetImpactParentBLE()
    // Delete impact
    const [deletedImpactAck, deleteImpactSoft] =
        useUsercommDeleteImpactSoftBLE()

    const [releaseResetTrigger, setReleaseResetTrigger] = useState<number>(0)
    const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false)
    const {
        hicRawMeasurementConsumable,
        emitDropHIC,
        consumeHICRawMeasurement,
    } = useUsercommContextBLE()

    const [shouldDecimate, setShouldDecimate] = useState<boolean>(true)
    const [shouldDrawRequirements, setShouldDrawRequirements] =
        useState<boolean>(true)
    const mooSyncRef = useRef<uPlot.SyncPubSub>(uPlot.sync("moo"))

    // calculated
    const [impactDurationMS, setImpactDurationMS] = useState<number | null>(
        null,
    )
    const [impactSamplingFrequency, setImpactSamplingFrequency] = useState<
        number | null
    >(null)
    const [impactInitialVelocity, setImpactInitialVelocity] = useState<
        number | null
    >(null)
    const [impactFinalVelocity, setImpactFinalVelocity] = useState<
        number | null
    >(null)
    const [impactResiliencePerc, setImpactResiliencePerc] = useState<
        number | null
    >(null)
    const [impactDeformationDistanceMM, setImpactDeformationDistanceMM] =
        useState<number | null>(null)
    const [impactDeformationDistancePerc, setImpactDeformationDistancePerc] =
        useState<number | null>(null)
    const [impactKinematicPoints, setImpactKinematicPoints] = useState<
        IKinematicPoint[] | null
    >(null)

    const [impacts, getImpacts] = useUsercommZoneImpactsBLE()
    const [impactIdx, setImpactIdx] = useState<number | null>(null)
    const [newImpactUUID, setNewImpactUUID] = useState<string | null>(null)

    // // Fetch impact
    useEffect(() => {
        if (memoImpactUUID === null) {
            return
        }
        let decimationRate = shouldDecimate ? 10 : 0
        getImpact(uuidToPbUUID(memoImpactUUID), decimationRate)
    }, [memoImpactUUID, shouldDecimate])

    useEffect(() => {
        if (impact === null) {
            return
        }
        console.log(`ImpactPage: impact received:`, impact.toObject())
        setZoneUUID(pbUUIDToUuid(impact.zone_uuid))
        setImpactHIC({
            hicMax: impact.impact_hic,
            hicDTms: 0,
            hicTms: 0,
        })
    }, [impact])

    const memoSportsMatThicknessMeanAndStd = useMemo((): MeanWithStd | null => {
        if (equipment === null) {
            return null
        }
        return getEquipmentSportsMatThicknessMeanAndStd([
            equipment.sports_mat_thickness_side_one,
            equipment.sports_mat_thickness_side_two,
            equipment.sports_mat_thickness_side_three,
            equipment.sports_mat_thickness_side_four,
        ])
    }, [equipment])

    useEffect(() => {
        if (impact === null) {
            return
        }
        let thicknessMean: number | null = null
        if (memoSportsMatThicknessMeanAndStd !== null) {
            thicknessMean = memoSportsMatThicknessMeanAndStd.mean
        }
        let calculatedKinematics = getImpactKinematics(impact, thicknessMean)
        // console.log(`calculatedKinematics:`, calculatedKinematics)
        if (calculatedKinematics === null) {
            return
        }
        let {
            samplingFrequency,
            calculatedFFH,
            calculatedTFF,
            calculatedGmax,
            calculatedHIC,
            initialVelocity,
            finalVelocity,
            resiliencePerc,
            deflectionDistanceMM: deformationDistanceMM,
            deflectionDistancePerc: deformationDistancePerc,
            kinematicPoints,
            calculatedDurationMS: impactDurationMS,
        } = calculatedKinematics
        setImpactGmax(calculatedGmax)
        // setImpactHIC(calculatedHIC)
        setImpactSamplingFrequency(samplingFrequency)
        setImpactDurationMS(impactDurationMS)
        setImpactInitialVelocity(initialVelocity)
        setImpactFinalVelocity(finalVelocity)
        setImpactResiliencePerc(resiliencePerc)
        setImpactDeformationDistanceMM(deformationDistanceMM)
        setImpactDeformationDistancePerc(deformationDistancePerc)
        setImpactKinematicPoints(kinematicPoints)
        setImpactHeightM(calculatedFFH)
    }, [impact, memoSportsMatThicknessMeanAndStd])

    // // Fetch zone
    useEffect(() => {
        if (zoneUUID === null) {
            return
        }
        getZone(uuidToPbUUID(zoneUUID))
    }, [zoneUUID])

    useEffect(() => {
        if (zone === null) {
            return
        }
        console.log(`ImpactPage: zone received:`, zone.toObject())
        setEquipmentUUID(pbUUIDToUuid(zone.equipment_uuid))
        setZoneName(zone.zone_name)
    }, [zone])

    // // Fetch equipment
    useEffect(() => {
        if (equipmentUUID === null) {
            return
        }
        getEquipment(uuidToPbUUID(equipmentUUID))
    }, [equipmentUUID])

    useEffect(() => {
        if (equipment === null) {
            return
        }
        console.log(`ImpactPage: equipment received:`, equipment.toObject())
        setSiteUUID(pbUUIDToUuid(equipment.site_uuid))
        setEquipmentName(equipment.equipment_name)
    }, [equipment])

    // // Fetch site
    useEffect(() => {
        if (siteUUID === null) {
            return
        }
        getSite(siteUUID)
    }, [siteUUID])

    useEffect(() => {
        if (site === null) {
            return
        }
        console.log(`ImpactPage: site received:`, site.toObject())
        setSiteName(site.site_name)
        setNormType(site.norm_type)
        setMethod(site.method_type_en_1177)
        setIsLabTest(site.is_lab_test)
    }, [site])

    // fetch zone impacts
    useEffect(() => {
        if (zoneUUID === null || equipment === null || site === null) {
            return
        }
        getImpacts(uuidToPbUUID(zoneUUID))
    }, [zoneUUID, equipment, site])
    useEffect(() => {
        if (impacts === null) {
            console.log(`ImpactsPage: zone impacts received:`, impacts)
        }
    }, [impacts])

    // // New Impact reception
    // Impact reception
    useEffect(() => {
        if (hicRawMeasurementConsumable === null) {
            return
        }
        consumeHICRawMeasurement()
        console.log(`Zone: newRecord received:`, hicRawMeasurementConsumable)
        antdMessage.success(<Translated keyEn="Received new impact!" />)
        if (zoneUUID === null) {
            return
        }
        setNewImpactUUID(pbUUIDToUuid(hicRawMeasurementConsumable))
        setImpactParent(hicRawMeasurementConsumable, uuidToPbUUID(zoneUUID))
    }, [zoneUUID, hicRawMeasurementConsumable])

    useEffect(() => {
        if (setImpactParentAck === null || newImpactUUID === null) {
            return
        }
        // antdMessage.success(
        //     <Translated keyEn="New impact was successfully associated to the zone!" />,
        // )
        setIsReleaseLoading(false)
        setReleaseResetTrigger(Date.now())
        if (zoneUUID !== null) {
            getImpacts(uuidToPbUUID(zoneUUID))
        }
        setTimeout(() => {
            history.push(`/impacts/${newImpactUUID}`)
        }, 1000)
    }, [newImpactUUID, zoneUUID, setImpactParentAck])

    const memoNormTypeElement = useMemo(() => {
        return normTypeElement(normType)
    }, [normType])

    const memoMethodElement = useMemo(() => {
        if (normType === NormType.EN_12503) {
            return null
        }
        return methodElement(method)
    }, [normType, method])

    const memoSportsMatTypeElement = useMemo(() => {
        if (equipment === null || normType !== NormType.EN_12503) {
            return null
        }
        return sportsMatTypeElement(equipment.sports_mat_type)
    }, [equipment, normType])

    const memoIsLabTestElement = useMemo(() => {
        return isLabTestElement(isLabTest)
    }, [isLabTest])

    const memoImpactName = useMemo(() => {
        return (
            <>
                <Translated keyEn="Impact" />{" "}
                {impactIdx !== null && <># {impactIdx ?? 0 + 1}</>}
            </>
        )
    }, [impact, impactIdx])

    const memoImpactsTable = useMemo(() => {
        if (normType === NormType.EN_1177) {
            if (
                method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
            ) {
                return (
                    <ZoneImpactsTablePF_CFH
                        impacts={impacts}
                        currentImpactId={memoImpactUUID}
                        zone={zone}
                    />
                )
            }
            return (
                <ZoneImpactsTablePFAdq
                    impacts={impacts}
                    currentImpactId={memoImpactUUID}
                />
            )
        }
        if (normType === NormType.EN_12503) {
            return (
                <ZoneImpactsTableSM
                    impacts={impacts}
                    currentImpactUUID={memoImpactUUID}
                    equipment={equipment}
                />
            )
        }
        return null
    }, [memoImpactUUID, impacts, normType, method, zone, equipment])

    const memoChartColStyle = useMemo(() => {
        return { height: 300, marginBottom: 50 }
    }, [])

    const memoRequirementsSM = useMemo(() => {
        if (equipment === null) {
            return null
        }
        let requirements = getRequirementsSM(equipment.sports_mat_type)
        return requirements
    }, [equipment])

    const memoImpactGmaxElement = useMemo(() => {
        let color = COLOR_BG_GRAY
        let value = "N/A"
        if (impactGmax === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactGmax.toFixed(1)
        if (memoRequirementsSM === null || memoRequirementsSM.gmax === null) {
            if (impactGmax <= 200) {
                color = COLOR_BG_GREEN
            } else {
                color = COLOR_BG_RED
            }
            return <span style={{ color }}>{value}g</span>
        }
        if (impactGmax <= memoRequirementsSM.gmax) {
            color = COLOR_BG_GREEN
        } else {
            color = COLOR_BG_RED
        }
        return <span style={{ color }}>{value}g</span>
    }, [impactGmax, memoRequirementsSM])

    const memoImpactHICElement = useMemo(() => {
        let color = COLOR_BG_GRAY
        let value = "N/A"
        if (impactHIC === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactHIC.hicMax.toFixed(1)
        if (impactHIC.hicMax <= 1000) {
            color = COLOR_BG_GREEN
        }
        return <span style={{ color }}>{value}</span>
    }, [impactHIC])

    const memoImpactResilienceElement = useMemo(() => {
        let color = COLOR_BG_GRAY
        let value = "N/A"
        if (impactResiliencePerc === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactResiliencePerc.toFixed(0)
        if (
            memoRequirementsSM === null ||
            memoRequirementsSM.resiliencePerc === null
        ) {
            return <span style={{ color }}>{value}%</span>
        }
        let [threshLow, threshHigh] = memoRequirementsSM.resiliencePerc
        console.log(
            `ImpactPage: threshLow: ${threshLow}; threshHigh: ${threshHigh}; value: ${value}`,
        )
        if (impactResiliencePerc < threshHigh) {
            if (threshLow === null) {
                color = COLOR_BG_GREEN
                return <span style={{ color }}>{value}%</span>
            }
            if (impactResiliencePerc > threshLow) {
                color = COLOR_BG_GREEN
                return <span style={{ color }}>{value}%</span>
            } else {
                color = COLOR_BG_RED
                return <span style={{ color }}>{value}%</span>
            }
        }
        color = COLOR_BG_RED
        return <span style={{ color }}>{value}%</span>
    }, [impactResiliencePerc, memoRequirementsSM])

    const memoImpactDeformationDistanceElement = useMemo(() => {
        let color = COLOR_BG_GRAY
        let value = "N/A"
        if (impactDeformationDistanceMM === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactDeformationDistanceMM.toFixed(1)
        if (
            memoRequirementsSM === null ||
            memoRequirementsSM.deformationDistanceMM === null
        ) {
            return <span style={{ color }}>{value}</span>
        }
        if (
            impactDeformationDistanceMM <=
            memoRequirementsSM.deformationDistanceMM
        ) {
            color = COLOR_BG_GREEN
        } else {
            color = COLOR_BG_RED
        }
        return <span style={{ color }}>{value}</span>
    }, [impactDeformationDistanceMM, memoRequirementsSM])

    const memoImpactDeformationPercElement = useMemo(() => {
        let color = COLOR_BG_GRAY
        let value = "N/A"
        if (impactDeformationDistancePerc === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactDeformationDistancePerc.toFixed(0)
        if (
            memoRequirementsSM === null ||
            memoRequirementsSM.deformationDistancePerc === null
        ) {
            return (
                <Tooltip
                    overlay={<>{impactDeformationDistanceMM?.toFixed(0)} mm</>}
                >
                    <span style={{ color }}>{value}%</span>
                </Tooltip>
            )
        }
        if (
            impactDeformationDistancePerc <=
            memoRequirementsSM.deformationDistancePerc
        ) {
            color = COLOR_BG_GREEN
        } else {
            color = COLOR_BG_RED
        }
        return (
            <Tooltip overlay={<>{impactDeformationDistanceMM} mm</>}>
                <span style={{ color }}>{value}%</span>
            </Tooltip>
        )
    }, [
        impactDeformationDistancePerc,
        impactDeformationDistanceMM,
        memoRequirementsSM,
    ])

    const memoImpactVelocitiesMSElement = useMemo(() => {
        let color = COLOR_BG_DARK
        let value = "N/A"
        if (impactInitialVelocity === null || impactFinalVelocity === null) {
            return <span style={{ color }}>{value}</span>
        }
        let initialVelocity = impactInitialVelocity.toFixed(2)
        let finalVelocity = impactFinalVelocity.toFixed(2)
        return (
            <span style={{ color }}>
                {initialVelocity}&nbsp;→&nbsp;{finalVelocity}
            </span>
        )
    }, [impactInitialVelocity, impactFinalVelocity])

    const memoImpactVelocitiesKMHElement = useMemo(() => {
        let color = COLOR_BG_DARK
        let value = "N/A"
        if (impactInitialVelocity === null || impactFinalVelocity === null) {
            return <span style={{ color }}>{value}</span>
        }
        let initialVelocity = (3.6 * impactInitialVelocity).toFixed(1)
        let finalVelocity = (3.6 * impactFinalVelocity).toFixed(1)
        return (
            <span style={{ color }}>
                {initialVelocity}&nbsp;→&nbsp;{finalVelocity}&nbsp;km/h
            </span>
        )
    }, [impactInitialVelocity, impactFinalVelocity])

    const memoImpactDurationElement = useMemo(() => {
        let color = COLOR_BG_DARK
        let value = "N/A"
        if (impactDurationMS === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactDurationMS.toFixed(0)
        return <span style={{ color }}>{value}ms</span>
    }, [impactDurationMS])

    const memoImpactHeightElement = useMemo(() => {
        let color = COLOR_BG_DARK
        let value = "N/A"
        if (impactHeightM === null) {
            return <span style={{ color }}>{value}</span>
        }
        value = impactHeightM.toFixed(2)
        return <span style={{ color }}>{value}m</span>
    }, [impactHeightM])

    const memoRequirementsAlert = useMemo(() => {
        if (normType === NormType.EN_12503 && equipment !== null) {
            return <RequirementsAlertSM smType={equipment.sports_mat_type} />
        } else if (normType === NormType.EN_1177) {
            return <RequirementsAlertPF />
        } else {
            return null
        }
    }, [normType, equipment])

    const onDelete = useCallback(async () => {
        if (impact === null) {
            return
        }
        deleteImpactSoft(impact)
    }, [impact])
    useEffect(() => {
        if (deletedImpactAck === null) {
            return
        }
        antdMessage.info(<>Impact deleted!</>)
        if (zoneUUID !== null) {
            getImpacts(uuidToPbUUID(zoneUUID))
        }
    }, [deletedImpactAck, zoneUUID])

    if (impact === null) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <SimplifiedBreadcrumb
                    previousItems={[
                        { href: `/sites/${siteUUID}`, label: siteName },
                        {
                            href: `/equipments/${equipmentUUID}`,
                            label: equipmentName,
                        },
                        {
                            href: `/zones/${zoneUUID}`,
                            label: zoneName,
                        },
                    ]}
                    currentItem={{ label: memoImpactName }}
                />
                <Row
                    justify="space-between"
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Col>
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Impact results" />
                            </Label>
                            <Typography.Text
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                {memoImpactName}
                            </Typography.Text>
                        </FlexCol>
                    </Col>
                    <Col>
                        <FlexCol style={{ alignItems: "end" }}>
                            {memoNormTypeElement}
                            {memoMethodElement}
                            {memoIsLabTestElement}
                            {memoSportsMatTypeElement}
                        </FlexCol>
                    </Col>
                </Row>
                {/* Requirements */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Requirements" />
                    </UnderlinedSectionTitle>
                    {memoRequirementsAlert}
                </div>
                {/* Impact data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Measured values" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                            justifyContent: "space-between",
                        }}
                    >
                        <Col>
                            <FlexRow>
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Fall height, m" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactHeightElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                                <DividerVertical />
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Duration, ms" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactDurationElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                            </FlexRow>
                        </Col>
                        <Col xs={8}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Velocity, m/s" />
                                </Label>
                                <MeasuredValueElement>
                                    {memoImpactVelocitiesMSElement}
                                </MeasuredValueElement>
                                <span>{memoImpactVelocitiesKMHElement}</span>
                            </FlexCol>
                        </Col>
                        <Col xs={24} />
                        <Col>
                            <FlexRow>
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="HIC" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactHICElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                                <DividerVertical />
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Gmax, g" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactGmaxElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                            </FlexRow>
                        </Col>
                        <Col>
                            <FlexRow>
                                {/* <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Deflection, mm" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactDeformationDistanceElement}
                                    </MeasuredValueElement>
                                </FlexCol> */}
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Gmax, g" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactGmaxElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                                <DividerVertical />
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Deflection. %" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactDeformationPercElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                                <DividerVertical />
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Resilience %" />
                                    </Label>
                                    <MeasuredValueElement>
                                        {memoImpactResilienceElement}
                                    </MeasuredValueElement>
                                </FlexCol>
                            </FlexRow>
                        </Col>
                    </Row>
                </div>
                {/* Impact chart */}
                <Row gutter={[10, 10]} justify="center">
                    <Col xs={24} style={memoChartColStyle}>
                        <ImpactChartRawAcceleration
                            impact={impact}
                            mooSync={mooSyncRef.current}
                        />
                    </Col>
                    <Col xs={24} md={8} style={memoChartColStyle}>
                        <ImpactChartKinematicAcceleration
                            kinematicPoints={impactKinematicPoints}
                            maxAcceptableG={
                                memoRequirementsSM === null
                                    ? null
                                    : memoRequirementsSM.gmax
                            }
                            mooSync={mooSyncRef.current}
                            shouldDrawRequirements={shouldDrawRequirements}
                        />
                    </Col>
                    <Col xs={24} md={8} style={memoChartColStyle}>
                        <ImpactChartKinematicVelocity
                            kinematicPoints={impactKinematicPoints}
                            maxAcceptableResiliencePerc={
                                memoRequirementsSM !== null &&
                                memoRequirementsSM.resiliencePerc !== null
                                    ? memoRequirementsSM.resiliencePerc[1]
                                    : null
                            }
                            mooSync={mooSyncRef.current}
                            shouldDrawRequirements={shouldDrawRequirements}
                        />
                    </Col>
                    <Col xs={24} md={8} style={memoChartColStyle}>
                        <ImpactChartKinematicDeflection
                            kinematicPoints={impactKinematicPoints}
                            totalDepth={memoSportsMatThicknessMeanAndStd}
                            maxAcceptableDeformationDist={
                                memoRequirementsSM === null
                                    ? null
                                    : memoRequirementsSM.deformationDistanceMM
                            }
                            maxAcceptableDeformationPerc={
                                memoRequirementsSM === null
                                    ? null
                                    : memoRequirementsSM.deformationDistancePerc
                            }
                            mooSync={mooSyncRef.current}
                            shouldDrawRequirements={shouldDrawRequirements}
                        />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                    <Col>
                        <FlexRow>
                            {" "}
                            <span>
                                <Translated keyEn="Draw Requirements" />
                            </span>
                            <Switch
                                checked={shouldDrawRequirements}
                                onChange={(checked) =>
                                    setShouldDrawRequirements(checked)
                                }
                            />
                        </FlexRow>
                    </Col>
                    <Col>
                        <FlexRow>
                            <span>
                                <Translated keyEn="Decimation" />
                            </span>
                            <Switch
                                checked={shouldDecimate}
                                onChange={(checked) =>
                                    setShouldDecimate(checked)
                                }
                            />
                        </FlexRow>
                    </Col>
                </Row>
                {/* Other impacts table */}
                <div>
                    <Label>
                        <Translated keyEn="Other impacts" />
                    </Label>
                    {memoImpactsTable}
                </div>

                {/* New impact slider button */}
                <div className="flex-col -mx-3">
                    <SliderButton
                        onReleaseHIC={() => {
                            emitDropHIC()
                            setIsReleaseLoading(true)
                            setTimeout(() => {
                                setIsReleaseLoading(false)
                                setReleaseResetTrigger(Date.now())
                            }, 10000)
                        }}
                        isLoading={isReleaseLoading}
                        resetTrigger={releaseResetTrigger}
                    />
                </div>

                {/* Delete & Save */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        alignSelf: "flex-end",
                    }}
                >
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this impact" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "0.8rem",
                                }}
                            >
                                <Translated keyEn="Delete" />
                            </span>
                        </Button>
                    </Popconfirm>
                </FlexRow>
            </FlexCol>
            <DataTreeDrawer site={site} selectedKey={memoImpactUUID} />
        </>
    )
}
