import { Message } from "google-protobuf"
import {
    Impact,
    NominatimAddress,
    NominatimReverseGeocodeEntity,
    NominatimZoomType,
    ReverseGeocodeRequest,
    ReverseGeocodeResponse,
} from "../generated/proto-ts/main"
import { callNominatimReverseGeocode } from "../utils/nominatim"

export const equalUint8Array = (a: Uint8Array, b: Uint8Array) => {
    if (a.length !== b.length) {
        return false
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false
        }
    }
    return true
}

const readVarint = (array: Uint8Array, offset: number): [number, number] => {
    let value = 0
    let shift = 0
    let effectiveOffset = offset

    while (true) {
        const byte = array[effectiveOffset++]
        value |= (byte & 0x7f) << shift
        if ((byte & 0x80) === 0) {
            break
        }
        shift += 7
    }
    return [value, effectiveOffset]
}

export const decodeLengthDelimitedArray = <T extends Message>(
    protoCls: { deserializeBinary: (data: Uint8Array) => T },
    data: Uint8Array,
): T[] => {
    let _messages: T[] = []
    let offset: number = 0
    while (offset < data.length) {
        let [size, newOffset] = readVarint(data, offset)
        // console.debug(
        //     `DecodeLengthDelimitedArray: site protowire: size: ${size}, offset: ${newOffset}`,
        // )
        let site = protoCls.deserializeBinary(
            data.slice(newOffset, newOffset + size),
        )
        _messages.push(site)
        offset = newOffset + size
    }
    return _messages
}

const bytesBEToUint16 = (bytes: Uint8Array): number => {
    return (bytes[0] << 8) | bytes[1]
}

const uint14_2ToNumber = (ui14_2Value: number): number => {
    const nDataBits = 13
    const maxSignificant = 8191
    const maxExponent = 3
    const signBit = (ui14_2Value >> 15) & 1
    const expBits = (ui14_2Value >> nDataBits) & maxExponent
    const ui12Value = ui14_2Value & maxSignificant
    let exp: number = 0
    switch (expBits) {
        case 0:
            exp = 1
            break
        case 1:
            exp = 1e1
            break
        case 2:
            exp = 1e2
            break
        case 3:
            exp = 1e3
            break
    }
    let f32Value = ui12Value / exp
    if (signBit === 1) {
        f32Value *= -1
    }
    return f32Value
}

const bytesBEToFloat32Array = (bytes: Uint8Array): number[] => {
    let f32Array: number[] = []
    for (let i = 0; i < bytes.length - 1; i += 2) {
        let ui14_2Value = bytesBEToUint16(bytes.slice(i, i + 2))
        let f32Value = uint14_2ToNumber(ui14_2Value)
        f32Array.push(f32Value)
    }
    return f32Array
}

export const decodeImpactDataPoints = (
    impact: Impact,
): [number[], number[], number[], number[]] => {
    let aq_points_floats = bytesBEToFloat32Array(impact.aq_points)
    let ax_points_floats = bytesBEToFloat32Array(impact.ax_points)
    let ay_points_floats = bytesBEToFloat32Array(impact.ay_points)
    let az_points_floats = bytesBEToFloat32Array(impact.az_points)

    return [
        aq_points_floats,
        ax_points_floats,
        ay_points_floats,
        az_points_floats,
    ]
}

export const handleReverseGeocodeRequest = async (
    reverseGeocodeRequest: ReverseGeocodeRequest,
): Promise<ReverseGeocodeResponse | null> => {
    let zoom = NominatimZoomType.MAJOR_STREETS
    let nominatimRevGeocode = await callNominatimReverseGeocode(
        reverseGeocodeRequest.lat,
        reverseGeocodeRequest.lon,
        zoom,
    )
    if (nominatimRevGeocode === null) {
        return null
    }
    let pbRevGeocode = new NominatimReverseGeocodeEntity({
        place_id: nominatimRevGeocode.place_id,
        // licence: nominatimRevGeocode.licence, // not used, avoid to save space
        osm_type: nominatimRevGeocode.osm_type,
        osm_id: nominatimRevGeocode.osm_id,
        lat: nominatimRevGeocode.lat,
        lon: nominatimRevGeocode.lon,
        category: nominatimRevGeocode.category,
        type: nominatimRevGeocode.type,
        place_rank: nominatimRevGeocode.place_rank,
        importance: nominatimRevGeocode.importance,
        address_type: nominatimRevGeocode.address_type,
        name: nominatimRevGeocode.name,
        display_name: nominatimRevGeocode.display_name,
        address: new NominatimAddress({
            road: nominatimRevGeocode.address.road,
            neighbourhood: nominatimRevGeocode.address.neighbourhood,
            suburb: nominatimRevGeocode.address.suburb,
            village: nominatimRevGeocode.address.village,
            city: nominatimRevGeocode.address.city,
            county: nominatimRevGeocode.address.county,
            municipality: nominatimRevGeocode.address.municipality,
            state_district: nominatimRevGeocode.address.state_district,
            state: nominatimRevGeocode.address.state,
            postcode: nominatimRevGeocode.address.postcode,
            country: nominatimRevGeocode.address.country,
            country_code: nominatimRevGeocode.address.country_code,
        }),
        bounding_box: nominatimRevGeocode.boundingbox,
    })
    let reverseGeocodeResponse = new ReverseGeocodeResponse({
        zoom: zoom,
        nominatim_entity: pbRevGeocode,
    })
    return reverseGeocodeResponse
}
