import { FC, useEffect, useMemo, useState } from "react"
import { Translated } from "../../utils/translated"
import { Tabs, TabsProps } from "antd"
import { FilePdfOutlined, UserOutlined } from "@ant-design/icons"
import { FlexCol } from "../../components/commons-ts/common"
import { ReportConfigSettings } from "./ReportConfigSettings"
import { AccountSettings } from "./AccountSettings"
import { useHistory, useLocation } from "react-router-dom"
import { getCloudApiUserIsAdmin } from "../../utils/cloudApiV2"

enum ETabKey {
    Account = "account",
    CloudSync = "sync",
    ReportConfig = "report",
}

export const SettingsGeneral: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const [selectedTabKey, setSelectedTabKey] = useState<ETabKey>(
        ETabKey.Account,
    )

    useEffect(() => {
        let hash = location.hash
        if (!hash) {
            return
        }
        hash = hash.replace("#", "")
        switch (hash) {
            case ETabKey.Account:
                setSelectedTabKey(ETabKey.Account)
                break
            case ETabKey.CloudSync:
                setSelectedTabKey(ETabKey.CloudSync)
                break
            case ETabKey.ReportConfig:
                setSelectedTabKey(ETabKey.ReportConfig)
                break
        }
    }, [])

    const memoIsAdmin = useMemo(() => {
        return getCloudApiUserIsAdmin()
    }, [])

    const memoTabItems = useMemo(() => {
        let items: TabsProps["items"] = [
            {
                key: ETabKey.Account,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Account" />
                    </span>
                ),
                icon: <UserOutlined />,
                children: <AccountSettings />,
            },
            {
                key: ETabKey.ReportConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Report" />
                    </span>
                ),
                icon: <FilePdfOutlined />,
                children: <ReportConfigSettings />,
            },
        ]
        return items
    }, [memoIsAdmin])

    return (
        <FlexCol
            style={{
                width: "80vw",
                maxWidth: "55rem", // MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
                alignItems: "center",
            }}
        >
            <Tabs
                style={{
                    width: "100%",
                }}
                destroyInactiveTabPane
                // centered
                animated
                tabBarGutter={20}
                activeKey={selectedTabKey}
                onChange={(key: string) => {
                    setSelectedTabKey(key as ETabKey)
                    // set the hash
                    history.push(`#${key}`)
                }}
                size="large"
                items={memoTabItems}
            ></Tabs>
        </FlexCol>
    )
}
