import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { SummarySitesTable } from "./Home/SitesSummaryTable"
import { SiteInitForm } from "./Home/SiteInitForm"
import {
    Button,
    Col,
    Empty,
    Modal,
    Row,
    Spin,
    Tabs,
    Tooltip,
    message as antdMessage,
} from "antd"
import { Translated } from "../utils/translated"
import { faLocationDot, faSatellite } from "@fortawesome/free-solid-svg-icons"
import { FlexCol, FlexRow } from "../components/commons-ts/common"
import {
    NominatimZoomType,
    Site,
    StorageEntityType,
} from "../generated/proto-ts/main"
import { callCloudApiV2 } from "../utils/cloudApiV2"
import { useUsercommContextBLE } from "../usercomm/usercommProviderBLE"
import {
    useUsercommDeleteSiteSoftBLE,
    useUsercommSitesBLE,
    useUsercommStatsBLE,
    useUsercommSyntheticImpactWithEmbeddedReferencesBLE,
} from "../usercomm/usercommAsyncRequestBLE"
import {
    COLOR_BG_BLUE,
    COLOR_BG_GRAY,
    COLOR_BG_GREEN,
    COLOR_BG_LIGHT_BLUE,
    COLOR_BG_RED,
    pbUUIDToUuid,
} from "../utils/utils"
import GaugeChart from "react-gauge-chart"
import { ICloudDevice } from "../types"
import { Paper } from "../components/commons-ts/Paper"
import { Link, useLocation } from "react-router-dom"
import { InfoCircleTwoTone } from "@ant-design/icons"
import { SummaryImpactsTable } from "./Home/ImpactsSummaryTable"
import {
    NominatimReverseGeocodeResponse,
    callNominatimReverseGeocode,
} from "../utils/nominatim"
import {
    GoogleMapsMarker,
    GoogleMapsReactSignedin,
    formatLCoordinateDegMinSec,
} from "../components/commons-ts/googleMaps"

const LIMIT_GMAX = 250
const LIMIT_HIC = 1100

const StatElement: FC<{
    name: string
    value: string | number | null
    success?: boolean
    danger?: boolean
}> = ({ name, value, success, danger }) => {
    const memoValueColor = useMemo(() => {
        if (danger) {
            return COLOR_BG_RED
        }
        if (success) {
            return COLOR_BG_GREEN
        }
        return COLOR_BG_BLUE
    }, [success, danger])

    return (
        <FlexCol
            style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                textAlign: "center",
            }}
        >
            <span
                style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    letterSpacing: "0.1rem",
                    textTransform: "uppercase",
                    color: COLOR_BG_GRAY,
                }}
            >
                {name}
            </span>
            <span
                style={{
                    fontSize: "2.5rem",
                    fontWeight: "bold",
                    color: memoValueColor,
                }}
            >
                {value === null ? "N/A" : value}
            </span>
        </FlexCol>
    )
}

const StatGauge: FC<{
    name: string
    value: number | null
    limit: number
}> = ({ name, value, limit }) => {
    if (value === null) {
        return null
    }
    return (
        <FlexCol
            style={{
                gap: 0,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <span
                style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    letterSpacing: "0.1rem",
                    textTransform: "uppercase",
                    color: COLOR_BG_GRAY,
                }}
            >
                {name}
            </span>
            <GaugeChart
                id={`gauge-chart-${name}`}
                style={{
                    width: 200,
                }}
                colors={[COLOR_BG_GREEN, COLOR_BG_RED]}
                percent={value / limit}
                formatTextValue={() => value.toFixed(0)}
                textColor={COLOR_BG_GRAY}
                nrOfLevels={4}
                arcWidth={0.1}
                arcPadding={0.03}
            />
        </FlexCol>
    )
}

const ReverseGeocodeWidget: FC = () => {
    const { stationSensors } = useUsercommContextBLE()
    const [lon, setLon] = useState<number | null>(null)
    const [lat, setLat] = useState<number | null>(null)
    const [reverseGeocode, setReverseGeocode] =
        useState<NominatimReverseGeocodeResponse | null>(null)

    useEffect(() => {
        if (stationSensors === null) {
            return
        }
        if (
            stationSensors.gnss_lon !== undefined &&
            stationSensors.gnss_lon !== 0
        ) {
            setLon(stationSensors.gnss_lon)
        } else {
            setLon(null)
        }
        if (
            stationSensors.gnss_lat !== undefined &&
            stationSensors.gnss_lat !== 0
        ) {
            setLat(stationSensors.gnss_lat)
        } else {
            setLat(null)
        }
    }, [stationSensors])

    useEffect(() => {
        if (lat === null || lon === null) {
            return
        }

        if (
            reverseGeocode !== null &&
            reverseGeocode.boundingbox !== undefined &&
            reverseGeocode.boundingbox.length === 4
        ) {
            let [bbLatFrom, bbLatTo, bbLonFrom, bbLonTo] =
                reverseGeocode.boundingbox
            if (
                lat >= parseFloat(bbLatFrom) &&
                lat <= parseFloat(bbLatTo) &&
                lon >= parseFloat(bbLonFrom) &&
                lon <= parseFloat(bbLonTo)
            ) {
                console.log(
                    `ReverseGeocodeWidget: lat/lon (${lat}/${lon}) is already in bounding box`,
                    reverseGeocode,
                )
                return
            }
        }

        callNominatimReverseGeocode(lat, lon, NominatimZoomType.BUILDING).then(
            (revGeocodeResp) => {
                console.log(
                    `ReverseGeocodeWidget: got reverseGeocode for lat/lon (${lat}/${lon})`,
                )
                setReverseGeocode(revGeocodeResp)
            },
        )
    }, [lat, lon])

    const memoReverseGeocodeAddressLinkElement = useMemo(() => {
        if (lat === null || lon === null) {
            return null
        }
        let addrStr = ""
        if (reverseGeocode !== null) {
            addrStr = reverseGeocode.display_name
        } else {
            let latStrSN = `${formatLCoordinateDegMinSec(lat)} ${lat < 0 ? "S" : "N"}`
            let lonStrEW = `${formatLCoordinateDegMinSec(lon)} ${lon < 0 ? "W" : "E"}`
            addrStr = `${latStrSN}, ${lonStrEW}`
        }
        return (
            <a
                href={`https://maps.google.com/maps?q=${lat},${lon}`}
                target="_blank"
                rel="noreferrer"
                style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color: COLOR_BG_BLUE,
                }}
            >
                {addrStr}
            </a>
        )
    }, [lat, lon, reverseGeocode])

    if (lat === null || lon === null) {
        return (
            <Empty
                image={
                    <FontAwesomeIcon
                        icon={faSatellite}
                        style={{
                            fontSize: "2rem",
                            color: COLOR_BG_LIGHT_BLUE,
                        }}
                    />
                }
                description={
                    <Tooltip
                        overlay="Try to find a clear sky spot!"
                        placement="bottom"
                    >
                        No GPS signal
                    </Tooltip>
                }
            />
        )
    }

    return (
        <FlexRow>
            <FontAwesomeIcon
                icon={faLocationDot}
                style={{
                    fontSize: "2rem",
                    color: COLOR_BG_LIGHT_BLUE,
                }}
            />
            <FlexCol style={{ gap: 10 }}>
                <span
                    style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        letterSpacing: "0.1rem",
                        textTransform: "uppercase",
                        color: COLOR_BG_GRAY,
                    }}
                >
                    Live Location
                </span>
                {memoReverseGeocodeAddressLinkElement}
                <div
                    style={{
                        height: "200px",
                        width: "100%",
                        minWidth: "200px",
                    }}
                >
                    <GoogleMapsReactSignedin
                        defaultCenter={{
                            lat,
                            lng: lon,
                        }}
                        defaultZoom={15}
                    >
                        <GoogleMapsMarker lat={lat} lng={lon} />
                    </GoogleMapsReactSignedin>
                </div>
                <Link to="/map">
                    <Button size="large" block type="primary">
                        Sites Map
                    </Button>
                </Link>
            </FlexCol>
        </FlexRow>
    )
}

const StatsWidget: FC<{
    setSiteInitFormModalIsOpen: (isOpen: boolean) => void
    setClonedSite: (site: Site | null) => void
}> = ({ setSiteInitFormModalIsOpen, setClonedSite }) => {
    const [stats, getStats] = useUsercommStatsBLE()

    const { bleIsConnected, stationConfig } = useUsercommContextBLE()

    useEffect(() => {
        getStats()
    }, [bleIsConnected])

    // Statistics
    const [sitesNb, setSitesNb] = useState<number | null>(null)
    const [impactsNb, setImpactsNb] = useState<number | null>(null)
    const [maxHIC, setMaxHIC] = useState<number | null>(null)
    const [maxHICImpactUUID, setMaxHICImpactUUID] = useState<string | null>(
        null,
    )
    const [maxGmax, setMaxGmax] = useState<number | null>(null)
    const [maxGmaxImpactUUID, setMaxGmaxImpactUUID] = useState<string | null>(
        null,
    )
    const [timesAboveGmaxLimit, setTimesAboveGmaxLimit] = useState<
        number | null
    >(null)

    // Device info (Cloud)
    const [deviceInfo, setDeviceInfo] = useState<ICloudDevice | null>(null)

    // Parse Stats
    useEffect(() => {
        if (stats === null) {
            return
        }
        console.log("StatsWidget: stats", stats.toObject())
        for (let count of stats.counts) {
            switch (count.entity_type) {
                case StorageEntityType.SITE:
                    setSitesNb(count.count)
                    break
                case StorageEntityType.IMPACT:
                    setImpactsNb(count.count)
                    break
            }
        }
        setMaxHIC(stats.max_hic)
        if (stats.max_hic_impact_uuid !== undefined) {
            setMaxHICImpactUUID(pbUUIDToUuid(stats.max_hic_impact_uuid))
        }
        setMaxGmax(stats.max_gmax)
        if (stats.max_gmax_impact_uuid !== undefined) {
            setMaxGmaxImpactUUID(pbUUIDToUuid(stats.max_gmax_impact_uuid))
        }
        setTimesAboveGmaxLimit(stats.count_gmax_above_limit)
    }, [stats])

    useEffect(() => {
        if (stationConfig === null) {
            return
        }
        callCloudApiV2<ICloudDevice>(
            `/device?device_name=${stationConfig.serial_number}`,
        ).then(({ entity: device }) => {
            setDeviceInfo(device)
        })
    }, [stationConfig])

    const memoMetrologyInversePercent = useMemo(() => {
        if (stationConfig === null) {
            return null
        }
        let d_full =
            stationConfig.next_metrology_date -
            stationConfig.last_metrology_date
        if (d_full <= 0) {
            return 0
        }
        let d_left = stationConfig.next_metrology_date - Date.now()
        return (100 * d_left) / d_full
    }, [stationConfig])

    const memoMetrologyDaysLeft = useMemo(() => {
        if (stationConfig === null) {
            return null
        }
        let d_left = stationConfig.next_metrology_date - Date.now()
        return Math.ceil(d_left / (1000 * 60 * 60 * 24))
    }, [stationConfig])

    const memoHICStatGauge = useMemo(() => {
        return (
            <FlexCol
                style={{
                    gap: 5,
                    height: "100%",
                    justifyContent: "space-between",
                }}
            >
                <StatGauge name="MAX HIC" value={maxHIC} limit={LIMIT_HIC} />
                <Link to={`/impacts/${maxHICImpactUUID}`}>
                    <Button block type="default">
                        Go to Impact
                    </Button>
                </Link>
            </FlexCol>
        )
    }, [maxHIC, maxHICImpactUUID])

    const memoGmaxStatGauge = useMemo(() => {
        let maxGmaxStr = "N/A"
        let color = COLOR_BG_BLUE
        if (maxGmax !== null) {
            maxGmaxStr = maxGmax.toFixed(0) + "g"
            if (maxGmax > LIMIT_GMAX) {
                color = COLOR_BG_RED
            } else if (maxGmax < 150) {
                color = COLOR_BG_GREEN
            }
        }
        return (
            <FlexCol
                style={{
                    gap: 5,
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <StatGauge name="MAX G" value={maxGmax} limit={LIMIT_GMAX} />
                <span
                    style={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        color: color,
                    }}
                >
                    {maxGmaxStr}
                </span>
                <Link to={`/impacts/${maxGmaxImpactUUID}`}>
                    <Button block type="default">
                        Go to Impact
                    </Button>
                </Link>
            </FlexCol>
        )
    }, [maxGmax, maxGmaxImpactUUID])

    const memoMetrologyProgress = useMemo(() => {
        if (memoMetrologyInversePercent === null) {
            return null
        }
        return (
            <FlexCol
                style={{
                    height: "100%",
                    gap: 5,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        letterSpacing: "0.1rem",
                        textTransform: "uppercase",
                        color: COLOR_BG_GRAY,
                    }}
                >
                    Metrology
                </span>
                <GaugeChart
                    id="gauge-chart-metrology"
                    style={{
                        width: 200,
                    }}
                    percent={memoMetrologyInversePercent / 100}
                    textColor={COLOR_BG_GRAY}
                    colors={[COLOR_BG_LIGHT_BLUE, COLOR_BG_BLUE]}
                    formatTextValue={() => `${memoMetrologyDaysLeft}d`}
                    nrOfLevels={4}
                    arcWidth={0.1}
                    arcPadding={0.03}
                />
                {stationConfig !== null &&
                    stationConfig.last_metrology_date > 0 && (
                        <div>
                            <div>
                                Last:{" "}
                                <strong>
                                    {new Date(
                                        stationConfig.last_metrology_date,
                                    ).toLocaleDateString()}
                                </strong>
                            </div>
                            <div>
                                Next: in{" "}
                                <strong>{memoMetrologyDaysLeft}</strong> days
                            </div>
                        </div>
                    )}
                <Button style={{ marginTop: 10 }} block type="primary">
                    Contact SAV
                </Button>
            </FlexCol>
        )
    }, [memoMetrologyInversePercent, memoMetrologyDaysLeft])

    const memoDeviceInfoPane = useMemo(() => {
        return (
            <FlexRow
                style={{
                    justifyContent: "space-between",
                    height: 230,
                }}
            >
                <img
                    src="petit-lug-on-largueur.jpg"
                    style={{
                        marginLeft: -10,
                        marginBottom: -10,
                        height: "100%",
                    }}
                />
                <FlexCol
                    style={{
                        alignItems: "end",
                        justifyContent: "space-between",
                    }}
                >
                    <span
                        style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            letterSpacing: "0.1rem",
                            textTransform: "uppercase",
                            color: COLOR_BG_GRAY,
                        }}
                    >
                        Device
                    </span>
                    {deviceInfo !== null && (
                        <FlexCol style={{ gap: 0, alignItems: "end" }}>
                            <span>
                                Product:{" "}
                                <b>{deviceInfo.DeviceType?.Name ?? "N/A"}</b>{" "}
                                <Tooltip
                                    overlay={deviceInfo.DeviceType?.Description}
                                    overlayInnerStyle={{ textAlign: "center" }}
                                >
                                    <InfoCircleTwoTone />
                                </Tooltip>
                            </span>
                            <span>
                                S/N: <b>{deviceInfo.Name}</b>{" "}
                                <Tooltip overlay={deviceInfo.SerialNumber}>
                                    <InfoCircleTwoTone />
                                </Tooltip>
                            </span>
                            <span>
                                Enterprise:{" "}
                                <b>{deviceInfo.Enterprise?.Name ?? "N/A"}</b>
                            </span>
                        </FlexCol>
                    )}
                    <Link to="/settings/device#history">
                        <Button block type="primary">
                            Device History
                        </Button>
                    </Link>
                </FlexCol>
            </FlexRow>
        )
    }, [deviceInfo])

    if (stats === null) {
        return (
            <FlexCol
                style={{
                    gap: 0,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <Row align="stretch" justify="end">
                <Col xs={{ flex: "auto" }} md={{ flex: "none" }}>
                    <Paper>{memoDeviceInfoPane}</Paper>
                </Col>
                <Col flex="auto">
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        {memoMetrologyProgress}
                    </Paper>
                </Col>
                <Col>
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        {memoGmaxStatGauge}
                    </Paper>
                </Col>
                <Col flex="auto">
                    <FlexCol
                        style={{
                            height: "calc(100% - 8px)",
                            justifyContent: "end",
                        }}
                    >
                        <Button
                            block
                            type="primary"
                            size="large"
                            onClick={() => {
                                setSiteInitFormModalIsOpen(true)
                                setClonedSite(null)
                            }}
                        >
                            <Translated keyEn="New mission" />
                        </Button>
                    </FlexCol>
                </Col>
                <Col>
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        <FlexCol
                            style={{
                                gap: 5,
                                justifyContent: "space-between",
                            }}
                        >
                            <StatElement
                                name="Total Impacts"
                                value={impactsNb}
                            />
                            <Link to="#impacts">
                                <Button
                                    style={{ marginTop: 10 }}
                                    block
                                    type="primary"
                                >
                                    Impacts
                                </Button>
                            </Link>
                        </FlexCol>
                    </Paper>
                </Col>
                <Col>
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        <FlexCol
                            style={{
                                gap: 5,
                                justifyContent: "space-between",
                            }}
                        >
                            <StatElement
                                name="Gmax > 250"
                                value={timesAboveGmaxLimit}
                                success={timesAboveGmaxLimit === 0}
                                danger={timesAboveGmaxLimit !== 0}
                            />
                            <Link to="#impacts">
                                <Button
                                    style={{ marginTop: 10 }}
                                    block
                                    type="primary"
                                    disabled={timesAboveGmaxLimit === 0}
                                >
                                    Show
                                </Button>
                            </Link>
                        </FlexCol>
                    </Paper>
                </Col>
                <Col>
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        <FlexCol
                            style={{
                                gap: 5,
                                justifyContent: "space-between",
                            }}
                        >
                            <StatElement name="Total Sites" value={sitesNb} />
                            <Link to="#sites">
                                <Button
                                    style={{ marginTop: 10 }}
                                    block
                                    type="primary"
                                >
                                    Sites
                                </Button>
                            </Link>
                        </FlexCol>
                    </Paper>
                </Col>
                <Col>
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        <ReverseGeocodeWidget />
                    </Paper>
                </Col>

                {/* <Col>
                    <Paper style={{ height: "calc(100% - 8px)" }}>
                        {memoHICStatGauge}
                    </Paper>
                </Col> */}
            </Row>
        </>
    )
}

enum ESummaryTableType {
    Sites = "sites",
    Impacts = "impacts",
}

export const HomePage: FC = () => {
    const [sites, getSites] = useUsercommSitesBLE()
    const [syntheticImpacts, getSyntheticImpacts] =
        useUsercommSyntheticImpactWithEmbeddedReferencesBLE()
    const [deletedSiteAck, deleteSiteSoft] = useUsercommDeleteSiteSoftBLE()
    const [summaryTableType, setSummaryTableType] = useState<ESummaryTableType>(
        ESummaryTableType.Sites,
    )
    const [clonedSite, setClonedSite] = useState<Site | null>(null)

    const [siteInitFormModalIsOpen, setSiteInitFormModalIsOpen] =
        useState<boolean>(false)

    const { bleIsConnected, emitGetHICConfig, emitGetStationConfig } =
        useUsercommContextBLE()

    const location = useLocation()

    useEffect(() => {
        if (location.hash === "") {
            return
        }
        switch (location.hash) {
            case "#sites":
                setSummaryTableType(ESummaryTableType.Sites)
                break
            case "#impacts":
                setSummaryTableType(ESummaryTableType.Impacts)
                break
        }
    }, [location.hash])

    useEffect(() => {
        if (!bleIsConnected) {
            return
        }
        emitGetHICConfig()
        emitGetStationConfig()
    }, [bleIsConnected])

    useEffect(() => {
        if (!bleIsConnected) {
            return
        }
        getSites()
        setTimeout(() => {
            getSyntheticImpacts()
        }, 200)
    }, [bleIsConnected])

    useEffect(() => {
        if (sites === null) {
            return
        }
        // console.log(
        //     "HomePage: sites",
        //     sites.map((s) => s.toObject()),
        // )
    }, [sites])

    useEffect(() => {
        if (syntheticImpacts === null) {
            return
        }
        // console.log("HomePage: impacts", syntheticImpacts)
    }, [syntheticImpacts])

    const onDeleteSite = useCallback((site: Site) => {
        console.log("HomePage: onDeleteSiteSoft", site)
        deleteSiteSoft(site)
    }, [])
    useEffect(() => {
        if (deletedSiteAck === null) {
            return
        }
        antdMessage.info("Site deleted!")
        getSites()
    }, [deletedSiteAck])

    const memoSummaryTableSites = useMemo(() => {
        return (
            <SummarySitesTable
                sites={sites}
                setClonedSite={(_site: Site | null) => {
                    setClonedSite(_site)
                    setSiteInitFormModalIsOpen(true)
                }}
                onDeleteSite={onDeleteSite}
            />
        )
    }, [sites])

    const memoSymmaryTableImpacts = useMemo(() => {
        return <SummaryImpactsTable impacts={syntheticImpacts} />
    }, [syntheticImpacts])

    return (
        <div>
            <Modal
                open={siteInitFormModalIsOpen}
                width={600}
                title="New mission"
                onCancel={() => {
                    setSiteInitFormModalIsOpen(false)
                }}
                onOk={() => {
                    setSiteInitFormModalIsOpen(false)
                }}
                footer={null}
            >
                <SiteInitForm
                    clonedSite={clonedSite}
                    onToggle={setSiteInitFormModalIsOpen}
                />
            </Modal>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: "60rem",
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <StatsWidget
                    setSiteInitFormModalIsOpen={setSiteInitFormModalIsOpen}
                    setClonedSite={setClonedSite}
                />
                <Tabs
                    activeKey={summaryTableType}
                    onChange={(key) => {
                        history.pushState(null, "", `#${key}`)
                        setSummaryTableType(key as ESummaryTableType)
                    }}
                    centered
                    style={{
                        width: "100%",
                    }}
                    items={[
                        {
                            key: ESummaryTableType.Sites,
                            label: "Sites",
                            children: memoSummaryTableSites,
                        },
                        {
                            key: ESummaryTableType.Impacts,
                            label: <Translated keyEn="Impacts" />,
                            children: memoSymmaryTableImpacts,
                        },
                    ]}
                />
            </FlexCol>
        </div>
    )
}
