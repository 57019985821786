import { GetProp, Modal, Upload, UploadFile, UploadProps } from "antd"
import { FC, useEffect, useState } from "react"
import { UUID } from "../../generated/proto-ts/main"
import { PlusOutlined } from "@ant-design/icons"
import { pbUUIDToUuid, uuidToPbUUID } from "../../utils/utils"
import ImageBlobReduce from "image-blob-reduce"
import Pica from "pica"

const pica = Pica({ features: ["js", "wasm", "cib"] })

export const UploadImageListV2: FC<{
    pictures: UUID[]
    setPictures: (pictures: UUID[]) => void
}> = ({ pictures, setPictures }) => {
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState("")
    const [fileList, setFileList] = useState<UploadFile[]>([])

    useEffect(() => {
        console.log(`UploadImagesListV2: setting files with pictures`, pictures)
        setFileList(
            pictures.map((pictureUUID) => {
                let uuidStr = pbUUIDToUuid(pictureUUID)
                return {
                    uid: uuidStr,
                    name: uuidStr,
                    status: "done",
                    url: `/api/uploads/${uuidStr}`,
                }
            }),
        )
    }, [pictures])

    const onPreview = async (file: UploadFile) => {
        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
    }

    const uploadButton = (
        <button style={{ border: 0, background: "none" }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    )

    const maxCount = 5

    return (
        <>
            <Upload
                accept="image/*"
                action="/api/uploads/"
                style={{ width: "100%" }}
                listType="picture-card"
                maxCount={maxCount}
                multiple
                fileList={fileList}
                onPreview={onPreview}
                beforeUpload={async (file) => {
                    try {
                        let resizedBlob = await new ImageBlobReduce({
                            pica,
                        }).toBlob(file, {
                            max: 1024, // 768
                        })
                        return resizedBlob
                    } catch (e: any) {
                        console.error(
                            `Failed to resize image ${file.name}: ${e.message}`,
                        )
                        return file
                    }
                }}
                onChange={({ fileList: newFilelist, file }) => {
                    if (file.status === "done") {
                        let responseUUID = file.response as string
                        let pbUUID = uuidToPbUUID(responseUUID)
                        file.uid = responseUUID
                        file.url = `/api/uploads/${responseUUID}`
                        setPictures([...pictures, pbUUID])
                    } else {
                        setFileList([...newFilelist])
                    }
                }}
                onRemove={(file) => {
                    fetch(`/api/uploads/${file.uid}`, {
                        method: "DELETE",
                    }).then((resp) => {
                        if (!resp.ok) {
                            console.warn(
                                `Failed to delete file ${file.uid} on the server; will remove from the state anyway..`,
                            )
                        }
                        let _pictures: UUID[] = []
                        for (let picture of pictures) {
                            let pictureUUID = pbUUIDToUuid(picture)
                            if (pictureUUID !== file.uid) {
                                _pictures.push(picture)
                            }
                        }
                        setPictures(_pictures)
                    })
                }}
            >
                {pictures.length >= maxCount ? null : uploadButton}
            </Upload>
            <Modal
                open={previewOpen}
                width={800}
                footer={null}
                onCancel={() => setPreviewOpen(false)}
            >
                <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                />
            </Modal>
        </>
    )
}
