import { FC, useCallback, useMemo } from "react"
import { Button, Tooltip, Typography, theme } from "antd"
import { SwapOutlined } from "@ant-design/icons"
import { FlexCol, FlexRow } from "../components/commons-ts/common"
import {
    navigatorSupportsWebBle,
    requestBleDevice,
    useUsercommContextBLE,
} from "../usercomm/usercommProviderBLE"
import { Translated } from "../utils/translated"

export const WebBleNotSupportedWidget: FC = () => {
    return (
        <FlexCol
            style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                gap: 0,
            }}
        >
            <Typography.Title
                level={3}
                style={{
                    textAlign: "center",
                }}
            >
                Your browser does not support <b>Web BLE</b>
            </Typography.Title>
            <Typography.Text>
                Please use <b>Google Chrome</b>, <b>Microsoft Edge</b> or{" "}
                <b>Opera Browser</b>
            </Typography.Text>
        </FlexCol>
    )
}

export const PairAndConnectivityWidget: FC<{
    name: string
    isConnected: boolean
    isPaired: boolean
    onPair: () => Promise<void>
}> = ({ name, isConnected, isPaired, onPair }) => {
    const {
        token: { colorSuccessText, colorInfoText, colorErrorText },
    } = theme.useToken()

    return (
        <FlexCol
            style={{
                alignItems: "center",
                gap: 0,
            }}
        >
            <Tooltip
                overlayInnerStyle={{ textAlign: "center" }}
                overlay={
                    <>
                        {(function () {
                            if (isConnected) {
                                return `${name} is connected`
                            } else {
                                if (isPaired) {
                                    return `${name} is paired, click to reconnect!`
                                } else {
                                    return `Click to pair with ${name}`
                                }
                            }
                        })()}
                    </>
                }
            >
                <SwapOutlined
                    style={{
                        color: (function () {
                            if (isConnected) {
                                return colorSuccessText
                            } else {
                                if (isPaired) {
                                    return colorInfoText
                                } else {
                                    return colorErrorText
                                }
                            }
                        })(),
                        fontSize: "3rem",
                    }}
                    onClick={onPair}
                />
            </Tooltip>
            {name}
        </FlexCol>
    )
}

export const BleConnectPage: FC = () => {
    const { bleDevice, bleIsConnected, setBleDevice } = useUsercommContextBLE()

    const memoIsPaired = useMemo(() => {
        return bleDevice !== undefined
    }, [bleDevice])

    const onDisconnect = useCallback(async () => {
        console.log(`BLEConnectPage: disconnecting from device: `, bleDevice)
        if (bleDevice && bleDevice.gatt && bleDevice.gatt.connected) {
            console.debug(
                `BLEConnectPage: disconnecting from gatt server: `,
                bleDevice.gatt,
            )
            bleDevice.gatt.disconnect()
        }
        if (bleDevice && bleDevice.forget) {
            console.debug(`BLEConnectPage: forgetting device: `, bleDevice)
            await bleDevice.forget()
            setBleDevice(undefined)
        }
    }, [bleDevice])

    if (!navigatorSupportsWebBle()) {
        return <WebBleNotSupportedWidget />
    }

    return (
        <FlexCol
            style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                gap: 0,
            }}
        >
            <Typography.Title
                level={3}
                style={{
                    marginBottom: 0,
                }}
            >
                {bleIsConnected
                    ? "Largueur Connected"
                    : "Largueur Disconnected"}
            </Typography.Title>
            <Typography.Title
                level={5}
                style={{
                    marginTop: 0,
                    marginBottom: 30,
                }}
            >
                {bleIsConnected ? (
                    <Translated keyEn="You can use the app" />
                ) : (
                    <Translated keyEn="Connect to Largueur in order to start" />
                )}
            </Typography.Title>
            <FlexRow
                style={{
                    justifyContent: "center",
                }}
            >
                <PairAndConnectivityWidget
                    name="Largueur"
                    isConnected={bleIsConnected}
                    isPaired={memoIsPaired}
                    onPair={async () => {
                        try {
                            let device = await requestBleDevice()
                            console.log(
                                "BLEConnectPage: selected device:",
                                device,
                            )
                            setBleDevice(undefined)
                            setBleDevice(device)
                        } catch (e) {}
                    }}
                />
            </FlexRow>
            <FlexCol
                style={{
                    marginTop: 50,
                    textAlign: "center",
                    maxWidth: "80%",
                }}
            >
                <i>
                    <span>
                        Hint: If you are using the app for the <b>first time</b>
                        , click on{" "}
                        <SwapOutlined style={{ fontSize: "1.5rem" }} />
                    </span>
                    <br />
                    <span>
                        You should select <b>LU XXXXXX</b> as indicated on your
                        device
                    </span>
                </i>
            </FlexCol>
            <FlexCol
                style={{
                    marginTop: 20,
                }}
            >
                <Button size="large" onClick={onDisconnect}>
                    Disconnect and Forget
                </Button>
            </FlexCol>
        </FlexCol>
    )
}
