import { FC, useEffect, useMemo, useState } from "react"
import {
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../../components/commons-ts/common"
import {
    Avatar,
    Button,
    Col,
    Input,
    Row,
    Select,
    Switch,
    Tooltip,
    Typography,
} from "antd"
import { Translated } from "../../utils/translated"
import { Label } from "../../components/commons-ts/input"
import { IdcardOutlined } from "@ant-design/icons"
import { ESiteMethodType } from "../../types"
import {
    loadLocalStorageDefaultSiteMethodType,
    saveLocalStorageDefaultSiteMethodType,
} from "../../utils/localStorage"
import {
    getCloudApiUserIsAdmin,
    getLocalStorageCloudApiUser,
} from "../../utils/cloudApiV2"
import { useUsercommContextBLE } from "../../usercomm/usercommProviderBLE"
import { COLOR_BG_BLUE, COLOR_BG_ORANGE } from "../../utils/utils"

export const AccountSettings: FC = () => {
    const [defaultMethod, setDefaultMethod] = useState<ESiteMethodType>(
        loadLocalStorageDefaultSiteMethodType(),
    )
    // const [buzzerState, setBuzzerState] = useState<boolean>(false)
    const {
        bleIsConnected,
        stationConfig,
        // emitSetStationConfig,
        // emitGetStationConfig,
    } = useUsercommContextBLE()

    // useEffect(() => {
    //     emitGetStationConfig()
    // }, [bleIsConnected])

    // useEffect(() => {
    //     if (
    //         stationConfig !== null &&
    //         stationConfig.buzzer_state !== undefined
    //     ) {
    //         setBuzzerState(stationConfig.buzzer_state)
    //     }
    // }, [stationConfig])

    const memoUserInfo = useMemo(() => {
        return getLocalStorageCloudApiUser()
    }, [])

    const memoIsAdmin = useMemo(() => {
        return getCloudApiUserIsAdmin()
    }, [memoUserInfo])

    return (
        <FlexCol
            style={{
                width: "100%",
                // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            <FlexCol style={{ gap: 0 }}>
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="User account" />
                </Typography.Text>
            </FlexCol>
            {/* User info */}
            {memoUserInfo !== null && (
                <div>
                    <FlexRow>
                        <UnderlinedSectionTitle>
                            <Translated keyEn="User info" />
                        </UnderlinedSectionTitle>
                        <Tooltip overlay="Admin" placement="top">
                            <Avatar
                                size="large"
                                style={{
                                    backgroundColor: COLOR_BG_ORANGE,
                                }}
                                icon={<IdcardOutlined />}
                            />
                        </Tooltip>
                    </FlexRow>
                    <Row gutter={[10, 10]}>
                        <Col xs={24}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Username" />
                                </Label>
                                <Input
                                    value={memoUserInfo.Username}
                                    size="large"
                                    variant="filled"
                                    disabled
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="First name" />
                                </Label>
                                <Input
                                    value={memoUserInfo.FirstName}
                                    size="large"
                                    variant="filled"
                                    disabled
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Last name" />
                                </Label>
                                <Input
                                    value={memoUserInfo.FirstName}
                                    size="large"
                                    variant="filled"
                                    disabled
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={12}>
                            <FlexCol style={{ gap: 0, alignItems: "start" }}>
                                <Label>
                                    <Translated keyEn="Default method" />
                                </Label>
                                <Select
                                    value={defaultMethod}
                                    options={[
                                        {
                                            label: (
                                                <Translated keyEn="Method 1: Critical Fall Height" />
                                            ),
                                            value: 1,
                                        },
                                        {
                                            label: "Method 2: Adequacy",
                                            value: 2,
                                        },
                                    ]}
                                    onChange={(value) => {
                                        if (value) {
                                            setDefaultMethod(value)
                                            saveLocalStorageDefaultSiteMethodType(
                                                value,
                                            )
                                        }
                                    }}
                                    style={{
                                        minWidth: "20rem",
                                    }}
                                />
                            </FlexCol>
                        </Col>
                    </Row>
                </div>
            )}
            {/* Sign out */}
            <Button
                block
                type="primary"
                size="large"
                href="/api/logout"
                danger
                // onClick={async () => {
                //     try {
                //         await callCloudApiV2("/logout")
                //     } finally {
                //         setLocalStorageCloudApiUser(null)
                //         history.push("/login")
                //     }
                // }}
            >
                <Translated keyEn="Sign out" />
            </Button>
        </FlexCol>
    )
}
