import { FC } from "react"

export const translated = (keyEn: string | null) => {
    return keyEn
}

export const Translated: FC<{
    keyEn: string | null
}> = ({ keyEn }) => {
    return <>{translated(keyEn)}</>
}
