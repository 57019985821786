import { FC, useCallback, useEffect, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

import UploadImage from "../../components/commons-ts/UploadImage"
import { callDeviceAPI } from "../../utils/deviceAPI"
import { Button, Popconfirm, Typography, message as antdMmessage } from "antd"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { Translated, translated } from "../../utils/translated"
import { Label } from "../../components/commons-ts/input"
import { IReportConfig } from "../../types"
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons"
import { MAX_WIDTH_CENTRAL_CONTAINER } from "../../utils/utils"

export const ReportConfigSettings: FC = () => {
    const [reportConfig, setReportConfig] = useState<IReportConfig | null>(null)
    const [logoPictureName, setLogoPictureName] = useState<string | null>(null)
    const [footnote, setFootnote] = useState<string | null>(null)
    const [htmlCustomContent, setHtmlCustomContent] = useState<string | null>(
        null,
    )

    useEffect(() => {
        callDeviceAPI<IReportConfig>("/report-config")
            .then((_reportConfig) => {
                setReportConfig(_reportConfig)
                if (_reportConfig) {
                    setLogoPictureName(_reportConfig.logoPicture)
                    setFootnote(_reportConfig.footnote)
                    setHtmlCustomContent(_reportConfig.htmlCustomPage)
                }
            })
            .catch((err: any) => {
                antdMmessage.error(`Error occurred: ${err.message}`)
            })
    }, [])

    const onSave = useCallback(() => {
        let body: Partial<IReportConfig> = {
            footnote,
            htmlCustomPage: htmlCustomContent,
        }
        callDeviceAPI("/report-config", {
            method: "PATCH",
            body: JSON.stringify(body),
        })
            .then(() => {
                antdMmessage.success(`Saved report config!`)
            })
            .catch((err: any) => {
                antdMmessage.error(
                    `Error occurred while saving report config: ${err.message}`,
                )
            })
    }, [footnote, htmlCustomContent])

    return (
        <FlexCol
            style={{
                width: "100%",
                // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            <FlexCol style={{ gap: 0 }}>
                <Label>
                    <Translated keyEn="Personalize your reports" />
                </Label>
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="Report" />
                </Typography.Text>
            </FlexCol>
            {/* Picture (logo) */}
            <FlexCol>
                <Label>
                    <Translated keyEn="Logo of your company" />
                </Label>
                <UploadImage
                    pictureName={logoPictureName}
                    setPictureName={setLogoPictureName}
                    processURL={`/report-config/picture`}
                />
            </FlexCol>
            {/* Footnote */}
            <FlexCol>
                <Label>
                    <Translated keyEn="Footnote" />
                </Label>
                <textarea
                    name="footnote"
                    className="appearance-none block w-full bg-grey-lighter text-grey-darker border rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white border-grey-lighter focus:border-grey"
                    cols={40}
                    rows={2}
                    value={footnote || ""}
                    onChange={(e) => setFootnote(e.currentTarget.value)}
                />
            </FlexCol>
            {/* Custom 2nd page */}
            <FlexCol>
                <Label>
                    <Translated keyEn="Custom 2nd-page content" />
                </Label>
                <ReactQuill
                    theme="snow"
                    modules={{
                        toolbar: [
                            ["bold", "italic", "underline", "strike"], // toggled buttons
                            ["blockquote", "code-block"],

                            [{ header: 1 }, { header: 2 }], // custom button values
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }], // superscript/subscript
                            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                            [{ direction: "rtl" }], // text direction

                            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],

                            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                            [{ font: [] }],
                            [{ align: [] }],

                            ["clean"], // remove formatting button
                        ],
                        clipboard: {
                            // toggle to add extra line breaks when pasting HTML:
                            matchVisual: false,
                        },
                    }}
                    value={htmlCustomContent || ""}
                    onChange={(html) => setHtmlCustomContent(html || null)}
                    placeholder={
                        translated(
                            "You can redact a custom 2nd page for your report here",
                        ) ?? ""
                    }
                />
            </FlexCol>
            {/* Save */}
            <FlexRow
                style={{
                    alignItems: "center",
                    alignSelf: "flex-end",
                }}
            >
                <div
                    style={{
                        width: 200,
                    }}
                >
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        size="large"
                        block
                        onClick={onSave}
                    >
                        <span
                            style={{
                                textTransform: "uppercase",
                            }}
                        >
                            <Translated keyEn="Save" />
                        </span>
                    </Button>
                </div>
            </FlexRow>
        </FlexCol>
    )
}
