import { log } from "console"
import {
    Equipment,
    Impact,
    MatTypeEN12503,
    Zone,
} from "../generated/proto-ts/main"
import { getEquipmentSportsMatThicknessMeanAndStd } from "../pages/Equipment"
import { MeanWithStd, IKinematicPoint } from "../types"
import { decodeImpactDataPoints } from "../usercomm/usercommUtils"
import { Point, findParabolicAandC } from "./goldenSectionSearch"
import { pbUUIDToUuid } from "./utils"

export const BOTTOM_GMAX = 195
export const TARGET_GMAX = 200
export const TOP_GMAX = 205

export const BOTTOM_HIC = 950
export const TARGET_HIC = 1000
export const TOP_HIC = 1050

export const G = 9.8065 // m/s^2
export const FREE_FALL_ACCELERATION_THRESHOLD = Math.sqrt(3) * G // m/s^2

export enum EResult {
    NotAdequate = 0,
    ToReview = 1,
    Adequate = 2,
}

export interface IZoneResult {
    result: EResult
    gmax: MeanWithStd | null
    hic: MeanWithStd | null
    cfh: MeanWithStd | null
    deflectionDistanceMM: MeanWithStd | null
    deflectionDistancePerc: MeanWithStd | null
    resiliencePerc: MeanWithStd | null
}

export interface IEquipmentResult {
    result: EResult
}

export const solveQuadraticEquation = (
    equation: [number, number, number],
    offset: number,
): [number | null, number | null] => {
    let [a, b, c] = equation
    let delta = Math.pow(b, 2) - 4 * a * (c - offset)
    if (delta < 0) {
        return [null, null]
    }
    let x1 = (-b - Math.sqrt(delta)) / (2 * a)
    let x2 = (-b + Math.sqrt(delta)) / (2 * a)
    return [x1, x2]
}

// PlaygroundFloor - Adequacy
export const getGmaxPFAdq = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length === 0) {
        return null
    }
    let gmaxValues: number[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0) {
            gmaxValues.push(impact.impact_gmax)
        }
    }
    gmaxValues.sort((a, b) => b - a)
    gmaxValues = gmaxValues.slice(0, 3)
    let count = gmaxValues.length
    let meanGmax = gmaxValues.reduce((a, b) => a + b) / count
    let stdGmax = Math.sqrt(
        gmaxValues
            .map((x) => Math.pow(x - meanGmax, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    return {
        mean: meanGmax,
        std: stdGmax,
        count,
    }
}
export const getHicPFAdq = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length === 0) {
        return null
    }
    let hicValues: number[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_hic !== 0) {
            hicValues.push(impact.impact_hic)
        }
    }
    hicValues.sort((a, b) => b - a)
    hicValues = hicValues.slice(0, 3)
    let count = hicValues.length
    let meanHic = hicValues.reduce((a, b) => a + b, 0) / count
    let stdHic = Math.sqrt(
        hicValues
            .map((x) => Math.pow(x - meanHic, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    return {
        mean: meanHic,
        std: stdHic,
        count,
    }
}
export const getImpactResultPFAdq = (impact: Impact): EResult => {
    let resultHic = 0
    if (impact.impact_hic < BOTTOM_HIC) {
        resultHic = 2
    } else if (impact.impact_hic > TOP_HIC) {
        resultHic = 0
    } else {
        resultHic = 1
    }

    let resultGmax = 0
    if (impact.impact_gmax < BOTTOM_GMAX) {
        resultGmax = 2
    } else if (impact.impact_gmax > TOP_GMAX) {
        resultGmax = 0
    } else {
        resultGmax = 1
    }

    let resultGlobal = Math.min(resultHic, resultGmax)

    return resultGlobal
}
export const getZoneResultPFAdq = (impacts: Impact[]): IZoneResult | null => {
    if (impacts === undefined || impacts.length === 0) {
        return null
    }

    let gmaxAdq = getGmaxPFAdq(impacts)
    if (gmaxAdq === null) {
        return null
    }
    let hicAdq = getHicPFAdq(impacts)
    if (hicAdq === null) {
        return null
    }

    let resultHic = 0
    if (hicAdq.mean + hicAdq.std < BOTTOM_HIC) {
        resultHic = 2
    } else if (hicAdq.mean + hicAdq.std > TOP_HIC) {
        resultHic = 0
    } else {
        resultHic = 1
    }

    let resultGmax = 0
    if (gmaxAdq.mean + gmaxAdq.std < BOTTOM_GMAX) {
        resultGmax = 2
    } else if (gmaxAdq.mean + gmaxAdq.std > TOP_GMAX) {
        resultGmax = 0
    } else {
        resultGmax = 1
    }

    let resultGlobal = Math.min(resultHic, resultGmax)

    return {
        result: resultGlobal,
        gmax: gmaxAdq,
        hic: hicAdq,
        cfh: null,
        deflectionDistanceMM: null,
        deflectionDistancePerc: null,
        resiliencePerc: null,
    }
}
export const getEquipmentResultPFAdq = (
    zones: Zone[],
    zoneImpactsMap: Record<string, Impact[]>,
): IEquipmentResult | null => {
    if (zones.length === 0) {
        return null
    }
    let result = EResult.Adequate
    for (let zone of zones) {
        if (zone.deleted_at !== null) {
            continue
        }
        let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
        let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
        if (zoneImpacts === undefined) {
            continue
        }
        let zoneResult = getZoneResultPFAdq(zoneImpacts)
        if (zoneResult === null) {
            continue
        }
        if (zoneResult.result === EResult.NotAdequate) {
            result = EResult.NotAdequate
            break
        }
        if (zoneResult.result === EResult.ToReview) {
            result = EResult.ToReview
        }
    }
    return {
        result,
    }
}

// PlaygroundFloor - CFH
export const getCFH_Gmax = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length < 2) {
        return null
    }
    let effectiveImpacts: Impact[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0) {
            effectiveImpacts.push(impact)
        }
    }
    let count = effectiveImpacts.length
    let heightGmaxPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        heightGmaxPoints.push([impact.impact_ffh, impact.impact_gmax])
    }
    let { equation } = findParabolicAandC(heightGmaxPoints)
    let [_, cfh] = solveQuadraticEquation(equation, TARGET_GMAX)
    if (cfh === null) {
        return null
    }
    return {
        mean: cfh,
        std: 0,
        count,
    }
}
export const getCFH_HIC = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length < 2) {
        return null
    }
    let effectiveImpacts: Impact[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_hic !== 0) {
            effectiveImpacts.push(impact)
        }
    }
    let count = effectiveImpacts.length
    let heightHICPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        heightHICPoints.push([impact.impact_ffh, impact.impact_hic])
    }
    let { equation } = findParabolicAandC(heightHICPoints)
    let [_, cfh] = solveQuadraticEquation(equation, TARGET_HIC)
    if (cfh === null) {
        return null
    }
    return {
        mean: cfh,
        std: 0,
        count,
    }
}
export const getCFH_Global = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length < 2) {
        return null
    }
    let effectiveImpacts: Impact[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0 && impact.impact_hic !== 0) {
            effectiveImpacts.push(impact)
        }
    }
    let count = effectiveImpacts.length
    // Gmax
    let gmaxHeightPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        gmaxHeightPoints.push([impact.impact_ffh, impact.impact_gmax])
    }
    let { equation: gmaxEquation } = findParabolicAandC(gmaxHeightPoints)
    let [_, gmaxCFH] = solveQuadraticEquation(gmaxEquation, TARGET_GMAX)

    // HIC
    let hicHeightPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        hicHeightPoints.push([impact.impact_ffh, impact.impact_hic])
    }
    let { equation: hicEquation } = findParabolicAandC(hicHeightPoints)
    let [__, hicCFH] = solveQuadraticEquation(hicEquation, TARGET_HIC)

    if (gmaxCFH === null || hicCFH === null) {
        return null
    }
    if (gmaxCFH !== null && hicCFH !== null) {
        let cfh = Math.min(gmaxCFH, hicCFH)
        return {
            mean: cfh,
            std: 0,
            count,
        }
    } else if (gmaxCFH !== null) {
        return {
            mean: gmaxCFH,
            std: 0,
            count,
        }
    } else if (hicCFH !== null) {
        return {
            mean: hicCFH,
            std: 0,
            count,
        }
    }
    return null
}

export const getZoneResultPF_CFH = (
    zone: Zone,
    impacts: Impact[],
): IZoneResult | null => {
    if (impacts.length === 0 || zone.zone_ffh_max === 0) {
        return null
    }
    const targetFFHmetres = zone.zone_ffh_max / 100

    let globalCFH = getCFH_Global(impacts)
    if (globalCFH === null) {
        return null
    }

    let resultGlobal = EResult.ToReview
    if (globalCFH.mean <= targetFFHmetres) {
        resultGlobal = EResult.Adequate
    } else {
        resultGlobal = EResult.NotAdequate
    }

    return {
        result: resultGlobal,
        gmax: null,
        hic: null,
        cfh: globalCFH,
        deflectionDistanceMM: null,
        deflectionDistancePerc: null,
        resiliencePerc: null,
    }
}
// SportsMat

interface RequirementsSM {
    gmax: number | null
    deformationDistanceMM: number | null
    deformationDistancePerc: number | null
    resiliencePerc: [number | null, number] | null // min, max
}
export const getRequirementsSM = (
    smType: MatTypeEN12503 | null,
): RequirementsSM | null => {
    if (smType === null) {
        return null
    }
    let requirements: RequirementsSM = {
        gmax: null,
        deformationDistanceMM: null,
        deformationDistancePerc: null,
        resiliencePerc: null,
    }
    switch (smType) {
        // Gymnastics
        case MatTypeEN12503.TYPE_1:
            requirements.gmax = 80
            break
        case MatTypeEN12503.TYPE_2:
            requirements.gmax = 65
            requirements.deformationDistanceMM = 35
            requirements.resiliencePerc = [20, 60]
            break
        case MatTypeEN12503.TYPE_3:
            requirements.gmax = 40
            requirements.deformationDistanceMM = 50
            requirements.resiliencePerc = [null, 55]
            break
        case MatTypeEN12503.TYPE_4:
            requirements.gmax = 40
            requirements.deformationDistanceMM = 110
            requirements.resiliencePerc = [null, 50]
            break
        case MatTypeEN12503.TYPE_5:
            requirements.gmax = 25
            requirements.deformationDistanceMM = 110
            requirements.resiliencePerc = [null, 30]
            break
        case MatTypeEN12503.TYPE_6:
            requirements.gmax = 14
            requirements.deformationDistanceMM = 105
            requirements.resiliencePerc = [null, 15]
            break
        case MatTypeEN12503.TYPE_7:
            requirements.gmax = 35
            requirements.deformationDistanceMM = 185
            requirements.resiliencePerc = [null, 25]
            break
        case MatTypeEN12503.TYPE_8:
            requirements.gmax = 20
            requirements.deformationDistanceMM = 250
            requirements.resiliencePerc = [null, 25]
            break
        // Pole vault
        case MatTypeEN12503.TYPE_9:
            requirements.gmax = 10
            requirements.deformationDistancePerc = 70
            requirements.resiliencePerc = [null, 20]
            break
        case MatTypeEN12503.TYPE_10:
            requirements.gmax = 10
            requirements.deformationDistancePerc = 60
            requirements.resiliencePerc = [null, 20]
            break
        case MatTypeEN12503.TYPE_11:
            requirements.gmax = 10
            requirements.deformationDistancePerc = 55
            requirements.resiliencePerc = [null, 20]
            break
        // Judo
        case MatTypeEN12503.TYPE_12:
            // WTF
            // Depends on impactor size and FFH
            break
        case MatTypeEN12503.TYPE_EN12572:
            requirements.gmax = 25
            requirements.deformationDistancePerc = 80
            requirements.resiliencePerc = [null, 15]
    }
    return requirements
}

export const getZoneGmaxSM = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length === 0) {
        return null
    }
    let gmaxValues: number[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0) {
            gmaxValues.push(impact.impact_gmax)
        }
    }
    let count = gmaxValues.length
    let meanGmax = gmaxValues.reduce((a, b) => a + b, 0) / count
    let stdGmax = Math.sqrt(
        gmaxValues
            .map((x) => Math.pow(x - meanGmax, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    return {
        mean: meanGmax,
        std: stdGmax,
        count,
    }
}
export const getZoneHicSM = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length === 0) {
        return null
    }
    let hicValues: number[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_hic !== 0) {
            hicValues.push(impact.impact_hic)
        }
    }
    let count = hicValues.length
    let meanHic = hicValues.reduce((a, b) => a + b, 0) / count
    let stdHic = Math.sqrt(
        hicValues
            .map((x) => Math.pow(x - meanHic, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    return {
        mean: meanHic,
        std: stdHic,
        count,
    }
}

export interface IImpactKinematicHIC {
    hicMax: number
    hicTms: number
    hicDTms: number
}

export interface IImpactKinematics {
    samplingFrequency: number | null
    calculatedDurationMS: number | null
    calculatedTFF: number | null
    calculatedFFH: number | null
    calculatedHIC: IImpactKinematicHIC
    calculatedGmax: number | null
    initialVelocity: number | null
    finalVelocity: number | null
    deflectionDistanceMM: number | null
    deflectionDistancePerc: number | null
    resiliencePerc: number | null

    kinematicPoints: IKinematicPoint[]
}

export const meanArr = (values: number[]): number => {
    return values.reduce((a, b) => a + b, 0) / values.length
}
export const sumArr = (values: number[]): number => {
    return values.reduce((a, b) => a + b, 0)
}
export const powArr = (values: number[], power: number): number[] => {
    return values.map((x) => Math.pow(x, power))
}

export const getImpactKinematics = (
    impact: Impact,
    smThicknessMM: number | null,
): IImpactKinematics | null => {
    let [aq_points, ax_points, ay_points, az_points] =
        decodeImpactDataPoints(impact)

    // console.log(`getImpactKinematics: aq_points`, aq_points)

    let f = impact.decimated_frequency // 10000 // Hz
    if (f === 0) {
        f = 1e3
    }
    // console.log(`getImpactKinematics: f=${f} Hz`)

    let impactHeightM = impact.impact_ffh // m
    // let nFF = impact.impact_index // n
    // let fEstimate = nFF / tFFEstimate // Hz (1/s)
    // let fCorrected = Math.round(fEstimate / 1000) * 1000 // 1kHZ step, e.g. 5kHz, 10kHz, 20kHz etc...

    let tFF = Math.sqrt((2 * impactHeightM) / G) // s (impact height is in m) // impactHeightM is probably including deformation below gtrans min threshold
    // tFF -= impact.impact_index / f
    let vInit = G * tFF // m/s
    // let realImpactHeightM = (G * Math.pow(tFF, 2)) / 2 // m
    // console.log(
    //     `getImpactKinematicsSM: impactHeight(estimate)=${impactHeightM}m, real impactHeight=${realImpactHeightM}m, t0=${tFF} s, f=${fCorrected} Hz vInit=${vInit} m/s`,
    // )

    let vCurr = vInit
    let tCurr = 0

    let dMin = 0
    let vMin = 0
    let gMax = 0
    let kinematicPoints: IKinematicPoint[] = []

    // First integral: velocity
    for (let i = 0; i < aq_points.length; i++) {
        let currQ = aq_points[i]
        let nextQ = aq_points[i + 1]
        if (nextQ === undefined) {
            break
        }
        // point index (sequence) and its delta
        // let dN = i + 1 - i
        // let nCurr = (i + i + 1) / 2 // n; i.e. trapezoidal rule

        // time and its delta
        let dT = 1 / f // s
        tCurr = i / f // s

        // mean acceleration (trapezoidal rule)
        let qMean = (currQ + nextQ) / 2 // g
        let aCurr = G * qMean // m/s^2

        // integrated velocity
        let dV = dT * aCurr // m/s
        vCurr -= dV
        vMin = Math.min(vCurr, vMin)

        gMax = Math.max(Math.abs(qMean), gMax)

        kinematicPoints.push({ g: qMean, a: aCurr, v: vCurr, t: tCurr, d: NaN })
    }

    let dCurr = 0
    // Second integral: displacement
    for (let i = 0; i < kinematicPoints.length; i++) {
        let currKinematicPoint = kinematicPoints[i]
        let nextKinematicPoint = kinematicPoints[i + 1]
        if (nextKinematicPoint === undefined) {
            break
        }
        let tCurr = currKinematicPoint.t
        let tNext = nextKinematicPoint.t

        let vCurr = currKinematicPoint.v
        let vNext = nextKinematicPoint.v

        let dT = tNext - tCurr // s
        let vMean = (vCurr + vNext) / 2 // m/s (trapezoidal rule)

        let dD = dT * vMean // m
        dCurr -= dD
        dMin = Math.min(dCurr, dMin)
        currKinematicPoint.d = dCurr
    }

    // Calculate INIT and FINAL values
    let iInit = 0
    let tInit = 0
    let dInit = 0
    // let vInit = 0
    for (let i = 0; i < kinematicPoints.length; i++) {
        let kp = kinematicPoints[i]
        iInit = i
        tInit = kp.t
        vInit = kp.v
        dInit = kp.d
        if (kp.a > FREE_FALL_ACCELERATION_THRESHOLD) {
            break
        }
    }
    let iFinal = 0
    let tFinal = 0
    let vFinal = 0
    let dFinal = 0
    for (let i = kinematicPoints.length - 1; i >= 0; i--) {
        let kp = kinematicPoints[i]
        iFinal = i
        tFinal = kp.t
        vFinal = kp.v
        dFinal = kp.d
        if (kp.a > FREE_FALL_ACCELERATION_THRESHOLD) {
            break
        }
    }

    let calculatedTFF = vInit / G
    let calculatedFFH = (G * Math.pow(calculatedTFF, 2)) / 2
    let impactDurationS = tFinal - tInit // s
    let calculatedDurationMS = 1000 * impactDurationS // ms

    let hicMax = 0
    let hicDt = 0
    let hicT0 = 0
    let initialDi = 0.036 * f // 36ms
    let hicNbIterations = 0
    // for (let di = initialDi; di < iFinal - iInit; di++) {
    //     // do not search for HIC with < 36ms
    //     for (let hicI1 = iInit; hicI1 < iFinal - di; hicI1++) {
    //         let sumQ = 0
    //         for (let i = 0; i < kinematicPoints.length; i++) {
    //             if (i < hicI1) {
    //                 continue
    //             }
    //             if (i > hicI1 + di) {
    //                 break
    //             }
    //             let kp = kinematicPoints[i]
    //             sumQ += Math.abs(kp.g) // gs
    //         }
    //         let dt = di / fCorrected // s
    //         let hic = dt * Math.pow(sumQ / fCorrected / dt, 2.5)
    //         if (hic > hicMax) {
    //             hicMax = hic
    //             hicDt = 1000 * dt
    //             hicT0 = 1000 * (hicI1 / fCorrected)
    //         }
    //     }
    //     hicNbIterations++
    // }

    // console.log(
    //     `getImpactKinematicsSM: HIC[@${hicT0}:${hicDt}]=${hicMax}; iterations=${hicNbIterations}`,
    // )

    // Offset distance
    dMin -= dInit
    for (let i = 0; i < kinematicPoints.length; i++) {
        let kp = kinematicPoints[i]
        kp.d -= dInit
    }

    let resilience = Math.pow(vFinal, 2) / Math.pow(vInit, 2) // ratio
    // console.log(
    //     `getImpactKinematicsSM: vFinal=${vCurr} m/s, tFinal=${tFinal}, dFinal=${dCurr} m, R=${resilience}, dEnf=${dCurr / 2} m, tShock=${impactDurationMS} ms`,
    // )
    let deflectionDistanceMM = Math.abs(dMin * 1000) // mm
    let deflectionDistancePerc: number | null = null
    if (smThicknessMM !== null) {
        deflectionDistancePerc = 100 * (deflectionDistanceMM / smThicknessMM) // %
    }
    let resiliencePerc = 100 * resilience // %
    return {
        samplingFrequency: f,
        calculatedDurationMS,
        calculatedTFF,
        calculatedFFH: calculatedFFH,
        calculatedHIC: {
            hicMax: hicMax,
            hicTms: hicT0,
            hicDTms: hicDt,
        },
        calculatedGmax: gMax,
        initialVelocity: vInit,
        finalVelocity: vFinal,
        deflectionDistanceMM,
        deflectionDistancePerc,
        resiliencePerc,
        kinematicPoints,
    }
}

export const getZoneResultSM = (
    impacts: Impact[],
    smType: MatTypeEN12503 | null,
    smThicknessMM: number | null,
): IZoneResult | null => {
    if (impacts.length === 0) {
        return null
    }

    let requirements = getRequirementsSM(smType)
    if (requirements === null) {
        return null
    }

    let zoneImpactsKinematicsMap: Record<string, IImpactKinematics> = {}
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        let kinematics = getImpactKinematics(impact, smThicknessMM)
        if (kinematics === null) {
            continue
        }
        let impactUUIDStr = pbUUIDToUuid(impact.uuid)
        zoneImpactsKinematicsMap[impactUUIDStr] = kinematics
    }

    let resultGmax = EResult.Adequate
    let gmaxAdq: MeanWithStd | null = null
    let gmaxValues = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0) {
            gmaxValues.push(impact.impact_gmax)
        }
    }
    let count = gmaxValues.length
    let meanGmax = gmaxValues.reduce((a, b) => a + b, 0) / count
    let stdGmax = Math.sqrt(
        gmaxValues
            .map((x) => Math.pow(x - meanGmax, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    gmaxAdq = {
        mean: meanGmax,
        std: stdGmax,
        count,
    }
    if (requirements.gmax !== null) {
        if (gmaxAdq.mean + gmaxAdq.std < requirements.gmax) {
            resultGmax = 2
        } else if (gmaxAdq.mean + gmaxAdq.std > requirements.gmax) {
            resultGmax = 0
        } else {
            resultGmax = 1
        }
    }

    let resultDeformationDistanceMM = EResult.Adequate
    let ddMAdq: MeanWithStd | null = null
    if (Object.keys(zoneImpactsKinematicsMap).length > 0) {
        let kDAbsValues = []
        for (let impactUuid in zoneImpactsKinematicsMap) {
            let kinematics = zoneImpactsKinematicsMap[impactUuid]
            if (kinematics.deflectionDistanceMM) {
                kDAbsValues.push(Math.abs(kinematics.deflectionDistanceMM))
            }
        }
        let count = kDAbsValues.length
        let meanKDAbs = kDAbsValues.reduce((a, b) => a + b, 0) / count
        let stdKDAbs = Math.sqrt(
            kDAbsValues
                .map((x) => Math.pow(x - meanKDAbs, 2))
                .reduce((a, b) => a + b, 0) / count,
        )
        ddMAdq = {
            mean: meanKDAbs,
            std: stdKDAbs,
            count,
        }
        if (requirements.deformationDistanceMM !== null) {
            if (meanKDAbs < requirements.deformationDistanceMM) {
                resultDeformationDistanceMM = EResult.Adequate
            } else if (meanKDAbs > requirements.deformationDistanceMM) {
                resultDeformationDistanceMM = EResult.NotAdequate
            } else {
                resultDeformationDistanceMM = EResult.ToReview
            }
        }
    }

    let resultDeformationDistancePerc = EResult.Adequate
    let ddPAdq: MeanWithStd | null = null
    if (Object.keys(zoneImpactsKinematicsMap).length > 0) {
        let kDPercValues = []
        for (let impactUuid in zoneImpactsKinematicsMap) {
            let kinematics = zoneImpactsKinematicsMap[impactUuid]
            if (kinematics.deflectionDistancePerc) {
                kDPercValues.push(kinematics.deflectionDistancePerc)
            }
        }
        let count = kDPercValues.length
        let meanKDPerc = kDPercValues.reduce((a, b) => a + b, 0) / count
        let stdKDPerc = Math.sqrt(
            kDPercValues
                .map((x) => Math.pow(x - meanKDPerc, 2))
                .reduce((a, b) => a + b, 0) / count,
        )
        ddPAdq = {
            mean: meanKDPerc,
            std: stdKDPerc,
            count,
        }
        if (requirements.deformationDistancePerc !== null) {
            if (meanKDPerc < requirements.deformationDistancePerc) {
                resultDeformationDistancePerc = EResult.Adequate
            } else if (meanKDPerc > requirements.deformationDistancePerc) {
                resultDeformationDistancePerc = EResult.NotAdequate
            } else {
                resultDeformationDistancePerc = EResult.ToReview
            }
        }
    }

    let resultResiliencePerc = EResult.Adequate
    let rPAdq: MeanWithStd | null = null
    if (Object.keys(zoneImpactsKinematicsMap).length > 0) {
        let resiliencePercValues = []
        for (let impactUuid in zoneImpactsKinematicsMap) {
            let kinematics = zoneImpactsKinematicsMap[impactUuid]
            if (kinematics.resiliencePerc) {
                resiliencePercValues.push(kinematics.resiliencePerc)
            }
        }
        let count = resiliencePercValues.length
        let meanResiliencePerc =
            resiliencePercValues.reduce((a, b) => a + b, 0) / count
        let stdResiliencePerc = Math.sqrt(
            resiliencePercValues
                .map((x) => Math.pow(x - meanResiliencePerc, 2))
                .reduce((a, b) => a + b, 0) / count,
        )
        rPAdq = {
            mean: meanResiliencePerc,
            std: stdResiliencePerc,
            count,
        }
        if (requirements.resiliencePerc !== null) {
            let resiliencePercFrom = requirements.resiliencePerc[0]
            let resiliencePercTo = requirements.resiliencePerc[1]
            if (resiliencePercFrom !== null && resiliencePercTo !== null) {
                if (
                    meanResiliencePerc < resiliencePercFrom ||
                    meanResiliencePerc > resiliencePercTo
                ) {
                    resultResiliencePerc = EResult.NotAdequate
                } else {
                    resultResiliencePerc = EResult.Adequate
                }
            } else if (resiliencePercTo !== null) {
                if (meanResiliencePerc > resiliencePercTo) {
                    resultResiliencePerc = EResult.NotAdequate
                } else {
                    resultResiliencePerc = EResult.Adequate
                }
            }
        }
    }

    let resultGlobal = Math.min(
        resultGmax,
        resultDeformationDistanceMM,
        resultDeformationDistancePerc,
        resultResiliencePerc,
    )

    return {
        result: resultGlobal,
        gmax: gmaxAdq,
        hic: null,
        cfh: null,
        deflectionDistanceMM: ddMAdq,
        deflectionDistancePerc: ddPAdq,
        resiliencePerc: rPAdq,
    }
}
export const getEquipmentResultSM = (
    equipment: Equipment,
    zones: Zone[],
    zoneImpactsMap: Record<string, Impact[]>,
): IEquipmentResult | null => {
    if (zones.length === 0) {
        return null
    }
    let equipmentSMThickness = getEquipmentSportsMatThicknessMeanAndStd([
        equipment.sports_mat_thickness_side_one,
        equipment.sports_mat_thickness_side_two,
        equipment.sports_mat_thickness_side_three,
        equipment.sports_mat_thickness_side_four,
    ])
    if (equipmentSMThickness === null) {
        return null
    }
    let result = EResult.ToReview
    for (let zone of zones) {
        if (zone.deleted_at !== 0) {
            continue
        }
        let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
        let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
        if (zoneImpacts === undefined) {
            continue
        }
        let zoneResult = getZoneResultSM(
            zoneImpacts,
            equipment.sports_mat_type,
            equipmentSMThickness.mean,
        )
        if (zoneResult === null) {
            continue
        }
        if (zoneResult.result === EResult.NotAdequate) {
            result = EResult.NotAdequate
            break
        }
        if (zoneResult.result === EResult.Adequate) {
            result = EResult.Adequate
        }
    }
    return {
        result,
    }
}
export const getImpactResultSM = (
    impact: Impact,
    smType: MatTypeEN12503 | null,
    smThicknessMM: number | null,
): EResult => {
    let requirements = getRequirementsSM(smType)
    if (requirements === null) {
        return EResult.ToReview
    }
    if (!impact.ax_points) {
        return EResult.ToReview
    }
    // let impactHeightM = impact.impact_ffh / 100
    let kinematics = getImpactKinematics(impact, smThicknessMM)
    if (kinematics === null) {
        return EResult.ToReview
    }

    let resultGmax = 2
    if (requirements.gmax !== null) {
        if (impact.impact_gmax < BOTTOM_GMAX) {
            resultGmax = 2
        } else if (impact.impact_gmax > TOP_GMAX) {
            resultGmax = 0
        } else {
            resultGmax = 1
        }
    }

    let resultDeformationDistanceMM = 2

    if (
        requirements.deformationDistanceMM !== null &&
        kinematics.deflectionDistanceMM
    ) {
        let kDAbs = Math.abs(kinematics.deflectionDistanceMM)
        // console.log(`getImpactResultSM: kDAbs=${kDAbs}`)
        if (kDAbs < requirements.deformationDistanceMM) {
            resultDeformationDistanceMM = 2
        } else if (kDAbs > requirements.deformationDistanceMM) {
            resultDeformationDistanceMM = 0
        } else {
            resultDeformationDistanceMM = 1
        }
    }

    let resultDeformationDistancePerc = 2
    if (
        requirements.deformationDistancePerc !== null &&
        kinematics.deflectionDistancePerc
    ) {
        if (
            kinematics.deflectionDistancePerc <
            requirements.deformationDistancePerc
        ) {
            resultDeformationDistancePerc = 2
        } else if (
            kinematics.deflectionDistancePerc >
            requirements.deformationDistancePerc
        ) {
            resultDeformationDistancePerc = 0
        } else {
            resultDeformationDistancePerc = 1
        }
    }

    let resultResiliencePerc = 2
    if (requirements.resiliencePerc !== null && kinematics.resiliencePerc) {
        let resiliencePercFrom = requirements.resiliencePerc[0]
        let resiliencePercTo = requirements.resiliencePerc[1]
        if (resiliencePercFrom !== null && resiliencePercTo !== null) {
            if (
                kinematics.resiliencePerc < resiliencePercFrom ||
                kinematics.resiliencePerc > resiliencePercTo
            ) {
                resultResiliencePerc = 0
            } else {
                resultResiliencePerc = 2
            }
        } else if (resiliencePercTo !== null) {
            if (kinematics.resiliencePerc > resiliencePercTo) {
                resultResiliencePerc = 0
            } else {
                resultResiliencePerc = 2
            }
        }
    }

    // console.log(`getImpactResultSM: resultGmax=${resultGmax},
    // resultDeformationDistanceMM=${resultDeformationDistanceMM},
    // resultDeformationDistancePerc=${resultDeformationDistancePerc},
    // resultResiliencePerc=${resultResiliencePerc}
    // `)
    let resultGlobal = Math.min(
        resultGmax,
        resultDeformationDistanceMM,
        resultDeformationDistancePerc,
        resultResiliencePerc,
    )

    return resultGlobal
}
