import { FC, useEffect, useState } from "react"
import { Avatar, Button, Popover, Switch, Tag, Tooltip } from "antd"
import { AccountBookOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { getLocalStorageCloudApiUser } from "../../utils/cloudApiV2"
import { FlexCol, FlexRow } from "./common"
import { COLOR_BG_BLUE, COLOR_BG_GRAY, COLOR_BG_GREEN } from "../../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { ICloudUser } from "../../types"

const RoundedTag: FC<{
    children: React.ReactNode
    color?: string
    style?: React.CSSProperties
}> = ({ children, color, style }) => {
    return (
        <Tag
            color={color}
            style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 20,
                ...style,
            }}
        >
            {children}
        </Tag>
    )
}

export const LoginWidget = () => {
    const [user, setUser] = useState<ICloudUser | null>(null)

    useEffect(() => {
        setUser(getLocalStorageCloudApiUser())
    }, [])

    if (user === null) {
        return null
    }

    return (
        <Popover
            trigger="click"
            placement="bottomRight"
            overlayStyle={{
                minWidth: 300,
                minHeight: 300,
            }}
            content={
                <FlexCol
                    style={{
                        gap: 3,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <FlexCol
                        style={{
                            alignItems: "stretch",
                            gap: 5,
                        }}
                    >
                        <FlexCol
                            style={{
                                gap: 5,
                                alignItems: "start",
                            }}
                        >
                            <b>
                                {user.LastName} {user.FirstName}
                            </b>
                            {user.Enterprise.Name && (
                                <RoundedTag color="blue">
                                    <FlexRow
                                        style={{
                                            fontSize: "1.1rem",
                                            gap: 3,
                                            alignItems: "center",
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faBuilding}
                                            color={COLOR_BG_BLUE}
                                        />
                                        {user.Enterprise.Name}
                                    </FlexRow>
                                </RoundedTag>
                            )}
                            {user.Username && (
                                <RoundedTag color="green">
                                    <FlexRow
                                        style={{
                                            fontSize: "1.1rem",
                                            gap: 3,
                                            alignItems: "center",
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUserCircle}
                                            color={COLOR_BG_GREEN}
                                        />
                                        {user.Username}
                                    </FlexRow>
                                </RoundedTag>
                            )}
                        </FlexCol>
                        <FlexRow>
                            <Link to="/settings/general#account">
                                <Button block>Profile</Button>
                            </Link>
                            <Button
                                danger
                                block
                                type="default"
                                href="/api/logout"
                            >
                                Logout
                            </Button>
                        </FlexRow>
                    </FlexCol>
                </FlexCol>
            }
        >
            <Avatar
                size="large"
                style={{
                    backgroundColor: user ? COLOR_BG_GREEN : COLOR_BG_GRAY,
                    cursor: "pointer",
                }}
                icon={<UserOutlined />}
            />
        </Popover>
    )
}
