import {
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from "react"
import {
    UCError,
    HICConfig,
    HICState,
    StationConfig,
    StationSensors,
    UCPayload,
    UCPayloadType,
    UCAck,
    UUID,
    ReverseGeocodeRequest,
} from "../generated/proto-ts/main"
import { v4 as uuidv4, parse as uuidParse } from "uuid"
import { Mutex, withTimeout, MutexInterface } from "async-mutex"
import { crc16ccitt } from "crc"
import {
    IUsercommContextProviderContextType,
    usercommContextV2_PB,
    usercommStateReducer,
} from "./usercommCommon"
import { handleReverseGeocodeRequest } from "./usercommUtils"

export const DEFAULT_DEVICE_DETECTION_INTERVAL = 1000
export const BLE_HIC_SERVICE_UUID = 0x181c // User Data Service
// export const BLE_HIC_SERVICE_UUID = "0000181c-0000-1000-8000-00805f9b34fb" // User Data Service
export const BLE_SOCI_CHARACTERISTIC_UUID =
    "0000856d-0000-1000-8000-00805f9b34fb"
export const BLE_SICO_CHARACTERISTIC_UUID =
    "0000169e-0000-1000-8000-00805f9b34fb"
export const BLE_HRBT_CHARACTERISTIC_UUID =
    "00002aea-0000-1000-8000-00805f9b34fb"

const BLE_MIN_MTU = 182 // Mac OS MTU is 182 (lowest among all platforms)
const BLE_MAX_MTU = 512 - 3 // BLE definition allows up to 512 bytes

const UC_BLE_START: Uint8Array = new Uint8Array([0x55, 0xaa, 0x55, 0xaa])
const UC_BLE_STOP: Uint8Array = new Uint8Array([0xaa, 0x55, 0xaa, 0x55])

const BLE_INTER_CHUNK_DELAY_MS = 20 // kB/s MAX
const BLE_INTER_MESSAGE_DELAY_MS = 100

const uint8ArraysAreEqual = (a: Uint8Array, b: Uint8Array) => {
    if (a.length !== b.length) {
        return false
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false
        }
    }
    return true
}

export const navigatorSupportsWebBle = () => {
    return "bluetooth" in navigator
}

export const getBleDeviceCandidates = async () => {
    if (!navigatorSupportsWebBle()) {
        throw new Error("Web Bluetooth is not supported")
    }
    const devices = await navigator.bluetooth.getDevices()
    let candidateDevices: (BluetoothDevice | null)[] = []
    for (let i = 0; i < devices.length; i++) {
        let d = devices[i]
        if (d.name && d.name.startsWith("LU")) {
            candidateDevices.push(d)
        }
    }
    return devices
}

export const requestBleDevice = async () => {
    if (!navigatorSupportsWebBle()) {
        throw new Error("Web Bluetooth is not supported")
    }
    const device = await navigator.bluetooth.requestDevice({
        filters: [
            {
                manufacturerData: [
                    {
                        companyIdentifier: 0x1189,
                    },
                ],
            },
        ],
        optionalServices: [BLE_HIC_SERVICE_UUID],
    })
    return device
}

const sleep = async (ms: number) => {
    return await new Promise((resolve) => setTimeout(resolve, ms))
}

export const useUsercommContextBLE =
    (): IUsercommContextProviderContextType => {
        const context = useContext(usercommContextV2_PB)
        if (context === undefined) {
            throw new Error(
                "useSocketContext must be used within a SocketContextProvider",
            )
        }
        return context
    }

export const UsercommProviderBLE: FC<{
    children: ReactNode
}> = ({ children }) => {
    // Important! Two notification characteristics on a single device causes unknown GATT error on Androind
    const [state, dispatch] = useReducer(usercommStateReducer, {
        socket: undefined,
        socketIsConnected: false,
        bleDevice: undefined,
        bleIsConnected: false,

        hicState: null,
        hicConfig: null,
        stationConfig: null,
        stationSensors: null,

        // Consumables
        hicConfigSetAckConsumable: null,
        stationConfigSetAckConsumable: null,
        hicRawMeasurementConsumable: null,

        // Storage message queue
        recvMessageQueue: [],
        emitMessageQueue: [],

        emitMtu: BLE_MAX_MTU,
        recvMtu: BLE_MIN_MTU,
    })

    const sociRecvBufferRef = useRef<Uint8Array>(
        new Uint8Array(10 * 1024 * 1024),
    ) // 10 MB
    const sociRecvBufferOffsetRef = useRef<number>(0)
    const sociRecvPayloadLengthRef = useRef<number>(0)
    const sociRecvEffectiveMTURef = useRef<number>(0)
    const sociRecvTsRef = useRef<number>(0)

    const recvMutexRef = useRef<MutexInterface>(new Mutex())
    const emitMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 1000))

    const sicoWriteToCharacteristic = useCallback(
        async (
            characteristic: BluetoothRemoteGATTCharacteristic | null,
            frame: Uint8Array,
        ) => {
            if (characteristic === null) {
                console.warn(
                    "sicoWriteToCharacteristic: sicoCharacteristic is null",
                )
                return
            }
            let frameLength = frame.length
            let tramView: DataView = new DataView(
                new ArrayBuffer(frameLength + 16),
            )
            // console.debug(
            //     `sicoWriteToCharacteristic: frameLength: ${frameLength}; tramLength: ${tramView.byteLength}`,
            // )
            let offset = 0
            for (let b of UC_BLE_START) {
                tramView.setUint8(offset, b)
                offset++
            }
            tramView.setUint16(offset, BLE_MIN_MTU) // notify the receiver of our receive MTU
            offset += 2
            tramView.setUint32(offset, frameLength)
            offset += 4
            for (let i = 0; i < frameLength; i++) {
                tramView.setUint8(offset, frame[i])
                offset++
            }
            let crc = crc16ccitt(frame)
            tramView.setUint16(offset, crc)
            offset += 2
            for (let b of UC_BLE_STOP) {
                tramView.setUint8(offset, b)
                offset++
            }
            try {
                for (let i = 0; i < tramView.byteLength; i += BLE_MAX_MTU) {
                    // Write with emit MTU (receiver supports max value of 512 bytes)
                    let endIdx = i + BLE_MAX_MTU
                    if (endIdx > tramView.byteLength) {
                        endIdx = tramView.byteLength
                    }
                    let chunk = new Uint8Array(tramView.buffer.slice(i, endIdx))
                    await characteristic.writeValue(chunk)
                    await sleep(BLE_INTER_CHUNK_DELAY_MS)
                    // let ratio = endIdx / tramView.byteLength
                    // let percInt = Math.floor(ratio * 100)
                    // if (percInt % 2 === 0) {
                    //     setEmitRatio(percInt / 100)
                    // }
                }
            } catch (e: any) {
                console.warn("sicoWriteToCharacteristic: error occurred", e)
            }
        },
        [],
    )

    const processSociEventCallback = useCallback(
        async (event: Event) => {
            try {
                if (event.target === null) {
                    return
                }
                let v = (event.target as BluetoothRemoteGATTCharacteristic)
                    .value
                if (!v) {
                    return
                }
                let d = new Uint8Array(v.buffer)
                if (sociRecvBufferOffsetRef.current === 0) {
                    sociRecvTsRef.current = Date.now()
                    sociRecvEffectiveMTURef.current = 0
                    sociRecvPayloadLengthRef.current = 0
                    // setRecvRatio(0)
                }
                if (d.length > sociRecvEffectiveMTURef.current) {
                    sociRecvEffectiveMTURef.current = d.length
                }
                // console.debug("BLE_PB: received char raw data", d.length)

                sociRecvBufferRef.current.set(
                    d,
                    sociRecvBufferOffsetRef.current,
                )
                sociRecvBufferOffsetRef.current += d.length
                // console.debug(
                //     `BLE_PB: received data (offset = ${sociRecvBufferOffsetRef.current}): `,
                //     sociRecvBufferRef.current.slice(0, sociRecvBufferOffsetRef.current),
                // )
                if (
                    !uint8ArraysAreEqual(
                        sociRecvBufferRef.current.slice(0, 4),
                        UC_BLE_START,
                    )
                ) {
                    console.warn(
                        "BLE_PB: received data does not start with UC_BLE_START: dropping the buffer",
                    )
                    sociRecvBufferOffsetRef.current = 0
                    // setRecvRatio(0)
                    return
                }
                if (sociRecvBufferRef.current.length < 10) {
                    // console.log(
                    //     `BLE_PB: received data is less than 10 bytes: tram length is unavailable yet`,
                    // )
                    return
                }
                if (sociRecvPayloadLengthRef.current === 0) {
                    sociRecvPayloadLengthRef.current = new DataView(
                        sociRecvBufferRef.current.buffer,
                    ).getUint32(6)
                } else {
                    let recvPayloadLength = sociRecvBufferOffsetRef.current - 16
                    let ratio =
                        recvPayloadLength / sociRecvPayloadLengthRef.current
                    let percInt = Math.floor(ratio * 100)
                    if (percInt % 2 === 0) {
                        // setRecvRatio(percInt / 100)
                    }
                }
                if (
                    !uint8ArraysAreEqual(
                        sociRecvBufferRef.current.slice(
                            sociRecvBufferOffsetRef.current - 4,
                            sociRecvBufferOffsetRef.current,
                        ),
                        UC_BLE_STOP,
                    )
                ) {
                    // console.debug(
                    //     "BLE_PB: received data does not end with UC_BLE_STOP: more data is expected",
                    // )
                    return
                }
                let tramView = new DataView(
                    sociRecvBufferRef.current.slice(
                        0,
                        sociRecvBufferOffsetRef.current,
                    ).buffer,
                )
                // setRecvRatio(1)
                sociRecvBufferOffsetRef.current = 0
                if (
                    sociRecvPayloadLengthRef.current !==
                    tramView.byteLength - 16
                ) {
                    console.warn(
                        `BLE_PB: received tram length and length header do not match: ${sociRecvPayloadLengthRef.current} !== ${tramView.byteLength - 16}`,
                    )
                }
                let tramPayload = new Uint8Array(
                    tramView.buffer.slice(
                        10,
                        10 + sociRecvPayloadLengthRef.current,
                    ),
                )
                let tramCrc = tramView.getUint16(
                    10 + sociRecvPayloadLengthRef.current,
                )
                // console.debug(
                //     "BLE_PB: received tram",
                //     sociRecvPayloadLengthRef.current,
                //     tramPayload.length,
                //     tramCrc,
                // )

                let localCrc = crc16ccitt(tramPayload)
                if (tramCrc !== localCrc) {
                    console.warn("BLE_PB: CRC mismatch: ", tramCrc, localCrc)
                }
                let ucPayload: UCPayload | null = null
                try {
                    ucPayload = UCPayload.deserializeBinary(tramPayload)
                } catch (e) {
                    console.error(
                        `BLE_PB: failed to deserialize UCPayload from BLE SOCI characteristic`,
                        e,
                    )
                    return
                }
                if (ucPayload === null) {
                    console.error(`BLE_PB: ucPayload is null`)
                    return
                }

                // console.log(`BLE_PB: UCPayload Type #${ucPayload.type}`)

                let ack: UCAck | null = null
                if (ucPayload.type < 64) {
                    // Errors and responses to SICO commands
                    switch (ucPayload.type) {
                        // Error
                        case UCPayloadType.SICO_ERROR:
                            let error = UCError.deserializeBinary(
                                ucPayload.data,
                            )
                            console.error(`BLE_PB: SICO_ERROR: ${error.value}`)
                            break
                        // Response to SICO GetHICConfigCommand
                        case UCPayloadType.SICO_GET_HIC_CONFIG_COMMAND:
                            let hicConfig = HICConfig.deserializeBinary(
                                ucPayload.data,
                            )
                            dispatch({
                                type: "SET_HIC_CONFIG",
                                payload: hicConfig,
                            })
                            break
                        // Response to SICO GetStationConfigCommand
                        case UCPayloadType.SICO_GET_STATION_CONFIG_COMMAND:
                            let stationConfig = StationConfig.deserializeBinary(
                                ucPayload.data,
                            )
                            dispatch({
                                type: "SET_STATION_CONFIG",
                                payload: stationConfig,
                            })
                            break
                        // Response to SICO SetHICConfigCommand
                        case UCPayloadType.SICO_SET_HIC_CONFIG_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            dispatch({
                                type: "SET_HIC_CONFIG_SET_ACK_CONSUMABLE",
                                payload: ack.value,
                            })
                            break
                        // Response to SICO SetStationConfigCommand
                        case UCPayloadType.SICO_SET_STATION_CONFIG_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            dispatch({
                                type: "SET_STATION_CONFIG_SET_ACK_CONSUMABLE",
                                payload: ack.value,
                            })
                            break
                        // Response to SICO DropHICCommand
                        case UCPayloadType.SICO_DROP_HIC_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.log(
                                `BLE_PB: SICO_DROP_HIC_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO RestartStationCommand
                        case UCPayloadType.SICO_RESTART_STATION_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.log(
                                `BLE_PB: SICO_RESTART_STATION_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO RebootStationCommand
                        case UCPayloadType.SICO_REBOOT_STATION_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.log(
                                `BLE_PB: SICO_REBOOT_STATION_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO HaltStationCommand
                        case UCPayloadType.SICO_HALT_STATION_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.log(
                                `BLE_PB: SICO_HALT_STATION_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO AddReverseGeocodeEntity
                        case UCPayloadType.SICO_ADD_REVERSE_GEOCODE_ENTITY:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.log(
                                `BLE_PB: SICO_ADD_REVERSE_GEOCODE_ENTITY: ${ack.value}`,
                            )
                            break
                        // Response to SICO LegacyHostAPIRequest
                        case UCPayloadType.SICO_LEGACY_HOST_API_REQUEST:
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        // MTU test
                        // case UCPayloadType.SICO_MTU_TEST:
                        //     let mtuTestResponse = MTUTestResponse.deserializeBinary(
                        //         ucPayload.data,
                        //     )
                        //     let _writeMtu = mtuTestResponse.received_size + 10
                        //     let _readMtu = sociRecvEffectiveMTURef.current
                        //     console.log(
                        //         `BLE_PB: received mtu test response: received size: ${mtuTestResponse.received_size}; Write MTU: ${_writeMtu}; Read MTU: ${_readMtu}; Initial MTU: ${payloadMtu}`,
                        //     )
                        //     dispatch({
                        //         type: "SET_MTU",
                        //         payload: _writeMtu,
                        //     })
                        //     break
                        // Echo test
                        case UCPayloadType.SICO_ECHO_TEST:
                            let dMs = Date.now() - sociRecvTsRef.current
                            if (dMs > 0) {
                                let throughputKBps =
                                    tramView.byteLength / 1024 / (dMs / 1000)
                                console.log(
                                    `BLE_PB: ECHO: received ${tramView.byteLength} bytes in ${dMs} ms: ${throughputKBps} kiB/s`,
                                )
                            }
                            break
                        // Get Releases
                        case UCPayloadType.SICO_GET_RELEASES:
                            console.log(`BLE_PB: received get releases command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        // Rename release
                        case UCPayloadType.SICO_RENAME_RELEASE:
                            console.log(
                                `BLE_PB: received rename release command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_SET_RELEASE:
                            console.log(`BLE_PB: received set release command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_DELETE_RELEASE:
                            console.log(
                                `BLE_PB: received delete release command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_BASH_CMD:
                            console.log(`BLE_PB: received bash command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        default:
                            console.warn(
                                `BLE_PB: unhandled message (<0x40)`,
                                ucPayload.type,
                            )
                            break
                    }
                } else if (ucPayload.type >= 128) {
                    switch (ucPayload.type) {
                        // SOCI Events
                        case UCPayloadType.SOCI_HIC_STATE_EVENT:
                            let hicState = HICState.deserializeBinary(
                                ucPayload.data,
                            )
                            dispatch({
                                type: "SET_HIC_STATE",
                                payload: hicState,
                            })
                            break
                        case UCPayloadType.SOCI_STATION_SENSORS_EVENT:
                            let stationSensors =
                                StationSensors.deserializeBinary(ucPayload.data)
                            dispatch({
                                type: "SET_STATION_SENSORS",
                                payload: stationSensors,
                            })
                            break
                        case UCPayloadType.SOCI_HIC_MEASUREMENT_EVENT:
                            let measurementUUID = UUID.deserializeBinary(
                                ucPayload.data,
                            )
                            console.log(
                                `BLE_PB: received hic measurement event`,
                                measurementUUID,
                            )
                            dispatch({
                                type: "SET_HIC_RAW_MEASUREMENT_CONSUMABLE",
                                payload: measurementUUID,
                            })
                            break
                        case UCPayloadType.SOCI_REVERSE_GEOCODE_REQUEST:
                            let reverseGeocodeRequest =
                                ReverseGeocodeRequest.deserializeBinary(
                                    ucPayload.data,
                                )
                            console.log(
                                `BLE_PB: received reverse geocode request`,
                                reverseGeocodeRequest,
                            )
                            handleReverseGeocodeRequest(
                                reverseGeocodeRequest,
                            ).then((reverseGeocodeResponse) => {
                                if (reverseGeocodeResponse === null) {
                                    console.warn(
                                        `BLE_PB: reverse geocode response is null`,
                                    )
                                    return
                                }
                                console.log(
                                    `BLE_PB: reverse geocode response`,
                                    reverseGeocodeResponse,
                                )
                                addEmitMessage(
                                    new UCPayload({
                                        uuid: undefined,
                                        type: UCPayloadType.SICO_ADD_REVERSE_GEOCODE_ENTITY,
                                        data: reverseGeocodeResponse.serializeBinary(),
                                    }),
                                )
                            })

                            break
                        case UCPayloadType.SOCI_BASH_STDOUT:
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        default:
                            console.warn(
                                `BLE_PB: unhandled message (>0x80)`,
                                ucPayload.uuid.value,
                                ucPayload.type,
                            )
                            break
                    }
                } else {
                    // Storage messages
                    dispatch({
                        type: "ADD_MESSAGE_TO_RECV_QUEUE",
                        payload: ucPayload,
                    })
                }
                return
            } catch (e: any) {
                console.error(
                    "BLE_PB: error processing received data",
                    e.message,
                )
            }
        },
        [dispatch],
    )

    const handleGattServiceDisconnected = (event: Event) => {
        console.log("BLE_PB: GATT service disconnected")
        setBleIsConnected(false)
    }

    const handleSociCharacteristicValueChanged = (event: Event) => {
        let t0 = Date.now()
        recvMutexRef.current.acquire().then((release) => {
            let tA = Date.now()
            // console.debug(
            //     `BLE_PB: SOCI received char value changed event: mutex acquired after ${tA - t0}ms`,
            // )
            processSociEventCallback(event).finally(() => {
                let tR = Date.now()
                // console.debug(
                //     `BLE_PB: SOCI: received char value changed event: process callback took ${tR - tA}ms`,
                // )
                release()
            })
        })
    }

    const handleHrbtCharacteristicValueChanged = (event: Event) => {
        if (event.target === null) {
            return
        }
        let v = (event.target as BluetoothRemoteGATTCharacteristic).value
        if (!v) {
            return
        }
        let hrbtIdx = new DataView(v.buffer).getUint16(0)
        console.debug("BLE_PB: HRBT #", hrbtIdx)
    }

    const startNotificationsCallback = useCallback(
        async (bleGattSnap: BluetoothRemoteGATTServer): Promise<boolean> => {
            if (bleGattSnap.connected === false) {
                return false
            }
            let service: BluetoothRemoteGATTService | null = null
            try {
                service =
                    await bleGattSnap.getPrimaryService(BLE_HIC_SERVICE_UUID)
            } catch (e: any) {
                console.log(
                    `BLE_PB: STARTUP: error getting primary service ${BLE_HIC_SERVICE_UUID}`,
                    e.message,
                )
            }
            if (service === null) {
                return false
            }

            let sociCharacteristic: BluetoothRemoteGATTCharacteristic | null =
                null
            let hrbtCharacteristic: BluetoothRemoteGATTCharacteristic | null =
                null
            try {
                sociCharacteristic = await service.getCharacteristic(
                    BLE_SOCI_CHARACTERISTIC_UUID,
                )
                sociCharacteristic.oncharacteristicvaluechanged =
                    handleSociCharacteristicValueChanged
                await sociCharacteristic.startNotifications()
                console.log(
                    `BLE_PB: STARTUP:  characteristic with value changed handler`,
                    sociCharacteristic,
                )
            } catch (e: any) {
                console.log(
                    `BLE_PB: STARTUP:  error starting notifications for soci characteristic`,
                    e.message,
                )
            }

            try {
                hrbtCharacteristic = await service.getCharacteristic(
                    BLE_HRBT_CHARACTERISTIC_UUID,
                )
                hrbtCharacteristic.oncharacteristicvaluechanged =
                    handleHrbtCharacteristicValueChanged
                await hrbtCharacteristic.startNotifications()
                console.log(
                    `BLE_PB: STARTUP: hrbt characteristic with value changed handler`,
                    hrbtCharacteristic,
                )
            } catch (e: any) {
                console.log(
                    `BLE_PB: STARTUP:  error starting notifications for hrbt characteristic`,
                    e.message,
                )
            }
            return sociCharacteristic !== null && hrbtCharacteristic !== null
        },
        [],
    )

    const processEmitQueueCallback = useCallback(
        async (
            bleDeviceSnap: BluetoothDevice | undefined,
            emitQueueSnap: UCPayload[],
        ) => {
            if (emitQueueSnap.length === 0) {
                return
            }
            // console.debug(
            //     `BLE_PB: SICO: EMIT_QUEUE length ${emitQueueSnap.length}`,
            // )
            if (
                bleDeviceSnap === undefined ||
                bleDeviceSnap.gatt === undefined
            ) {
                return
            }
            let service =
                await bleDeviceSnap.gatt.getPrimaryService(BLE_HIC_SERVICE_UUID)
            let sicoCharacteristic = await service.getCharacteristic(
                BLE_SICO_CHARACTERISTIC_UUID,
            )
            // console.log(
            //     `BLE_PB: SICO: got sico characteristic`,
            //     sicoCharacteristic,
            // )

            for (let ucPayload of emitQueueSnap) {
                let frame = ucPayload.serializeBinary()
                console.log(
                    `BLE_PB: SICO: sending command of type #${ucPayload.type}: ${frame.length} bytes`,
                )
                try {
                    await sicoWriteToCharacteristic(sicoCharacteristic, frame)
                } catch (e: any) {
                    console.error(
                        "BLE_PB: SICO: error sending command",
                        e.message,
                    )
                } finally {
                    await sleep(BLE_INTER_MESSAGE_DELAY_MS)
                    dispatch({
                        type: "CONSUME_MESSAGE_FROM_EMIT_QUEUE",
                        payload: ucPayload.uuid,
                    })
                }
            }
        },
        [],
    )
    useEffect(() => {
        if (emitMutexRef.current.isLocked()) {
            return
        }
        if (state.emitMessageQueue.length === 0) {
            return
        }
        let t0 = Date.now()
        emitMutexRef.current
            .acquire()
            .then(async (release) => {
                let tA = Date.now()
                // console.debug(
                //     `BLE_PB: SICO: mutex acquired after ${Date.now() - t0}ms`,
                // )
                processEmitQueueCallback(
                    state.bleDevice,
                    state.emitMessageQueue,
                ).finally(() => {
                    let tR = Date.now()
                    // console.debug(
                    //     `BLE_PB: SICO: processing EMIT_QUEUE took ${tR - tA}ms `,
                    // )
                    release()
                })
            })
            .catch((e: any) => {
                console.warn(
                    `BLE_PB: SICO: mutex could not be acquired: ${e.message}`,
                )
            })
    }, [state.bleDevice, state.emitMessageQueue])

    const conectGattCallback = useCallback(
        async (bleDeviceSnap: BluetoothDevice) => {
            if (bleDeviceSnap.gatt === undefined) {
                return
            }
            if (bleDeviceSnap.watchAdvertisements !== undefined) {
                await bleDeviceSnap.watchAdvertisements()
            }
            try {
                await sleep(1000)
                let gatt = await bleDeviceSnap.gatt.connect()
                gatt.device.ongattserverdisconnected =
                    handleGattServiceDisconnected
                await sleep(1000)
                startNotificationsCallback(gatt).then(async (isSuccess) => {
                    if (isSuccess) {
                        await sleep(1000)
                        setBleIsConnected(true)
                    } else {
                        gatt.disconnect()
                        if (gatt.device.watchAdvertisements !== undefined) {
                            await gatt.device.watchAdvertisements()
                        }
                        setBleIsConnected(false)
                    }
                })
                console.log("BLE_PB: connected to GATT", gatt)
            } catch (e: any) {
                console.error("BLE_PB: error connecting to GATT", e.message)
                setBleIsConnected(false)
            }
        },
        [],
    )

    const disconnectGattCallback = useCallback(
        async (bleDeviceSnap: BluetoothDevice) => {
            if (bleDeviceSnap.gatt === undefined) {
                return
            }
            try {
                bleDeviceSnap.gatt.disconnect()
                console.log(
                    "BLE_PB: disconnected from GATT",
                    bleDeviceSnap.gatt,
                )
            } catch (e: any) {
                console.error(
                    "BLE_PB: error disconnecting from GATT",
                    e.message,
                )
            }
        },
        [],
    )

    useEffect(() => {
        if (!navigatorSupportsWebBle()) {
            return
        }
        console.log("BLE_PB: ble device", state.bleDevice)
        if (state.bleDevice === undefined) {
            return
        }
        conectGattCallback(state.bleDevice)
        return () => {
            if (state.bleDevice === undefined) {
                return
            }
            disconnectGattCallback(state.bleDevice)
        }
    }, [state.bleDevice])

    // useEffect(() => {
    //     let i = setInterval(() => {
    //         if (
    //             state.bleDevice === undefined ||
    //             state.bleDevice.gatt === undefined ||
    //             state.bleDevice.gatt.connected === false
    //         ) {
    //             clearInterval(i)
    //             return
    //         }
    //         let dt = Date.now() - sociRecvTsRef.current
    //         console.debug("BLE_PB: dt", dt)
    //         if (dt > 3000) {
    //             state.bleDevice.gatt.disconnect()
    //             setBleIsConnected(false)
    //         }
    //     }, 1000)
    //     return () => clearInterval(i)
    // }, [
    //     state.bleDevice,
    //     state.bleDevice?.gatt,
    //     state.bleDevice?.gatt?.connected,
    // ])

    const handleOnAdvertisementReceived = useCallback(
        (event: BluetoothAdvertisingEvent) => {
            // console.debug("BLE_PB: ADVERTISEMENT received", event)
            for (let serviceUUID of event.uuids) {
                let serviceUUIDStr = serviceUUID.toString()
                let serviceUUIDInt = parseInt(serviceUUIDStr, 16)
                if (serviceUUIDInt === BLE_HIC_SERVICE_UUID) {
                    console.log("BLE_PB: ADVERTISEMENT contains HIC service")
                    setBleDevice(undefined)
                    setBleDevice(event.device)
                }
            }
        },
        [state.bleDevice],
    )

    const subscribeForKnownDevicesAdvertisementsCallback =
        useCallback(async () => {
            let deviceCandidates = await getBleDeviceCandidates()
            console.log("BLE_PB: SEARCH: device candidates:", deviceCandidates)
            if (deviceCandidates.length === 0) {
                return
            }
            for (let deviceCandidate of deviceCandidates) {
                if (
                    deviceCandidate.watchAdvertisements !== undefined &&
                    !deviceCandidate.watchingAdvertisements
                ) {
                    deviceCandidate.watchAdvertisements()
                }
                deviceCandidate.onadvertisementreceived =
                    handleOnAdvertisementReceived
                if (deviceCandidate.gatt === undefined) {
                    continue
                }
                if (deviceCandidate.gatt.connected) {
                    console.log(
                        "BLE_PB: SEARCH: deviceCandidate is already connected",
                    )
                    setBleDevice(deviceCandidate)
                    continue
                }
                try {
                    await deviceCandidate.gatt.connect()
                } catch (e: any) {
                    console.warn(
                        "BLE_PB: SEARCH: error connecting to deviceCandidate",
                        e.message,
                        deviceCandidate,
                    )
                    continue
                }
            }
        }, [])

    useEffect(() => {
        subscribeForKnownDevicesAdvertisementsCallback()
    }, [state.bleIsConnected, state.bleDevice])

    const consumeHICRawMeasurement = useCallback(() => {
        dispatch({ type: "SET_HIC_RAW_MEASUREMENT_CONSUMABLE", payload: null })
    }, [])

    const consumeHICConfigSetAck = useCallback(() => {
        dispatch({ type: "SET_HIC_CONFIG_SET_ACK_CONSUMABLE", payload: null })
    }, [])

    const consumeStationConfigSetAck = useCallback(() => {
        dispatch({
            type: "SET_STATION_CONFIG_SET_ACK_CONSUMABLE",
            payload: null,
        })
    }, [])

    const emitGetHICConfig = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_GET_HIC_CONFIG_COMMAND,
        })
        console.log(`BLE_PB: sending get hic config`, ucPayload.toObject())
        addEmitMessage(ucPayload)
    }, [])

    const emitGetStationConfig = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_GET_STATION_CONFIG_COMMAND,
        })
        console.log(`BLE_PB: sending get station config`, ucPayload.toObject())
        addEmitMessage(ucPayload)
    }, [])

    const emitSetHICConfig = useCallback((config: HICConfig, uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_SET_HIC_CONFIG_COMMAND,
            data: config.serializeBinary(),
        })
        console.log(`BLE_PB: sending set hic config`, ucPayload.toObject())
        addEmitMessage(ucPayload)
    }, [])

    const emitSetStationConfig = useCallback(
        (config: StationConfig, uuid?: string) => {
            const ucPayload = new UCPayload({
                uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
                type: UCPayloadType.SICO_SET_STATION_CONFIG_COMMAND,
                data: config.serializeBinary(),
            })
            console.log(
                `BLE_PB: sending set station config`,
                config.toObject(),
                ucPayload.toObject(),
            )
            addEmitMessage(ucPayload)
        },
        [],
    )

    const emitDropHIC = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_DROP_HIC_COMMAND,
        })
        console.log(
            `BLE_PB: sending start hic measurement`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const emitRestartStation = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_RESTART_STATION_COMMAND,
        })
        console.log(
            `BLE_PB: sending restart station command`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const emitRebootStation = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_REBOOT_STATION_COMMAND,
        })
        console.log(
            `BLE_PB: sending reboot station command`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const emitHaltStation = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_HALT_STATION_COMMAND,
        })
        console.log(
            `BLE_PB: sending halt station command`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const consumeRecvMessage = useCallback(
        (uuid: UCPayload["uuid"]) => {
            dispatch({ type: "CONSUME_MESSAGE_FROM_RECV_QUEUE", payload: uuid })
        },
        [dispatch],
    )

    const addEmitMessage = useCallback(
        (payload: UCPayload) => {
            dispatch({ type: "ADD_MESSAGE_TO_EMIT_QUEUE", payload })
        },
        [dispatch],
    )

    const setBleDevice = useCallback(
        (device: BluetoothDevice | undefined) => {
            dispatch({ type: "SET_BLE_DEVICE", payload: device })
        },
        [dispatch],
    )

    const setBleIsConnected = useCallback(
        (isConnected: boolean) => {
            dispatch({ type: "SET_BLE_IS_CONNECTED", payload: isConnected })
        },
        [dispatch],
    )

    return (
        <usercommContextV2_PB.Provider
            value={{
                ...state,
                socket: undefined,
                socketIsConnected: false,

                setSocketIsConnected: (isConnected: boolean) => {},
                setBleDevice,
                setBleIsConnected,

                emitGetHICConfig,
                emitSetHICConfig,
                emitGetStationConfig,
                emitSetStationConfig,
                emitDropHIC,
                emitRestartStation,
                emitRebootStation,
                emitHaltStation,

                consumeHICConfigSetAck,
                consumeStationConfigSetAck,
                consumeHICRawMeasurement,

                consumeRecvMessage,
                addEmitMessage,

                // emitMtuTest,
            }}
        >
            {children}
        </usercommContextV2_PB.Provider>
    )
}
