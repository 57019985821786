import { FC, ReactNode } from "react"
import { FlexCol } from "./common"
import { Input } from "antd"
import { Translated } from "../../utils/translated"

export const Label: FC<{
    children?: ReactNode
}> = ({ children }) => {
    return (
        <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">
            {children}
        </label>
    )
}

export const LabeledInput: FC<{
    label: string | null
    value: string | null
    setValue: (value: string | null) => void
    addonAfter?: string
}> = ({ label, value, setValue, addonAfter }) => {
    return (
        <FlexCol style={{ gap: 0 }}>
            <Label>
                <Translated keyEn={label} />
            </Label>
            <Input
                size="large"
                value={value ?? ""}
                onChange={(value) => {
                    setValue(value.target.value)
                }}
                variant="filled"
                addonAfter={addonAfter}
            />
        </FlexCol>
    )
}
