import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Helmet } from "react-helmet"
import { Link, useHistory, useLocation } from "react-router-dom"
import dayjs from "dayjs"
import { ISpecimenElement, MeanWithStd } from "../types"
import { callDeviceAPI } from "../utils/deviceAPI"
import {
    EResultTag,
    FlexCol,
    FlexRow,
    RequirementsAlertSM,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import { ErrorAlert } from "../components/commons-ts/errorAlert"
import { Translated, translated } from "../utils/translated"
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Input,
    Popconfirm,
    Popover,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Tag,
    Tooltip,
    Typography,
    message as antdMessage,
} from "antd"
import Container from "../components/commons-ts/container"
import {
    DeleteOutlined,
    DoubleRightOutlined,
    InfoCircleTwoTone,
    SaveOutlined,
    WarningFilled,
} from "@ant-design/icons"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import Table, { ColumnsType } from "antd/es/table"
import {
    isLabTestElement,
    methodElement,
    normTypeElement,
    sportsMatTypeDescriptionStr,
} from "../components/commons-ts/tags"
import {
    COLOR_BG_ROW_DELETED,
    ENTITY_SAVE_DEBOUNCE_DELAY_MS,
    MAX_WIDTH_CENTRAL_CONTAINER,
    parsePathForEntityID,
    parsePathForEntityUUID,
    pbUUIDToUuid,
    uuidToPbUUID,
} from "../utils/utils"
import {
    getZoneGmaxSM,
    getZoneHicSM,
    getZoneResultSM,
    getCFH_Gmax,
    getCFH_HIC,
    getCFH_Global,
    getZoneResultPFAdq,
    getGmaxPFAdq,
    getHicPFAdq,
    getZoneResultPF_CFH,
    IZoneResult,
} from "../utils/maths"
import {
    Equipment,
    Impact,
    MatTypeEN12503,
    MethodTypeEN1177,
    NormType,
    RawStorageEntity,
    Site,
    StorageEntityType,
    StorageRequest,
    StorageResponse,
    UCAck,
    UCError,
    UCPayload,
    UCPayloadType,
    UUID,
    Zone,
} from "../generated/proto-ts/main"
import { decodeLengthDelimitedArray } from "../usercomm/usercommUtils"
import {
    useUsercommCreateZoneBLE,
    useUsercommDeleteEquipmentBLE,
    useUsercommDeleteEquipmentSoftBLE,
    useUsercommEquipmentBLE,
    useUsercommEquipmentChildrenRecursiveBLE,
    useUsercommEquipmentZonesBLE,
    useUsercommSiteBLE,
    useUsercommUpdateEquipmentBLE,
} from "../usercomm/usercommAsyncRequestBLE"
import { UploadImageListV2 } from "../components/commons-ts/UploadImageV2AD"
import { DataTreeDrawer } from "./TreeDrawer"

const renderMeanStd = (
    meanStdValue: MeanWithStd,
    minCount: number,
): ReactElement => {
    return (
        <span>
            <b>{meanStdValue.mean.toFixed(0)}</b>&nbsp;±&nbsp;
            {meanStdValue.std.toFixed(0)}
            {/* &nbsp; */}
            {/* {meanStdValue.count && `[${meanStdValue.count}]`} */}
            {/* {meanStdValue.count && meanStdValue.count < minCount && (
                <Tooltip
                    overlay={
                        <span>
                            <Translated keyEn="Not enough impacts to compute a reliable mean and standard deviation" />
                            &nbsp;({"< "}
                            {minCount})
                        </span>
                    }
                >
                    <WarningFilled
                        style={{
                            color: "orange",
                        }}
                    />
                </Tooltip>
            )} */}
        </span>
    )
}

export const getEquipmentSportsMatThicknessMeanAndStd = (
    thicknesses: (number | null)[],
): MeanWithStd | null => {
    let thicknessesFiltered = []
    for (let thickness of thicknesses) {
        if (thickness !== null && thickness !== 0) {
            thicknessesFiltered.push(thickness)
        }
    }
    if (thicknessesFiltered.length === 0) {
        return null
    }
    let mean =
        thicknessesFiltered.reduce((a, b) => a + b, 0) /
        thicknessesFiltered.length
    let std = Math.sqrt(
        thicknessesFiltered
            .map((v) => (v - mean) ** 2)
            .reduce((a, b) => a + b, 0) / thicknessesFiltered.length,
    )
    return {
        mean,
        std,
        count: thicknessesFiltered.length,
    }
}

const LabeledInputInMM: FC<{
    label: string
    value: number | null
    setValue: (value: number | null) => void
}> = ({ label, value, setValue }) => {
    const setValueStr = useCallback(
        (v: string | null) => {
            if (v === null) {
                setValue(null)
                return
            }
            let value = parseInt(v)
            if (isNaN(value)) {
                setValue(null)
                return
            }
            setValue(value)
        },
        [setValue],
    )
    const memoValueStr = useMemo(() => {
        if (value === null) {
            return ""
        }
        return value.toFixed(0)
    }, [value])
    return (
        <LabeledInput
            label={label}
            value={memoValueStr}
            setValue={setValueStr}
            addonAfter="mm"
        />
    )
}

const LabeledInputInCM: FC<{
    label: string
    value: number | null
    setValue: (value: number | null) => void
}> = ({ label, value, setValue }) => {
    const setValueStr = useCallback(
        (v: string | null) => {
            if (v === null) {
                setValue(null)
                return
            }
            let value = 10 * parseInt(v)
            if (isNaN(value)) {
                setValue(null)
                return
            }
            setValue(value)
        },
        [setValue],
    )
    const memoValueStr = useMemo(() => {
        if (value === null) {
            return ""
        }
        return (value / 10).toFixed(0)
    }, [value])
    return (
        <LabeledInput
            label={label}
            value={memoValueStr}
            setValue={setValueStr}
            addonAfter="cm"
        />
    )
}

export const EquipmentZonesTableSM: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
    smType: MatTypeEN12503 | null
    smThickness: MeanWithStd | null
}> = ({ equipmentUUID, zones, smType, smThickness }) => {
    const [zoneResultsMap, setZoneResultsMap] = useState<Record<
        string,
        IZoneResult
    > | null>(null)
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBLE()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    useEffect(() => {
        if (zoneImpactsMap === null) {
            return
        }
        let _zoneResultsMap: Record<string, IZoneResult> = {}
        for (let zoneUUIDStr of Object.keys(zoneImpactsMap)) {
            let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
            if (zoneImpacts === undefined) {
                continue
            }
            let result = getZoneResultSM(
                zoneImpacts,
                smType,
                smThickness ? smThickness.mean : null,
            )
            if (result === null) {
                continue
            }
            _zoneResultsMap[zoneUUIDStr] = result
        }
        setZoneResultsMap(_zoneResultsMap)
    }, [zoneImpactsMap, smType, smThickness])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return (
                        <Link
                            to={`/zones/${pbUUIDToUuid(zone.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {zone.zone_name}
                        </Link>
                    )
                },
            },
            {
                title: (
                    <Tooltip overlay={<Translated keyEn="Nb impacts" />}>
                        # Imp
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            // {
            //     key: "ffh",
            //     title: (
            //         <Tooltip
            //             overlay={
            //                 <Translated keyEn="Target Free Fall Height, m" />
            //             }
            //         >
            //             <div>
            //                 <Translated keyEn="Target FFH, m" />
            //             </div>
            //         </Tooltip>
            //     ),
            //     render: (_, zone) => {
            //         let valueMeters = zone.zone_ffh_max / 100
            //         if (valueMeters === 0) {
            //             return "N/A"
            //         }
            //         return <span>{valueMeters.toFixed(2)}</span>
            //     },
            // },
            {
                key: "measuredFFH",
                title: (
                    <div>
                        <Tooltip
                            overlay={
                                <Translated keyEn="Max Measured Free Fall Height, m" />
                            }
                        >
                            <div>
                                <Translated keyEn="Height, m" />
                            </div>
                        </Tooltip>
                    </div>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let heightValues: number[] = []
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at !== 0) {
                            continue
                        }
                        if (impact.impact_ffh === 0) {
                            continue
                        }
                        heightValues.push(impact.impact_ffh)
                    }
                    if (heightValues.length === 0) {
                        return <Tag>N/A</Tag>
                    }
                    let max = Math.max(...heightValues)
                    return <span>{max.toFixed(2)}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.gmax === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.gmax, 3)
                },
            },
            {
                key: "deflectionDistanceMM",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Deflection distance, mm" />}
                    >
                        <div>
                            <Translated keyEn="Def. mm" />
                        </div>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistanceMM === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistanceMM, 3)
                },
            },
            {
                key: "deflectionDistancePerc",
                title: (
                    <Tooltip
                        overlay={
                            <Translated keyEn="Deflection percentage, %" />
                        }
                    >
                        <div>
                            <Translated keyEn="Def. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistancePerc === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistancePerc, 3)
                },
            },
            {
                key: "resiliencePerc",
                title: (
                    <Tooltip
                        overlay={
                            <Translated keyEn="Resilience percentage, %" />
                        }
                    >
                        <div>
                            <Translated keyEn="Res. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.resiliencePerc === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.resiliencePerc, 3)
                },
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTag result={result.result} />
                },
                fixed: "right",
            },
            {
                title: "",
                width: "2rem",
                render: (_, zone) => {
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
                fixed: "right",
            },
        ]
    }, [zoneImpactsMap, zoneResultsMap, smType, smThickness])

    return (
        <Table
            dataSource={zones ?? []}
            columns={columns}
            loading={zoneResultsMap === null}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(zone) => {
                return {
                    style: {
                        backgroundColor:
                            zone.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
        />
    )
}

export const EquipmentZonesTableSM_Print: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
    smType: MatTypeEN12503 | null
    smThickness: MeanWithStd | null
}> = ({ equipmentUUID, zones, smType, smThickness }) => {
    const [zoneResultsMap, setZoneResultsMap] = useState<Record<
        string,
        IZoneResult
    > | null>(null)
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBLE()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    useEffect(() => {
        if (zoneImpactsMap === null) {
            return
        }
        let _zoneResultsMap: Record<string, IZoneResult> = {}
        for (let zoneUUIDStr of Object.keys(zoneImpactsMap)) {
            let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
            if (zoneImpacts === undefined) {
                continue
            }
            let result = getZoneResultSM(
                zoneImpacts,
                smType,
                smThickness ? smThickness.mean : null,
            )
            if (result === null) {
                continue
            }
            _zoneResultsMap[zoneUUIDStr] = result
        }
        setZoneResultsMap(_zoneResultsMap)
    }, [zoneImpactsMap, smType, smThickness])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return zone.zone_name
                },
            },
            {
                title: "Nb impacts",
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "measuredFFH",
                title: <Translated keyEn="Height, m" />,
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let heightValues: number[] = []
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at !== 0) {
                            continue
                        }
                        if (impact.impact_ffh === 0) {
                            continue
                        }
                        heightValues.push(impact.impact_ffh)
                    }
                    if (heightValues.length === 0) {
                        return <Tag>N/A</Tag>
                    }
                    let max = Math.max(...heightValues)
                    return <span>{max.toFixed(2)}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.gmax === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.gmax, 3)
                },
            },
            {
                key: "deflectionDistanceMM",
                title: <Translated keyEn="Deflection, mm" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistanceMM === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistanceMM, 3)
                },
            },
            {
                key: "deflectionDistancePerc",
                title: <Translated keyEn="Deflection, %" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistancePerc === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistancePerc, 3)
                },
            },
            {
                key: "resiliencePerc",
                title: <Translated keyEn="Resilience, %" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.resiliencePerc === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.resiliencePerc, 3)
                },
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTag result={result.result} />
                },
                fixed: "right",
            },
        ]
    }, [zoneImpactsMap, zoneResultsMap, smType, smThickness])

    return (
        <Table
            dataSource={zones ?? []}
            columns={columns}
            loading={zoneResultsMap === null}
            pagination={false}
            rowKey="uuid"
            size="small"
            bordered
        />
    )
}

const EquipmentZonesTablePF_CFH: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBLE()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return (
                        <Link
                            to={`/zones/${pbUUIDToUuid(zone.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {zone.zone_name}
                        </Link>
                    )
                },
            },
            {
                title: <Translated keyEn="Nb Impacts" />,
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "floorThickness",
                title: (
                    <Tooltip overlay={translated("Ground thickness in mm")}>
                        <Translated keyEn="Thickness" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    return <span>{zone.floor_thickness}</span>
                },
            },
            {
                key: "ffh",
                title: (
                    <Tooltip
                        overlay={translated("Authorized Free Fall Height in m")}
                    >
                        <Translated keyEn="FFH" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let valueMeters = zone.zone_ffh_max / 100
                    return <span>{valueMeters.toFixed(2)}</span>
                },
            },
            // {
            //     key: "gmaxCFH",
            //     title: (
            //         <Tooltip
            //             overlay={translated(
            //                 "Critical Fall Height (calculated using Gmax) in m",
            //             )}
            //         >
            //             <Translated keyEn="CFHgmax" />
            //         </Tooltip>
            //     ),
            //     render: (_, zone) => {
            //         if (zone.impacts === undefined) {
            //             return null
            //         }
            //         let gmaxCFH = getCFH_Gmax(zone.impacts)
            //         if (gmaxCFH === null) {
            //             return null
            //         }
            //         return (
            //             <span>
            //                 {gmaxCFH.mean.toFixed(2)}
            //                 {gmaxCFH.count && `[${gmaxCFH.count}]`}
            //                 {gmaxCFH.count && gmaxCFH.count < 3 && (
            //                     <Tooltip
            //                         overlay={
            //                             <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
            //                         }
            //                     >
            //                         <WarningFilled
            //                             style={{
            //                                 color: "orange",
            //                             }}
            //                         />
            //                     </Tooltip>
            //                 )}
            //             </span>
            //         )
            //     },
            // },
            // {
            //     key: "hicCFH",
            //     title: (
            //         <Tooltip
            //             overlay={translated(
            //                 "Critical Fall Height (calculated using HIC) in m",
            //             )}
            //         >
            //             <Translated keyEn="CFHhic" />
            //         </Tooltip>
            //     ),
            //     render: (_, zone) => {
            //         if (zone.impacts === undefined) {
            //             return null
            //         }
            //         let hicCFH = getCFH_HIC(zone.impacts)
            //         if (hicCFH === null) {
            //             return null
            //         }
            //         return (
            //             <span>
            //                 {hicCFH.mean.toFixed(2)}
            //                 {hicCFH.count && `[${hicCFH.count}]`}
            //                 {hicCFH.count && hicCFH.count < 3 && (
            //                     <Tooltip
            //                         overlay={
            //                             <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
            //                         }
            //                     >
            //                         <WarningFilled
            //                             style={{
            //                                 color: "orange",
            //                             }}
            //                         />
            //                     </Tooltip>
            //                 )}
            //             </span>
            //         )
            //     },
            // },
            {
                key: "CFH",
                title: (
                    <Tooltip overlay={translated("Critical Fall Height in m")}>
                        <Translated keyEn="CFH" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let cfh = getCFH_Global(zoneImpacts)
                    if (cfh === null) {
                        return null
                    }
                    let cfhMeanM = cfh.mean / 100
                    return (
                        <span>
                            {cfhMeanM.toFixed(1)}{" "}
                            {cfh.count && `[${cfh.count}]`}
                            {cfh.count && cfh.count < 3 && (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
                                    }
                                >
                                    <WarningFilled
                                        style={{
                                            color: "orange",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    )
                },
            },
            {
                key: "result",
                title: (
                    <Tooltip overlay={translated("Result")}>
                        <Translated keyEn="Result" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneImpacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
                    if (zoneImpacts === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let result = getZoneResultPF_CFH(zone, zoneImpacts)
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTag result={result.result} />
                },
                fixed: "right",
            },
            {
                title: "",
                width: "2rem",
                render: (_, zone) => {
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
                fixed: "right",
            },
        ]
    }, [zones, zoneImpactsMap])

    return (
        <Table
            dataSource={zones ?? []}
            loading={zones === null || zoneImpactsMap === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(zone) => {
                return {
                    style: {
                        backgroundColor:
                            zone.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
        />
    )
}

const EquipmentZonesTablePFAdq: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBLE()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return (
                        <Link
                            to={`/zones/${pbUUIDToUuid(zone.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {zone.zone_name}
                        </Link>
                    )
                },
            },
            {
                title: <Translated keyEn="Nb Impacts" />,
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "floorThickness",
                title: (
                    <Tooltip overlay={translated("Ground thickness in mm")}>
                        <Translated keyEn="Thickness" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    return <span>{zone.floor_thickness}</span>
                },
            },
            {
                key: "ffh",
                title: (
                    <Tooltip
                        overlay={translated("Authorized Free Fall Height in m")}
                    >
                        <Translated keyEn="FFH" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let valueMeters = zone.zone_ffh_max / 100
                    return <span>{valueMeters.toFixed(2)}</span>
                },
            },
            {
                key: "measuredFFH",
                title: (
                    <Tooltip
                        overlay={translated("Measured Free Fall Height in cm")}
                    >
                        <Translated keyEn="Height" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let heightValues: number[] = []
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at !== 0) {
                            continue
                        }
                        if (impact.impact_ffh === 0) {
                            continue
                        }
                        heightValues.push(impact.impact_ffh)
                    }
                    let max = Math.max(...heightValues)
                    return <span>{max.toFixed(0)}</span>
                },
            },
            {
                key: "gmax",
                title: (
                    <Tooltip
                        overlay={translated("Max impact acceleration in g")}
                    >
                        <Translated keyEn="Gmax" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let gmaxAdq = getGmaxPFAdq(zoneImpacts)
                    if (gmaxAdq === null) {
                        return null
                    }
                    return (
                        <span>
                            {gmaxAdq.mean.toFixed(0)} ± {gmaxAdq.std.toFixed(0)}
                            {gmaxAdq.count && `[${gmaxAdq.count}]`}
                            {gmaxAdq.count && gmaxAdq.count < 3 && (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
                                    }
                                >
                                    <WarningFilled
                                        style={{
                                            color: "orange",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    )
                },
            },
            {
                key: "hic",
                title: (
                    <Tooltip overlay={translated("Head Injury Criterion")}>
                        <Translated keyEn="HIC" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let hicAdq = getHicPFAdq(zoneImpacts)
                    if (hicAdq === null) {
                        return null
                    }
                    return (
                        <span>
                            {hicAdq.mean.toFixed(0)} ± {hicAdq.std.toFixed(0)}
                            {hicAdq.count && `[${hicAdq.count}]`}
                            {hicAdq.count && hicAdq.count < 3 && (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
                                    }
                                >
                                    <WarningFilled
                                        style={{
                                            color: "orange",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    )
                },
            },
            {
                key: "result",
                title: (
                    <Tooltip overlay={translated("Result")}>
                        <Translated keyEn="Result" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let result = getZoneResultPFAdq(zoneImpacts)
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTag result={result.result} />
                },
                fixed: "right",
            },
            {
                title: "",
                width: "2rem",
                render: (_, zone) => {
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
                fixed: "right",
            },
        ]
    }, [zones, zoneImpactsMap])

    return (
        <Table
            dataSource={zones ?? []}
            loading={zones === null || zoneImpactsMap === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(zone) => {
                return {
                    style: {
                        backgroundColor:
                            zone.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
        />
    )
}

const NewZoneButton: FC<{
    equipmentUUID: UUID | null
    zoneName: string | null
    zoneFFH: number | null
}> = ({ equipmentUUID, zoneName, zoneFFH }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const history = useHistory()

    const [createdZoneUUID, createZoneRequest] = useUsercommCreateZoneBLE()

    // REQUEST CREATE ZONE
    const onCreate = useCallback(async () => {
        setIsLoading(true)
        if (equipmentUUID === null || zoneName === null) {
            setIsLoading(false)
            return
        }
        setIsLoading(true)
        let zone = new Zone({
            equipment_uuid: equipmentUUID,
            zone_name: zoneName,
            zone_ffh_max: zoneFFH ?? undefined,
        })
        console.log(`NewZoneButton: creating zone:`, zone.toObject())
        createZoneRequest(equipmentUUID, zone)
    }, [equipmentUUID, zoneName, zoneFFH])
    // RESPONSE CREATE ZONE
    useEffect(() => {
        if (createdZoneUUID === null) {
            return
        }
        history.push(`/zones/${pbUUIDToUuid(createdZoneUUID)}`)
    }, [createdZoneUUID])

    return (
        <Button
            disabled={equipmentUUID === null || zoneName === null}
            onClick={onCreate}
            loading={isLoading}
            size="large"
            type="primary"
        >
            <span
                style={{
                    textTransform: "uppercase",
                }}
            >
                <Translated keyEn="Start" />
            </span>
        </Button>
    )
}

export const EquipmentPage: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const memoEquipmentUUID = useMemo((): string | null => {
        return parsePathForEntityUUID(location.pathname)
    }, [location.pathname])

    // Site getter
    const [site, getSite] = useUsercommSiteBLE()

    // Site fields
    const [siteUUID, setSiteUUID] = useState<string | null>(null)
    const [siteName, setSiteName] = useState<string | null>(null)
    const [normType, setNormType] = useState<NormType | null>(null)
    const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
    const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

    // Equipment CRUD
    const [equipment, getEquipment] = useUsercommEquipmentBLE()
    const [updatedEquipmentAck, updateEquipment] =
        useUsercommUpdateEquipmentBLE()
    const [deleteEquipmentAck, deleteEquipmentSoft] =
        useUsercommDeleteEquipmentSoftBLE()

    // Equipment fields
    const [equipmentName, setEquipmentName] = useState<string | null>(null)
    const [equipmentInstallationDate, setEquipmentDate] =
        useState<dayjs.Dayjs | null>(null)
    const [equipmentManufacturer, setEquipmentManufacturer] = useState<
        string | null
    >(null)
    const [equipmentRef, setEquipmentRef] = useState<string | null>(null)
    const [maxFreeFallHeight, setMaxFreeFallHeight] = useState<string | null>(
        null,
    )
    const [floorCondition, setFloorCondition] = useState<string | null>(null)
    const [floorDate, setFloorDate] = useState<dayjs.Dayjs | null>(null)
    const [floorManufacturer, setFloorManufacturer] = useState<string | null>(
        null,
    )
    const [floorKind, setFloorKind] = useState<string | null>(null)
    const [floorRef, setFloorRef] = useState<string | null>(null)
    const [floorSubstrat, setFloorSubstrat] = useState<string | null>(null)

    // Zones getter
    const [zones, getZones] = useUsercommEquipmentZonesBLE()

    // Dimensions are stored as integers in mm
    const [sportsMatDimensionLength, setSportsMatDimensionLength] = useState<
        number | null
    >(null)
    const [sportsMatDimensionWidth, setSportsMatDimensionWidth] = useState<
        number | null
    >(null)
    const [
        sportsMatDimensionThicknessSide1,
        setSportsMatDimensionThicknessSide1,
    ] = useState<number | null>(null)
    const [
        sportsMatDimensionThicknessSide2,
        setSportsMatDimensionThicknessSide2,
    ] = useState<number | null>(null)
    const [
        sportsMatDimensionThicknessSide3,
        setSportsMatDimensionThicknessSide3,
    ] = useState<number | null>(null)
    const [
        sportsMatDimensionThicknessSide4,
        setSportsMatDimensionThicknessSide4,
    ] = useState<number | null>(null)
    const [sportsMatType, setSportsMatType] = useState<MatTypeEN12503 | null>(
        null,
    )
    const [sportsMatMetalFrameIsInPlace, setSportsMatMetalFrameIsInPlace] =
        useState<boolean | null>(null)

    const [specimenData, setSpecimenData] = useState<ISpecimenElement[] | null>(
        null,
    )
    const [geolocation, setGeolocation] = useState<string | null>(null)

    const [pictures, setPictures] = useState<Site["pictures"]>([])
    const [newZoneName, setNewZoneName] = useState<string | null>(null)

    const [error, setError] = useState<string | null>(null)

    // GET EQUIPMENT REQUEST
    useEffect(() => {
        if (memoEquipmentUUID === null) {
            return
        }
        getEquipment(uuidToPbUUID(memoEquipmentUUID))
    }, [memoEquipmentUUID])
    // GET EQUIPMENT RESPONSE
    useEffect(() => {
        if (equipment === null) {
            return
        }
        console.log(`EquipmentPage: equipment:`, equipment.toObject())
        if (equipment.site_uuid !== undefined) {
            setSiteUUID(pbUUIDToUuid(equipment.site_uuid))
        } else {
            console.warn(`EquipmentPage: equipment.site_uuid is undefined`)
        }
        setEquipmentName(equipment.equipment_name)
        if (equipment.equipment_installation_date === 0) {
            setEquipmentDate(null)
        } else {
            setEquipmentDate(dayjs(equipment.equipment_installation_date))
        }
        setEquipmentManufacturer(equipment.equipment_manufacturer)
        setEquipmentRef(equipment.equipment_reference)
        setMaxFreeFallHeight(equipment.equipment_ffh_max.toFixed(2))
        setFloorCondition(equipment.floor_condition)
        if (equipment.floor_installation_date === 0) {
            setFloorDate(null)
        } else {
            setFloorDate(dayjs(equipment.floor_installation_date))
        }
        setFloorManufacturer(equipment.floor_manufacturer)
        setFloorKind(equipment.floor_kind)
        setFloorRef(equipment.floor_reference)
        setFloorSubstrat(equipment.floor_substrat)
        // let specimenDataStr = equipment.specimen_data
        // if (specimenDataStr) {
        //     let specimenData = JSON.parse(specimenDataStr)
        //     setSpecimenData(specimenData)
        // }
        setPictures(equipment.pictures)
        if (equipment.sports_mat_type > 0) {
            setSportsMatType(equipment.sports_mat_type)
        } else {
            setSportsMatType(null)
        }
        setSportsMatMetalFrameIsInPlace(
            equipment.sports_mat_metal_frame_is_present,
        )
        setSportsMatDimensionLength(equipment.sports_mat_length)
        setSportsMatDimensionWidth(equipment.sports_mat_width)
        setSportsMatDimensionThicknessSide1(
            equipment.sports_mat_thickness_side_one ?? null,
        )
        setSportsMatDimensionThicknessSide2(
            equipment.sports_mat_thickness_side_two ?? null,
        )
        setSportsMatDimensionThicknessSide3(
            equipment.sports_mat_thickness_side_three ?? null,
        )
        setSportsMatDimensionThicknessSide4(
            equipment.sports_mat_thickness_side_four ?? null,
        )
        setSportsMatMetalFrameIsInPlace(
            equipment.sports_mat_metal_frame_is_present,
        )
    }, [equipment])

    // GET ZONES REQUEST
    useEffect(() => {
        if (memoEquipmentUUID === null) {
            return
        }
        getZones(uuidToPbUUID(memoEquipmentUUID))
    }, [memoEquipmentUUID])
    // GET ZONES RESPONSE
    useEffect(() => {
        if (zones === null) {
            return
        }
        console.log(`EquipmentPage: zones:`, zones)
    }, [zones])

    // GET SITE REQUEST
    useEffect(() => {
        if (equipment === null || equipment.site_uuid === undefined) {
            return
        }
        getSite(pbUUIDToUuid(equipment.site_uuid))
    }, [equipment])
    // GET SITE RESPONSE
    useEffect(() => {
        if (site === null) {
            return
        }
        console.log(`EquipmentPage: parent site:`, site.toObject())
        setSiteName(site.site_name)
        setNormType(site.norm_type)
        setMethod(site.method_type_en_1177)
        setIsLabTest(site.is_lab_test)
    }, [site])

    const memoNormTypeElement = useMemo(() => {
        return normTypeElement(normType)
    }, [normType])

    const memoMethodElement = useMemo(() => {
        if (normType === null || normType === NormType.EN_12503) {
            return null
        }
        return methodElement(method)
    }, [normType, method])

    const memoIsLabTestElement = useMemo(() => {
        return isLabTestElement(isLabTest)
    }, [isLabTest])

    const memoSportsMatThicknessMeanAndStd = useMemo((): MeanWithStd | null => {
        return getEquipmentSportsMatThicknessMeanAndStd([
            sportsMatDimensionThicknessSide1,
            sportsMatDimensionThicknessSide2,
            sportsMatDimensionThicknessSide3,
            sportsMatDimensionThicknessSide4,
        ])
    }, [
        sportsMatDimensionThicknessSide1,
        sportsMatDimensionThicknessSide2,
        sportsMatDimensionThicknessSide3,
        sportsMatDimensionThicknessSide4,
    ])

    const memoZonesTable = useMemo(() => {
        if (memoEquipmentUUID === null) {
            return null
        }
        let equipmentUUID = uuidToPbUUID(memoEquipmentUUID)
        console.log(`EquipmentPage: memoZonesTable:`, zones, normType, method)
        if (normType === NormType.EN_12503) {
            return (
                <EquipmentZonesTableSM
                    equipmentUUID={equipmentUUID}
                    zones={zones}
                    smType={sportsMatType}
                    smThickness={memoSportsMatThicknessMeanAndStd}
                />
            )
        }
        if (method === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE) {
            return (
                <EquipmentZonesTablePFAdq
                    equipmentUUID={equipmentUUID}
                    zones={zones}
                />
            )
        } else if (
            method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
        ) {
            return (
                <EquipmentZonesTablePF_CFH
                    equipmentUUID={equipmentUUID}
                    zones={zones}
                />
            )
        }
    }, [
        memoEquipmentUUID,
        zones,
        normType,
        method,
        sportsMatType,
        memoSportsMatThicknessMeanAndStd,
    ])

    const memoSportsMatLayoutElement = useMemo((): ReactElement | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        let src = ""
        let legend: ReactElement | null = null
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
            case MatTypeEN12503.TYPE_2:
            case MatTypeEN12503.TYPE_3:
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
                src = "/figure-tapis-types-1-8.png"
                legend = <Translated keyEn="Dimensions in cm" />
                break
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
            case MatTypeEN12503.TYPE_11:
                src = "/figure-tapis-types-9-11.png"
                legend = (
                    <FlexCol>
                        <Translated keyEn="Dimensions in cm" />
                        <ul>
                            <li>
                                a - <Translated keyEn="length of the mat" />
                            </li>
                            <li>
                                b - <Translated keyEn="width of the mat" />
                            </li>
                        </ul>
                    </FlexCol>
                )
                break
            case MatTypeEN12503.TYPE_12:
                src = "/figure-tapis-types-12.png"
                legend = (
                    <FlexCol>
                        <Translated keyEn="Dimensions in cm" />
                        <ul>
                            <li>
                                a - <Translated keyEn="length of the mat" />
                            </li>
                            <li>
                                b - <Translated keyEn="width of the mat" />
                            </li>
                            <li>
                                1-8 -{" "}
                                <Translated keyEn="positions of the test zones" />
                            </li>
                        </ul>
                    </FlexCol>
                )
                break
            case MatTypeEN12503.TYPE_EN12572:
                src = "/figure-tapis-type-en12572.png"
                legend = (
                    <FlexCol>
                        <Translated keyEn="Dimensions in m" />
                    </FlexCol>
                )
        }
        return (
            <Popover
                title={
                    <span
                        style={{
                            textTransform: "uppercase",
                        }}
                    >
                        Sports mat layout for <b>Type #{sportsMatType}</b>
                    </span>
                }
                content={
                    <FlexCol>
                        <img
                            src={src}
                            style={{
                                maxWidth: "500px",
                            }}
                        />
                        <div>
                            <b>
                                <Translated keyEn="Legend" />
                            </b>
                            :<br />
                            {legend}
                        </div>
                    </FlexCol>
                }
                // open
                trigger="click"
            >
                <Button
                    type="text"
                    style={{
                        height: "fit-content",
                    }}
                >
                    <FlexCol
                        style={{
                            gap: 0,
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img
                                src={src}
                                style={{
                                    width: "100%",
                                }}
                            />
                        </div>
                        <span>
                            <Translated keyEn="Layout" />
                        </span>
                    </FlexCol>
                </Button>
            </Popover>
        )
    }, [sportsMatType, normType])

    const memoSportsMatTargetImpactorDiameter =
        useMemo((): MeanWithStd | null => {
            if (sportsMatType === null || normType !== NormType.EN_12503) {
                return null
            }
            if (sportsMatType === MatTypeEN12503.TYPE_12) {
                return null
            }
            return {
                mean: 150,
                std: 0.5,
            }
        }, [sportsMatType, normType])

    const memoSportsMatTargetImpactorMass = useMemo((): {
        mean: number
        std: number
    } | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
            case MatTypeEN12503.TYPE_2:
            case MatTypeEN12503.TYPE_3:
                return {
                    mean: 10,
                    std: 0.1,
                }
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
                return {
                    mean: 20,
                    std: 0.2,
                }
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
            case MatTypeEN12503.TYPE_11:
                return {
                    mean: 30,
                    std: 0.3,
                }
            case MatTypeEN12503.TYPE_12:
                return {
                    mean: 8,
                    std: 0.05,
                }
            case MatTypeEN12503.TYPE_EN12572:
                return {
                    mean: 30,
                    std: 0.3,
                }
        }
        return null
    }, [sportsMatType, normType])

    const memoSportsMatTypeName = useMemo((): string | null => {
        if (sportsMatType === null) {
            return null
        }
        let name = `Type ${sportsMatType}`
        if (sportsMatType === MatTypeEN12503.TYPE_EN12572) {
            name = `Type "EN12572"`
        }
        return name
    }, [sportsMatType])

    const memoSportsMatTargetFFH = useMemo((): MeanWithStd | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        if (sportsMatType === MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) {
            return null
        }
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
                return {
                    mean: 150,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_2:
                return {
                    mean: 300,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_3:
                return {
                    mean: 400,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
                return {
                    mean: 800,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
                return {
                    mean: 1200,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_11:
            case MatTypeEN12503.TYPE_12:
                return null
            case MatTypeEN12503.TYPE_EN12572:
                return {
                    mean: 220,
                    std: 0.3,
                }
        }
    }, [sportsMatType, normType])

    const memoSportsMatTargetNbZones = useMemo((): number | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        if (sportsMatType === MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) {
            return null
        }
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
            case MatTypeEN12503.TYPE_2:
            case MatTypeEN12503.TYPE_3:
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
            case MatTypeEN12503.TYPE_11:
            case MatTypeEN12503.TYPE_12:
                return 8
            case MatTypeEN12503.TYPE_EN12572:
                return 5
        }
    }, [sportsMatType, normType])

    // DELETE EQUIPMENT REQUEST
    const onDelete = useCallback(async () => {
        if (equipment === null) {
            return
        }
        deleteEquipmentSoft(equipment)
    }, [equipment])
    // DELETE EQUIPMENT RESPONSE
    useEffect(() => {
        if (deleteEquipmentAck === null) {
            return
        }
        antdMessage.info(`Equipment deleted!`)
        if (siteUUID !== null) {
            setTimeout(() => {
                history.push(`/sites/${siteUUID}`)
            }, 1000)
        } else {
            console.warn(
                `EquipmentPage: onDelete: received delteEquipmentAck but siteUUID is null`,
            )
        }
    }, [siteUUID, deleteEquipmentAck])

    const __equipmentMutableFields = [
        equipment,
        memoEquipmentUUID,
        equipmentName,
        equipmentRef,
        equipmentManufacturer,
        equipmentInstallationDate,
        maxFreeFallHeight,
        floorRef,
        floorManufacturer,
        floorDate,
        floorKind,
        floorSubstrat,
        floorCondition,
        geolocation,
        specimenData,
        pictures,
        sportsMatType,
        sportsMatDimensionLength,
        sportsMatDimensionWidth,
        sportsMatDimensionThicknessSide1,
        sportsMatDimensionThicknessSide2,
        sportsMatDimensionThicknessSide3,
        sportsMatDimensionThicknessSide4,
        sportsMatMetalFrameIsInPlace,
    ]

    const memoEquipmentIsMutated = useMemo(() => {
        if (equipment === null) {
            return false
        }
        let prevEquipmentDateInt = equipment.equipment_installation_date
        let currEquipmentDateInt: number = 0
        if (equipmentInstallationDate !== null) {
            currEquipmentDateInt = equipmentInstallationDate.toDate().getTime()
        }
        let prevFloorDateInt = equipment.floor_installation_date
        let currFloorDateInt: number = 0
        if (floorDate !== null) {
            currFloorDateInt = floorDate.toDate().getTime()
        }

        let prevPicturesStr = equipment.pictures
            .map((p) => pbUUIDToUuid(p))
            .join(",")
        let currPicturesStr = pictures.map((p) => pbUUIDToUuid(p)).join(",")

        let isMutated =
            equipment.equipment_name !== (equipmentName ?? "") ||
            equipment.equipment_reference !== equipmentRef ||
            equipment.equipment_manufacturer !== equipmentManufacturer ||
            prevEquipmentDateInt !== currEquipmentDateInt ||
            equipment.equipment_ffh_max.toFixed(2) !== maxFreeFallHeight ||
            equipment.floor_reference !== floorRef ||
            equipment.floor_manufacturer !== floorManufacturer ||
            prevFloorDateInt !== currFloorDateInt ||
            equipment.floor_kind !== floorKind ||
            equipment.floor_substrat !== floorSubstrat ||
            equipment.floor_condition !== floorCondition ||
            prevPicturesStr !== currPicturesStr ||
            equipment.sports_mat_type !==
                (sportsMatType ?? MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) ||
            equipment.sports_mat_length !== sportsMatDimensionLength ||
            equipment.sports_mat_width !== sportsMatDimensionWidth ||
            equipment.sports_mat_thickness_side_one !==
                sportsMatDimensionThicknessSide1 ||
            equipment.sports_mat_thickness_side_two !==
                sportsMatDimensionThicknessSide2 ||
            equipment.sports_mat_thickness_side_three !==
                sportsMatDimensionThicknessSide3 ||
            equipment.sports_mat_thickness_side_four !==
                sportsMatDimensionThicknessSide4 ||
            equipment.sports_mat_metal_frame_is_present !==
                sportsMatMetalFrameIsInPlace
        if (isMutated) {
            console.log(`Equipment: isMutated: ${isMutated}
                equipment: ${equipment.equipment_name} vs ${equipmentName}
                ref: ${equipment.equipment_reference}  vs ${equipmentRef}
                manufacturer: ${equipment.equipment_manufacturer} vs ${equipmentManufacturer}
                installed_at: ${prevEquipmentDateInt} vs ${currEquipmentDateInt}
                ffh: ${equipment.equipment_ffh_max} vs ${maxFreeFallHeight}
                floor_ref: ${equipment.floor_reference} vs ${floorRef}
                floor_manufacturer: ${equipment.floor_manufacturer} vs ${floorManufacturer}
                floor_installed_at: ${prevFloorDateInt} vs ${currFloorDateInt}
                floor_kind: ${equipment.floor_kind} vs ${floorKind}
                floor_substrat: ${equipment.floor_substrat} vs ${floorSubstrat}
                floor_condition: ${equipment.floor_condition} vs ${floorCondition}
                picture: ${prevPicturesStr} vs ${currPicturesStr}
                sports_mat_type: ${equipment.sports_mat_type} vs ${sportsMatType}
                sports_mat_length: ${equipment.sports_mat_length} vs ${sportsMatDimensionLength}
                sports_mat_width: ${equipment.sports_mat_width} vs ${sportsMatDimensionWidth}
                sports_mat_thickness_side_one: ${equipment.sports_mat_thickness_side_one} vs ${sportsMatDimensionThicknessSide1}
                sports_mat_thickness_side_two: ${equipment.sports_mat_thickness_side_two} vs ${sportsMatDimensionThicknessSide2}
                sports_mat_thickness_side_three: ${equipment.sports_mat_thickness_side_three} vs ${sportsMatDimensionThicknessSide3}
                sports_mat_thickness_side_four: ${equipment.sports_mat_thickness_side_four} vs ${sportsMatDimensionThicknessSide4}
                sports_mat_metal_frame_is_present: ${equipment.sports_mat_metal_frame_is_present} vs ${sportsMatMetalFrameIsInPlace}
                `)
        }
        return isMutated
    }, __equipmentMutableFields)

    // UPDATE EQUIPMENT REQUEST
    const onUpdate = useCallback(async () => {
        if (memoEquipmentUUID === null) {
            return
        }
        if (equipment === null) {
            return
        }
        let equipmentInstallationDateInt: number | null = null
        if (equipmentInstallationDate !== null) {
            equipmentInstallationDateInt = equipmentInstallationDate
                .toDate()
                .getTime()
        }
        let floorInstalledAt: number | null = null
        if (floorDate !== null) {
            floorInstalledAt = floorDate.toDate().getTime()
        }
        let updatedEquipment = equipment.clone()
        if (equipmentName !== null) {
            updatedEquipment.equipment_name = equipmentName
        }
        if (equipmentRef !== null) {
            updatedEquipment.equipment_reference = equipmentRef
        }
        if (equipmentManufacturer !== null) {
            updatedEquipment.equipment_manufacturer = equipmentManufacturer
        }
        if (equipmentInstallationDateInt !== null) {
            updatedEquipment.equipment_installation_date =
                equipmentInstallationDateInt
        }
        if (maxFreeFallHeight !== null) {
            updatedEquipment.equipment_ffh_max = parseFloat(maxFreeFallHeight)
        }
        if (floorRef !== null) {
            updatedEquipment.floor_reference = floorRef
        }
        if (floorManufacturer !== null) {
            updatedEquipment.floor_manufacturer = floorManufacturer
        }
        if (floorInstalledAt !== null) {
            updatedEquipment.floor_installation_date = floorInstalledAt
        }
        if (floorKind !== null) {
            updatedEquipment.floor_kind = floorKind
        }
        if (floorSubstrat !== null) {
            updatedEquipment.floor_substrat = floorSubstrat
        }
        if (floorCondition !== null) {
            updatedEquipment.floor_condition = floorCondition
        }
        updatedEquipment.pictures = pictures
        updatedEquipment.sports_mat_type =
            sportsMatType ?? MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503
        if (sportsMatDimensionLength !== null) {
            updatedEquipment.sports_mat_length = sportsMatDimensionLength
        }
        if (sportsMatDimensionWidth !== null) {
            updatedEquipment.sports_mat_width = sportsMatDimensionWidth
        }
        if (sportsMatDimensionThicknessSide1 !== null) {
            updatedEquipment.sports_mat_thickness_side_one =
                sportsMatDimensionThicknessSide1
        }
        if (sportsMatDimensionThicknessSide2 !== null) {
            updatedEquipment.sports_mat_thickness_side_two =
                sportsMatDimensionThicknessSide2
        }
        if (sportsMatDimensionThicknessSide3 !== null) {
            updatedEquipment.sports_mat_thickness_side_three =
                sportsMatDimensionThicknessSide3
        }
        if (sportsMatDimensionThicknessSide4 !== null) {
            updatedEquipment.sports_mat_thickness_side_four =
                sportsMatDimensionThicknessSide4
        }
        if (sportsMatMetalFrameIsInPlace !== null) {
            updatedEquipment.sports_mat_metal_frame_is_present =
                sportsMatMetalFrameIsInPlace
        }
        updateEquipment(updatedEquipment)
    }, __equipmentMutableFields)
    // UPDATE EQUIPMENT RESPONSE
    useEffect(() => {
        if (memoEquipmentUUID === null || updatedEquipmentAck === null) {
            return
        }
        antdMessage.success(
            <>
                Equipment <b>{equipmentName}</b> was successfully updated!
            </>,
        )
        getEquipment(uuidToPbUUID(memoEquipmentUUID))
    }, [updatedEquipmentAck, memoEquipmentUUID])

    useEffect(() => {
        if (memoEquipmentIsMutated === false) {
            return
        }
        const t = setTimeout(() => {
            onUpdate()
        }, ENTITY_SAVE_DEBOUNCE_DELAY_MS)
        return () => {
            clearTimeout(t)
        }
    }, [memoEquipmentIsMutated, ...__equipmentMutableFields])

    const memoSportsMatInformationElement = useMemo(() => {
        if (sportsMatType === null) {
            return null
        }
        return (
            <Row gutter={[10, 10]} justify="space-between">
                <Col xs={18}>
                    <FlexCol>
                        <Alert
                            message={
                                <>
                                    Mat of <b>{memoSportsMatTypeName}</b> is
                                    required to be tested on{" "}
                                    <b>{memoSportsMatTargetNbZones}</b> zones:
                                    (see layout)
                                </>
                            }
                            showIcon
                            type="info"
                        />
                        <Alert
                            message={
                                <>
                                    Mat <b>{memoSportsMatTypeName}</b> is
                                    required to be tested with an impactor of{" "}
                                    {memoSportsMatTargetImpactorDiameter !==
                                    null ? (
                                        <>
                                            diameter&nbsp;
                                            <b>
                                                {memoSportsMatTargetImpactorDiameter.mean.toFixed(
                                                    0,
                                                )}
                                                &nbsp;± &nbsp;
                                                {memoSportsMatTargetImpactorDiameter.std.toFixed(
                                                    0,
                                                )}
                                            </b>
                                            &nbsp; mm
                                        </>
                                    ) : (
                                        <>unknown diameter</>
                                    )}
                                    {memoSportsMatTargetImpactorMass !==
                                    null ? (
                                        <>
                                            {" "}
                                            and mass of&nbsp;
                                            <b>
                                                {memoSportsMatTargetImpactorMass?.mean.toFixed(
                                                    0,
                                                )}
                                                &nbsp;±&nbsp;
                                                {memoSportsMatTargetImpactorMass?.std.toFixed(
                                                    1,
                                                )}
                                            </b>
                                            &nbsp; kg
                                        </>
                                    ) : (
                                        <>and unknown mass</>
                                    )}
                                </>
                            }
                            showIcon
                            type="info"
                        />
                    </FlexCol>
                </Col>
                <Col xs={6}>
                    <FlexRow style={{ justifyContent: "center" }}>
                        {memoSportsMatLayoutElement}
                    </FlexRow>
                </Col>
            </Row>
        )
    }, [sportsMatType, memoSportsMatTypeName, memoSportsMatLayoutElement])

    if (equipment === null) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <ErrorAlert error={error} />
                <SimplifiedBreadcrumb
                    previousItems={[
                        { href: `/sites/${siteUUID}`, label: siteName },
                    ]}
                    currentItem={{ label: equipmentName }}
                />
                <Row
                    justify="space-between"
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Col>
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Equipment to test" />
                            </Label>
                            <Typography.Text
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                {equipmentName}
                            </Typography.Text>
                        </FlexCol>
                    </Col>
                    <Col>
                        <FlexCol style={{ gap: 3 }}>
                            {memoNormTypeElement}
                            {memoMethodElement}
                            {memoIsLabTestElement}
                        </FlexCol>
                    </Col>
                </Row>
                {/* Equipment name */}
                <Row gutter={[10, 10]}>
                    <Col xs={"auto"}>
                        <LabeledInput
                            label={translated("Equipment")}
                            value={equipmentName}
                            setValue={setEquipmentName}
                        />
                    </Col>
                    {normType === NormType.EN_12503 && (
                        <Col flex={"auto"}>
                            <Label>
                                <Translated keyEn="Type" />
                            </Label>
                            <Select
                                size="large"
                                value={sportsMatType}
                                onChange={(value) => {
                                    setSportsMatType(value)
                                }}
                                options={[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                ].map((t) => ({
                                    label: `Type "${t}": ${translated(
                                        sportsMatTypeDescriptionStr(t),
                                    )}`,
                                    value: t,
                                }))}
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Col>
                    )}
                    <Col xs={24}>
                        {normType === NormType.EN_12503 && (
                            <RequirementsAlertSM smType={sportsMatType} />
                        )}
                    </Col>
                    <Col xs={24}>
                        {normType === NormType.EN_12503 &&
                            memoSportsMatInformationElement}
                    </Col>
                </Row>
                {/* New Zone */}
                <FlexCol>
                    <Label>
                        <Translated keyEn="New zone" />
                    </Label>
                    <Space.Compact
                        style={{
                            width: "100%",
                        }}
                    >
                        <Input
                            size="large"
                            value={newZoneName ?? ""}
                            onChange={(e) => {
                                setNewZoneName(e.target.value)
                            }}
                            placeholder={translated("New zone") ?? ""}
                        />
                        <NewZoneButton
                            equipmentUUID={equipment.uuid}
                            zoneName={newZoneName}
                            zoneFFH={
                                memoSportsMatTargetFFH
                                    ? memoSportsMatTargetFFH.mean
                                    : null
                            }
                        />
                    </Space.Compact>
                    {normType === NormType.EN_12503 &&
                        memoSportsMatTargetFFH !== null && (
                            <div>
                                <InfoCircleTwoTone />
                                <i>
                                    <Translated keyEn="Newly created zone will have a predefined target FFH of" />{" "}
                                    <b>
                                        {memoSportsMatTargetFFH.mean.toFixed(0)}
                                        &nbsp;±&nbsp;
                                        {memoSportsMatTargetFFH.std.toFixed(1)}
                                    </b>
                                    &nbsp;cm (<b>{memoSportsMatTypeName}</b>)
                                </i>{" "}
                            </div>
                        )}
                </FlexCol>
                {/* Equipment zones */}
                <FlexCol>
                    <Label>
                        <Translated keyEn="Tested zones" />
                    </Label>
                    {memoZonesTable}
                </FlexCol>
                {/* Equipment picture */}
                <Label>
                    <Translated keyEn="Picture" />
                </Label>
                <UploadImageListV2
                    pictures={pictures}
                    setPictures={setPictures}
                />
                {/* Sports mat data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Sports mat" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                        }}
                    >
                        {/* 2 dimensions */}
                        <Col xs={24} md={12}>
                            <LabeledInputInCM
                                label="Length"
                                value={sportsMatDimensionLength}
                                setValue={setSportsMatDimensionLength}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInputInCM
                                label="Width"
                                value={sportsMatDimensionWidth}
                                setValue={setSportsMatDimensionWidth}
                            />
                        </Col>
                        {/* 4 thicknesses */}
                        <Col xs={12} md={4}>
                            <LabeledInputInMM
                                label="Thickness 1"
                                value={sportsMatDimensionThicknessSide1}
                                setValue={setSportsMatDimensionThicknessSide1}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <LabeledInputInMM
                                label="Thickness 2"
                                value={sportsMatDimensionThicknessSide2}
                                setValue={setSportsMatDimensionThicknessSide2}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <LabeledInputInMM
                                label="Thickness 3"
                                value={sportsMatDimensionThicknessSide3}
                                setValue={setSportsMatDimensionThicknessSide3}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <LabeledInputInMM
                                label="Thickness 4"
                                value={sportsMatDimensionThicknessSide4}
                                setValue={setSportsMatDimensionThicknessSide4}
                            />
                        </Col>
                        {/* Mean thickness */}

                        <Col xs={12} md={8}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Mean thickness" />
                                </Label>
                                <Input
                                    size="large"
                                    value={
                                        memoSportsMatThicknessMeanAndStd
                                            ? `${memoSportsMatThicknessMeanAndStd.mean.toFixed(0)} ± ${memoSportsMatThicknessMeanAndStd.std.toFixed(0)}`
                                            : ""
                                    }
                                    disabled
                                    addonAfter="mm"
                                />
                            </FlexCol>
                        </Col>
                        <Col>
                            <Label>
                                <Translated keyEn="Metal frame in place" />?
                            </Label>
                            <Switch
                                checked={sportsMatMetalFrameIsInPlace === true}
                                onChange={(value) => {
                                    setSportsMatMetalFrameIsInPlace(value)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                {/* Equipment data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Equipment" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Reference"
                                value={equipmentRef}
                                setValue={setEquipmentRef}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Manufacturer"
                                value={equipmentManufacturer}
                                setValue={setEquipmentManufacturer}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Installation date"} />
                                </Label>
                                <DatePicker
                                    size="large"
                                    value={equipmentInstallationDate}
                                    onChange={(value) => {
                                        setEquipmentDate(value)
                                    }}
                                    variant="filled"
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Free Fall Height"} />
                                </Label>
                                <Input
                                    size="large"
                                    value={maxFreeFallHeight ?? ""}
                                    onChange={(value) => {
                                        setMaxFreeFallHeight(value.target.value)
                                    }}
                                    variant="filled"
                                    addonAfter="m"
                                />
                            </FlexCol>
                        </Col>
                    </Row>
                </div>
                {/* Floor data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Floor" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Reference"
                                value={floorRef}
                                setValue={setFloorRef}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Manufacturer"
                                value={floorManufacturer}
                                setValue={setFloorManufacturer}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Kind"
                                value={floorKind}
                                setValue={setFloorKind}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Substrat"
                                value={floorSubstrat}
                                setValue={setFloorSubstrat}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Installation date"} />
                                </Label>
                                <DatePicker
                                    size="large"
                                    value={floorDate}
                                    onChange={(value) => {
                                        setFloorDate(value)
                                    }}
                                    variant="filled"
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label="Condition"
                                value={floorCondition}
                                setValue={setFloorCondition}
                            />
                        </Col>
                    </Row>
                </div>
                {/* Specimen data */}
                {/* <div></div> */}
                {/* Delete & Save */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        alignSelf: "flex-end",
                    }}
                >
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this site" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "0.8rem",
                                }}
                            >
                                <Translated keyEn="Delete" />
                            </span>
                        </Button>
                    </Popconfirm>
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="large"
                            block
                            disabled={!memoEquipmentIsMutated}
                            onClick={onUpdate}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                <Translated keyEn="Save" />
                            </span>
                        </Button>
                    </div>
                </FlexRow>
            </FlexCol>
            <DataTreeDrawer site={site} selectedKey={memoEquipmentUUID} />
        </>
    )
}
