import { ECloudRole, ICloudUser } from "../types"
import { loadLocalStorageUser, saveLocalStorageUser } from "./localStorage"

export const setLocalStorageCloudApiUser = (user: ICloudUser | null) => {
    if (user === null) {
        saveLocalStorageUser(null)
        return
    }
    return saveLocalStorageUser(JSON.stringify(user))
}

export const getLocalStorageCloudApiUser = (): ICloudUser | null => {
    let user = loadLocalStorageUser()
    if (user !== null) {
        return JSON.parse(user) as ICloudUser
    }
    return null
}

export const getCloudApiUserIsAdmin = (): boolean => {
    let user = getLocalStorageCloudApiUser()
    if (user === null) {
        return false
    }
    for (let role of user.Roles) {
        if (role.Name === ECloudRole.Admin) {
            return true
        }
    }
    return false
}

export const callCloudApiV2 = async <T>(
    path: string,
    requestInit?: RequestInit,
): Promise<{
    resp: Response
    entity: T | null
}> => {
    let url = "/api" + path
    let resp = await fetch(url, {
        ...requestInit,
    })
    let entity: T | null = null
    if (requestInit !== undefined && requestInit.method === "HEAD") {
        return { resp, entity }
    }
    try {
        entity = await resp.json()
    } catch (e: any) {
        console.error(`callCloudApiV2 (${path}) JSON decoding error: `, e)
    }
    return { resp, entity }
}

export const callCloudApiV2HTML = async (
    path: string,
    requestInit?: RequestInit,
): Promise<{
    resp: Response
    htmlDoc: Document | null
}> => {
    let url = "/api" + path
    let resp = await fetch(url, {
        ...requestInit,
    })
    let respText = await resp.text()
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(respText, "text/html")
    return { resp, htmlDoc }
}

export const callCloudApiV2Buffer = async (
    path: string,
    requestInit?: RequestInit,
): Promise<{
    resp: Response
    data: ArrayBuffer | null
}> => {
    let url = "/api" + path
    let resp = await fetch(url, {
        ...requestInit,
    })
    let data: ArrayBuffer | null = null
    try {
        data = await resp.arrayBuffer()
    } catch (e: any) {
        console.error(`callCloudApiV2Buffer (${path}) error: `, e)
    }
    return { resp, data }
}
