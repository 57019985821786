import { FC, useEffect, useMemo, useState } from "react"
import { Translated } from "../../utils/translated"
import { Tabs, TabsProps } from "antd"
import {
    DeploymentUnitOutlined,
    ToolOutlined,
    WifiOutlined,
} from "@ant-design/icons"
import { FlexCol } from "../../components/commons-ts/common"
import { StationConfigSettings } from "./StationConfigSettings"
import { WifiSettings } from "./WifiSettings"
import { useHistory, useLocation } from "react-router-dom"
import { HeadHICConfigSettings } from "./HeadHICConfigSettings"
import { getCloudApiUserIsAdmin } from "../../utils/cloudApiV2"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimeline } from "@fortawesome/free-solid-svg-icons"
import { DeviceHistorySettings } from "./DeviceHistorySettings"

enum ETabKey {
    DeviceHistory = "history",
    StationConfig = "station",
    HeadHICConfig = "headhic",
    WifiConfig = "wifi",
}

export const SettingsDevice: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const [selectedTabKey, setSelectedTabKey] = useState<ETabKey>(
        ETabKey.StationConfig,
    )

    useEffect(() => {
        let hash = location.hash
        if (!hash) {
            return
        }
        hash = hash.replace("#", "")
        switch (hash) {
            case ETabKey.StationConfig:
                setSelectedTabKey(ETabKey.StationConfig)
                break
            case ETabKey.HeadHICConfig:
                setSelectedTabKey(ETabKey.HeadHICConfig)
                break
            case ETabKey.WifiConfig:
                setSelectedTabKey(ETabKey.WifiConfig)
                break
            case ETabKey.DeviceHistory:
                setSelectedTabKey(ETabKey.DeviceHistory)
                break
        }
    }, [])

    const memoIsAdmin = useMemo(() => {
        return getCloudApiUserIsAdmin()
    }, [])

    const memoTabItems = useMemo(() => {
        let items: TabsProps["items"] = [
            {
                key: ETabKey.DeviceHistory,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Device History" />
                    </span>
                ),
                icon: <FontAwesomeIcon icon={faTimeline} />,
                children: <DeviceHistorySettings />,
            },
            {
                key: ETabKey.StationConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Station" />
                    </span>
                ),
                icon: <ToolOutlined />,
                children: <StationConfigSettings />,
            },
            {
                key: ETabKey.HeadHICConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Head HIC" />
                    </span>
                ),
                icon: <DeploymentUnitOutlined />,
                children: <HeadHICConfigSettings />,
            },
            {
                key: ETabKey.WifiConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Wifi" />
                    </span>
                ),
                icon: <WifiOutlined />,
                children: <WifiSettings />,
            },
        ]
        return items
    }, [memoIsAdmin])

    return (
        <FlexCol
            style={{
                width: "80vw",
                maxWidth: "55rem", // MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
                alignItems: "center",
            }}
        >
            <Tabs
                style={{
                    width: "100%",
                }}
                destroyInactiveTabPane
                // centered
                animated
                tabBarGutter={20}
                activeKey={selectedTabKey}
                onChange={(key: string) => {
                    setSelectedTabKey(key as ETabKey)
                    // set the hash
                    history.push(`#${key}`)
                }}
                size="large"
                items={memoTabItems}
            ></Tabs>
        </FlexCol>
    )
}
