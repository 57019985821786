import {
    CSSProperties,
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"

import { useHistory } from "react-router-dom"
import { Alert, Button, Col, DatePicker, Input, Radio, Row, Switch } from "antd"

import dayjs from "dayjs"
import { Translated } from "../../utils/translated"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { ErrorAlert } from "../../components/commons-ts/errorAlert"
import { MethodTypeEN1177, NormType, Site } from "../../generated/proto-ts/main"
import { useUsercommCreateSiteBLE } from "../../usercomm/usercommAsyncRequestBLE"
import { getLocalStorageCloudApiUser } from "../../utils/cloudApiV2"
import { pbUUIDToUuid } from "../../utils/utils"

export const SiteInitForm: FC<{
    clonedSite: Site | null
    onToggle: (state: boolean) => void
}> = ({ clonedSite, onToggle }) => {
    const [missionNormType, setMissionNormType] = useState<NormType>(
        NormType.EN_1177,
    )
    const [missionMethod, setMissionMethod] = useState<MethodTypeEN1177 | null>(
        MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
    )
    const [missionName, setMissionName] = useState<string>("")
    const [clientName, setClientName] = useState<string>("")
    const [siteName, setSiteName] = useState<string>("")
    const [isLabTest, setIsLabTest] = useState<boolean | null>(false)
    const [executionDate, setExecutionDate] = useState<dayjs.Dayjs | null>(null)

    const [userUUID, setUserUUID] = useState<string | null>(null)

    const [createdSiteUUID, createSite] = useUsercommCreateSiteBLE()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const history = useHistory()

    useEffect(() => {
        let user = getLocalStorageCloudApiUser()
        if (user !== null && user.UUID !== null) {
            setUserUUID(user.UUID)
        }
    }, [])

    const onSubmit = useCallback(async () => {
        if (userUUID === null) {
            setError("User UUID is required")
            return
        }
        if (missionName === "") {
            setError("Mission name is required")
            return
        }
        if (clientName === "") {
            setError("Client name is required")
            return
        }
        if (siteName === "") {
            setError("Site name is required")
            return
        }

        setIsLoading(true)
        setError(null)

        let method_type = missionMethod
        let isLabTest: boolean | null = null
        if (missionNormType === NormType.EN_12503) {
            method_type = null
            isLabTest = null
        }
        let newSite = new Site({
            norm_type: missionNormType,
            method_type_en_1177: method_type ?? undefined,
            is_lab_test: isLabTest ?? undefined,
            mission_name: missionName,
            client_name: clientName,
            site_name: siteName,
        })
        if (executionDate !== null) {
            newSite.execution_date = executionDate?.toDate().getTime()
        }
        createSite(userUUID, newSite)
    }, [
        missionNormType,
        missionMethod,
        missionName,
        clientName,
        siteName,
        isLabTest,
        executionDate,
        userUUID,
    ])
    useEffect(() => {
        if (createdSiteUUID === null) {
            return
        }
        setIsLoading(false)
        history.push(`/sites/${pbUUIDToUuid(createdSiteUUID)}`)
    }, [createdSiteUUID])

    useEffect(() => {
        if (clonedSite === null) {
            return
        }
        setMissionNormType(clonedSite.norm_type)
        setMissionMethod(clonedSite.method_type_en_1177)
        if (clonedSite.execution_date) {
            setExecutionDate(dayjs(clonedSite.execution_date))
        }
        setIsLabTest(clonedSite.is_lab_test)
        setMissionName(clonedSite.mission_name)
        setClientName(clonedSite.client_name)
        setSiteName(clonedSite.site_name)
    }, [clonedSite])

    const memoMissionNormTypeExplicationElement = useMemo(() => {
        let explication: ReactNode = null
        if (missionNormType === NormType.EN_1177) {
            explication = (
                <Alert
                    message={
                        <>
                            <Translated keyEn="European norm" /> <b>EN 1177</b>:
                            <Translated keyEn="control of HIC values of playground floor elements" />
                        </>
                    }
                    description={
                        <>
                            <Translated keyEn="The test is either performed in a laboratory or on site: please indicate" />
                            <br />
                            <Translated keyEn="Lugsoft supports 2 methods for calculating conformity result:" />
                            <br />
                            <ul>
                                <li>
                                    <Translated keyEn="1: CFH (Critical Fall Height)" />
                                </li>
                                <li>
                                    <Translated keyEn="2: Adequacy" />
                                </li>
                            </ul>
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
        } else if (missionNormType === NormType.EN_12503) {
            explication = (
                <Alert
                    message={
                        <>
                            <Translated keyEn="European norm:" />{" "}
                            <b>EN 12503</b>
                            :
                            <Translated keyEn="control of shock absorption of sports mats" />
                        </>
                    }
                    description={
                        <>
                            <Translated keyEn="The test is performed in a laboratory on a chosen type of sports mat" />
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
        }
        return explication
    }, [missionNormType])

    return (
        <FlexCol
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
                minHeight: "70vh",
            }}
        >
            <Row gutter={[10, 10]} justify={"space-between"}>
                <Col xs={24}>
                    <ErrorAlert error={error} />
                </Col>
                <Col xs={24}>
                    <Radio.Group
                        value={missionNormType}
                        buttonStyle="solid"
                        size="large"
                        style={{
                            width: "100%",
                        }}
                        onChange={(e) => setMissionNormType(e.target.value)}
                    >
                        <Radio.Button
                            value={NormType.EN_1177}
                            style={{
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            <Translated keyEn="Playground floor" />
                        </Radio.Button>
                        <Radio.Button
                            value={NormType.EN_12503}
                            style={{
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            <Translated keyEn="Sports Mats" />
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col xs={24}>{memoMissionNormTypeExplicationElement}</Col>
                {missionNormType === NormType.EN_1177 ? (
                    <>
                        <Col>
                            <FlexCol>
                                <Translated keyEn="Method" />
                                <Radio.Group
                                    buttonStyle="solid"
                                    size="large"
                                    value={missionMethod}
                                    onChange={(e) =>
                                        setMissionMethod(e.target.value)
                                    }
                                >
                                    <Radio.Button
                                        value={
                                            MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
                                        }
                                    >
                                        1: <Translated keyEn="CFH" />
                                    </Radio.Button>
                                    <Radio.Button
                                        value={
                                            MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE
                                        }
                                    >
                                        2: <Translated keyEn="Adequacy" />
                                    </Radio.Button>
                                </Radio.Group>
                            </FlexCol>
                        </Col>
                        <Col>
                            <FlexCol>
                                <Translated keyEn="LabTest" />
                                <Switch
                                    checked={isLabTest === true}
                                    onChange={setIsLabTest}
                                />
                            </FlexCol>
                        </Col>
                    </>
                ) : (
                    <Col />
                )}
                <Col>
                    <FlexCol>
                        Execution Date
                        <DatePicker
                            picker="date"
                            variant="filled"
                            size="large"
                            value={executionDate}
                            onChange={(date) => setExecutionDate(date)}
                        />
                    </FlexCol>
                </Col>
                <Col xs={24}>
                    <FlexCol>
                        <Translated keyEn="Mission" />
                        <Input
                            value={missionName}
                            onChange={(e) => setMissionName(e.target.value)}
                            size="large"
                            placeholder="Mission name"
                            status={
                                error !== null && missionName === ""
                                    ? "error"
                                    : undefined
                            }
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={24}>
                    <FlexCol>
                        <Translated keyEn="Client" />
                        <Input
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            size="large"
                            placeholder="Client name"
                            status={
                                error !== null && clientName === ""
                                    ? "error"
                                    : undefined
                            }
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={24}>
                    <FlexCol>
                        <Translated keyEn="Site" />
                        <Input
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}
                            size="large"
                            placeholder="Site name"
                            status={
                                error !== null && siteName === ""
                                    ? "error"
                                    : undefined
                            }
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
            </Row>
            <FlexRow
                style={{
                    alignItems: "center",
                    justifyContent: "end",
                }}
            >
                <Button
                    onClick={() => {
                        onToggle(false)
                    }}
                    type="link"
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={onSubmit}
                    loading={isLoading}
                    disabled={isLoading}
                    size="large"
                    style={{
                        minWidth: 200,
                    }}
                >
                    Create
                </Button>
            </FlexRow>
        </FlexCol>
    )
}
