import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { callHostServerAPI } from "../../utils/hostServerAPI"

import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Tag,
    Typography,
    message as antdMessage,
} from "antd"
import {
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../../components/commons-ts/common"
import { Translated, translated } from "../../utils/translated"
import { MAX_WIDTH_CENTRAL_CONTAINER } from "../../utils/utils"
import { Label, LabeledInput } from "../../components/commons-ts/input"
import { BaseOptionType, OptionProps } from "antd/es/select"
import { LockOutlined, ReloadOutlined, UnlockOutlined } from "@ant-design/icons"
import Container from "../../components/commons-ts/container"
import { useLocation } from "react-router-dom"
import { useUsercommLegacyHostApiBLE } from "../../usercomm/usercommAsyncRequestBLE"

interface WPANetwork {
    bssid: string // "5c:7b:5c:79:33:24"
    frequency: number // 2462
    signal_level: number // -55
    flags: string // "[WPA-PSK-CCMP][WPA2-PSK-CCMP][WPS][ESS]"
    ssid: string // "SFR_3322"
}

enum EWPAState {
    COMPLETED = "COMPLETED",
    ASSOCIATING = "ASSOCIATING",
    SCANNING = "SCANNING",
    INACTIVE = "INACTIVE",
    DISCONNECTED = "DISCONNECTED",
    INTERFACE_DISABLED = "INTERFACE_DISABLED",
}

interface WPAStatus {
    bssid: string // "58:fc:20:53:14:91"
    freq: string // "5640"
    ssid: string // "sfropolino"
    id: number // 0
    mode: string // "station"
    wifi_generation: string // "5"
    pairwise_cipher: string // "CCMP"
    group_cipher: string // "CCMP"
    key_mgmt: string // "WPA2-PSK"
    wpa_state: EWPAState // "COMPLETED"
    ip_address: string // "192.168.1.199"
    p2p_device_address: string // "de:a6:32:20:33:e8"
    address: string // "dc:a6:32:20:33:e8"
    uuid: string // "3e97b725-b166-5780-900a-04741216c9ac"
    ieee80211ac: string // "1"
}

type WPANetworkMap = Record<string, WPANetwork[]>

export const WifiSettings: FC = () => {
    const [networksMap, setNetworksMap] = useState<WPANetworkMap | null>(null)
    const [selectedSsid, setSelectedSsid] = useState<string | null>(null)

    const [password, setPassword] = useState<string | null>(null)

    const [isScanning, setIsScanning] = useState<boolean>(false)
    const [isConnecting, setIsConnecting] = useState<boolean>(false)
    const [connectionStatus, setConnectionStatus] = useState<WPAStatus | null>(
        null,
    )

    const [scanResponse, requestScan] = useUsercommLegacyHostApiBLE()
    const [statusResponse, requestStatus] = useUsercommLegacyHostApiBLE()
    const [connectResponse, requestConnect] = useUsercommLegacyHostApiBLE()

    const location = useLocation()

    const scanForNetworks = useCallback(() => {
        setIsScanning(true)
        requestScan("/wpa/scan")
    }, [])
    useEffect(() => {
        if (scanResponse === null) {
            return
        }
        try {
            let respText = new TextDecoder().decode(scanResponse.body)
            let respJSON: WPANetworkMap = JSON.parse(respText)
            setNetworksMap(respJSON)
        } finally {
            setIsScanning(false)
        }
    }, [scanResponse])

    const loadWPAStatus = useCallback(async () => {
        requestStatus("/wpa/status")
        // let _status = await callHostServerAPI<WPAStatus>("/wpa/status")
        // if (_status && _status.wpa_state === EWPAState.COMPLETED) {
        //     setSelectedSsid(_status.ssid)
        // } else {
        //     setSelectedSsid(null)
        // }
        // return _status
    }, [])
    useEffect(() => {
        if (statusResponse === null) {
            return
        }
        try {
            let respText = new TextDecoder().decode(statusResponse.body)
            let respJSON: WPAStatus = JSON.parse(respText)
            if (respJSON.wpa_state === EWPAState.COMPLETED) {
                setSelectedSsid(respJSON.ssid)
            } else {
                setSelectedSsid(null)
            }
            setConnectionStatus(respJSON)
        } finally {
        }
    }, [statusResponse])

    const connectToNetwork = useCallback(() => {
        if (selectedSsid === null) {
            antdMessage.error("Please select a network first")
            return
        }
        if (networksMap === null) {
            antdMessage.error("Please scan for networks first")
            return
        }
        let networks = networksMap[selectedSsid]
        let network = networks[0]
        if (network === undefined) {
            antdMessage.error("Please scan for networks first")
            return
        }
        let networkIsOpen = !network.flags.includes("WPA")

        let queryParams = new URLSearchParams()
        queryParams.set("id", "0")
        queryParams.set("ssid", selectedSsid)
        if (!networkIsOpen) {
            if (password === null) {
                antdMessage.error("Please enter a password first")
                return
            }
            queryParams.set("psk", password)
        }
        let connectUri = `/wpa/connect?${queryParams.toString()}`
        console.log(`WifiSettings: connectUri: ${connectUri}`)
        setIsConnecting(true)
        // callHostServerAPI(connectUri)
        //     .then(() => {
        //         // We only pass by ASSOCIATING status phase when connecting to a WPA network
        //         let reassociationInitialized = networkIsOpen
        //         const t0 = Date.now()
        //         const t = setInterval(async () => {
        //             if (Date.now() - t0 > 10000) {
        //                 setIsConnecting(false)
        //                 clearInterval(t)
        //                 antdMessage.error(
        //                     translated(
        //                         "Failed to connect to network. Please check SSID/Password and try again..",
        //                     ),
        //                 )
        //                 return
        //             }
        //             let _status = await loadWPAStatus()
        //             setConnectionStatus(_status)
        //             if (_status === null) {
        //                 return
        //             }
        //             if (_status.wpa_state === EWPAState.ASSOCIATING) {
        //                 reassociationInitialized = true
        //                 return
        //             }
        //             if (
        //                 reassociationInitialized &&
        //                 _status.wpa_state === EWPAState.COMPLETED
        //             ) {
        //                 setIsConnecting(false)
        //                 clearInterval(t)
        //                 antdMessage.success(
        //                     translated("Successfully connected to network!"),
        //                 )
        //             }
        //         }, 500)
        //     })
        //     .catch((err) => {
        //         antdMessage.error(
        //             `Error while connecting to network: ${err.message}`,
        //         )
        //     })
        requestConnect(connectUri)
    }, [selectedSsid, password, networksMap])

    useEffect(() => {
        if (connectResponse === null) {
            return
        }
        try {
            let respText = new TextDecoder().decode(connectResponse.body)
            console.log(`WifiSettings: connectResponse:`, respText)
            antdMessage.success(
                translated("Successfully connected to network!"),
            )
        } finally {
            setIsConnecting(false)
        }
    }, [connectResponse])

    useEffect(() => {
        scanForNetworks()
        loadWPAStatus()
    }, [])

    const memoNetworkOptions = useMemo(() => {
        let options: BaseOptionType[] = []
        let preOptions: {
            ssid: string
            strengthPercentage: number
            isOpen: boolean

            frequencyElement: ReactElement
            securityElement: ReactElement
            strengthElement: ReactElement
        }[] = []
        if (networksMap === null) {
            return options
        }
        for (let [ssid, networks] of Object.entries(networksMap)) {
            if (ssid.trim() === "") {
                continue
            }
            if (networks.length === 0) {
                continue
            }
            // Sort networks by frequency (5GHz first)
            networks.sort((a, b) => b.frequency - a.frequency)
            let network = networks[0]

            let frequencyElement = <Tag color="blue">2.4GHz</Tag>
            if (network.frequency > 5e3) {
                frequencyElement = <Tag color="green">5.5GHz</Tag>
            }

            let securityElement = (
                <Tag color="green">
                    <UnlockOutlined />
                </Tag>
            )
            if (network.flags.includes("WPA")) {
                securityElement = (
                    <Tag color="orange">
                        <LockOutlined />
                    </Tag>
                )
            }

            let inverseStrength = -network.signal_level
            let strengthPercentage = (100 * (inverseStrength - 30)) / (90 - 30)
            if (strengthPercentage < 0) {
                strengthPercentage = 100
            }
            let strengthColor = "green"
            if (strengthPercentage < 50) {
                strengthColor = "orange"
            }
            if (strengthPercentage < 25) {
                strengthColor = "red"
            }

            let strengthElement = (
                <Tag color={strengthColor}>
                    {strengthPercentage.toFixed(0)}%
                </Tag>
            )

            preOptions.push({
                ssid,
                strengthPercentage,
                isOpen: !network.flags.includes("WPA"),
                frequencyElement,
                securityElement,
                strengthElement,
            })
        }

        preOptions.sort((a, b) => b.strengthPercentage - a.strengthPercentage)

        for (let preOption of preOptions) {
            let { ssid, frequencyElement, securityElement, strengthElement } =
                preOption
            options.push({
                value: ssid,
                label: (
                    <FlexRow
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <span>{ssid}</span>

                        <FlexRow
                            style={{
                                gap: 3,
                            }}
                        >
                            {frequencyElement}
                            {strengthElement}
                            {securityElement}
                        </FlexRow>
                    </FlexRow>
                ),
            })
        }
        return options
    }, [networksMap])

    const memoSelectedNetworkIsOpen = useMemo((): boolean => {
        if (networksMap === null) {
            return false
        }
        if (selectedSsid === null) {
            return false
        }
        let networks = networksMap[selectedSsid]
        if (networks === undefined) {
            return false
        }
        let network = networks[0]
        if (network === undefined) {
            return false
        }
        return !network.flags.includes("WPA")
    }, [networksMap, selectedSsid])

    const memoStatusElement = useMemo(() => {
        if (connectionStatus === null) {
            return <Tag color="orange">N/A</Tag>
        }
        let { wpa_state } = connectionStatus
        let color = "orange"
        let text = "Unknown"
        switch (wpa_state) {
            case EWPAState.COMPLETED:
                color = "green"
                text = "Connected"
                break
            case EWPAState.DISCONNECTED:
                color = "red"
                text = "Disconnected"
                break
            case EWPAState.INACTIVE:
                color = "red"
                text = "Inactive"
                break
            case EWPAState.INTERFACE_DISABLED:
                color = "red"
                text = "Interface disabled"
                break
            case EWPAState.SCANNING:
                color = "red"
                text = "Scanning"
                break
            case EWPAState.ASSOCIATING:
                color = "orange"
                text = "Associating"
                break
        }
        return <Tag color={color}>{text}</Tag>
    }, [connectionStatus])

    const memoCurrentlyConectedSsid = useMemo((): string | null => {
        if (connectionStatus === null) {
            return null
        }
        return connectionStatus.ssid
    }, [connectionStatus])

    let content = (
        <FlexCol
            style={{
                width: "100%",
                // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            <FlexCol style={{ gap: 0 }}>
                {/* <Label>
                    <Translated keyEn="Connect device to The Internet" />
                </Label> */}
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="Wi-Fi" />
                </Typography.Text>
            </FlexCol>
            {/* Current STATUS */}
            <div>
                <UnderlinedSectionTitle>
                    <Translated keyEn="Current status" />
                </UnderlinedSectionTitle>
                <FlexCol style={{ gap: 0 }}>
                    <div>
                        <Translated keyEn="Status" />: {memoStatusElement}
                        <Button
                            onClick={async () => {
                                loadWPAStatus()
                            }}
                            icon={<ReloadOutlined />}
                            size="small"
                        />
                    </div>
                    <div>
                        <Translated keyEn="Currently connected to" />:{` `}
                        <span
                            style={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                            }}
                        >
                            {memoCurrentlyConectedSsid}
                        </span>
                    </div>
                </FlexCol>
            </div>
            {/* SSID & Password */}
            <div>
                <UnderlinedSectionTitle>
                    <Translated keyEn="Connect to WiFi" />
                </UnderlinedSectionTitle>
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        <Label>
                            <Translated keyEn="SSID" />
                        </Label>
                        <FlexRow>
                            <Select
                                allowClear
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                options={memoNetworkOptions}
                                value={selectedSsid}
                                onChange={(value) => {
                                    setSelectedSsid(value)
                                }}
                                size="large"
                            />
                            <Button
                                size="large"
                                type="primary"
                                loading={isScanning}
                                onClick={scanForNetworks}
                            >
                                Scan
                            </Button>
                        </FlexRow>
                    </Col>
                    {!memoSelectedNetworkIsOpen && (
                        <Col xs={24}>
                            <Label>
                                <Translated keyEn="Password" />
                            </Label>
                            <Input
                                value={password ?? ""}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                variant="filled"
                            />
                        </Col>
                    )}
                    <Col xs={24}>
                        <Button
                            block
                            size="large"
                            type="primary"
                            disabled={
                                memoCurrentlyConectedSsid !== null &&
                                memoCurrentlyConectedSsid === selectedSsid
                            }
                            loading={isConnecting}
                            onClick={connectToNetwork}
                        >
                            <Translated keyEn="Connect" />
                        </Button>
                    </Col>
                </Row>
            </div>
        </FlexCol>
    )
    return content
}

export const PublicWifiSettingsPage: FC = () => {
    return (
        <FlexCol
            style={{
                width: "80vw",
                maxWidth: "55rem", // MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
                alignItems: "center",
            }}
        >
            <WifiSettings />
        </FlexCol>
    )
}
