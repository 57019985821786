import GoogleMapReact from "google-map-react"
import { FC, ReactElement, ReactNode } from "react"
import { FlexCol } from "./common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { COLOR_BG_BLUE } from "../../utils/utils"

export const formatLCoordinateDegMinSec = (coord: number | null) => {
    if (coord === null) {
        return "N/A"
    }
    let absCoord = Math.abs(coord)
    let deg = Math.floor(absCoord)
    let min = Math.floor((absCoord - deg) * 60)
    let sec = Math.floor(((absCoord - deg) * 60 - min) * 60)
    return `${deg}° ${min}' ${sec}''`
}

export const GoogleMapsMarker: FC<{
    lat: number
    lng: number
    children?: ReactNode
}> = ({ children: children }) => (
    <FlexCol
        style={{
            gap: 0,
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-1.5rem",
        }}
    >
        <FontAwesomeIcon
            icon={faLocationDot}
            style={{
                fontSize: "1.5rem",
                color: COLOR_BG_BLUE,
            }}
        />
        {children}
    </FlexCol>
)

export const GoogleMapsReactSignedin: FC<GoogleMapReact.Props> = (props) => {
    return (
        <div style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
                }}
                {...props}
            />
        </div>
    )
}
