import { HomeOutlined, LeftCircleFilled } from "@ant-design/icons"
import { Breadcrumb as AntdBreadcrumb, Button } from "antd"
import { ItemType } from "antd/es/breadcrumb/Breadcrumb"
import { FC, ReactElement, useMemo } from "react"
import { Link } from "react-router-dom"
import { FlexRow } from "./common"
import { Translated } from "../../utils/translated"

export const SimplifiedBreadcrumb: FC<{
    previousItems: { href: string; label: ReactElement | string | null }[]
    currentItem: { label: ReactElement | string | null }
}> = ({ previousItems, currentItem }) => {
    const memoItems = useMemo(() => {
        let antdItems: ItemType[] = [
            {
                title: (
                    <Link to="/">
                        <HomeOutlined />
                    </Link>
                ),
            },
        ]
        for (let item of previousItems) {
            let title = item.label
            if (item.href !== undefined) {
                title = <Link to={item.href}>{item.label}</Link>
            }
            antdItems.push({ title })
        }
        // Push curent item (no link)
        antdItems.push({
            title: currentItem.label,
        })
        return antdItems
    }, [previousItems, currentItem])

    const memoBackButtonHref = useMemo(() => {
        let lastItem = previousItems[previousItems.length - 1]
        if (lastItem === undefined) {
            return "/"
        }
        return lastItem.href
    }, [previousItems])

    return (
        <FlexRow style={{ alignItems: "start" }}>
            <Link to={memoBackButtonHref}>
                <Button icon={<LeftCircleFilled />} shape="round" size="small">
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Back" />
                    </span>
                </Button>
            </Link>
            <AntdBreadcrumb items={memoItems} />
        </FlexRow>
    )
}
