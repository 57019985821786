import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
    COLOR_BG_BLUE,
    COLOR_BG_DARK_BLUE_TEXT,
    parsePathForEntityUUID,
    pbUUIDToUuid,
} from "../utils/utils"
import { Translated } from "../utils/translated"
import {
    Equipment,
    Impact,
    MethodTypeEN1177,
    NormType,
    Site,
    Zone,
} from "../generated/proto-ts/main"
import {
    Avatar,
    Button,
    Carousel,
    Col,
    Divider,
    FloatButton,
    Input,
    InputProps,
    InputRef,
    Row,
    Spin,
    Upload,
} from "antd"
import {
    useUsercommSiteBLE,
    useUsercommSiteChildrenRecursiveBLE,
} from "../usercomm/usercommAsyncRequestBLE"
import {
    FlexCol,
    FlexRow,
    RequirementsAlertSM,
} from "../components/commons-ts/common"
import TextArea, { TextAreaProps } from "antd/es/input/TextArea"
import { callCloudApiV2 } from "../utils/cloudApiV2"
import { ICloudUser, MeanWithStd } from "../types"
import {
    CloseOutlined,
    DeleteOutlined,
    FilePdfOutlined,
    LeftCircleOutlined,
    PictureOutlined,
} from "@ant-design/icons"
import { sportsMatTypeDescriptionStr } from "../components/commons-ts/tags"
import {
    EquipmentZonesTableSM,
    EquipmentZonesTableSM_Print,
    getEquipmentSportsMatThicknessMeanAndStd,
} from "./Equipment"
import { createContext } from "vm"
import React from "react"
import {
    ImpactChartRawAcceleration,
    ImpactChartKinematicAcceleration,
    ImpactChartKinematicVelocity,
    ImpactChartKinematicDeflection,
} from "../components/commons-ts/charts"
import { getImpactKinematics, getRequirementsSM } from "../utils/maths"

import ImageBlobReduce from "image-blob-reduce"
import Pica from "pica"
import {
    loadLocalStorageReportLeftFooterText,
    loadLocalStorageReportLeftLogoDataURL,
    loadLocalStorageReportRightFooterText,
    loadLocalStorageReportRightLogoDataURL,
    saveLocalStorageReportLeftFooterText,
    saveLocalStorageReportLeftLogoDataURL,
    saveLocalStorageReportRightFooterText,
    saveLocalStorageReportRightLogoDataURL,
} from "../utils/localStorage"
import { useUsercommContextBLE } from "../usercomm/usercommProviderBLE"
import { InputType } from "zlib"
const pica = Pica({ features: ["js", "wasm", "cib"] })

const HEADER_HEIGHT = 128
const FOOTER_HEIGHT = 64

interface ReportConfigState {
    leftDataURL: string | null
    rightDataURL: string | null
    leftFootnote: string | null
    rightFootnote: string | null
}

interface ReportConfigContextType extends ReportConfigState {
    setLeftDataURL: (dataURL: string | null) => void
    setRightDataURL: (dataURL: string | null) => void
    setLeftFootnote: (footnote: string | null) => void
    setRightFootnote: (footnote: string | null) => void
}

const ReportConfigContext = React.createContext<
    ReportConfigContextType | undefined
>(undefined)

const useReportConfigContext = () => {
    const context = React.useContext(ReportConfigContext)
    if (context === undefined) {
        throw new Error(
            "useReportConfigContext must be used within a ReportConfigProvider",
        )
    }
    return context
}

const ReportConfigProvider: FC<{
    children: React.ReactNode
}> = ({ children }) => {
    const [leftDataURL, _setLeftDataURL] = useState<string | null>(
        loadLocalStorageReportLeftLogoDataURL,
    )
    const [rightDataURL, _setRightDataURL] = useState<string | null>(
        loadLocalStorageReportRightLogoDataURL,
    )
    const [leftFootnote, _setLeftFootnote] = useState<string | null>(
        loadLocalStorageReportLeftFooterText,
    )
    const [rightFootnote, _setRightFootnote] = useState<string | null>(
        loadLocalStorageReportRightFooterText,
    )

    const setLeftDataURL = useCallback((dataURL: string | null) => {
        saveLocalStorageReportLeftLogoDataURL(dataURL)
        _setLeftDataURL(dataURL)
    }, [])

    const setRightDataURL = useCallback((dataURL: string | null) => {
        saveLocalStorageReportRightLogoDataURL(dataURL)
        _setRightDataURL(dataURL)
    }, [])

    const setLeftFootnote = useCallback((footnote: string | null) => {
        saveLocalStorageReportLeftFooterText(footnote)
        _setLeftFootnote(footnote)
    }, [])

    const setRightFootnote = useCallback((footnote: string | null) => {
        saveLocalStorageReportRightFooterText(footnote)
        _setRightFootnote(footnote)
    }, [])

    return (
        <ReportConfigContext.Provider
            value={{
                leftDataURL,
                rightDataURL,
                leftFootnote,
                rightFootnote,
                setLeftDataURL,
                setRightDataURL,
                setLeftFootnote,
                setRightFootnote,
            }}
        >
            {children}
        </ReportConfigContext.Provider>
    )
}

const PDFInput: FC<InputProps> = (props) => {
    let { defaultValue } = props
    const [value, setValue] = useState(defaultValue)
    const inputRef = useRef<InputRef | null>(null)
    useEffect(() => {
        let _value = value
        if (!_value) {
            _value = "N/A"
        }
        setValue(_value)
        if (
            inputRef.current !== null &&
            inputRef.current.input &&
            typeof _value === "string" &&
            _value.length > 0
        ) {
            let _size = _value.length - 3
            if (_size < 1) {
                _size = 1
            }
            inputRef.current.input.size = _size
        }
    }, [defaultValue])

    return (
        <Input
            ref={inputRef}
            {...props}
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
                if (
                    inputRef.current !== null &&
                    inputRef.current.input &&
                    typeof e.target.value === "string"
                ) {
                    let _size = e.target.value.length - 3
                    if (_size < 1) {
                        _size = 1
                    }
                    inputRef.current.input.size = _size
                }
            }}
            className="pdf-input"
            variant="borderless"
        />
    )
}

const PDFInputTextArea: FC<TextAreaProps> = (props) => {
    return <TextArea {...props} className="pdf-input" variant="borderless" />
}

const ImageUploader: FC<{
    dataURL: string | null
    setDataURL: (dataURL: string | null) => void
}> = ({ dataURL, setDataURL }) => {
    const beforeUpload = useCallback(async (file: File) => {
        let resizedBlob = await new ImageBlobReduce({
            pica,
        }).toBlob(file, {
            max: 2 * HEADER_HEIGHT,
        })
        const reader = new FileReader()
        reader.readAsDataURL(resizedBlob)
        reader.onload = () => {
            let dataURL = reader.result
            if (typeof dataURL === "string") {
                console.log(`ImageUploader: dataURL length: `, dataURL.length)
                setDataURL(dataURL)
            }
        }
        return false
    }, [])

    const onChange = useCallback((info: any) => {
        console.log(info)
    }, [])

    const memoUploadChildren = useMemo(() => {
        if (dataURL !== null) {
            return (
                <img
                    src={dataURL}
                    alt="LOGO"
                    style={{ height: HEADER_HEIGHT, cursor: "pointer" }}
                />
            )
        }
        return (
            <Avatar
                // className="pdf-invisible"
                icon={<PictureOutlined />}
                shape="square"
                size={HEADER_HEIGHT}
                style={{
                    cursor: "pointer",
                }}
            />
        )
    }, [dataURL])

    return (
        <FlexRow style={{ gap: 0 }}>
            <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={onChange}
                style={{
                    cursor: "pointer",
                    height: "100%",
                }}
            >
                {memoUploadChildren}
            </Upload>
            {dataURL && (
                <Button
                    className="pdf-invisible"
                    size="small"
                    type="link"
                    danger
                    icon={<CloseOutlined />}
                    onClick={() => setDataURL(null)}
                    style={{
                        marginLeft: -3,
                        marginTop: -5,
                    }}
                />
            )}
        </FlexRow>
    )
}

const PDFHeader: FC<{
    site: Site | null
}> = ({ site }) => {
    const { leftDataURL, setLeftDataURL } = useReportConfigContext()

    const memoReportRef = useMemo(() => {
        if (!site) {
            return ""
        }
        let ref =
            site.mission_name + "_" + site.client_name + "_" + site.site_name
        return ref.replaceAll(" ", "_")
    }, [site])

    return (
        <header
            style={{
                borderBottom: `3px solid ${COLOR_BG_BLUE}`,
                fontSize: "0.9rem",
                textAlign: "center",
            }}
        >
            <FlexRow
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: HEADER_HEIGHT,
                    marginBottom: 5,
                }}
            >
                <ImageUploader
                    dataURL={leftDataURL}
                    setDataURL={setLeftDataURL}
                />
                <FlexCol
                    style={{
                        alignItems: "end",
                        gap: 0,
                    }}
                >
                    <span>Report Ref. {memoReportRef}</span>
                    <span>Report Date: {new Date().toLocaleDateString()}</span>
                    <span>Version: 1.0</span>
                    <span style={{ marginTop: 10 }}>
                        <i>
                            This version replaces and cancels all the previous
                            ones
                        </i>
                    </span>
                </FlexCol>
            </FlexRow>
        </header>
    )
}

const FootnoteTextArea: FC<{
    footnote: string | null
    setFootnote: (footnote: string | null) => void
    textAlign: "center" | "left" | "right"
}> = ({ footnote, setFootnote, textAlign }) => {
    return (
        <span
            style={{
                textAlign: "center",
                width: "40%",
            }}
        >
            <PDFInputTextArea
                style={{
                    textAlign,
                }}
                autoSize={{
                    minRows: 2,
                    maxRows: 2,
                }}
                value={footnote ?? ""}
                onChange={(e) => {
                    setFootnote(e.target.value)
                }}
            />
        </span>
    )
}

const PDFFooter: FC<{
    pageNumber: number
    totalPages: number
}> = ({ pageNumber, totalPages }) => {
    const { leftFootnote, rightFootnote, setLeftFootnote, setRightFootnote } =
        useReportConfigContext()

    return (
        <footer
            style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                marginLeft: "8mm",
                marginRight: "8mm",
                width: "calc(100% - 16mm)",
                height: FOOTER_HEIGHT,
                // borderTop: "1px solid black",
            }}
        >
            <FlexRow
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "0.8rem",
                }}
            >
                <FootnoteTextArea
                    footnote={leftFootnote}
                    setFootnote={setLeftFootnote}
                    textAlign="left"
                />
                <p>
                    Page {pageNumber} of {totalPages}
                </p>
                <FootnoteTextArea
                    footnote={rightFootnote}
                    setFootnote={setRightFootnote}
                    textAlign="right"
                />
            </FlexRow>
        </footer>
    )
}

const EmptySpaceFiller: FC = () => {
    return (
        <div
            style={{
                flexGrow: 3,
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                // line crossing the div from top right corner to bottom left corner
                backgroundColor: "gray",
            }}
        />
    )
}

const PDFPage: FC<{
    site: Site | null
    pageNumber: number
    totalPages: number
    children: any
}> = ({ site, pageNumber, totalPages, children }) => {
    return (
        <section
            className="sheet p-8mm"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
            }}
        >
            <PDFHeader site={site} />
            {children}
            <PDFFooter pageNumber={pageNumber} totalPages={totalPages} />
        </section>
    )
}

const FirstPageContent: FC<{
    user: ICloudUser | null
    site: Site
}> = ({ user, site }) => {
    const memoNormType = useMemo(() => {
        switch (site.norm_type) {
            case NormType.EN_1177:
                return "NF EN 1177"
            case NormType.EN_12503:
                return "NF EN 12503"
        }
    }, [site])

    const memoMethodTypeEN1177Element = useMemo(() => {
        if (site.norm_type !== NormType.EN_1177) {
            return <></>
        }
        switch (site.method_type_en_1177) {
            case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
                return (
                    <>
                        [Method 1: <b>Critical Fall Height Determination</b>]
                    </>
                )
            case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
                return (
                    <>
                        [Method 2: <b>Impact Attenuation Compliance</b>]
                    </>
                )
        }
    }, [site])

    const memoSitePicture = useMemo(() => {
        if (!site.pictures) {
            return <></>
        }
        let pictureUUID = site.pictures[0]
        if (!pictureUUID) {
            return <></>
        }
        return (
            <img
                key={pbUUIDToUuid(pictureUUID)}
                className="max-h-80mm object-contain border-2 border-primary-dark"
                style={{
                    maxWidth: "80%",
                }}
                src={`/api/uploads/${pbUUIDToUuid(pictureUUID)}`}
                alt="site"
            />
        )
    }, [site])

    const memoOnSiteConditionsSubscript = useMemo(() => {
        if (!site.is_lab_test) {
            return (
                <span
                    style={{
                        textAlign: "center",
                    }}
                >
                    <PDFInputTextArea
                        autoSize={{
                            minRows: 4,
                        }}
                        value="This test was carried out on site with the particular climatic and site conditions occurring on the day of test. The results cannot be used to indicate the performance of the product under any other conditions or in other locations."
                    />
                </span>
            )
        }
        return <></>
    }, [site])

    const memoUserFullName = useMemo(() => {
        if (!user) {
            return ""
        }
        return user.FirstName + " " + user.LastName
    }, [user])

    const memoUserPhoneNumber = useMemo(() => {
        if (!user) {
            return ""
        }
        return user.PhoneNumber
    }, [user])

    const memoUserEmail = useMemo(() => {
        if (!user) {
            return ""
        }
        return user.Email
    }, [user])

    return (
        <FlexCol
            style={{
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
            }}
        >
            {/* Names: mission, client, site */}
            <FlexCol
                style={{
                    alignItems: "center",
                    gap: 0,
                }}
            >
                {/* Mission */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        color: COLOR_BG_DARK_BLUE_TEXT,
                        fontWeight: "bold",
                        fontSize: "1.7rem",
                    }}
                >
                    <span>
                        <Translated keyEn="Mission" />:
                    </span>
                    <span>
                        <PDFInput
                            defaultValue={site.mission_name}
                            style={{
                                width: "fit-content",
                            }}
                        />
                    </span>
                </FlexRow>
                {/* Client */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        color: COLOR_BG_DARK_BLUE_TEXT,
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                    }}
                >
                    <span>
                        <Translated keyEn="Client" />:
                    </span>
                    <span>
                        <PDFInput
                            defaultValue={site.client_name}
                            style={{
                                width: "fit-content",
                            }}
                        />
                    </span>
                </FlexRow>
                <FlexRow
                    style={{
                        alignItems: "center",
                        marginTop: "5mm",
                        marginBottom: "5mm",
                        color: COLOR_BG_DARK_BLUE_TEXT,
                        fontWeight: "bold",
                    }}
                >
                    <p>
                        <Translated keyEn="Tests carried out on site in accordance with the norm" />{" "}
                        <b>{memoNormType}</b>
                        <b> {memoMethodTypeEN1177Element}</b>
                        <span> on site:</span>
                    </p>
                </FlexRow>
                {/* Site */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        color: COLOR_BG_DARK_BLUE_TEXT,
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                        textDecoration: "underline",
                    }}
                >
                    <span>
                        <Translated keyEn="Site" />:
                    </span>
                    <span>
                        <PDFInput
                            defaultValue={site.site_name}
                            style={{
                                width: "fit-content",
                            }}
                        />
                    </span>
                </FlexRow>
                <Divider />
                <FlexCol
                    style={{
                        alignItems: "center",
                    }}
                >
                    {memoSitePicture}
                </FlexCol>
                <Divider />
                {/* Details and contact */}
                <FlexCol
                    style={{
                        fontSize: "1rem",
                        gap: 0,
                        fontWeight: "bold",
                    }}
                >
                    <FlexRow
                        style={{
                            alignItems: "center",
                        }}
                    >
                        <Translated keyEn="Site&nbsp;address" />:
                        <PDFInput defaultValue={site.address} />
                    </FlexRow>
                    <FlexRow
                        style={{
                            alignItems: "center",
                            color: COLOR_BG_DARK_BLUE_TEXT,
                        }}
                    >
                        <Translated keyEn="Inspector" />:
                        <PDFInput defaultValue={memoUserFullName} />
                    </FlexRow>
                    <FlexRow
                        style={{
                            alignItems: "center",
                            color: COLOR_BG_DARK_BLUE_TEXT,
                        }}
                    >
                        <Translated keyEn="Date" />:
                        <PDFInput
                            defaultValue={new Date(
                                site.execution_date,
                            ).toLocaleDateString()}
                        />
                    </FlexRow>
                    {/* <FlexRow
                        style={{
                            alignItems: "center",
                        }}
                    >
                        <Translated keyEn="Email" />:
                        <PDFInput defaultValue={memoUserEmail} />
                    </FlexRow>
                    <FlexRow
                        style={{
                            alignItems: "center",
                        }}
                    >
                        <Translated keyEn="Phone" />:
                        <PDFInput defaultValue={memoUserPhoneNumber} />
                    </FlexRow> */}
                </FlexCol>
            </FlexCol>
            <div
                style={{
                    width: "80%",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                }}
            >
                {memoOnSiteConditionsSubscript}
            </div>
        </FlexCol>
    )
}

const PicturesRow: FC<{
    pictures: Site["pictures"]
}> = ({ pictures }) => {
    return (
        <Row gutter={[10, 10]} justify="center" style={{ width: "100%" }}>
            {pictures.map((pictureUUID) => {
                return (
                    <Col key={pbUUIDToUuid(pictureUUID)}>
                        <img
                            src={`/api/uploads/${pbUUIDToUuid(pictureUUID)}`}
                            style={{
                                maxWidth: 300,
                                maxHeight: 300,
                                border: "2px solid black",
                            }}
                        />
                    </Col>
                )
            })}
        </Row>
    )
}

const SecondPageContent: FC<{
    user: ICloudUser | null
    site: Site
}> = ({ user, site }) => {
    return (
        <FlexCol
            style={{
                marginTop: 30,
                justifyContent: "start",
                alignItems: "center",
                height: "100%",
            }}
        >
            <div
                style={{
                    width: "80%",
                }}
            >
                <h3>Notes</h3>
                <PDFInputTextArea
                    defaultValue={"... Observations and legal notices ..."}
                />
            </div>
        </FlexCol>
    )
}

const EquipmentPageContent: FC<{
    user: ICloudUser | null
    site: Site
    equipment: Equipment
    equipmentZonesMap: Record<string, Zone[]>
    zoneImpactsMap: Record<string, Impact[]>
}> = ({ user, site, equipment, equipmentZonesMap, zoneImpactsMap }) => {
    const mooSyncRef = useRef<uPlot.SyncPubSub | null>(null)

    const memoSportsMatTypeDescription = useMemo(() => {
        if (equipment.sports_mat_type === null) {
            return ""
        }
        return `Type "${equipment.sports_mat_type}": ${sportsMatTypeDescriptionStr(equipment.sports_mat_type)}`
    }, [equipment])

    const memoSportsMatThicknessMeanAndStd = useMemo((): MeanWithStd | null => {
        return getEquipmentSportsMatThicknessMeanAndStd([
            equipment.sports_mat_thickness_side_one,
            equipment.sports_mat_thickness_side_two,
            equipment.sports_mat_thickness_side_three,
            equipment.sports_mat_thickness_side_four,
        ])
    }, [equipment])

    const memoEquipmentZones = useMemo(() => {
        if (equipmentZonesMap === null) {
            return []
        }
        return equipmentZonesMap[pbUUIDToUuid(equipment.uuid)]
    }, [equipmentZonesMap])

    const memoMostUnfavorableImpact = useMemo(() => {
        if (zoneImpactsMap === null) {
            return null
        }
        let zones = equipmentZonesMap[pbUUIDToUuid(equipment.uuid)]
        if (zones === undefined) {
            return null
        }
        let mostUnfavorableImpact: Impact | null = null
        let mostUnfavorableImpactValue = -1
        for (let zone of zones) {
            let impacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
            if (impacts === undefined) {
                continue
            }
            for (let impact of impacts) {
                if (impact.impact_gmax > mostUnfavorableImpactValue) {
                    mostUnfavorableImpact = impact
                    mostUnfavorableImpactValue = impact.impact_gmax
                }
            }
        }
        return mostUnfavorableImpact
    }, [zoneImpactsMap])

    const memoImpactChartColStyle = useMemo(() => {
        return { height: 300, marginBottom: 50 }
    }, [])

    const memoRequirementsSM = useMemo(() => {
        if (equipment === null) {
            return null
        }
        let requirements = getRequirementsSM(equipment.sports_mat_type)
        return requirements
    }, [equipment])

    const memoMostUnfavorableImpactChartsRow = useMemo(() => {
        if (
            memoMostUnfavorableImpact === null ||
            memoSportsMatThicknessMeanAndStd === null
        ) {
            return <></>
        }
        let smThickness = memoSportsMatThicknessMeanAndStd.mean
        let impactKinematicPoints = getImpactKinematics(
            memoMostUnfavorableImpact,
            smThickness,
        )
        if (memoRequirementsSM === null || impactKinematicPoints === null) {
            return null
        }
        return (
            <div style={{ width: "100%" }}>
                <Row justify="center">
                    <Col xs={8} style={memoImpactChartColStyle}>
                        <ImpactChartKinematicAcceleration
                            kinematicPoints={
                                impactKinematicPoints.kinematicPoints
                            }
                            maxAcceptableG={
                                memoRequirementsSM === null
                                    ? null
                                    : memoRequirementsSM.gmax
                            }
                            mooSync={mooSyncRef.current}
                            shouldDrawRequirements={true}
                        />
                    </Col>
                    <Col xs={8} style={memoImpactChartColStyle}>
                        <ImpactChartKinematicVelocity
                            kinematicPoints={
                                impactKinematicPoints.kinematicPoints
                            }
                            maxAcceptableResiliencePerc={
                                memoRequirementsSM !== null &&
                                memoRequirementsSM.resiliencePerc !== null
                                    ? memoRequirementsSM.resiliencePerc[1]
                                    : null
                            }
                            mooSync={mooSyncRef.current}
                            shouldDrawRequirements={true}
                        />
                    </Col>
                    <Col xs={8} style={memoImpactChartColStyle}>
                        <ImpactChartKinematicDeflection
                            kinematicPoints={
                                impactKinematicPoints.kinematicPoints
                            }
                            totalDepth={memoSportsMatThicknessMeanAndStd}
                            maxAcceptableDeformationDist={
                                memoRequirementsSM === null
                                    ? null
                                    : memoRequirementsSM.deformationDistanceMM
                            }
                            maxAcceptableDeformationPerc={
                                memoRequirementsSM === null
                                    ? null
                                    : memoRequirementsSM.deformationDistancePerc
                            }
                            mooSync={mooSyncRef.current}
                            shouldDrawRequirements={true}
                        />
                    </Col>
                </Row>
            </div>
        )
    }, [
        memoMostUnfavorableImpact,
        memoSportsMatThicknessMeanAndStd,
        memoRequirementsSM,
    ])

    const memoRenderedSportsMatThicknessMeanAndStd = useMemo(() => {
        if (memoSportsMatThicknessMeanAndStd === null) {
            return ""
        }
        return `${memoSportsMatThicknessMeanAndStd.mean.toFixed(0)} ± ${memoSportsMatThicknessMeanAndStd.std.toFixed(1)}`
    }, [memoSportsMatThicknessMeanAndStd])

    return (
        <FlexCol
            style={{
                justifyContent: "start",
                alignItems: "start",
                maxHeight: "90%",
                overflow: "hidden",
            }}
        >
            <h2
                style={{
                    width: "100%",
                }}
            >
                <FlexRow
                    style={{
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Translated keyEn="Equipment" />:
                    <PDFInput defaultValue={equipment.equipment_name} />
                </FlexRow>
            </h2>
            <FlexRow
                style={{
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Translated keyEn="Type" />:
                <PDFInput defaultValue={memoSportsMatTypeDescription} />
            </FlexRow>
            <RequirementsAlertSM smType={equipment.sports_mat_type} />
            <Row gutter={[10, 10]} style={{ width: "100%" }}>
                <Col xs={12}>
                    <h3>
                        <Translated keyEn="References" />
                    </h3>
                    <FlexCol style={{ gap: 0 }}>
                        <FlexRow
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <Translated keyEn="Manufacturer" />:
                            <PDFInput
                                defaultValue={equipment.equipment_manufacturer}
                            />
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <Translated keyEn="Reference" />:
                            <PDFInput
                                defaultValue={equipment.equipment_reference}
                            />
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <Translated keyEn="Installation&nbsp;date" />:
                            <PDFInput
                                defaultValue={new Date(
                                    equipment.equipment_installation_date,
                                ).toLocaleDateString()}
                            />
                        </FlexRow>
                    </FlexCol>
                </Col>
                <Col xs={12}>
                    <h3>
                        <Translated keyEn="Measurements" />
                    </h3>
                    <FlexCol style={{ gap: 0 }}>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <Translated keyEn="Length,&nbsp;cm" />:
                            <PDFInput
                                defaultValue={equipment.sports_mat_length}
                            />
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <Translated keyEn="Width,&nbsp;cm" />:
                            <PDFInput
                                defaultValue={equipment.sports_mat_width}
                            />
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                            }}
                        >
                            <Translated keyEn="Thickness,&nbsp;mm" />:
                            <PDFInput
                                defaultValue={
                                    memoRenderedSportsMatThicknessMeanAndStd
                                }
                            />
                        </FlexRow>
                    </FlexCol>
                    <Col xs={12}></Col>
                </Col>
            </Row>
            <h3>
                <Translated keyEn="Results" />
            </h3>
            <EquipmentZonesTableSM_Print
                equipmentUUID={equipment.uuid}
                smThickness={memoSportsMatThicknessMeanAndStd}
                smType={equipment.sports_mat_type}
                zones={memoEquipmentZones}
            />
            {memoMostUnfavorableImpactChartsRow}
            <PicturesRow pictures={equipment.pictures} />
        </FlexCol>
    )
}

const LastPageContent: FC<{
    user: ICloudUser | null
    site: Site
}> = ({ user, site }) => {
    return (
        <FlexCol
            style={{
                marginTop: 30,
                justifyContent: "start",
                alignItems: "center",
                height: "100%",
            }}
        >
            <PDFInputTextArea
                defaultValue={"... Final remarks and conclusions ..."}
            />
            <span>End of Report</span>
        </FlexCol>
    )
}

export const ReportPage: FC = () => {
    const location = useLocation()
    const { bleIsConnected } = useUsercommContextBLE()
    const siteUUID = useMemo(() => {
        return parsePathForEntityUUID(location.pathname)
    }, [location])
    const [totalPages, setTotalPages] = useState(2)
    const [site, getSite] = useUsercommSiteBLE()
    const [
        siteEquipmentsMap,
        equipmentZonesMap,
        zoneImpactsMap,
        getSiteChildrenRecursive,
    ] = useUsercommSiteChildrenRecursiveBLE()
    const [user, setUser] = useState<ICloudUser | null>(null)
    useEffect(() => {
        if (site === null || !site.user_uuid) {
            return
        }
        callCloudApiV2<ICloudUser>(
            `/user?user_uuid=${pbUUIDToUuid(site.user_uuid)}`,
        ).then(({ entity: user }) => {
            setUser(user)
        })
    }, [site])

    // Site
    useEffect(() => {
        getSite(siteUUID)
    }, [siteUUID, bleIsConnected])
    useEffect(() => {
        if (!site) {
            return
        }
        console.log(`ReportPage: site`, site.toObject())
    }, [site])

    // Site children
    useEffect(() => {
        if (site == null) {
            return
        }
        getSiteChildrenRecursive(site.uuid, true)
    }, [site])

    useEffect(() => {
        if (equipmentZonesMap === null) {
            return
        }
        let nbPages = 3
        for (let siteUUID in siteEquipmentsMap) {
            let equipments = siteEquipmentsMap[siteUUID]
            if (equipments === undefined) {
                continue
            }
            nbPages += equipments.length
        }
        setTotalPages(nbPages)
    }, [equipmentZonesMap])

    const memoEquipmentPages = useMemo(() => {
        if (siteEquipmentsMap === null || site === null) {
            return <></>
        }
        let pages: JSX.Element[] = []
        for (let siteUUID in siteEquipmentsMap) {
            let equipments = siteEquipmentsMap[siteUUID]
            if (equipments === undefined) {
                continue
            }
            let i = 0
            for (let equipment of equipments) {
                i++
                let equipmentPage = (
                    <PDFPage
                        site={site}
                        pageNumber={2 + i}
                        totalPages={totalPages}
                        key={pbUUIDToUuid(equipment.uuid)}
                    >
                        <EquipmentPageContent
                            user={user}
                            site={site}
                            equipment={equipment}
                            equipmentZonesMap={equipmentZonesMap}
                            zoneImpactsMap={zoneImpactsMap}
                        />
                    </PDFPage>
                )
                pages.push(equipmentPage)
            }
        }
        return pages
    }, [siteEquipmentsMap, equipmentZonesMap, zoneImpactsMap])

    if (site === null) {
        return (
            <FlexCol
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <ReportConfigProvider>
            <div id="pdf" className="A4">
                <PDFPage site={site} pageNumber={1} totalPages={totalPages}>
                    <FirstPageContent user={user} site={site} />
                </PDFPage>
                <PDFPage site={site} pageNumber={2} totalPages={totalPages}>
                    <SecondPageContent user={user} site={site} />
                </PDFPage>
                {memoEquipmentPages}
                <PDFPage
                    site={site}
                    pageNumber={totalPages}
                    totalPages={totalPages}
                >
                    <LastPageContent user={user} site={site} />
                </PDFPage>
            </div>
            <div className="pdf-invisible">
                <FloatButton
                    icon={<FilePdfOutlined />}
                    description="Print"
                    type="primary"
                    style={{
                        width: 60,
                        height: 60,
                        right: 40,
                    }}
                    onClick={() => {
                        window.print()
                    }}
                />
                <Link to="/">
                    <FloatButton
                        icon={<LeftCircleOutlined />}
                        description="Back"
                        type="default"
                        style={{
                            width: 60,
                            height: 60,
                            right: 110,
                        }}
                    />
                </Link>
            </div>
        </ReportConfigProvider>
    )
}
