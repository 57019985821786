import { FC, useCallback, useEffect, useMemo, useState } from "react"
import {
    Alert,
    Button,
    Col,
    Input,
    Row,
    Typography,
    message as antdMessage,
} from "antd"
import { Label } from "recharts"
import {
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../../components/commons-ts/common"
import { Translated, translated } from "../../utils/translated"
import { LabeledInput } from "../../components/commons-ts/input"
import { WSHICConfig } from "../../types"
import dayjs from "dayjs"
import { ReloadOutlined } from "@ant-design/icons"
import { callHostServerAPI } from "../../utils/hostServerAPI"
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite"
import "react-json-view-lite/dist/index.css"
import { useUsercommContextWS } from "../../usercomm/usercommProviderWS"
import { HICConfig, StationConfig } from "../../generated/proto-ts/main"
import { useUsercommContextBLE } from "../../usercomm/usercommProviderBLE"

export const HeadHICConfigSettings: FC = () => {
    const {
        bleIsConnected,
        hicConfig,
        hicState,

        hicConfigSetAckConsumable,
        consumeHICConfigSetAck,

        emitGetHICConfig,
        emitSetHICConfig,
    } = useUsercommContextBLE()

    // HIC Config
    const [serialNumber, setSerialNumber] = useState<string | null>(null)
    const [lastUpdate, setLastUpdate] = useState<dayjs.Dayjs | null>(null)
    const [correct5VStr, setCorrect5VStr] = useState<string | null>(null)
    const [triggerThresholdStr, setTriggerThresholdStr] = useState<
        string | null
    >(null)
    const [nbChuteStr, setNbChuteStr] = useState<string | null>(null)
    const [maxGStr, setMaxGStr] = useState<string | null>(null)
    const [dividerBatteryPointStr, setDividerBatteryPointStr] = useState<
        string | null
    >(null)
    const [fallOffsetStr, setFallOffsetStr] = useState<string | null>(null)

    const [xSensibilityStr, setXSensibilityStr] = useState<string | null>(null)
    const [xOffsetStr, setXOffsetStr] = useState<string | null>(null)
    const [xSerialStr, setXSerialStr] = useState<string | null>(null)

    const [ySensibilityStr, setYSensibilityStr] = useState<string | null>(null)
    const [yOffsetStr, setYOffsetStr] = useState<string | null>(null)
    const [ySerialStr, setYSerialStr] = useState<string | null>(null)

    const [zSensibilityStr, setZSensibilityStr] = useState<string | null>(null)
    const [zOffsetStr, setZOffsetStr] = useState<string | null>(null)
    const [zSerialStr, setZSerialStr] = useState<string | null>(null)

    const [hicScanIsLoading, setHicScanIsLoading] = useState<boolean>(false)
    const [hicScanResponse, setHicScanResponse] = useState<any>(null)

    const [apIsLoading, setApIsLoading] = useState<boolean>(false)
    const [apResponse, setApResponse] = useState<any>(null)
    const [apSsidName, setApSsidName] = useState<string | null>(null)

    const memoHicIsConnected = useMemo(() => {
        if (hicState === null) {
            return false
        }
        return hicState.is_connected
    }, [hicState])

    useEffect(() => {
        console.log("hicConfigSetAckConsumable", hicConfigSetAckConsumable)
        if (hicConfigSetAckConsumable !== null) {
            antdMessage.success("HIC configuration saved!")
            consumeHICConfigSetAck()
        }
    }, [hicConfigSetAckConsumable])

    useEffect(() => {
        emitGetHICConfig()
    }, [bleIsConnected, memoHicIsConnected])

    useEffect(() => {
        if (hicConfig === null) {
            return
        }
        if (hicConfig.serial_number) {
            setSerialNumber(hicConfig.serial_number)
        }
        if (hicConfig.last_control_date) {
            // e.g. 20240116
            setLastUpdate(dayjs(hicConfig.last_control_date, "YYYYMMDD"))
        }
        if (hicConfig.sensor_x_sensitivity) {
            setXSensibilityStr(hicConfig.sensor_x_sensitivity)
        }
        if (hicConfig.sensor_x_offset) {
            setXOffsetStr(hicConfig.sensor_x_offset)
        }
        if (hicConfig.sensor_x_serial_number) {
            setXSerialStr(hicConfig.sensor_x_serial_number)
        }
        if (hicConfig.sensor_y_sensitivity) {
            setYSensibilityStr(hicConfig.sensor_y_sensitivity)
        }
        if (hicConfig.sensor_y_offset) {
            setYOffsetStr(hicConfig.sensor_y_offset)
        }
        if (hicConfig.sensor_y_serial_number) {
            setYSerialStr(hicConfig.sensor_y_serial_number)
        }
        if (hicConfig.sensor_z_sensitivity) {
            setZSensibilityStr(hicConfig.sensor_z_sensitivity)
        }
        if (hicConfig.sensor_z_offset) {
            setZOffsetStr(hicConfig.sensor_z_offset)
        }
        if (hicConfig.sensor_z_serial_number) {
            setZSerialStr(hicConfig.sensor_z_serial_number)
        }
        if (hicConfig.real_5v_corrected) {
            setCorrect5VStr(hicConfig.real_5v_corrected.toString())
        }
        if (hicConfig.trigger_point_in_g) {
            setTriggerThresholdStr(hicConfig.trigger_point_in_g)
        }
        if (hicConfig.all_time_max_g) {
            setMaxGStr(hicConfig.all_time_max_g.toString())
        }
        if (hicConfig.battery_coef) {
            setDividerBatteryPointStr(hicConfig.battery_coef.toString())
        }
        if (hicConfig.fall_offset) {
            setFallOffsetStr(hicConfig.fall_offset.toString())
        }
        if (hicConfig.fall_count) {
            setNbChuteStr(hicConfig.fall_count.toString())
        }
    }, [hicConfig])

    const onSave = useCallback(() => {
        let newConfig: typeof hicConfig = new HICConfig({})
        if (serialNumber !== null) {
            newConfig.serial_number = serialNumber
        }
        if (lastUpdate !== null && lastUpdate.isValid()) {
            newConfig.last_control_date = lastUpdate.format("YYYYMMDD")
        }
        if (xSensibilityStr !== null && xSensibilityStr !== "") {
            newConfig.sensor_x_sensitivity = xSensibilityStr
        }
        if (xOffsetStr !== null && xOffsetStr !== "") {
            newConfig.sensor_x_offset = xOffsetStr
        }
        if (xSerialStr !== null && xSerialStr !== "") {
            newConfig.sensor_x_serial_number = xSerialStr
        }
        if (ySensibilityStr !== null && ySensibilityStr !== "") {
            newConfig.sensor_y_sensitivity = ySensibilityStr
        }
        if (yOffsetStr !== null && yOffsetStr !== "") {
            newConfig.sensor_y_offset = yOffsetStr
        }
        if (ySerialStr !== null && ySerialStr !== "") {
            newConfig.sensor_y_serial_number = ySerialStr
        }
        if (zSensibilityStr !== null && zSensibilityStr !== "") {
            newConfig.sensor_z_sensitivity = zSensibilityStr
        }
        if (zOffsetStr !== null && zOffsetStr !== "") {
            newConfig.sensor_z_offset = zOffsetStr
        }
        if (zSerialStr !== null && zSerialStr !== "") {
            newConfig.sensor_z_serial_number = zSerialStr
        }
        if (correct5VStr !== null && correct5VStr !== "") {
            newConfig.real_5v_corrected = correct5VStr
        }
        if (triggerThresholdStr !== null && triggerThresholdStr !== "") {
            newConfig.trigger_point_in_g = triggerThresholdStr
        }
        if (maxGStr !== null && maxGStr !== "") {
            newConfig.all_time_max_g = maxGStr
        }
        if (dividerBatteryPointStr !== null && dividerBatteryPointStr !== "") {
            newConfig.battery_coef = dividerBatteryPointStr
        }
        if (fallOffsetStr !== null && fallOffsetStr !== "") {
            newConfig.fall_offset = fallOffsetStr
        }
        if (nbChuteStr !== null && nbChuteStr !== "") {
            newConfig.fall_count = nbChuteStr
        }
        emitSetHICConfig(newConfig)
    }, [
        serialNumber,
        correct5VStr,
        dividerBatteryPointStr,
        fallOffsetStr,
        serialNumber,
        maxGStr,
        nbChuteStr,
        triggerThresholdStr,
        xSensibilityStr,
        xOffsetStr,
        xSerialStr,
        ySensibilityStr,
        yOffsetStr,
        ySerialStr,
        zSensibilityStr,
        zOffsetStr,
        zSerialStr,
    ])

    return (
        <FlexCol
            style={{
                width: "100%",
                // maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            <FlexCol style={{ gap: 0 }}>
                <Label>
                    <Translated keyEn="Configure Head HIC hardware parameters" />
                </Label>
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="Head HIC" />
                </Typography.Text>
            </FlexCol>
            {/* HIC Config */}
            <div>
                <FlexRow style={{ justifyContent: "space-between" }}>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="HIC Configuration" />
                    </UnderlinedSectionTitle>
                    <div>
                        <Button
                            size="large"
                            onClick={() => emitGetHICConfig()}
                            icon={<ReloadOutlined />}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    // fontWeight: "bold",
                                }}
                            >
                                <Translated keyEn="Reload" />
                            </span>
                        </Button>
                    </div>
                </FlexRow>
                <Row gutter={[10, 10]}>
                    {!memoHicIsConnected && (
                        <Col xs={24}>
                            <Alert
                                message={translated(
                                    "Head HIC is not connected to Station (Largueur). Please check that it is powered!",
                                )}
                                type="warning"
                                showIcon
                            />
                        </Col>
                    )}
                    <Col xs={24}>
                        <LabeledInput
                            label={translated("HIC S/N")}
                            value={serialNumber}
                            setValue={setSerialNumber}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Real 5V")}
                            value={correct5VStr}
                            setValue={setCorrect5VStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Trigger threshold")}
                            value={triggerThresholdStr}
                            setValue={setTriggerThresholdStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Number of falls")}
                            value={nbChuteStr}
                            setValue={setNbChuteStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Max G")}
                            value={maxGStr}
                            setValue={setMaxGStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Battery divider")}
                            value={dividerBatteryPointStr}
                            setValue={setDividerBatteryPointStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Fall offset")}
                            value={fallOffsetStr}
                            setValue={setFallOffsetStr}
                        />
                    </Col>
                </Row>
            </div>
            {/* X */}
            <div>
                <UnderlinedSectionTitle>
                    <Translated keyEn="Accelerometer X" />
                </UnderlinedSectionTitle>
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        <LabeledInput
                            label={translated("Serial")}
                            value={xSerialStr}
                            setValue={setXSerialStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Sensibility")}
                            value={xSensibilityStr}
                            setValue={setXSensibilityStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Offset")}
                            value={xOffsetStr}
                            setValue={setXOffsetStr}
                        />
                    </Col>
                </Row>
            </div>
            {/* Y */}
            <div>
                <UnderlinedSectionTitle>
                    <Translated keyEn="Accelerometer Y" />
                </UnderlinedSectionTitle>
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        <LabeledInput
                            label={translated("Serial")}
                            value={ySerialStr}
                            setValue={setYSerialStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Sensibility")}
                            value={ySensibilityStr}
                            setValue={setYSensibilityStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Offset")}
                            value={yOffsetStr}
                            setValue={setYOffsetStr}
                        />
                    </Col>
                </Row>
            </div>
            {/* Z */}
            <div>
                <UnderlinedSectionTitle>
                    <Translated keyEn="Accelerometer Z" />
                </UnderlinedSectionTitle>
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        <LabeledInput
                            label={translated("Serial")}
                            value={zSerialStr}
                            setValue={setZSerialStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Sensibility")}
                            value={zSensibilityStr}
                            setValue={setZSensibilityStr}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInput
                            label={translated("Offset")}
                            value={zOffsetStr}
                            setValue={setZOffsetStr}
                        />
                    </Col>
                </Row>
            </div>
            {/* Save */}
            <div>
                <Button block size="large" type="primary" onClick={onSave}>
                    <span
                        style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                        }}
                    >
                        <Translated keyEn="Save" />
                    </span>
                </Button>
            </div>
        </FlexCol>
    )
}
