export interface ICloudError {
    Reason: string
}

export enum ECloudRole {
    SuperAdmin = "superadmin",
    Admin = "admin",
    User = "user",
}

export enum ECloudReleaseChannel {
    Beta = "beta",
    Prod = "prod",
}

export interface ICloudUser {
    UUID: string
    Username: string
    Password: string
    Email: string
    PhoneNumber: string
    LastName: string
    FirstName: string
    Enterprise: {
        UUID: string
        Name: string
    }
    Roles: {
        UUID: string
        Name: ECloudRole
    }[]
    ReleaseChannels: {
        UUID: string
        Name: ECloudReleaseChannel
    }[]
}

export interface ICloudDeviceType {
    UUID: string
    Name: string
    LaunchDate: string
    EndDate: string
    Description: string
}

export interface ICloudEnterprise {
    UUID: string
    Name: string
}

export interface ICloudDevice {
    UUID: string
    SerialNumber: string
    Name: string
    DeviceType: ICloudDeviceType | null
    Enterprise: ICloudEnterprise | null
    // syncPool: SyncPool
}

export enum ECloudDeviceEventType {
    Unknown = 0,
    Revision = 1,
    SAV = 2,
    Metrology = 3,
    Lugnium = 4,
}

export enum ECloudDeviceEventState {
    Unknown = 0,
    Pending = 1,
    InProgress = 2,
    Completed = 3,
}

export interface ICloudDeviceEvent {
    UUID: string
    DeviceUUID: string
    ID: number
    EventType: ECloudDeviceEventType
    EventState: ECloudDeviceEventState
    StartDate: string | null
    EndDate: string | null
    Description: string
    Assignee: string
    Flags: number
    IsGmaxHigh: boolean
    Files: ICloudFile[]
}

export interface ICloudFile {
    UUID: string
    LegacyID: number
    Extension: string
    MimeType: string
    OriginalName: string
}

export interface LocalTreeBranch {
    deviceID: string
    siteID: number
    UID: string
}
export type LocalTree = LocalTreeBranch[]

export interface RemoteTreeBranch {
    deviceID: string
    siteID: string
    mission: string
    site: string
    client: string
}

export type RemoteTree = RemoteTreeBranch[]

export const getLocalSiteID = (remoteBranch: RemoteTreeBranch): number => {
    return parseInt(remoteBranch.siteID.split("_")[1])
}

export enum ESiteNormType {
    PlaygroundFloor = 1, // EN 1177
    SportsMat = 2, // EN 12503-4
}

export enum ESiteMethodType {
    CriticalFallHeight = 1,
    Adequacy = 2,
}

export enum ESportsMatType {
    Type1 = 1,
    Type2 = 2,
    Type3 = 3,
    Type4 = 4,
    Type5 = 5,
    Type6 = 6,
    Type7 = 7,
    Type8 = 8,
    Type9 = 9,
    Type10 = 10,
    Type11 = 11,
    Type12 = 12,
}

export interface ISpecimenElement {
    id: number
    key: string
    value: string
}

export interface ISiteModel {
    id: number | null
    uid: string | null
    device_id: string | null
    user_id: number

    norm_type: ESiteNormType
    method: ESiteMethodType | null
    is_lab_test: boolean | null

    site: string
    mission: string
    client: string
    address: string | null
    picture: string | null
    geolocation: string | null
    report_version: string | null
    execution_date: string | null // Date in the DB

    created_at: string | null // Date in the DB
    updated_at: string | null // Date in the DB
    deleted_at: string | null // Date in the DB

    equipments?: IEquipmentModel[]
    annexes?: IAnnexModel[]
}

export interface IAnnexModel {
    id: number | null
    uid: string | null
    site_id: number
    title: string
    picture: string | null
    created_at: string | null // Date in the DB
    updated_at: string | null // Date in the DB
    deleted_at: string | null // Date in the DB
}

export interface IEquipmentModel {
    id: number | null
    site_id: number
    uid: string | null
    equipment: string
    ref: string | null
    manufacturer: string | null
    installed_at: string | null // Date in the DB
    ffh_max: string | null
    floor_ref: string | null
    floor_manufacturer: string | null
    floor_kind: string | null
    floor_substrat: string | null
    floor_condition: string | null
    floor_installed_at: string | null // Date in the DB
    picture: string | null
    picture2: string | null
    specimen_data: string | null
    geolocation: string | null

    sports_mat_type: ESportsMatType | null
    sports_mat_length: number | null
    sports_mat_width: number | null
    sports_mat_thickness_side_one: number | null
    sports_mat_thickness_side_two: number | null
    sports_mat_thickness_side_three: number | null
    sports_mat_thickness_side_four: number | null
    sports_mat_metal_frame_is_present: boolean | null

    created_at: string | null // Date in the DB
    updated_at: string | null // Date in the DB
    deleted_at: string | null // Date in the DB

    zones?: IZoneModel[]
}

export interface IZoneModel {
    id: number | null
    uid: string | null
    equipment_id: number
    zone: string
    temp: number | null
    hygro: number | null
    floor_temp: number | null
    floor_hygro: number | null
    specimen_temp: number | null
    specimen_hygro: number | null
    ffh: number | null
    ground_thickness: number | null
    picture: string | null
    comments: string | null
    created_at: string | null // Date in the DB
    updated_at: string | null // Date in the DB
    deleted_at: string | null // Date in the DB

    impacts?: IImpactModel[]
}

export interface IImpactPoint {
    x: number
    y: number
    z: number
    t: number
    q: number
    // d: number
}

export interface IImpactModel {
    id: number | null
    uid: string | null
    zone_id: number
    height: number
    gmax: number
    hic: number
    points: string | null
    metadata: string | null
    created_at: string | null // Date in the DB
    updated_at: string | null // Date in the DB
    deleted_at: string | null // Date in the DB
}

export interface IUserModel {
    id: string | null
    username: string
    enterprise: string
    password: string
    professional_designation: string | null
    is_admin: boolean
    created_at: Date | null
    updated_at: Date | null
}

export interface IStats {
    count_impacts: number
    count_sites: number
    count_missions: number
    max_gmax: number
    max_hic: number
    count_impacts_above_limit: number
}

export interface ILegacyWSSensorsData {
    temperature: number
    height: number
    humidity: number
    battery: number
}

export interface ILegacyWSAcquisition {
    HIC: number
    GMax: number
    HCL: number
    NbLecture: number
    Data: {
        d: number // fall distance
        q: number // quadratic sum
        t: number // time
        x: number
        y: number
        z: number
    }[]
}

export interface WSHICConfig {
    serial: any
    lastUpdate: any
    Xsensibility: number | undefined // float
    Xoffset: number | undefined // float
    Xserial: number | undefined // int
    Ysensibility: number | undefined // float
    Yoffset: number | undefined // float
    Yserial: number | undefined // int
    Zsensibility: number | undefined // float
    Zoffset: number | undefined // float
    Zserial: number | undefined // int
    correct5V: number | undefined // float
    triggerThreshold: number | undefined // float
    nbChute: number | undefined // float
    maxG: number | undefined // float
    dividerBatteryPoint: number | undefined // float
    fallOffset: number | undefined // float
}

export interface IDeviceConfig {
    devicePicture: string | undefined
    gmaxLimitWarning: number | undefined
    gmaxLimitCritical: number | undefined
    lastMetrology: string | undefined // Date
    nextMetrology: string | undefined // Date
}

export interface IReportConfig {
    logoPicture: string | null
    footnote: string | null
    htmlCustomPage: string | null
}

export interface IImpactModelWithReferences extends IImpactModel {
    equipment_id: number
    equipment_name: string
    site_id: number
    site_name: string
    site_norm_type: ESiteNormType
    site_method_type: ESiteMethodType
    equipment_sports_mat_type: ESportsMatType
    equipment_sports_mat_thickness: number
}

export interface MeanWithStd {
    mean: number
    std: number
    count?: number
}

export interface IKinematicPoint {
    g: number
    a: number
    v: number
    d: number
    t: number
}

export interface IHICConfig {
    SerialNumber?: string
    LastControlDate?: string
    SensorXSensitivity?: string
    SensorXOffset?: string
    SensorXSerialNumber?: string
    SensorYSensitivity?: string
    SensorYOffset?: string
    SensorYSerialNumber?: string
    SensorZSensitivity?: string
    SensorZOffset?: string
    SensorZSerialNumber?: string
    Real5VCorrected?: string
    TriggerPointInG?: string
    AcquisitionTime?: string
    AcquisitionSpeed?: string
    FallCount?: string
    AllTimeMaxG?: string
    BatteryCoef?: string
    FallOffset?: string
    FirmwareVersion?: string
    DebugMode?: string
    HoldFallCount?: string
    BatteryLevel?: string
}

export interface IHICState {
    IsConnected: boolean
    EffectiveBDAddr: string
    BatteryLevelVoltFloat64: number
    BatteryLevelCoefFloat64: number
    BatteryLevelPercFloat64: number
}

export interface IHICRawMeasurement {
    HIC: number
    GMax: number
    FFH: number
    StartIndex: number
    EndIndex: number
    XArr: [number, number][]
    YArr: [number, number][]
    ZArr: [number, number][]
    EntityCnt: number
    IsComplete: boolean
}

export interface IStationConfig {
    HeadHICBDAddr?: string // should not be ""
    SerialNumber?: string // should not be ""
    BuzzerState?: boolean // should not be undefined, otherwise it will be set to false
    LidarDistanceOffsetCm?: number // cm
}

export interface IStationSensors {
    LidarDistance: number // cm
    Temperature: number // °C
    RelativeHumidity: number // %
    BatteryLevel: number // $
    GNSSLat: number
    GNSSLon: number
}
