import React, { FC, PureComponent, useEffect, useState } from "react"
import { FilePond, registerPlugin } from "react-filepond"
import axios from "axios"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { getDeviceApiEndpoint } from "../../utils/deviceAPI"
import {
    ActualFileObject,
    FilePondErrorDescription,
    FilePondFile,
    FilePondInitialFile,
} from "filepond"

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
)

const UploadImage: FC<{
    pictureName: string | null
    setPictureName: (name: string | null) => void
    processURL: string
}> = ({ pictureName, processURL, setPictureName }) => {
    const [files, setFiles] = useState<FilePondInitialFile[]>([])

    useEffect(() => {
        if (pictureName === null) {
            setFiles([])
            return
        }
        setFiles([
            {
                source: pictureName,
                options: {
                    type: "local",
                },
            },
        ])
    }, [pictureName])

    return (
        <FilePond
            name="pics" // filepond will send the file as multipart-formdata with this key
            files={files}
            onprocessfile={(error: any, file: FilePondFile) => {
                console.log("onprocessfile", error, file)
                if (error) {
                    return
                }
                let _initialFiles: FilePondInitialFile[] = []
                if (file.file !== null) {
                    _initialFiles.push({
                        source: file.serverId,
                        options: {
                            type: "local",
                        },
                    })
                }
                setFiles(_initialFiles)
                if (file === undefined) {
                    setPictureName(null)
                } else {
                    setPictureName(file.serverId)
                }
            }}
            acceptedFileTypes={["image/*"]}
            allowMultiple={false}
            maxFiles={1}
            server={{
                url: getDeviceApiEndpoint(),
                process: {
                    url: processURL,
                    method: "POST",
                    withCredentials: false,
                    timeout: 7000,
                },
                revert: {
                    url: processURL,
                    method: "DELETE",
                    withCredentials: false,
                    timeout: 7000,
                },
                remove: async (load, error) => {
                    try {
                        await axios.delete(getDeviceApiEndpoint() + processURL)
                        setPictureName(null)
                    } catch (err) {
                        let msg = `Error while deleting picture: ${err}`
                        console.error(msg)
                        error()
                    }
                },
                load: {
                    url: "/uploads/",
                    method: "GET",
                    withCredentials: false,
                    timeout: 7000,
                },
            }}
            // labelIdle=""
        />
    )
}

export default UploadImage
