import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import {
    COLOR_BG_ROW_DELETED,
    ENTITY_SAVE_DEBOUNCE_DELAY_MS,
    MAX_WIDTH_CENTRAL_CONTAINER,
    parsePathForEntityUUID,
    pbUUIDToUuid,
    uuidToPbUUID,
} from "../utils/utils"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Dropdown,
    Input,
    Popconfirm,
    Row,
    Space,
    Spin,
    Table,
    Tag,
    Tooltip,
    Typography,
    message as antdMessage,
} from "antd"
import dayjs from "dayjs"
import Container from "../components/commons-ts/container"
import { Helmet } from "react-helmet"
import {
    EResultTag,
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import { ErrorAlert } from "../components/commons-ts/errorAlert"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import { Translated, translated } from "../utils/translated"
import {
    normTypeElement,
    methodElement,
    isLabTestElement,
} from "../components/commons-ts/tags"
import {
    DeleteOutlined,
    DoubleRightOutlined,
    FileAddOutlined,
    FilePdfOutlined,
    SaveOutlined,
} from "@ant-design/icons"
import { ColumnsType } from "antd/es/table"
import {
    getEquipmentResultSM,
    getEquipmentResultPFAdq,
    IEquipmentResult,
} from "../utils/maths"
import {
    Annex,
    Equipment,
    MethodTypeEN1177,
    NormType,
    Site,
    UUID,
} from "../generated/proto-ts/main"

import {
    useUsercommSiteAnnexesBLE,
    useUsercommCreateAnnexBLE,
    useUsercommCreateEquipmentBLE,
    useUsercommDeleteAnnexBLE,
    useUsercommDeleteSiteBLE,
    useUsercommSiteEquipmentsBLE,
    useUsercommSiteBLE,
    useUsercommSiteChildrenRecursiveBLE,
    useUsercommUpdateAnnexBLE,
    useUsercommUpdateSiteBLE,
    useUsercommDeleteSiteSoftBLE,
} from "../usercomm/usercommAsyncRequestBLE"
import { UploadImageListV2 } from "../components/commons-ts/UploadImageV2AD"
import { DataTreeDrawer } from "./TreeDrawer"

const AnnexWidget: FC<{
    annex: Annex | null
    idx: number
    getAnnexes: () => void
}> = ({ annex, idx, getAnnexes }) => {
    const [annexContent, setAnnexContent] = useState<string | null>(null)
    const [pictures, setPictures] = useState<UUID[]>([])

    const [updateAnnexResponse, updateAnnexRequest] =
        useUsercommUpdateAnnexBLE()
    const [deleteAnnexResponse, deleteAnnexRequest] =
        useUsercommDeleteAnnexBLE()

    useEffect(() => {
        if (annex === null) {
            return
        }
        setAnnexContent(annex.annex_content)
        setPictures(annex.pictures)
    }, [annex])

    // UPDATE ANNEX REQUEST
    const onUpdate = useCallback(async () => {
        if (annex === null) {
            return
        }
        let updatedAnnex = annex.clone()
        updatedAnnex.annex_idx = idx
        updatedAnnex.annex_content = annexContent ?? ""
        updatedAnnex.pictures = pictures
        console.log(
            `AnnexWidget: about to update annex`,
            updatedAnnex.toObject(),
        )
        updateAnnexRequest(updatedAnnex)
    }, [annex, annexContent, pictures, idx])
    // UPDATE ANNEX RESPONSE
    useEffect(() => {
        if (updateAnnexResponse === null) {
            return
        }
        console.log(
            `AnnexWidget: updateAnnexResponse`,
            updateAnnexResponse.toObject(),
        )
        getAnnexes()
    }, [updateAnnexResponse])

    // DELETE ANNEX REQUEST
    const onDelete = useCallback(async () => {
        if (annex === null) {
            return
        }
        deleteAnnexRequest(annex.uuid)
    }, [annex, idx])
    // DELETE ANNEX RESPONSE
    useEffect(() => {
        if (deleteAnnexResponse === null) {
            return
        }
        console.log(
            `AnnexWidget: deleteAnnexResponse`,
            deleteAnnexResponse.toObject(),
        )
        getAnnexes()
    }, [deleteAnnexResponse, idx])

    if (annex === null) {
        return <Spin size="large" />
    }

    return (
        <div>
            <Label>
                <Translated keyEn="Annex" /> #{idx}
            </Label>
            <FlexCol style={{ gap: 5 }}>
                <Space.Compact>
                    <Input
                        size="large"
                        value={annexContent ?? ""}
                        onChange={(e) => {
                            setAnnexContent(e.target.value)
                        }}
                        placeholder={translated("Annex name") ?? ""}
                        variant="filled"
                    />
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this annex" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button icon={<DeleteOutlined />} size="large" danger />
                    </Popconfirm>
                    <Button
                        icon={<SaveOutlined />}
                        onClick={onUpdate}
                        size="large"
                        type="primary"
                    />
                </Space.Compact>
                <UploadImageListV2
                    pictures={pictures}
                    setPictures={setPictures}
                />
            </FlexCol>
        </div>
    )
}

const SiteEquipmentsTableSM: FC<{
    siteUUID: UUID | null
    equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
    const [equipmentResultsMap, setEquipmentResultsMap] = useState<Record<
        string,
        IEquipmentResult
    > | null>(null)
    const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
        useUsercommSiteChildrenRecursiveBLE()

    useEffect(() => {
        if (siteUUID === null) {
            return
        }
        getEntitiesRecursive(siteUUID, true)
    }, [siteUUID, equipments])

    useEffect(() => {
        if (equipments === null) {
            return
        }
        let _equipmentResultsMap: Record<string, IEquipmentResult> = {}
        for (let equipment of equipments) {
            let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
            let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
            if (equipmentZones === undefined) {
                continue
            }
            let result = getEquipmentResultSM(
                equipment,
                equipmentZones,
                zoneImpactsMap,
            )
            if (result === null) {
                continue
            }
            _equipmentResultsMap[equipmentUUIDStr] = result
        }
        setEquipmentResultsMap(_equipmentResultsMap)
    }, [equipmentZonesMap, zoneImpactsMap])

    const columns = useMemo((): ColumnsType<Equipment> => {
        return [
            {
                key: "equipment",
                title: <Translated keyEn="Equipment" />,
                ellipsis: true,
                render: (_, equipment) => {
                    return (
                        <Link
                            to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {equipment.equipment_name}
                        </Link>
                    )
                },
            },
            {
                title: <Translated keyEn="Mat type" />,
                render: (_, equipment) => {
                    if (equipment.sports_mat_type === null) {
                        return <span>N/A</span>
                    }
                    return <span>Type {equipment.sports_mat_type}</span>
                },
            },
            {
                title: <Translated keyEn="Nb Zones" />,
                render: (_, equipment) => {
                    let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
                    let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
                    if (equipmentZones == undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let nbZones = 0
                    for (let zone of equipmentZones) {
                        if (zone.deleted_at === 0) {
                            nbZones++
                        }
                    }
                    return <span>{nbZones}</span>
                },
            },
            {
                title: <Translated keyEn="Nb Impacts" />,
                render: (_, equipment) => {
                    let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
                    let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
                    if (equipmentZones == undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let nbImpacts = 0
                    for (let zone of equipmentZones) {
                        if (zone.deleted_at === 0) {
                            let zoneImpacts =
                                zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
                            if (zoneImpacts === undefined) {
                                continue
                            }
                            for (let impact of zoneImpacts) {
                                if (impact.deleted_at === 0) {
                                    nbImpacts++
                                }
                            }
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "result",
                title: (
                    <Tooltip overlay={translated("Result")}>
                        <Translated keyEn="Result" />
                    </Tooltip>
                ),
                render: (_, equipment) => {
                    if (equipmentResultsMap === null) {
                        return null
                    }
                    let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
                    let result = equipmentResultsMap[equipmentUUIDStr]
                    if (result === undefined || result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTag result={result.result} />
                },
                fixed: "right",
            },
            {
                title: "",
                width: "2rem",
                render: (_, equipment) => {
                    return (
                        <Link
                            to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
                        >
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
                fixed: "right",
            },
        ]
    }, [equipments, equipmentZonesMap, zoneImpactsMap, equipmentResultsMap])

    return (
        <Table
            dataSource={equipments ?? []}
            loading={equipmentResultsMap === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(equipment) => {
                return {
                    style: {
                        backgroundColor:
                            equipment.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
        />
    )
}

const SiteEquipmentsTablePFAdq: FC<{
    siteUUID: UUID | null
    equipments: Equipment[] | null
}> = ({ siteUUID, equipments }) => {
    const [_, equipmentZonesMap, zoneImpactsMap, getEntitiesRecursive] =
        useUsercommSiteChildrenRecursiveBLE()

    useEffect(() => {
        if (siteUUID === null) {
            return
        }
        getEntitiesRecursive(siteUUID, true)
    }, [siteUUID, equipments])

    const columns = useMemo((): ColumnsType<Equipment> => {
        return [
            {
                key: "equipment",
                title: <Translated keyEn="Equipment" />,
                ellipsis: true,
                render: (_, equipment) => {
                    return (
                        <Link
                            to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {equipment.equipment_name}
                        </Link>
                    )
                },
            },
            {
                title: <Translated keyEn="Nb Zones" />,
                render: (_, equipment) => {
                    let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
                    let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
                    if (equipmentZones == undefined) {
                        return <span>N/A</span>
                    }
                    if (!equipmentZones) {
                        return <span>N/A</span>
                    }
                    let nbZones = 0
                    for (let zone of equipmentZones) {
                        if (zone.deleted_at === 0) {
                            nbZones++
                        }
                    }
                    return <span>{nbZones}</span>
                },
            },
            {
                key: "result",
                title: (
                    <Tooltip overlay={translated("Result")}>
                        <Translated keyEn="Result" />
                    </Tooltip>
                ),
                render: (_, equipment) => {
                    let equipmentUUIDStr = pbUUIDToUuid(equipment.uuid)
                    let equipmentZones = equipmentZonesMap[equipmentUUIDStr]
                    if (equipmentZones === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let result = getEquipmentResultPFAdq(
                        equipmentZones,
                        zoneImpactsMap,
                    )
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTag result={result.result} />
                },
                fixed: "right",
            },
            {
                title: "",
                width: "2rem",
                render: (_, equipment) => {
                    return (
                        <Link
                            to={`/equipments/${pbUUIDToUuid(equipment.uuid)}`}
                        >
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
                fixed: "right",
            },
        ]
    }, [equipments, equipmentZonesMap, zoneImpactsMap])

    return (
        <Table
            dataSource={equipments ?? []}
            loading={equipments === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(equipment) => {
                return {
                    style: {
                        backgroundColor:
                            equipment.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
        />
    )
}

const NewEquipmentButton: FC<{
    siteUUID: string | null
    equipmentName: string | null
}> = ({ siteUUID: siteUUID, equipmentName }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const history = useHistory()

    const [createEquipmentNewUUID, createEquipmentRequest] =
        useUsercommCreateEquipmentBLE()

    // CREATE EQUIPMENT REQUEST
    const onCreate = useCallback(async () => {
        setIsLoading(true)
        if (siteUUID === null) {
            throw new Error("siteUUID is null")
        }
        if (equipmentName === null) {
            throw new Error("equipmentName is null")
        }
        createEquipmentRequest(
            uuidToPbUUID(siteUUID),
            new Equipment({
                equipment_name: equipmentName,
            }),
        )
    }, [siteUUID, equipmentName])
    // CREATE EQUIPMENT RESPONSE
    useEffect(() => {
        if (createEquipmentNewUUID === null) {
            return
        }
        setIsLoading(false)
        if (createEquipmentNewUUID.value) {
            antdMessage.success(
                <>
                    Equipment <b>{equipmentName}</b> was successfully created!
                </>,
            )
            history.push(`/equipments/${pbUUIDToUuid(createEquipmentNewUUID)}`)
        }
    }, [createEquipmentNewUUID])

    return (
        <Button
            disabled={siteUUID === null || equipmentName === null}
            onClick={onCreate}
            loading={isLoading}
            size="large"
            type="primary"
        >
            <span
                style={{
                    textTransform: "uppercase",
                }}
            >
                <Translated keyEn="Start" />
            </span>
        </Button>
    )
}

export const SitePage: FC = () => {
    const location = useLocation()
    const history = useHistory()

    const memoSiteUUID = useMemo((): string | null => {
        return parsePathForEntityUUID(location.pathname)
    }, [location.pathname])

    const [site, getSite] = useUsercommSiteBLE()
    const [updateSiteAck, updateSite] = useUsercommUpdateSiteBLE()
    const [deleteSiteSoftAck, deleteSiteSoft] = useUsercommDeleteSiteSoftBLE()

    const [annexes, getAnnexes] = useUsercommSiteAnnexesBLE()
    const [createAnnexNewUUID, createAnnex] = useUsercommCreateAnnexBLE()

    const [equipments, getEquipments] = useUsercommSiteEquipmentsBLE()

    const [siteName, setSiteName] = useState<string | null>(null)
    const [client, setClient] = useState<string | null>(null)
    const [mission, setMission] = useState<string | null>(null)
    const [normType, setNormType] = useState<NormType | null>(null)
    const [methodType, setMethodType] = useState<MethodTypeEN1177 | null>(null)
    const [isLabTest, setIsLabTest] = useState<boolean | null>(null)
    const [address, setAddress] = useState<string | null>(null)
    const [executionDate, setExecutionDate] = useState<dayjs.Dayjs | null>(null)
    const [pictures, setPictures] = useState<Site["pictures"]>([])

    const [newEquipmentName, setNewEquipmentName] = useState<string | null>(
        null,
    )

    const [error, setError] = useState<string | null>(null)

    // SITE REQUEST
    useEffect(() => {
        if (memoSiteUUID === null) {
            return
        }
        getSite(memoSiteUUID)
    }, [memoSiteUUID])
    // SITE RESPONSE
    useEffect(() => {
        if (site == null) {
            return
        }
        console.log(`SitePage: site`, site.toObject())
        setSiteName(site.site_name)
        setClient(site.client_name)
        setMission(site.mission_name)
        setNormType(site.norm_type)
        setMethodType(site.method_type_en_1177)
        setIsLabTest(site.is_lab_test)
        setAddress(site.address)
        if (site.execution_date === 0) {
            setExecutionDate(null)
        } else {
            setExecutionDate(dayjs(site.execution_date))
        }
        setPictures(site.pictures)
    }, [site])

    // ANNEXES REQUEST
    useEffect(() => {
        if (memoSiteUUID === null) {
            return
        }
        getAnnexes(memoSiteUUID)
    }, [memoSiteUUID])
    // ANNEXES RESPONSE
    useEffect(() => {
        if (annexes !== null) {
            console.log(
                `SitePage: anexes`,
                annexes.map((a) => a.toObject()),
            )
        }
    }, [annexes])

    // EQUIPMENTS REQUEST
    useEffect(() => {
        if (memoSiteUUID === null) {
            return
        }
        getEquipments(uuidToPbUUID(memoSiteUUID))
    }, [memoSiteUUID])
    // EQUIPMENTS RESPONSE
    useEffect(() => {
        if (equipments === null) {
            return
        }
        console.log(
            `SitePage: equipments`,
            equipments.map((e) => e.toObject()),
        )
    }, [equipments])

    // CREATE ANNEX REQUEST
    const onAddAnnex = useCallback(async () => {
        if (memoSiteUUID === null) {
            return
        }
        let annex = new Annex({
            site_uuid: uuidToPbUUID(memoSiteUUID),
        })
        createAnnex(memoSiteUUID, annex)
    }, [memoSiteUUID])
    // NEW ANNEX RESPONSE
    useEffect(() => {
        if (createAnnexNewUUID === null) {
            return
        }
        if (createAnnexNewUUID.value) {
            antdMessage.success(<>New annex created!</>)
        } else {
            antdMessage.error(<>Could not create annex</>)
        }
        getAnnexes(memoSiteUUID)
    }, [createAnnexNewUUID])

    // DELETE SITE REQUEST
    const onDelete = useCallback(async () => {
        if (site === null) {
            return
        }
        deleteSiteSoft(site)
    }, [site])
    // DELETE SITE RESPONSE
    useEffect(() => {
        if (deleteSiteSoftAck === null) {
            return
        }
        antdMessage.info(`Site deleted!`)
        setTimeout(() => {
            history.push("/")
        }, 1000)
    }, [deleteSiteSoftAck])

    useEffect(() => {
        if (normType === NormType.EN_12503) {
            setMethodType(null)
            return
        }
        if (normType === NormType.EN_1177 && methodType === null) {
            setMethodType(MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION)
            return
        }
    }, [normType, methodType, isLabTest])

    const memoNormTypeElement = useMemo(() => {
        return normTypeElement(normType)
    }, [normType])

    const memoMethodElement = useMemo(() => {
        if (normType === null || normType === NormType.EN_12503) {
            return null
        }
        return methodElement(methodType)
    }, [normType, methodType])

    const memoIsLabTestElement = useMemo(() => {
        return isLabTestElement(isLabTest)
    }, [isLabTest])

    const memoEquipmentsTable = useMemo(() => {
        if (normType === null) {
            return null
        }
        if (normType === NormType.EN_12503) {
            return (
                <SiteEquipmentsTableSM
                    siteUUID={site ? site.uuid : null}
                    equipments={equipments}
                />
            )
        }
        return (
            <SiteEquipmentsTablePFAdq
                siteUUID={site ? site.uuid : null}
                equipments={equipments}
            />
        )
    }, [site, equipments, methodType])

    const getAnnexesCallback = useCallback(() => {
        getAnnexes(memoSiteUUID)
    }, [memoSiteUUID])

    const memoAnnexesElements = useMemo(() => {
        if (annexes === null) {
            return null
        }
        let children: ReactElement[] = []

        for (let i = 0; i < annexes.length; i++) {
            children.push(
                <AnnexWidget
                    annex={annexes[i]}
                    idx={i + 1}
                    getAnnexes={getAnnexesCallback}
                    key={i}
                />,
            )
            if (i !== annexes.length - 1) {
                children.push(<Divider key={`div-${i}`} />)
            }
        }
        return <div style={{ width: "100%" }}>{children}</div>
    }, [memoSiteUUID, annexes])

    const __siteMutableFields = [
        site,
        siteName,
        client,
        mission,
        normType,
        methodType,
        isLabTest,
        address,
        executionDate,
        pictures,
    ]

    const memoSiteIsMutated = useMemo(() => {
        if (site === null) {
            return false
        }
        if (
            site.site_name === null &&
            site.mission_name == null &&
            site.client_name === null
        ) {
            return
        }
        let prevExecutionDateInt: number | null = site.execution_date
        let currExecutionDateInt: number | null = null
        if (executionDate !== null) {
            currExecutionDateInt = executionDate.toDate().getTime()
        }
        let executionDateChanged = true
        if (
            (prevExecutionDateInt === null && currExecutionDateInt === null) ||
            prevExecutionDateInt === currExecutionDateInt
        ) {
            executionDateChanged = false
        }
        let prevPicturesStr = site.pictures
            .map((u) => pbUUIDToUuid(u))
            .join(",")
        let currPicturesStr = ""
        if (pictures !== null) {
            currPicturesStr = pictures.map((u) => pbUUIDToUuid(u)).join(",")
        }
        let isMutated =
            site.site_name !== (siteName ?? "") ||
            site.client_name !== (client ?? "") ||
            site.mission_name !== (mission ?? "") ||
            site.norm_type !== normType ||
            site.method_type_en_1177 !== methodType ||
            site.is_lab_test !== isLabTest ||
            site.address !== address ||
            executionDateChanged ||
            prevPicturesStr !== currPicturesStr
        if (isMutated) {
            console.log(
                `SitePage: isMutated:
                site: ${site.site_name} vs ${siteName}
                client: ${site.client_name} vs ${client}
                mission: ${site.mission_name} vs ${mission}
                norm_type: ${site.norm_type} vs ${normType}
                method: ${site.method_type_en_1177} vs ${methodType}
                is_lab_test: ${site.is_lab_test} vs ${isLabTest}
                address: ${site.address} vs ${address}
                execution_date: ${site.execution_date} vs ${executionDate}
                pictures: ${prevPicturesStr} vs ${currPicturesStr}
                `,
            )
        }
        return isMutated
    }, __siteMutableFields)

    // UPDATE SITE REQUEST
    const onUpdate = useCallback(async () => {
        if (memoSiteUUID === null || site === null) {
            return
        }
        let updatedSite = site.clone()
        updatedSite.site_name = siteName ?? ""
        updatedSite.client_name = client ?? ""
        updatedSite.mission_name = mission ?? ""
        updatedSite.norm_type = normType ?? NormType.EN_1177
        updatedSite.method_type_en_1177 =
            methodType ?? MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
        updatedSite.is_lab_test = isLabTest ?? false
        updatedSite.address = address ?? ""
        updatedSite.execution_date =
            executionDate !== null ? executionDate.toDate().getTime() : 0
        updatedSite.pictures = pictures ?? []
        console.log(`SitePage: about to update site`, updatedSite.toObject())
        updateSite(updatedSite)
    }, __siteMutableFields)
    // UPDATE SITE RESPONSE
    useEffect(() => {
        if (memoSiteUUID === null || updateSiteAck === null) {
            return
        }
        getSite(memoSiteUUID)
    }, [memoSiteUUID, updateSiteAck])

    useEffect(() => {
        if (memoSiteIsMutated === false) {
            return
        }
        const t = setTimeout(() => {
            onUpdate()
        }, ENTITY_SAVE_DEBOUNCE_DELAY_MS)
        return () => {
            clearTimeout(t)
        }
    }, [memoSiteIsMutated, ...__siteMutableFields])

    if (site === null) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <ErrorAlert error={error} />
                <SimplifiedBreadcrumb
                    previousItems={[]}
                    currentItem={{ label: siteName }}
                />
                <Row
                    justify="space-between"
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Col>
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Site to test" />
                            </Label>
                            <Typography.Text
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                {siteName}
                            </Typography.Text>
                        </FlexCol>
                    </Col>
                    <Col>
                        <FlexCol style={{ gap: 3 }}>
                            {memoNormTypeElement}
                            {memoMethodElement}
                            {memoIsLabTestElement}
                        </FlexCol>
                    </Col>
                </Row>
                {/* Site name */}
                <LabeledInput
                    label={translated("Site")}
                    value={siteName}
                    setValue={setSiteName}
                />
                {/* New equipment */}
                <div>
                    <Label>
                        <Translated keyEn="New equipment" />
                    </Label>
                    <Space.Compact
                        style={{
                            width: "100%",
                        }}
                    >
                        <Input
                            size="large"
                            value={newEquipmentName ?? ""}
                            onChange={(e) => {
                                setNewEquipmentName(e.target.value)
                            }}
                            placeholder={translated("New equipment") ?? ""}
                        />
                        <NewEquipmentButton
                            siteUUID={memoSiteUUID}
                            equipmentName={newEquipmentName}
                        />
                    </Space.Compact>
                </div>
                {/* Site equipments */}
                <div>
                    <Label>
                        <Translated keyEn="Equipments" />
                    </Label>
                </div>
                {memoEquipmentsTable}
                {/* Site picture */}
                <FlexCol style={{ gap: 0 }}>
                    <Label>
                        <Translated keyEn="Picture" />
                    </Label>
                    <UploadImageListV2
                        pictures={pictures}
                        setPictures={setPictures}
                    />
                </FlexCol>
                {/* Site data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Site" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label={translated("Client")}
                                value={client}
                                setValue={setClient}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label={translated("Mission")}
                                value={mission}
                                setValue={setMission}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                label={translated("Address")}
                                value={address}
                                setValue={setAddress}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Execution date" />
                                </Label>
                                <DatePicker
                                    size="large"
                                    value={executionDate}
                                    onChange={(value) => {
                                        setExecutionDate(value)
                                    }}
                                    variant="filled"
                                />
                            </FlexCol>
                        </Col>
                    </Row>
                </div>
                {/* Norm and method types swap */}
                <FlexCol
                    style={{
                        width: "100%",
                    }}
                >
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Site type" />
                    </UnderlinedSectionTitle>
                    {normType !== null && (
                        <FlexRow>
                            <Dropdown.Button
                                size="large"
                                style={{
                                    minWidth: "30rem",
                                }}
                                menu={{
                                    items: [
                                        {
                                            key: NormType.EN_1177,
                                            label: (
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    <Translated keyEn="Playground floor" />
                                                </span>
                                            ),
                                        },
                                        {
                                            key: NormType.EN_12503,
                                            label: (
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    <Translated keyEn="Sports mat" />
                                                </span>
                                            ),
                                        },
                                    ],
                                    onClick: (key) => {
                                        if (key === null) {
                                            return
                                        }
                                        let _normType = parseInt(key.key)
                                        setNormType(_normType as NormType)
                                        antdMessage.info(
                                            <>
                                                Changed norm type to{" "}
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    <b>
                                                        {_normType ===
                                                        NormType.EN_1177
                                                            ? "playground floors"
                                                            : "Sports mats"}
                                                    </b>
                                                </span>
                                            </>,
                                        )
                                    },
                                }}
                                onClick={() => {
                                    if (normType === null) {
                                        return
                                    }
                                    let _normType =
                                        normType === NormType.EN_1177
                                            ? NormType.EN_12503
                                            : NormType.EN_1177
                                    setNormType(_normType)
                                    antdMessage.info(
                                        <>
                                            Changed norm type to{" "}
                                            <span
                                                style={{
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                <b>
                                                    {_normType ===
                                                    NormType.EN_1177
                                                        ? "Playground floors"
                                                        : "Sports mats"}
                                                </b>
                                            </span>
                                        </>,
                                    )
                                }}
                            >
                                <span>
                                    <Translated keyEn="Change norm to" />
                                    {": "}
                                    {normType === NormType.EN_1177 ? (
                                        <b>
                                            <Translated keyEn="Sports Mat" />
                                        </b>
                                    ) : (
                                        <b>
                                            <Translated keyEn="Playground Floor" />
                                        </b>
                                    )}
                                </span>
                            </Dropdown.Button>
                        </FlexRow>
                    )}
                    {methodType !== null && (
                        <div>
                            <Dropdown.Button
                                size="large"
                                style={{
                                    minWidth: "15rem",
                                }}
                                menu={{
                                    items: [
                                        {
                                            key: MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
                                            label: (
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    <Translated keyEn="Method 1: Critical Fall Height" />
                                                </span>
                                            ),
                                        },
                                        {
                                            key: MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE,
                                            label: (
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    <Translated keyEn="Method 2: Adequacy" />
                                                </span>
                                            ),
                                        },
                                    ],
                                    onClick: (key) => {
                                        if (key === null) {
                                            return
                                        }
                                        let _methodType = parseInt(key.key)
                                        setMethodType(
                                            _methodType as MethodTypeEN1177,
                                        )
                                        antdMessage.info(
                                            <>
                                                Changed method type to{" "}
                                                <span
                                                    style={{
                                                        textTransform:
                                                            "uppercase",
                                                    }}
                                                >
                                                    <b>
                                                        {_methodType ===
                                                        MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION ? (
                                                            <Translated keyEn="Method 1: Critical Fall Height" />
                                                        ) : (
                                                            <Translated keyEn="Method 2: Adequacy" />
                                                        )}
                                                    </b>
                                                </span>
                                            </>,
                                        )
                                    },
                                }}
                                onClick={() => {
                                    if (methodType === null) {
                                        return
                                    }
                                    let _methodType =
                                        methodType ===
                                        MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
                                            ? MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE
                                            : MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
                                    setMethodType(_methodType)
                                    antdMessage.info(
                                        <>
                                            Changed method type to{" "}
                                            <span
                                                style={{
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                <b>
                                                    {_methodType ===
                                                    MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION ? (
                                                        <Translated keyEn="Method 1: Critical Fall Height" />
                                                    ) : (
                                                        <Translated keyEn="Method 2: Adequacy" />
                                                    )}
                                                </b>
                                            </span>
                                        </>,
                                    )
                                }}
                            >
                                <span>
                                    <Translated keyEn="Change method to" />
                                    {": "}
                                    {methodType ===
                                    MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION ? (
                                        <b>
                                            <Translated keyEn="Adequacy" />
                                        </b>
                                    ) : (
                                        <b>
                                            <Translated keyEn="Critical Fall Height" />
                                        </b>
                                    )}
                                </span>
                            </Dropdown.Button>
                        </div>
                    )}
                </FlexCol>
                {/* Annexes */}
                <UnderlinedSectionTitle>
                    <Translated keyEn="Annexes" />
                </UnderlinedSectionTitle>
                <div>
                    <FlexCol
                        style={{
                            alignItems: "start",
                            gap: 0,
                        }}
                    >
                        {memoAnnexesElements}
                        <Button
                            type="primary"
                            size="large"
                            onClick={onAddAnnex}
                            style={{
                                width: 200,
                            }}
                            icon={<FileAddOutlined />}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                <Translated keyEn="Add annex" />
                            </span>
                        </Button>
                    </FlexCol>
                </div>
                {/* Delete & Save */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        alignSelf: "flex-end",
                    }}
                >
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this site" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "0.8rem",
                                }}
                            >
                                <Translated keyEn="Delete" />
                            </span>
                        </Button>
                    </Popconfirm>
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="large"
                            block
                            disabled={!memoSiteIsMutated}
                            onClick={onUpdate}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                <Translated keyEn="Save" />
                            </span>
                        </Button>
                    </div>
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Link to={`/report/${memoSiteUUID}`}>
                            <Button
                                type="primary"
                                icon={<FilePdfOutlined />}
                                size="large"
                                block
                            >
                                <span
                                    style={{
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <Translated keyEn="Report" />
                                </span>
                            </Button>
                        </Link>
                    </div>
                </FlexRow>
            </FlexCol>
            <DataTreeDrawer site={site} selectedKey={memoSiteUUID} />
        </>
    )
}
