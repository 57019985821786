export interface NominatimReverseGeocodeResponse {
    place_id: number
    licence: string
    osm_type: string
    osm_id: number
    lat: string
    lon: string
    category: string
    type: string
    place_rank: number
    importance: number
    address_type: string
    name: string
    display_name: string
    address: {
        road: string
        neighbourhood: string
        suburb: string
        village: string
        city: string
        county: string
        municipality: string
        state_district: string
        state: string
        postcode: string
        country: string
        country_code: string
    }
    boundingbox: string[]
}

export const callNominatimReverseGeocode = async (
    lat: number,
    lon: number,
    zoom: number,
): Promise<NominatimReverseGeocodeResponse | null> => {
    let revGeocode: NominatimReverseGeocodeResponse | null = null
    let url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&zoom=${zoom}&format=json`
    let response = await fetch(url)
    let respText = await response.text()
    if (!response.ok) {
        console.warn(
            `NominatimReverseGeocode: nok: ${response.statusText}`,
            respText,
        )
        return revGeocode
    }
    try {
        revGeocode = JSON.parse(respText)
    } catch (e: any) {
        console.warn(
            `NominatimReverseGeocode: error decoding json: ${e.message}`,
            respText,
        )
    }
    return revGeocode
}
