import {
    CSSProperties,
    FC,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Link, Redirect, Route, Switch } from "react-router-dom"

import { HomePage } from "./pages/Home"
import { LoginPage } from "./pages/Login"

import {
    callCloudApiV2,
    getLocalStorageCloudApiUser,
    setLocalStorageCloudApiUser,
} from "./utils/cloudApiV2"
import { EquipmentPage } from "./pages/Equipment"
import { ImpactPage } from "./pages/Impact"
import { SettingsGeneral } from "./pages/Settings/SettingsGeneralPage"
import { SitePage } from "./pages/Site"
import { ZonePage } from "./pages/Zone"
import {
    HomeOutlined,
    ControlOutlined,
    BugOutlined,
    SettingOutlined,
    SwapOutlined,
    ReloadOutlined,
} from "@ant-design/icons"
import { Layout, Menu } from "antd"
import Sider from "antd/es/layout/Sider"
import { FlexCol, FlexRow } from "./components/commons-ts/common"
import { useMediaQuery } from "./utils/utils"
import { Header, Content, Footer } from "antd/es/layout/layout"
import { LoginWidget } from "./components/commons-ts/loginWidget"
import { ReactComponent as LugdosphereLogoSVG } from "./svg/text-logo.svg"
import { BleConnectPage } from "./pages/BLEConnectPage"
import { DevicesConnectionWidgetBLE } from "./components/commons-ts/deviceConnectionWidgetBLE"
import { DebugPage } from "./pages/DebugPage"
import { DeviceSensorsWidgetBLE } from "./components/commons-ts/deviceSensorsWidgetBLE"
import { UpdatePage } from "./pages/UpdatePage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHardDrive } from "@fortawesome/free-solid-svg-icons"
import { ReactComponent as HicIcon } from "./svg/hic-icon.svg"
import { SettingsDevice } from "./pages/Settings/SettingsDevicePage"
import { ReportPage } from "./pages/Report"
import { ICloudUser } from "./types"

const PrivateRoute: FC<{
    path: string
    children: ReactNode
}> = ({ children, path }) => {
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null)

    useEffect(() => {
        callCloudApiV2<ICloudUser>("/me")
            .then(({ resp, entity: user }) => {
                // Explicitly unauthorized
                if (resp.status === 401) {
                    console.log(
                        `PrivateRoute: explicitly unauthorized, logging out..`,
                    )
                    setLocalStorageCloudApiUser(null)
                    setIsAuthorized(false)
                    return
                }
                // Not unauthorized but null user
                if (user === null) {
                    console.log(
                        `PrivateRoute: not unauthorized but null user, will use the local storage user if any..`,
                    )
                    user = getLocalStorageCloudApiUser()
                    if (user === null) {
                        console.log(
                            `PrivateRoute: no local storage user either, logging out..`,
                        )
                        setLocalStorageCloudApiUser(null)
                        setIsAuthorized(false)
                        return
                    }
                }
                // Authorized and user OK (either from fetch or local storage)
                console.log(`PrivateRoute: connected as`, user.UUID)
                setLocalStorageCloudApiUser(user)
                setIsAuthorized(true)
            })
            .catch((err: any) => {
                // Fetch request error (e.g. network connection error)
                console.log(`PrivateRoute: fetch error`, err)
                let user = getLocalStorageCloudApiUser()
                if (user !== null) {
                    console.log(
                        `PrivateRoute: fetch error, using local storage user`,
                    )
                    setIsAuthorized(true)
                    return
                }
                // No remote user nor local storage user => not authorized
                setIsAuthorized(false)
            })
    }, [])

    if (isAuthorized === null) {
        return null
    }
    if (isAuthorized === false) {
        return <Redirect to="/login" />
    }

    return <Route path={path}>{children}</Route>
}

const SIDER_WIDTH = 250
const SIDER_COLLAPSED_WIDTH = 80

const AppMenu: FC<{
    isCollapsed: boolean
    setIsCollapsed: (isCollapsed: boolean) => void
}> = ({ isCollapsed, setIsCollapsed }) => {
    const isMobile = useMediaQuery()

    const memoSiderStyle = useMemo((): CSSProperties => {
        let style: CSSProperties = {
            overflow: "auto",
            position: "fixed",
            height: "100vh",
            left: 0,
            top: 0,
            bottom: 0,
        }
        if (isMobile) {
            style.width = "100%"
            style.right = 0
            style.top = undefined
            style.zIndex = 1000
            style.height = undefined
        }
        return style
    }, [isMobile])

    return (
        <Sider
            style={memoSiderStyle}
            collapsible={isMobile ? false : true}
            collapsed={isCollapsed}
            onCollapse={(collapsed) => {
                setIsCollapsed(collapsed)
            }}
            collapsedWidth={isMobile ? "100%" : undefined}
            width={isMobile ? "100%" : SIDER_WIDTH}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    gap: 0,
                }}
            >
                {!isMobile && (
                    <FlexCol
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 0,
                        }}
                    >
                        <FlexRow
                            style={{
                                height: "74px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Link to="/">
                                <div
                                    style={{
                                        backgroundColor: "#fff",
                                        height: 64 - 10,
                                        borderRadius: 32,
                                        margin: 10,
                                        display: "flex",
                                        alignItems: "center",
                                        transition: "all 0.25s ease-in-out",
                                    }}
                                >
                                    {isCollapsed ? (
                                        <img
                                            src="/icon512-casque-blue-on-transparent.png"
                                            width={64 - 10}
                                        />
                                    ) : (
                                        <LugdosphereLogoSVG
                                            style={{
                                                width: "100%",
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                            }}
                                        />
                                    )}
                                </div>
                            </Link>
                        </FlexRow>
                        {!isCollapsed && (
                            <span
                                style={{
                                    marginTop: -8,
                                    color: "#fff",
                                    fontSize: "1.1rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Lugsoft Webapp
                            </span>
                        )}
                    </FlexCol>
                )}
                <Menu
                    theme="dark"
                    mode={isMobile ? "horizontal" : "inline"}
                    inlineCollapsed={false}
                    style={{
                        width: "100%",
                        fontSize: "1.2rem",
                    }}
                    defaultActiveFirst
                    defaultOpenKeys={
                        isCollapsed
                            ? []
                            : ["menu-key-device", "menu-key-settings"]
                    }
                    items={[
                        {
                            key: "menu-key-home",
                            icon: <HomeOutlined />,
                            label: (
                                <Link to="/" style={{ textDecoration: "none" }}>
                                    Home
                                </Link>
                            ),
                        },
                        {
                            key: "menu-key-device-connect",
                            icon: <SwapOutlined />,
                            label: (
                                <Link
                                    to={`/connect`}
                                    style={{ textDecoration: "none" }}
                                >
                                    Connect
                                </Link>
                            ),
                        },
                        {
                            key: "menu-key-device",
                            icon: <FontAwesomeIcon icon={faHardDrive} />,
                            label: (
                                <Link
                                    to={`/settings/device`}
                                    style={{
                                        textDecoration: "none",
                                        color: "#fff",
                                    }}
                                >
                                    Device
                                </Link>
                            ),
                            children: [
                                {
                                    key: "menu-key-device-station",
                                    icon: (
                                        <FontAwesomeIcon icon={faHardDrive} />
                                    ),
                                    label: (
                                        <Link
                                            to={`/settings/device#station`}
                                            style={{
                                                textDecoration: "none",
                                                color: "#fff",
                                            }}
                                        >
                                            Station
                                        </Link>
                                    ),
                                },
                                {
                                    key: "menu-key-device-hic",
                                    icon: (
                                        <HicIcon
                                            width={24}
                                            style={{
                                                marginLeft: -3,
                                                marginBottom: -7,
                                                fill: "#fff",
                                            }}
                                        />
                                    ),
                                    label: (
                                        <Link
                                            to={`/settings/device#headhic`}
                                            style={{
                                                textDecoration: "none",
                                                color: "#fff",
                                            }}
                                        >
                                            Head HIC
                                        </Link>
                                    ),
                                },
                                {
                                    key: "menu-key-device-update",
                                    icon: <ReloadOutlined />,
                                    label: (
                                        <Link
                                            to={`/update`}
                                            style={{
                                                textDecoration: "none",
                                                color: "#fff",
                                            }}
                                        >
                                            Update
                                        </Link>
                                    ),
                                },
                            ],
                        },

                        {
                            key: "menu-key-settings",
                            icon: <SettingOutlined />,
                            label: "Settings",
                            children: [
                                {
                                    key: "menu-key-settings-general",
                                    icon: <ControlOutlined />,
                                    label: (
                                        <Link
                                            to={`/settings/general`}
                                            style={{ textDecoration: "none" }}
                                        >
                                            General
                                        </Link>
                                    ),
                                },
                                {
                                    key: "menu-key-debug",
                                    icon: <BugOutlined />,
                                    label: (
                                        <Link
                                            to={`/debug`}
                                            style={{
                                                textDecoration: "none",
                                                color: "#fff",
                                            }}
                                        >
                                            Debug
                                        </Link>
                                    ),
                                },
                            ],
                        },
                    ]}
                />
                <div />
            </div>
        </Sider>
    )
}

export const App: FC = () => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const isMobile = useMediaQuery()
    const minHeightOffset = isMobile ? 215 : 188

    let year = process.env.REACT_APP_YEAR
    if (year === undefined || year === "") {
        year = new Date().getFullYear().toString()
    }

    const memoSiderWidth = useMemo(() => {
        return isMobile ? 0 : isCollapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH
    }, [isMobile, isCollapsed])

    const memoHeaderContent = useMemo(() => {
        if (isMobile) {
            return (
                <div style={{ position: "relative" }}>
                    <FlexRow
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                            paddingRight: 20,
                        }}
                    >
                        <Link to="/">
                            <img
                                alt="logo"
                                src="/icon512-casque-blue-on-transparent.png"
                                width={64 - 10}
                            />
                        </Link>
                        <DevicesConnectionWidgetBLE />
                        <LoginWidget />
                    </FlexRow>
                </div>
            )
        } else {
            return (
                <FlexRow
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "100%",
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    <div
                        style={{
                            width: 50,
                            overflow: "visible",
                        }}
                    >
                        <DeviceSensorsWidgetBLE />
                    </div>
                    <DevicesConnectionWidgetBLE />
                    <LoginWidget />
                </FlexRow>
            )
        }
    }, [isMobile])

    return (
        <Switch>
            <Route path="/login">
                <LoginPage />
            </Route>
            <PrivateRoute path="/report/:site_uuid">
                <ReportPage />
            </PrivateRoute>
            <Route path="/">
                <Layout>
                    <AppMenu
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Layout
                        className="site-layout"
                        style={{
                            marginLeft: memoSiderWidth,
                            transition: `margin-left 0.25s ease-in-out`,
                        }}
                    >
                        <Header
                            style={{
                                padding: 0,
                                position: "sticky",
                                top: 0,
                                zIndex: 500,
                                lineHeight: "1em",
                                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            {memoHeaderContent}
                        </Header>

                        <Content
                            style={{
                                marginTop: 16,
                                marginLeft: 16,
                                marginRight: 16,
                                marginBottom: 24,
                                padding: isMobile ? 0 : 24,
                                overflow: "initial",
                                minHeight: `calc(100vh - ${minHeightOffset}px)`,
                            }}
                        >
                            <Switch>
                                <PrivateRoute path="/sites/:site_uuid">
                                    <SitePage />
                                </PrivateRoute>
                                <PrivateRoute path="/equipments/:equipment_uuid">
                                    <EquipmentPage />
                                </PrivateRoute>
                                <PrivateRoute path="/zones/:zone_uuid">
                                    <ZonePage />
                                </PrivateRoute>
                                <PrivateRoute path="/impacts/:impact_uuid">
                                    <ImpactPage />
                                </PrivateRoute>
                                <PrivateRoute path="/connect">
                                    <BleConnectPage />
                                </PrivateRoute>
                                <PrivateRoute path="/settings/general">
                                    <SettingsGeneral />
                                </PrivateRoute>
                                <PrivateRoute path="/settings/device">
                                    <SettingsDevice />
                                </PrivateRoute>
                                <PrivateRoute path="/debug">
                                    <DebugPage />
                                </PrivateRoute>
                                <PrivateRoute path="/update">
                                    <UpdatePage />
                                </PrivateRoute>
                                <PrivateRoute path="/">
                                    <HomePage />
                                </PrivateRoute>
                            </Switch>
                        </Content>
                        <Footer>
                            <FlexCol
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // marginBottom: "1rem",
                                    gap: 0,
                                }}
                            >
                                <span>
                                    Copyright © 2018 - {year}, Lugdosphère
                                </span>
                                <i>Ver. {process.env.REACT_APP_VER}</i>
                            </FlexCol>
                        </Footer>
                    </Layout>
                </Layout>
            </Route>
        </Switch>
    )
}
