import { FC, ReactElement, useEffect, useMemo, useState } from "react"
import {
    ICloudDevice,
    RemoteTree,
    ESiteMethodType,
    ESiteNormType,
} from "../../types"
import Table, { ColumnsType } from "antd/es/table"
import { Translated, translated } from "../../utils/translated"
import { Avatar, Button, Input, Popconfirm, Tooltip } from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faFlask } from "@fortawesome/free-solid-svg-icons"
import { Label } from "../../components/commons-ts/input"
import { MethodTypeEN1177, NormType, Site } from "../../generated/proto-ts/main"
import { callCloudApiV2, getCloudApiUserIsAdmin } from "../../utils/cloudApiV2"
import {
    COLOR_BG_GRAY,
    COLOR_BG_ROW_DELETED,
    pbUUIDToUuid,
} from "../../utils/utils"
import { DeleteFilled, FilePdfOutlined } from "@ant-design/icons"

const renderAsLink = (value: any, site: Site) => {
    return (
        <Link
            to={{
                pathname: `/sites/${pbUUIDToUuid(site.uuid)}`,
            }}
            style={{
                textDecoration: "none",
            }}
        >
            {value}
        </Link>
    )
}

export const SummarySitesTable: FC<{
    sites: Site[] | null
    setClonedSite: (site: Site | null) => void
    onDeleteSite: (site: Site) => void
}> = ({ sites, setClonedSite, onDeleteSite }) => {
    const [remoteDeviceMap, setRemoteDeviceMap] = useState<Record<
        ICloudDevice["SerialNumber"],
        ICloudDevice
    > | null>(null)
    const [filteredSites, setFilteredSites] = useState<Site[] | null>(null)
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {
        callCloudApiV2<ICloudDevice[]>("/devices").then(
            ({ entity: devices }) => {
                if (devices === null) {
                    return
                }
                let _remoteDeviceMap: Record<
                    ICloudDevice["SerialNumber"],
                    ICloudDevice
                > = {}
                for (let device of devices) {
                    _remoteDeviceMap[device.SerialNumber] = device
                }
                setRemoteDeviceMap(_remoteDeviceMap)
            },
        )
    }, [])

    useEffect(() => {
        if (sites === null) {
            setFilteredSites(null)
            return
        }
        if (searchValue === "") {
            setFilteredSites(sites)
            return
        }

        const t = setTimeout(() => {
            let _filteredSites: Site[] = []
            let normalizedSearchValue = searchValue.toLowerCase().normalize()
            for (let site of sites) {
                if (
                    site.mission_name
                        .toLowerCase()
                        .normalize()
                        .includes(normalizedSearchValue)
                ) {
                    _filteredSites.push(site)
                    continue
                }
                if (
                    site.site_name
                        .toLowerCase()
                        .normalize()
                        .includes(normalizedSearchValue)
                ) {
                    _filteredSites.push(site)
                    continue
                }
                if (
                    site.client_name
                        .toLowerCase()
                        .normalize()
                        .includes(normalizedSearchValue)
                ) {
                    _filteredSites.push(site)
                    continue
                }
            }
            setFilteredSites(_filteredSites)
        }, 250)
        return () => {
            clearTimeout(t)
        }
    }, [searchValue, sites])

    const memoIsAdmin = useMemo(() => {
        return getCloudApiUserIsAdmin()
    }, [])

    const columns = useMemo((): ColumnsType<Site> => {
        console.log(`SitesTable: columns useMemo`)
        return [
            {
                title: <Translated keyEn="Norm" />,
                width: "1rem",
                render: (_, site) => {
                    let el: ReactElement | null = null
                    switch (site.norm_type) {
                        case NormType.EN_1177:
                            el = (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Playground floor" />
                                    }
                                >
                                    <Avatar
                                        style={{
                                            color: "#cf1322", // red-7
                                            backgroundColor: "#ffccc7", // red-2
                                        }}
                                    >
                                        <Translated keyEn="PG" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                        case NormType.EN_12503:
                            el = (
                                <Tooltip
                                    overlay={<Translated keyEn="Sports mat" />}
                                >
                                    <Avatar
                                        style={{
                                            color: "#389e0d", // green-7
                                            backgroundColor: "#d9f7be", // green-2
                                        }}
                                    >
                                        <Translated keyEn="SM" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                    }
                    return el
                },
                sorter: (a, b) => {
                    return b.norm_type - a.norm_type
                },
                filters: [
                    {
                        text: <Translated keyEn="Playground floor" />,
                        value: ESiteNormType.PlaygroundFloor,
                    },
                    {
                        text: <Translated keyEn="Sports mat" />,
                        value: ESiteNormType.SportsMat,
                    },
                ],
                onFilter: (value, record) => {
                    return record.norm_type === value
                },
            },
            {
                title: <Translated keyEn="Method" />,
                width: "1rem",
                render: (_, site) => {
                    let el: ReactElement | null = null
                    switch (site.method_type_en_1177) {
                        default:
                            el = (
                                <Tooltip overlay="No method specified">
                                    <Avatar>NA</Avatar>
                                </Tooltip>
                            )
                            break
                        case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
                            el = (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Critical fall height" />
                                    }
                                >
                                    <Avatar
                                        style={{
                                            color: "#cf1322", // magenta-7
                                            backgroundColor: "#ffd6e7", // magenta-2
                                        }}
                                    >
                                        <Translated keyEn="CFH" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                        case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
                            el = (
                                <Tooltip
                                    overlay={<Translated keyEn="Adequacy" />}
                                >
                                    <Avatar
                                        style={{
                                            color: "#9254de", // purple-5
                                            backgroundColor: "#efdbff", // purple-2
                                        }}
                                    >
                                        <Translated keyEn="ADQ" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                    }
                    if (site.is_lab_test) {
                        el = (
                            <FlexRow
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {el}{" "}
                                <FontAwesomeIcon
                                    icon={faFlask}
                                    style={{
                                        color: "#f759ab", // magenta-5
                                    }}
                                />
                            </FlexRow>
                        )
                    }
                    return el
                },
                sorter: (a, b) => {
                    return (
                        (b.method_type_en_1177 ?? 0) -
                        (a.method_type_en_1177 ?? 0)
                    )
                },
                filters: [
                    {
                        text: <Translated keyEn="CFH" />,
                        value: ESiteMethodType.CriticalFallHeight,
                    },
                    {
                        text: <Translated keyEn="ADQ" />,
                        value: ESiteMethodType.Adequacy,
                    },
                ],
                onFilter: (value, record) => {
                    // If no method is specified, show the row
                    return (
                        !record.method_type_en_1177 ||
                        record.method_type_en_1177 === value
                    )
                },
            },
            {
                title: <Translated keyEn="Title" />,
                width: "30%",
                render: (_, site) => {
                    return (
                        <b>
                            {renderAsLink(
                                `${site.site_name} — ${site.mission_name} @ ${site.client_name}`,
                                site,
                            )}
                        </b>
                    )
                },
            },
            {
                title: <Translated keyEn="Device" />,
                render: (_, site) => {
                    let deviceSnStr = "N/A"
                    let deviceSnBigInt = BigInt(site.device_sn)
                    if (deviceSnBigInt !== BigInt(0)) {
                        deviceSnStr = deviceSnBigInt.toString(16)
                    }
                    if (
                        deviceSnBigInt !== BigInt(0) &&
                        remoteDeviceMap !== null
                    ) {
                        let remoteDevice = remoteDeviceMap[deviceSnStr]
                        if (remoteDevice !== undefined && remoteDevice.Name) {
                            deviceSnStr = remoteDevice.Name
                        }
                    }
                    return <i>{deviceSnStr}</i>
                    // return renderAsLink(deviceIDStr, site)
                },
                sorter: (a, b) => {
                    return (b.device_sn ?? 0) > (a.device_sn ?? 0) ? 1 : -1
                },
            },
            {
                title: <Translated keyEn="Date" />,
                render: (_, site) => {
                    if (site.execution_date === 0) {
                        let formattedDate = dayjs(site.created_at).format(
                            "YYYY-MM-DD",
                        )
                        return <i>{formattedDate}</i>
                        // return renderAsLink(<i>{formattedDate}</i>, site)
                    } else {
                        let formattedDate = dayjs(site.execution_date).format(
                            "YYYY-MM-DD",
                        )
                        return formattedDate
                        // return renderAsLink(formattedDate, site)
                    }
                },
                sorter: (a, b) => {
                    let dateA = a.execution_date ?? a.created_at
                    let dateB = b.execution_date ?? b.created_at
                    return dayjs(dateB).unix() - dayjs(dateA).unix()
                },
            },
            {
                title: "",
                fixed: "right",
                width: "1rem",
                render: (_, site) => {
                    return (
                        <FlexRow
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 5,
                            }}
                        >
                            {/* Report */}
                            <Tooltip
                                overlay={<Translated keyEn="Generate report" />}
                            >
                                <Link to={`/report/${pbUUIDToUuid(site.uuid)}`}>
                                    <Button
                                        type="link"
                                        icon={<FilePdfOutlined />}
                                    />
                                </Link>
                            </Tooltip>
                            {/* Duplicate */}
                            <Tooltip overlay={<Translated keyEn="Duplicate" />}>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    onClick={() => {
                                        setClonedSite(site)
                                    }}
                                    size="lg"
                                    style={{
                                        color: COLOR_BG_GRAY,
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                            {/* Delete */}
                            <Tooltip overlay={<Translated keyEn="Delete" />}>
                                <Popconfirm
                                    title="Are you sure to delete this site?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => {
                                        onDeleteSite(site)
                                    }}
                                >
                                    <Button
                                        danger
                                        icon={<DeleteFilled />}
                                        type="text"
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </FlexRow>
                    )
                },
            },
        ]
    }, [memoIsAdmin, remoteDeviceMap])

    return (
        <FlexCol>
            <FlexCol style={{ gap: 0 }}>
                <Label>
                    <Translated keyEn={"Universal search"} />
                </Label>
                <Input
                    size="large"
                    value={searchValue ?? ""}
                    placeholder={
                        translated("Search for mission, site, client..") ?? ""
                    }
                    onChange={(value) => {
                        setSearchValue(value.target.value)
                    }}
                    variant="filled"
                />
            </FlexCol>
            <Table
                dataSource={filteredSites ?? []}
                loading={sites === null}
                columns={columns}
                pagination={{
                    defaultPageSize: 20,
                    hideOnSinglePage: true,
                }}
                rowKey="uuid"
                scroll={{ x: true }}
                bordered
                onRow={(site) => {
                    return {
                        style: {
                            backgroundColor:
                                site.deleted_at !== 0
                                    ? COLOR_BG_ROW_DELETED
                                    : "",
                        },
                    }
                }}
            />
        </FlexCol>
    )
}
